import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { rootSelector } from 'app/services/store/rootReducer'
import { UsersActions, UsersSelectors } from 'app/services/store'

import ChangePasswordPage from 'app/components/pages/login/ChangePasswordPage'

const ChangePasswordContainer: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const isLoading = UsersSelectors.isLoading(useSelector(rootSelector))

  const changePassword = useCallback(
    (newPassword: string, confirmPassword: string): void => {
      dispatch(UsersActions.changePasswordRequest({ newPassword, confirmPassword }))
    },
    [dispatch],
  )

  useEffect(() => {
    dispatch(UsersActions.passwordValidateRequest())
  }, [dispatch])

  return <ChangePasswordPage isLoading={isLoading} changePassword={changePassword} />
}

export default ChangePasswordContainer
