import { PayloadAction, Action } from '@reduxjs/toolkit'
import { ofType } from 'redux-observable'
import { of, Observable, EMPTY, concat, forkJoin } from 'rxjs'
import { map, catchError, switchMap, concatMap, first, mergeMap } from 'rxjs/operators'

import {
  ProjectSelectors,
  DatastoreSelectors,
  ItemsDetailsActions,
  ItemsSelectors,
  ItemDetailsSelectors,
  ItemsActions,
  UsersSelectors,
  WorkspaceSelectors,
  ProjectActions,
  DatastoreActions,
  UsersActions,
} from '..'
import HttpService from 'app/services/httpService/httpService'
import * as apiModels from './epic.types'
import ApiHelper from 'app/utils/apiHelper'
import { ItemDetailMode, LinkedAllDbsItems } from './types'
import ActionHelper from 'app/utils/actionHelper'
import { ItemDetailsState } from './state'

import { orderError } from 'app/constants/inventory'
import { errorMessages } from 'app/constants/system'

/**
 * @action HTTP
 * @in [ItemsDetails] getAutoNumberEpic
 * @param {string} field_Id flag if we wish or not to keep the field from the itemDetails
 * or reset them
 * @param {string} supplier_Id flag if we wish or not to keep the field from the itemDetails
 * or reset them
 *
 * @description Request to enter in new item mode. This will retrieve an new itemId from the API and
 * use the ItemDetails in new item Mode
 *
 * @outSuccess [ItemsDetails] getAutoNumberSuccess
 *
 * @outFailed [ItemsDetails] getAutoNumberFailed
 */
const getAutoNumberEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsDetailsActions.getAutoNumberRequest),
    switchMap((action: { payload: { field_Id: string; supplier_Id: string }; navigatePath: string }) => {
      const pId = ProjectSelectors.getCurrentProjectId(state$.value)
      const datastore_id = DatastoreSelectors.getCurrentDatastoreId(state$.value)
      const field_Id = action.payload.field_Id
      const supplier_Id = action.payload.supplier_Id + '-'
      return HttpService.GetAsync<null, { autoNumber: any }>(
        `applications/${pId}/datastores/${datastore_id}/fields/${field_Id}/autonum?branch_key=${supplier_Id}&zero_padding=true&digit=4`,
        null,
        HttpService.LinkerAPIBasePath,
      ).pipe(
        map(result => {
          const autoNumberItem = result.data
          return ItemsDetailsActions.getAutoNumberSuccess({
            autoNumberItem: result.data,
          })
        }),
        catchError((error: string) => {
          return of(ItemsDetailsActions.getAutoNumberFailed({ error }))
        }),
      )
    }),
  )

/**
 * @action HTTP
 * @in [ItemsDetails] getDatastoreFieldsEpic
 * @param {string} d_id flag if we wish or not to keep the field from the itemDetails
 * or reset them
 *
 * @description Request to enter in new item mode. This will retrieve an new itemId from the API and
 * use the ItemDetails in new item Mode
 *
 * @outSuccess [ItemsDetails] getDatastoreFieldsSuccess
 *
 * @outFailed [ItemsDetails] getDatastoreFieldsFailed
 */
const getDatastoreFieldsEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsDetailsActions.getDatastoreFieldsRequest),
    switchMap((action: { payload: { d_Id: string }; navigatePath: string }) => {
      const pId = ProjectSelectors.getCurrentProjectId(state$.value)
      const datastore_id = action.payload.d_Id
      return HttpService.GetAsync<null, { dataStoreItemField: any }>(
        `applications/${pId}/datastores/${datastore_id}/fields`,
        null,
        HttpService.LinkerAPIBasePath,
      ).pipe(
        map(result => {
          const datastoreFieldsItem = result.data
          return ItemsDetailsActions.getDatastoreFieldsSuccess({
            dataStoreItemField: result.data,
          })
        }),
        catchError((error: string) => {
          return of(ItemsDetailsActions.getDatastoreFieldsFailed({ error }))
        }),
      )
    }),
  )

/**
 * @action HTTP
 * @in [ItemsDetails] getItemDetailsRequest
 *
 * @description Load the item details from the API, build the entries and
 * set all in the store
 *
 * @outSuccess [ItemsDetails] getItemDetailsSuccess
 * @outFailed [ItemsDetails] getItemDetailsFailed
 */
const getItemDetailsEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsDetailsActions.getItemDetailsRequest),
    switchMap((action: PayloadAction<{ itemId: string }>) => {
      const datastore_id = DatastoreSelectors.getCurrentDatastoreId(state$.value)
      const project_id = ProjectSelectors.getCurrentProjectId(state$.value)
      return HttpService.PostAsync<
        apiModels.api_aggregated_api_itemdetails_request,
        apiModels.api_aggregated_api_itemdetails_response
      >(
        `aggregated_api_itemdetails`,
        {
          get_datastore_item_details: {
            datastore_id,
            item_id: action.payload.itemId,
          },
          get_item_posts: {
            datastore_id,
            project_id,
            item_id: action.payload.itemId,
            from_item_index: 0,
            to_item_index: 20,
          },
        },
        HttpService.HexaLinkBasePath,
      ).pipe(
        map(result => {
          const items = [result.data.get_datastore_item_details.itemDetails]
          const fields = Object.values(result.data.get_datastore_item_details.fields)
          const entry = ApiHelper.buildEntries(items, fields)[0]
          const statusList = result.data.get_datastore_item_details.statuses

          return ItemsDetailsActions.getItemDetailsSuccess({
            entry: entry,
            labels: result.data.get_datastore_item_details.labels,
            histories: result.data.get_item_posts.histories,
            unread: result.data.get_item_posts.unread,
            fields: result.data.get_datastore_item_details.fields,
            layout: result.data.get_datastore_item_details.field_layout,
            statusActions: result.data.get_datastore_item_details.actions,
            relations: result.data.get_datastore_item_details.related_datastores,
            actions: result.data.get_datastore_item_details.stateflowActions,
            statuses: {
              statuses: result.data.get_datastore_item_details.statuses,
              statusOrderSettings: result.data.get_datastore_item_details.statusOrderSettings,
            },
            titles: result.data.get_datastore_item_details.titles,
            mode: ItemDetailMode.DISPLAY,
          })
        }),
        catchError((error: string) => {
          return of(ItemsDetailsActions.getItemDetailsFailed({ error }))
        }),
      )
    }),
  )

/**
 * @action LINEAR
 * @in [ItemsDetails] selectAction
 * @param {string} actionId the id of the action to select
 * @description Depending on the type of datastore action, dispatch the approriated
 * action to the store
 *
 * @outADD [ItemsDetails] newItemModeRequest
 * @outADD [ItemsDetails] getActionSettingsRequest
 * @outADD [ItemsDetails] setMode
 *
 * @outEDIT [ItemsDetails] getActionSettingsRequest
 * @outEDIT [ItemsDetails] setMode
 *
 * @outDELETE [ItemsDetails] deleteItemRequest
 *
 * @outCOPY [ItemsDetails] newItemModeRequest
 * @outCOPY [ItemsDetails] getActionSettingsRequest
 * @outCOPY [ItemsDetails] setMode
 */
const selectActionEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsDetailsActions.selectAction),
    concatMap((action: PayloadAction<{ actionId: string }>) => {
      const sAction = ItemDetailsSelectors.getActionById(state$.value)(action.payload.actionId)
      switch (sAction.operation) {
        case ActionHelper.actionTypes.ADD:
          return [
            ItemsDetailsActions.newItemModeRequest({ keepFieldsValue: false }),
            ItemsDetailsActions.getActionSettingsRequest({ actionId: action.payload.actionId }),
            ItemsDetailsActions.setMode({ mode: ItemDetailMode.NEW }),
          ]
        case ActionHelper.actionTypes.EDIT:
          return [
            ItemsDetailsActions.getActionSettingsRequest({ actionId: action.payload.actionId }),
            ItemsDetailsActions.setMode({ mode: ItemDetailMode.UPDATE }),
          ]
        case ActionHelper.actionTypes.DELETE:
          return [
            ItemsActions.deleteItemRequest({
              itemId: ItemsSelectors.getCurrentItemId(state$.value),
              actionId: action.payload.actionId,
            }),
          ]
        case ActionHelper.actionTypes.COPY:
          return [
            ItemsDetailsActions.newItemModeRequest({ keepFieldsValue: true }),
            ItemsDetailsActions.getActionSettingsRequest({ actionId: action.payload.actionId }),
            ItemsDetailsActions.setMode({ mode: ItemDetailMode.COPY }),
          ]
        default:
          return EMPTY
      }
    }),
  )

/**
 * @action HTTP
 * @in [ItemsDetails] newItemModeRequestEpic
 * @param {boolean} keepFieldsValue flag if we wish or not to keep the field from the itemDetails
 * or reset them
 *
 * @description Request to enter in new item mode. This will retrieve an new itemId from the API and
 * use the ItemDetails in new item Mode
 *
 * @outSuccess [ItemsDetails] newItemModeSuccess
 *
 * @outFailed [ItemsDetails] newItemModeFailed
 */
const newItemModeRequestEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsDetailsActions.newItemModeRequest),
    switchMap((action: PayloadAction<{ keepFieldsValue: boolean }>) => {
      const newFieldsValues: { [k: string]: any } = {}
      const fields = ItemDetailsSelectors.getFields(state$.value)
      const entry = ItemDetailsSelectors.getEntry(state$.value)
      const keepFields = action.payload.keepFieldsValue
      for (const key in fields) {
        if (Object.prototype.hasOwnProperty.call(fields, key)) {
          newFieldsValues[key] = keepFields ? entry.fields[key] : ''
        }
      }
      return HttpService.GetAsync<null, { item_id: string }>(`get_new_item_id`).pipe(
        map(result => {
          return ItemsDetailsActions.newItemModeSuccess({
            newItemId: result.data.item_id,
            fields: newFieldsValues,
          })
        }),
        catchError((error: string) => {
          return of(ItemsDetailsActions.newItemModeFailed({ error }))
        }),
      )
    }),
  )

/**
 * @action HTTP
 * @in [ItemsDetails] getActionSettingsRequest
 * @param {string} actionId the id of the action we wish to get the settings from
 *
 * @description Execute an API call in order to retrieve the settings of an action
 *
 * @outSuccess [ItemsDetails] getActionSettingsSuccess
 *
 * @outFailed [ItemsDetails] getActionSettingsFailed
 */
const getActionSettingsRequestEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsDetailsActions.getActionSettingsRequest),
    switchMap((action: { payload: { actionId: string } }) => {
      return HttpService.GetAsync<
        apiModels.get_action_and_field_settings_request,
        apiModels.get_action_and_field_settings_response
      >(
        `get_action_and_field_settings`,
        {
          action_id: action.payload.actionId,
        },
        HttpService.HexacloudBasePath,
      ).pipe(
        map(result => {
          return ItemsDetailsActions.getActionSettingsSuccess({
            action: {
              ...result.data.action,
              action_field_settings: result.data.action_field_settings,
            },
          })
        }),
        catchError((error: string) => {
          return of(ItemsDetailsActions.getActionSettingsFailed({ error }))
        }),
      )
    }),
  )

/**
 * @action HTTP
 * @in [ItemsDetails] newItemRequestEpic
 * @param {string} actionId the id of the action we wish to get the settings from
 * @param {{[k: string]: any}} fields the new items fields
 *
 * @description Execute an API call to create a new item, add this entry to the items list
 * and close the item details
 *
 * @outSuccess [Items] addEntry
 * @outSuccess [ItemsDetails] setMode
 *
 * @outFailed [ItemsDetails] getActionSettingsFailed
 */
const newItemRequestEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsDetailsActions.newItemRequest),
    switchMap((action: PayloadAction<{ actionId: string; fields: { [k: string]: any } }>) => {
      const datastoreId = DatastoreSelectors.getCurrentDatastoreId(state$.value)
      const projectId = ProjectSelectors.getCurrentProjectId(state$.value)
      const workspaceId = WorkspaceSelectors.getCurrentWorkspaceId(state$.value)
      const itemId = ItemDetailsSelectors.getEntryId(state$.value)
      const userId = UsersSelectors.getUserId(state$.value)
      const username = UsersSelectors.getUsername(state$.value)

      return HttpService.PostAsync<apiModels.new_item_record_request, apiModels.new_item_record_response>(
        `new_item_record`,
        {
          a_id: action.payload.actionId,
          d_id: datastoreId,
          p_id: projectId,
          item: {
            d_id: datastoreId,
            p_id: projectId,
            ...action.payload.fields,
          },
          item_id: itemId,
          user_id: userId,
          w_id: workspaceId as string,
        },
        HttpService.HexacloudBasePath,
      ).pipe(
        switchMap(result => {
          const buildChanges = ApiHelper.getFieldsAsArray(action.payload.fields)
          const entry = ApiHelper.changeEntryField(
            buildChanges,
            ItemDetailsSelectors.getEntry(state$.value),
            ItemDetailsSelectors.getFields(state$.value),
          )
          return [
            ItemsActions.addEntry({
              entry: {
                access_keys: '',
                created_at: Date.now().toString(),
                created_by: username,
                fields: entry,
                d_id: datastoreId,
                i_id: result.data.id,
                p_id: projectId,
                rev_no: 0,
                title: '',
                unread: '0',
                updated_at: Date.now().toString(),
                updated_by: username,
                _id: result.data.id,
              },
            }),
            ItemsDetailsActions.setMode({ mode: ItemDetailMode.CLOSE }),
          ]
        }),
        catchError((error: string) => {
          return of(ItemsDetailsActions.getActionSettingsFailed({ error }))
        }),
      )
    }),
  )

/**
 * @action HTTP
 * @in [ItemsDetails] updateItemRequest
 * @param {string} actionId the id of the action we wish to get the settings from
 * @param {{[k: string]: any}} fields the updated items fields
 *
 * @description Execute an API call to update an item, update it in the items list
 * change the revision number and close the itemsdetails
 *
 * @outSuccess [ItemsDetails] updateItemSuccess
 * @outSuccess [Items] updateEntry
 * @outSuccess [ItemsDetails] setRevisionNumber
 * @outSuccess [ItemsDetails] setMode
 *
 * @outFailed [ItemsDetails] updateItemFailed
 */
const updateItemRequestEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsDetailsActions.updateItemRequest),
    switchMap((action: PayloadAction<{ actionId: string; changes: { [k: string]: any } }>) => {
      const buildChanges = ApiHelper.getFieldsAsArray(action.payload.changes)
      return HttpService.PostAsync<
        apiModels.update_post_item_history_request,
        apiModels.update_post_item_history_response
      >(
        `update_post_item_history`,
        {
          action_id: action.payload.actionId,
          history: {
            datastore_id: DatastoreSelectors.getCurrentDatastoreId(state$.value),
            item_id: ItemsSelectors.getCurrentItemId(state$.value),
            comment: '',
          },
          changes: buildChanges,
          rev_no: ItemDetailsSelectors.getRevisionNumber(state$.value),
        },
        HttpService.HexacloudBasePath,
      ).pipe(
        switchMap(result => {
          const entry = ApiHelper.changeEntryField(
            buildChanges,
            ItemDetailsSelectors.getEntry(state$.value),
            ItemDetailsSelectors.getFields(state$.value),
          )
          return [
            ItemsDetailsActions.updateItemSuccess({ changes: buildChanges }),
            ItemsActions.updateEntry({
              fieldId: ItemsSelectors.getCurrentItemId(state$.value),
              changes: { fields: entry },
            }),
            ItemsDetailsActions.setRevisionNumber({ revisionNumber: result.data.error.rev_no }),
            ItemsDetailsActions.setMode({ mode: ItemDetailMode.DISPLAY }),
          ]
        }),
        catchError((error: string) => {
          return of(ItemsDetailsActions.updateItemFailed({ error }))
        }),
      )
    }),
  )

/**
 * @action LINEAR
 * @in [ItemsDetails] goToItem
 * @param {string} projectId    Id of the item's project we wish to go to
 * @param {string} datastoreId  Id of the item's datastore we wish to go to
 * @param {string} itemId       Id of the item we wish to go to
 *
 * @description Go to an item in another project or datastore. If required, also load those project and
 * datastore
 *
 * @outSuccess [Project] setCurrentProjectId
 * @outSuccess [Datastore] setCurrentDatastoreId
 * @outSuccess [Items] setCurrentItemId
 */

const goToItemEpic = (action$, state$): Observable<any> =>
  action$.pipe(
    ofType(ItemsDetailsActions.goToItem),
    concatMap((action: { payload: { projectId: string; datastoreId: string; itemId: string } }) => {
      const projectId = ProjectSelectors.getCurrentProjectId(state$.value)
      if (action.payload.projectId !== projectId) {
        return concat(
          of(ProjectActions.setCurrentProjectId({ projectId: action.payload.projectId })),
          action$.pipe(
            ofType(DatastoreActions.getDatastoresSuccess),
            first(),
            switchMap(() =>
              of(
                DatastoreActions.setCurrentDatastoreId({ datastoreId: action.payload.datastoreId }),
                ItemsActions.setCurrentItemId({ itemId: action.payload.itemId }),
              ),
            ),
          ),
        )
      } else {
        return [
          DatastoreActions.setCurrentDatastoreId({ datastoreId: action.payload.datastoreId }),
          ItemsActions.setCurrentItemId({ itemId: action.payload.itemId }),
        ]
      }
    }),
  )

const getPDFEpicRequest = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsDetailsActions.getPDF),
    switchMap((action: PayloadAction<{ pdfId: string; fileName: string }>) => {
      const pdfId = action.payload.pdfId
      const fileName = action.payload.fileName
      return HttpService.GetAsync<null, ArrayBuffer>(`files/${pdfId}`, null, HttpService.LinkerAPIBasePath, {
        responseType: 'arraybuffer',
      }).pipe(
        map(response => {
          const blob = response.data
          const url = window.URL.createObjectURL(
            new Blob([blob], {
              type: 'application/pdf',
            }),
          )

          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          document.body.removeChild(link)

          return ItemsDetailsActions.getPDFSucess()
        }),
        catchError((error: string) => {
          return of(ItemsDetailsActions.getPDFFailed({ error }))
        }),
      )
    }),
  )

const updateCommentHistoryEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsDetailsActions.updateCommentHistoryRequest),
    concatMap(
      (action: {
        payload: {
          comment: string
        }
      }) => {
        return HttpService.PostAsync<apiModels.updateCommentHistoryRequest, null>(
          `datastores/${DatastoreSelectors.getCurrentDatastoreId(state$.value)}/items/histories`,
          {
            project_id: ProjectSelectors.getCurrentProjectId(state$.value),
            item_id: ItemsSelectors.getCurrentItemId(state$.value),
            comment: action.payload.comment,
          },
          HttpService.LinkerAPIBasePath,
        ).pipe(
          concatMap(response => {
            return of(ItemsDetailsActions.updateCommentHistorySuccess(), ItemsDetailsActions.getHistoriesRequest())
          }),
          catchError((error: string) => {
            return of(ItemsActions.postActionFailed({ error }))
          }),
        )
      },
    ),
  )

const getHistoriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsDetailsActions.getHistoriesRequest),
    concatMap(() => {
      const datastore_id = DatastoreSelectors.getCurrentDatastoreId(state$.value)
      const item_id = ItemsSelectors.getCurrentItemId(state$.value)
      return HttpService.GetAsync<null, apiModels.historiesResponse>(
        `datastores/${datastore_id}/items/${item_id}/histories`,
        null,
        HttpService.LinkerAPIBasePath,
      ).pipe(
        map(response => {
          return ItemsDetailsActions.getHistoriesSuccess({
            histories: response.data.histories,
            unread: response.data.unread,
          })
        }),
        catchError((error: string) => {
          return of(ItemsDetailsActions.getHistoriesFailed({ error }))
        }),
      )
    }),
  )

const createItemEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsDetailsActions.createItemRequest),
    switchMap(
      (action: {
        payload: {
          dId: string
          item: { [key: string]: any }
          navigatePath: string | undefined
          update: string | undefined
          updateParams: { [key: string]: any } | undefined
          lastFlag: boolean
          action_Id: string
        }
      }) => {
        const pId = ProjectSelectors.getCurrentProjectId(state$.value)
        return HttpService.PostAsync<{ item: { [key: string]: any } }, any>(
          `/applications/${pId}/datastores/${action.payload.dId}/items/new`,
          {
            item: action.payload.item,
          },
          HttpService.LinkerAPIBasePath,
        )
          .pipe(
            mergeMap(response => {
              if (action.payload.update != null && action.payload.updateParams != null) {
                if (action.payload.update == 'linkedDB') {
                  const iId = action.payload.updateParams.iId
                  const relations = action.payload.updateParams.relations
                  const dId = action.payload.updateParams.dId
                  return of(
                    ItemsDetailsActions.createItemSuccess(),
                    ItemsDetailsActions.getLinkedAllDbsItemsRequest({ iId, relations, dId }),
                  )
                }
              }

              if (action.payload.action_Id && action.payload.lastFlag) {
                // 納品書作成画面で最後のアイテム登録後にaction_Idでステータス変更を行い、
                // Hexabase側のアクションスクリプトでSVFを実行し、PDFを作成する。
                const item_id = response.data.item_id
                const changes = []
                return of(
                  ItemsDetailsActions.createItemSuccess(),
                  ItemsActions.postExecuteActionRequest({
                    itemId: item_id,
                    datastoreId: action.payload.dId,
                    actionId: action.payload.action_Id,
                    changes,
                  }),
                )
              } else {
                return action.payload.navigatePath != null
                  ? of(
                      ItemsDetailsActions.createItemSuccess(),
                      UsersActions.navigate({ path: action.payload.navigatePath }),
                    )
                  : of(ItemsDetailsActions.createItemSuccess())
              }
            }),
            catchError((error: string) => {
              return of(ItemsDetailsActions.createItemFailed({ error }))
            }),
          )
          .toPromise()
      },
    ),
  )

const updateItem_v0_Epic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsDetailsActions.updateItem_v0_Request),
    switchMap((action: { payload: { dId: string; item: { [key: string]: any }; i_id: string } }) => {
      const pId = ProjectSelectors.getCurrentProjectId(state$.value)
      return HttpService.PostAsync<
        {
          item: { [key: string]: any }
          use_display_id: boolean
          is_force_update: boolean
          return_item_result: boolean
        },
        any
      >(
        `/applications/${pId}/datastores/${action.payload.dId}/items/edit/${action.payload.i_id}`,
        {
          item: action.payload.item,
          use_display_id: true,
          is_force_update: true,
          return_item_result: false,
        },
        HttpService.LinkerAPIBasePath,
      )
        .pipe(
          mergeMap(response => {
            return of(ItemsDetailsActions.updateItem_v0_Success())
          }),
          catchError((error: string) => {
            return of(ItemsDetailsActions.updateItem_v0_Failed({ error }))
          }),
        )
        .toPromise()
    }),
  )

const deleteItemEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsDetailsActions.deleteItemRequest),
    switchMap((action: { payload: { navigatePath: string } }) => {
      const project_id = ProjectSelectors.getCurrentProjectId(state$.value)
      const datastore_id = DatastoreSelectors.getCurrentDatastoreId(state$.value)
      const item_id = ItemsSelectors.getCurrentItemId(state$.value)

      return HttpService.DeleteAsync<{}, { error: any; history_id: string; item_id: string }>(
        `applications/${project_id}/datastores/${datastore_id}/items/delete/${item_id}`,
        HttpService.LinkerAPIBasePath,
        { data: {}, headers: { 'Content-Type': 'application/json' } },
      ).pipe(
        mergeMap(response => {
          return of(
            ItemsDetailsActions.deleteItemSuccess(),
            UsersActions.navigate({ path: action.payload.navigatePath }),
          )
        }),
        catchError((error: string) => {
          return of(ItemsDetailsActions.deleteItemFailed({ error }))
        }),
      )
    }),
  )

const orderItemDeleteEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsDetailsActions.orderItemDeleteRequest),
    switchMap(
      (action: {
        payload: {
          dId: string
          itemId: string
          actionId: string
          param: { rev_no?: number; is_force_update?: boolean; comment: string }
          navigatePath: string
        }
      }) => {
        const project_id = ProjectSelectors.getCurrentProjectId(state$.value)
        const datastore_id = action.payload.dId
        const item_id = action.payload.itemId
        const action_id = action.payload.actionId

        return HttpService.PostAsync<{ rrev_no?: number; is_force_update?: boolean; comment: string }, null>(
          `applications/${project_id}/datastores/${datastore_id}/items/action/${item_id}/${action_id}`,
          action.payload.param,
          HttpService.LinkerAPIBasePath,
        ).pipe(
          mergeMap(response => {
            return of(ItemsDetailsActions.deleteItemRequest({ navigatePath: action.payload.navigatePath }))
          }),
          catchError((error: string) => {
            return of(ItemsDetailsActions.deleteItemFailed({ error }))
          }),
        )
      },
    ),
  )

const deleteItemByConditionEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsDetailsActions.deleteItemByConditionRequest),
    switchMap((action: { payload: { did: string; condition: string } }) => {
      const project_id = ProjectSelectors.getCurrentProjectId(state$.value)
      const datastore_id = action.payload.did || DatastoreSelectors.getCurrentDatastoreId(state$.value)
      const condition = action.payload.condition

      return HttpService.DeleteAsync<{}, { error: any; history_id: string; item_id: string }>(
        `applications/${project_id}/datastores/${datastore_id}/items/delete`,
        HttpService.LinkerAPIBasePath,
        { data: condition, headers: { 'Content-Type': 'application/json' } },
      )
        .pipe(
          mergeMap(response => {
            return of(ItemsDetailsActions.deleteItemSuccess())
          }),
          catchError((error: string) => {
            return of(ItemsDetailsActions.deleteItemFailed({ error }))
          }),
        )
        .toPromise()
    }),
  )

const getLinkedDbItemsEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsDetailsActions.getLinkedItemRequest),
    switchMap((action: { payload: { iId: string; linkedDatastoreName: string } }) => {
      const dId = DatastoreSelectors.getCurrentDatastoreId(state$.value)
      const iId = action.payload.iId
      const linkedDatastore = DatastoreSelectors.getDatastores(state$.value).find(
        datastore => datastore.name === action.payload.linkedDatastoreName,
      )

      const linkedId = linkedDatastore && linkedDatastore.d_id

      return HttpService.GetAsync<null, apiModels.LinkedDbItemsResponse>(
        `datastores/${dId}/items/${iId}/links/${linkedId}`,
        null,
        HttpService.LinkerAPIBasePath,
      ).pipe(
        mergeMap(response => {
          return of(
            ItemsDetailsActions.getLinkedItemSuccess({
              linkedDbItems: {
                d_id: response.data.datastore_id,
                fields: response.data.fields,
                items: response.data.items,
                columnsSettings: response.data.column_settings,
              },
            }),
          )
        }),
        catchError((error: string) => {
          return of(ItemsDetailsActions.getLinkedItemFailed({ error }))
        }),
      )
    }),
  )

const getLinkedAllDbsItemsRequestEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsDetailsActions.getLinkedAllDbsItemsRequest),
    switchMap(
      (action: { payload: { iId: string; relations: ItemDetailsState['relations']; dId: string | undefined } }) => {
        const iId = action.payload.iId
        const relations = action.payload.relations
        const dId =
          action.payload.dId == null ? DatastoreSelectors.getCurrentDatastoreId(state$.value) : action.payload.dId

        const observableResponses = relations.map(relation =>
          HttpService.GetAsync<null, apiModels.LinkedDbItemsResponse>(
            `datastores/${dId}/items/${iId}/links/${relation.d_id}`,
            null,
            HttpService.LinkerAPIBasePath,
          ).pipe(
            switchMap(response => of(response)),
            // TODO: error handling
            catchError((error: string) => of(null)),
          ),
        )

        return forkJoin(observableResponses).pipe(
          switchMap(responses => {
            const linkedAllDbsItems = responses.map(response => {
              if (response) {
                return {
                  d_id: response.data.datastore_id,
                  fields: response.data.fields,
                  items: response.data.items,
                  columnsSettings: response.data.column_settings,
                }
              }
              return []
            })

            return of(
              ItemsDetailsActions.getLinkedAllDbsItemsSuccess({
                linkedAllDbsItems: linkedAllDbsItems as LinkedAllDbsItems,
              }),
            )
          }),
          catchError((error: string) => of(ItemsDetailsActions.getLinkedAllDbsItemsFaild({ error }))),
        )
      },
    ),
  )

const uploadFileEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsDetailsActions.uploadFileRequest),
    concatMap(
      (action: {
        payload: {
          field_id: string
          a_id: string
          params: FormData
        }
      }) => {
        return HttpService.PostAsync<FormData, apiModels.uploadApiResult>(
          `items/${ItemsSelectors.getCurrentItemId(state$.value)}/fields/${action.payload.field_id}/attachments`,
          action.payload.params,
          HttpService.LinkerAPIBasePath,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        ).pipe(
          map(result => {
            if (result) {
              const a_id: string = action.payload.a_id
              const updateParams: string = JSON.stringify({
                is_force_update: true,
                history: {
                  datastore_id: DatastoreSelectors.getCurrentDatastoreId(state$.value),
                  comment: '添付ファイルの登録',
                },
                changes: [
                  {
                    id: action.payload.field_id,
                    value: [result['data']['file_id']],
                  },
                ],
                return_item_result: true,
              })
              return ItemsDetailsActions.updateDetailsRequest({ a_id, updateParams })
            }
          }),
          catchError((error: string) => {
            return of(ItemsDetailsActions.updateItemFailed({ error }))
          }),
        )
      },
    ),
  )

// PDFファイルアップロード後のアイテム更新アクション処理
const updateDetailsActionEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsDetailsActions.updateDetailsRequest),
    concatMap(
      (action: {
        payload: {
          a_id: string
          updateParams: string
        }
      }) => {
        const projectId = ProjectSelectors.getCurrentProjectId(state$.value)
        const datastoreID = DatastoreSelectors.getCurrentDatastoreId(state$.value)
        const itemID = ItemsSelectors.getCurrentItemId(state$.value)
        return HttpService.PostAsync<string, any>(
          `applications/${projectId}/datastores/${datastoreID}/items/action/${itemID}/${action.payload.a_id}`,
          action.payload.updateParams,
          HttpService.LinkerAPIBasePath,
          { headers: { 'Content-Type': 'application/json' } },
        ).pipe(
          switchMap(result => {
            return of(
              ItemsDetailsActions.updateItemSuccess({ changes: JSON.parse(action.payload.updateParams).changes }),
              ItemsDetailsActions.getItemDetailsRequest({ itemId: itemID }),
              ItemsActions.getItemsListRequest({ projectId, datastoreId: datastoreID }),
            )
          }),
          catchError((error: string) => {
            return of(ItemsDetailsActions.updateItemFailed({ error }))
          }),
        )
      },
    ),
  )

const deletePDFActionEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsDetailsActions.deletePDFRequest),
    concatMap(
      (action: {
        payload: {
          field_id: string
          file_id: string
        }
      }) => {
        const itemID = ItemsSelectors.getCurrentItemId(state$.value)
        return HttpService.DeleteAsync<null, null>(
          `items/${itemID}/fields/${action.payload.field_id}/attachments/${action.payload.file_id}`,
          HttpService.LinkerAPIBasePath,
          { headers: { 'Content-Type': 'application/json' } },
        ).pipe(
          switchMap(result => {
            return of(ItemsDetailsActions.deletePDFSuccess())
          }),
          catchError((error: string) => {
            return of(ItemsDetailsActions.updateItemFailed({ error }))
          }),
        )
      },
    ),
  )

const updateDeliverySlipEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsDetailsActions.updateDeliverySlip),
    concatMap(
      (action: {
        payload: {
          item_id: string
          field_id: string
          file_id: string
          changes: any
          actions: any
          last_action: string
          lastFlag: boolean
          type: string
        }
      }) => {
        const itemID = action.payload.item_id ? action.payload.item_id : ItemsSelectors.getCurrentItemId(state$.value)
        return HttpService.DeleteAsync<null, null>(
          `items/${itemID}/fields/${action.payload.field_id}/attachments/${action.payload.file_id}`,
          HttpService.LinkerAPIBasePath,
          { headers: { 'Content-Type': 'application/json' } },
        )
          .pipe(
            switchMap(result => {
              return of(
                ItemsActions.postActionRequest({
                  itemId: action.payload.item_id,
                  datastoreId: action.payload.actions['d_id'],
                  actionId: action.payload.actions['a_id'],
                  changes: action.payload.changes,
                  lastFlag: action.payload.lastFlag,
                  action_Id: action.payload.last_action,
                  actionType: action.payload.type,
                }),
              )
            }),
            catchError((error: string) => {
              return of(ItemsDetailsActions.updateItemFailed({ error }))
            }),
          )
          .toPromise()
      },
    ),
  )

const sendMailActionEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsDetailsActions.sendMail),
    concatMap(
      (action: {
        payload: {
          itemId: string
          actionId: string
          datastoreId: string
        }
      }) => {
        return HttpService.PostAsync<any, null>(
          `items/${action.payload.itemId}/actions/${action.payload.actionId}`,
          {
            is_force_update: true,
            history: {
              datastore_id: action.payload.datastoreId,
              comment: '',
            },
            changes: [],
          },
          HttpService.LinkerAPIBasePath,
        ).pipe(
          concatMap(response => {
            return of(ItemsActions.postActionSucess())
          }),
          catchError((error: string) => {
            return of(ItemsActions.postActionFailed({ error }))
          }),
        )
      },
    ),
  )

const orderForInventoryActionEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsDetailsActions.orderForInventoryAction),
    concatMap(
      (action: {
        payload: {
          itemId: string
          datastoreId: string
          actionId: string
          changes: any
          isOrder: boolean
        }
      }) => {
        const projectId = ProjectSelectors.getCurrentProjectId(state$.value)
        const itemID = action.payload.itemId
        const datastoreID = action.payload.datastoreId
        const actionID = action.payload.actionId
        const changes = action.payload.changes
        const isOrder = action.payload.isOrder
        return HttpService.GetAsync<null, apiModels.ItemDetails>(
          `applications/${projectId}/datastores/${datastoreID}/items/details/${itemID}`,
          null,
          HttpService.LinkerAPIBasePath,
        ).pipe(
          switchMap(result => {
            // 発注の場合アイテムステータスを再確認
            if (isOrder) {
              const itemData = result.data
              const statuses = itemData.status_list
              const itemStatus = itemData.field_values.find(f => f.dataType === 'status')
              if (itemStatus === undefined || itemStatus.value === null) {
                return of(ItemsDetailsActions.getError({ error: errorMessages.internalServerError }))
              }
              const currentStatus = statuses.find(status => status.status_id === itemStatus.value)
              if (currentStatus !== undefined && currentStatus.status_name === orderError.targetStatus) {
                return of(ItemsDetailsActions.getError({ error: orderError.message }))
              }
            }

            return of(
              ItemsActions.postActionRequest({
                itemId: itemID,
                datastoreId: datastoreID,
                actionId: actionID,
                changes,
              }),
            )
          }),
          catchError((error: string) => {
            return of(ItemsDetailsActions.getItemDetailsFailed({ error }))
          }),
        )
      },
    ),
  )

export default [
  getAutoNumberEpic,
  getDatastoreFieldsEpic,
  getItemDetailsEpic,
  selectActionEpic,
  newItemModeRequestEpic,
  newItemRequestEpic,
  getActionSettingsRequestEpic,
  updateItemRequestEpic,
  goToItemEpic,
  getPDFEpicRequest,
  updateCommentHistoryEpic,
  getHistoriesEpic,
  deleteItemEpic,
  deleteItemByConditionEpic,
  orderItemDeleteEpic,
  createItemEpic,
  updateItem_v0_Epic,
  getLinkedDbItemsEpic,
  getLinkedAllDbsItemsRequestEpic,
  uploadFileEpic,
  updateDetailsActionEpic,
  deletePDFActionEpic,
  updateDeliverySlipEpic,
  sendMailActionEpic,
  orderForInventoryActionEpic,
]
