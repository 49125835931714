import { createSelector } from 'reselect'
import { RootState } from '../rootReducer'
import { ItemDetailsState } from './state'
import memoize from 'lodash.memoize'
import StringUtils from 'app/utils/stringUtils'
import {
  ActionFieldSettings,
  ItemDetailsField,
  ItemDetailsTitle,
  ItemDetailsAction,
  ItemDetailsCurrentAction,
  ItemDetailMode,
  LinkedAllDbsItemsEntries,
  LinkedAllDbsItems,
  LinkedItem,
} from './types'
import { Datastore } from 'app/services/store/datastore/types'
import { DatastoreField, ItemEntry, ItemDisplayableEntry } from '../items/types'
import { History, itemStatusList, autoNumberItem, dataStoreItemField } from 'app/services/store/itemDetails/types'
import { formKeys } from 'app/constants/deliverySlip'
import { getDatastoreNameFromId } from 'app/utils/store/index'
import { actionNameLabel, orderColumnNameLabel } from 'app/constants/order'

const itemDetailsState = (state: RootState): ItemDetailsState => state.itemDetails
const itemDetailsEntry = (state: RootState): ItemEntry => state.itemDetails.entry || {}
const itemDetailsTitles = (state: RootState): Array<ItemDetailsTitle> => state.itemDetails.titles || []
const linkedAllDbsItems = (state: RootState): LinkedAllDbsItems => state.itemDetails.linkedAllDbsItems || null
const datastores = (state: RootState): Array<Datastore> => state.datastores.datastores_list
const itemEntry = (state: RootState): ItemEntry => state.itemDetails.entry
const itemsFields = (state: RootState): Array<DatastoreField> => state.items.fields
const itemStatuses = (state: RootState): Array<any> => state.itemDetails.statuses.statuses
const autoNumberItemFields = (state: RootState): autoNumberItem => state.itemDetails.autoNumberItem || null
const dataStoreItemFields = (state: RootState): dataStoreItemField => state.itemDetails.dataStoreItemField || null

/**
 * @selector
 *
 * @description Return The current loading status of the itemDetails
 *
 * @returns {boolean} the current loading status of the itemDetails
 */
export const getIsLoading = createSelector<RootState, ItemDetailsState, boolean>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.loading,
)

/**
 * @selector
 *
 * @description Return The entry list from the itemdetails
 *
 * @returns {ItemEntry} the entry list from the itemdetails
 */
export const getEntry = createSelector<RootState, ItemDetailsState, ItemEntry>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.entry,
)

/**
 * @selector
 *
 * @description Return the item revision number
 *
 * @returns {number} the item revision number
 */
export const getRevisionNumber = createSelector<RootState, ItemDetailsState, number>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.entry.rev_no,
)

/**
 * @selector
 *
 * @description Return the fields of the item
 *
 * @returns {{[k: string]: ItemDetailsField}} the fields of the item sorted by field_id
 */
export const getFields = createSelector<RootState, ItemDetailsState, { [k: string]: ItemDetailsField }>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.fields,
)

/**
 * @selector
 *
 * @description Return the actions of the item
 *
 * @returns {Array<ItemDetailsAction>}  the actions of the item
 */
export const getActions = createSelector<RootState, ItemDetailsState, Array<ItemDetailsAction>>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.actions,
)

/**
 * @selector
 *
 * @description Return the status action of the item
 *
 * @returns {Array<ItemDetailsAction>} the status actions of the item
 */
export const getStateActions = createSelector<RootState, ItemDetailsState, Array<ItemDetailsAction>>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.statusActions || [],
)

/**
 * @selector
 *
 * @description return the current selected action
 *
 * @returns {ItemDetailsCurrentAction | undefined} the current selected action (undefined if none)
 */
export const getCurrentAction = createSelector<RootState, ItemDetailsState, ItemDetailsCurrentAction | undefined>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.currentAction,
)

/**
 * @selector
 *
 * @description return the id of the item
 *
 * @returns {string} the id of the item
 */
export const getEntryId = createSelector<RootState, ItemDetailsState, string>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.entry.i_id,
)

/**
 * @selector memoized
 *
 * @param {string} fieldId the id of the field we wish to get
 *
 * @description return an item field based on the provided field id
 *
 * @returns {ItemDetailsField} the field based on the id provided
 */
export const getFieldById = createSelector<RootState, ItemDetailsState, (f_id: string) => ItemDetailsField>(
  itemDetailsState,
  itemDetailsState => memoize<ItemDetailsField>(f_id => itemDetailsState.fields[f_id]),
)

/**
 * @selector memoized
 *
 * @param {string} fieldId the id of the field we wish to get
 *
 * @description return an item field value based on the provided field id
 *
 * @returns {any} the field value based on the id provided
 */
export const getEntryValueById = createSelector<RootState, ItemDetailsState, (f_id: string) => any>(
  itemDetailsState,
  itemDetailsState => memoize<any>(f_id => itemDetailsState.entry.fields[f_id]),
)

/**
 * @selector memoized
 *
 * @param {string} actionId the id of the action we wish to get
 *
 * @description return an item action based on the provided action id
 *
 * @returns {ItemDetailsAction} the action based on the id provided
 */
export const getActionById = createSelector<RootState, ItemDetailsState, (a_id: string) => ItemDetailsAction>(
  itemDetailsState,
  itemDetailsState => memoize<ItemDetailsAction>(a_id => itemDetailsState.actions.find(a => a.a_id === a_id)),
)

/**
 * @selector
 *
 * @description return the current itemDetails display mode
 *
 * @returns {ItemDetailMode} the current display mode
 */
export const getDisplayMode = createSelector<RootState, ItemDetailsState, ItemDetailMode>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.mode,
)

/**
 * @selector
 *
 * @description Return the item list of title
 *
 * @returns {Array<string>} the item list of title
 */
export const getItemsTitlesIds = createSelector<RootState, Array<ItemDetailsTitle>, ItemEntry, Array<string>>(
  itemDetailsTitles,
  itemDetailsEntry,
  (itemDetailsTitle: Array<ItemDetailsTitle>, itemEntry: ItemEntry) => {
    const titles = Array<string>()
    itemDetailsTitle.forEach(title => {
      titles.push(itemEntry.fields[title.columnID])
    })
    return titles
  },
)

export const getActionFiealdSettings = createSelector<RootState, ItemDetailsState, ActionFieldSettings | undefined>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => {
    if (itemsDetailsState.currentAction) {
      return itemsDetailsState.currentAction.action_field_settings
    }
  },
)

export const getHistories = createSelector<RootState, ItemDetailsState, Array<History> | null>(
  itemDetailsState,
  (itemDetailsState: ItemDetailsState) => itemDetailsState.histories,
)

export const getHistoriesLoading = createSelector<RootState, ItemDetailsState, boolean>(
  itemDetailsState,
  (itemDetailsState: ItemDetailsState) => itemDetailsState.historiesLoading,
)

// TODO: delete this selector
export const getDisplayableLinkedItemData = createSelector<RootState, ItemDetailsState, Array<{ [k: string]: string }>>(
  itemDetailsState,
  (itemDetailsState: ItemDetailsState) => {
    const items = itemDetailsState.linkedDbItems ? itemDetailsState.linkedDbItems.items : null
    const columnsSettings = itemDetailsState.linkedDbItems ? itemDetailsState.linkedDbItems.columnsSettings : null

    const linkedColumnIds = {}
    const linkedItemData = new Array<{ [k: string]: string }>()

    if (columnsSettings && items) {
      Object.entries(columnsSettings).forEach(([key, value]) => {
        if (
          value['display_id'] === formKeys.ID ||
          value['display_id'] === formKeys.ITEM_NAME ||
          value['display_id'] === formKeys.ORDER_QUANTITY ||
          value['display_id'] === formKeys.ANSWER_DELIVERY_DATE
        ) {
          linkedColumnIds[value['display_id']] = key
        }
      })
      items.forEach(item => {
        linkedItemData.push({
          [formKeys.ID]: item[linkedColumnIds[formKeys.ID]],
          [formKeys.ITEM_NAME]: item[linkedColumnIds[formKeys.ITEM_NAME]],
          [formKeys.ORDER_QUANTITY]: item[linkedColumnIds[formKeys.ORDER_QUANTITY]],
          [formKeys.ANSWER_DELIVERY_DATE]: item[linkedColumnIds[formKeys.ANSWER_DELIVERY_DATE]],
          key: `${StringUtils.GenUUID()}-${formKeys.ID}`,
        })
      })
    }
    return linkedItemData
  },
)

export const getRelations = createSelector<RootState, ItemDetailsState, ItemDetailsState['relations']>(
  itemDetailsState,
  (itemDetailsState: ItemDetailsState) => itemDetailsState.relations,
)

export const getLinkedAllDbsItemsDisplayableEntries = createSelector<
  RootState,
  LinkedAllDbsItems,
  Array<Datastore>,
  Array<LinkedAllDbsItemsEntries>
>(linkedAllDbsItems, datastores, (linkedAllDbsItems: LinkedAllDbsItems, datastores: Array<Datastore>) => {
  const linkedItemData = new Array<LinkedAllDbsItemsEntries>()

  if (linkedAllDbsItems) {
    linkedAllDbsItems.forEach(linkedItems => {
      if (linkedItems && linkedItems.items && linkedItems.columnsSettings) {
        const datastoreName = getDatastoreNameFromId(datastores, linkedItems.d_id)
        const dispColumns = Object.values(linkedItems.columnsSettings).map(setting => {
          return {
            title: setting.name,
            dataIndex: setting.display_id,
            key: setting.display_id,
            id: setting.id,
          }
        })

        const dispItems = linkedItems.items.map((item: LinkedItem) => {
          const targetObj: { [key: string]: any } = {}

          dispColumns.forEach((column: any) => {
            targetObj[column.key] = item[column.id]
          })

          targetObj['key'] = `${StringUtils.GenUUID()}-${item.i_id}`
          targetObj['i_id'] = item.i_id
          targetObj['d_id'] = item.d_id
          targetObj['dName'] = datastoreName
          targetObj['status_id'] = item.status_id

          targetObj['created_at'] = item.created_at
          targetObj['updated_at'] = item.updated_at

          return targetObj
        })
        linkedItemData.push({
          columns: dispColumns,
          items: dispItems,
          dName: datastoreName,
        })
      }
    })
  }

  return linkedItemData
})

/**
 * @selector
 *
 * @description This selector will create a set of displayable item that are easy to manipulate
 * using the current entry and fields set in the item list store. This way all the information is in one place
 * and no need to manipulate to many different field to get what we want. It should provide you with enough data to display anything
 *
 * @returns {ItemDisplayableEntry} the created displayable entry from this list
 */
export const getDisplayableEntry = createSelector<
  RootState,
  ItemEntry,
  Array<DatastoreField>,
  Array<any>,
  ItemDisplayableEntry
>(itemEntry, itemsFields, itemStatuses, (entry: ItemEntry, fields: Array<DatastoreField>, statuses: Array<any>) => {
  const dispColumns = fields.map((field: any) => {
    return {
      title: field.displayName,
      dataIndex: field.displayName,
      key: field.displayID,
      id: field.field,
    }
  })

  const targetObj: { [k: string]: any } = {}
  dispColumns.forEach((element: any) => {
    if (entry.fields[element.id]) {
      if (element.key === 'ステータス') {
        //Check the value of ステータス is a status text or a specific id.
        let correctStatusValue = false
        Object.entries(actionNameLabel).forEach((actionKey, actionName) => {
          if (actionName === entry.fields[element.id]) {
            correctStatusValue = true
            targetObj[element.key] = entry.fields[element.id]
          }
        })
        if (!correctStatusValue) {
          //The value of ステータス is a id, loop statuses and find the status name.
          for (let j = 0; j < statuses.length; j++) {
            if (statuses[j].data.id === entry.fields[element.id]) {
              targetObj[element.key] = statuses[j].data.name
              break
            }
          }
        }
      } else if (element.key === orderColumnNameLabel.SUPPLIER && Array.isArray(entry.fields[element.id])) {
        //The value of 発注先 is an array, put the user name.
        targetObj[element.key] = entry.fields[element.id][0] ? entry.fields[element.id][0].user_name : ''
      } else {
        targetObj[element.key] = entry.fields[element.id]
      }
    } else {
      targetObj[element.key] = ''
    }
  })

  targetObj['key'] = `${StringUtils.GenUUID()}-${entry.i_id}`
  targetObj['i_id'] = entry.i_id
  targetObj['unread'] = entry.unread

  return { columns: dispColumns, item: targetObj }
})

export const getStatusOptions = createSelector<RootState, ItemDetailsState, itemStatusList>(
  itemDetailsState,
  (itemDetailsState: ItemDetailsState) => itemDetailsState.statuses.statuses,
)

export const getAutoNumberItem = createSelector<RootState, autoNumberItem, autoNumberItem>(
  autoNumberItemFields,
  (autoNumberItemFields: autoNumberItem) => autoNumberItemFields,
)

export const getDataStoreItemFields = createSelector<RootState, dataStoreItemField, dataStoreItemField>(
  dataStoreItemFields,
  (autoNumberItemFields: dataStoreItemField) => autoNumberItemFields,
)

export const isErrorDialog = createSelector<RootState, ItemDetailsState, boolean>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => itemsDetailsState.isErrorDialog || false,
)

export const getErrorMessage = createSelector<RootState, ItemDetailsState, string>(
  itemDetailsState,
  (itemsDetailsState: ItemDetailsState) => (itemsDetailsState.error ? itemsDetailsState.error : ''),
)

export default [
  getIsLoading,
  getEntry,
  getFields,
  getActions,
  getStateActions,
  getFieldById,
  getCurrentAction,
  getEntryValueById,
  getDisplayMode,
  getItemsTitlesIds,
  getActionFiealdSettings,
  getHistories,
  getHistoriesLoading,
  getDisplayableLinkedItemData,
  getRelations,
  getStatusOptions,
  getAutoNumberItem,
  getDataStoreItemFields,
  isErrorDialog,
  getErrorMessage,
]
