import businessMan from 'assets/images/businessMan.svg'
import executive from 'assets/images/executive.svg'

export const NUMBER_OF_STATUS_TYPES = 5
export const dateFormat = 'YYYY-MM-DD'
export const datetimeFormat = 'YYYY-MM-DD HH:MM'
export const errorMessage = '入力してください。'
export const errorMessageRange = '以上を入力してください。'

export const dateTypeKeyListOfOrder = ['発注日', '希望納期', '納期回答 締切', '回答納期', '出荷日', '確定入荷日']

export const actionNameLabel = {
  UPDATE_BY_SHIROYAMA: '更新する（白山工業様）',
  UPDATE_BY_SUPPLIER: '更新する（サプライヤー様）',
  COPY: '発注データをコピーする',
  DELETE: 'このデータを削除する',
  UPDATE_DELIVERY_DATE: '手配確定後納期変更',
  UPDATE_COST_DATE_QUANTITY: '単価・納期・数量変更',
  ARRANGEMENT: '手配する',
  ENREY: '入庫',
  REPLY_TO_SUPPLIER: '発注者へ回答',
}

export const statusActionLabel = {
  CREATE_DELIVERY_SLIP_DATA: '納品データを作成',
  CONTACT_SUPPLIER: 'サプライヤーへ連絡',
  REPLY_TO_SUPPLIER: '発注者へ回答',
  ARRANGEMENT: '手配する',
  ENREY: '入庫',
}

export const orderColumnNameLabel = {
  CUSTOMER_NO: '客先注文No',
  INVENTORY_ID: 'ID',
  INVENTORY_NAME: '物品名',
  COLOR: '色',
  QUANTITY_OF_CURRENT_INVENTORY: '現在庫',
  SET_INVENTORY: '設定在庫',
  ORDER_PONIT: '発注点',
  SUPPLIER: '発注先',
  SUPPLIER_INPUT: '発注先直接入力',
  ORDERED_DATE: '発注日',
  ORDERED_QUANTITY: '発注個数',
  DESIRED_DELIVERY_DATE: '希望納期',
  LIMIT_OF_ANSWERING: '納期回答 締切',
  DESIRED_UNIT_COST: '希望単価',
  UNIT_COST: '単価',
  ANSWERED_DELIVERY_DATE: '回答納期',
  ESTIMATED_PRICE: '見積金額',
  ESTIMATED_PRICE_OF_SUM: '見積合計',
  FREIGHT: '運賃',
  METHOD_OF_SHIPMENT: '配送方法',
  SHIPPING_DATE: '出荷日',
  BOTTOM_COMMENT: '下部コメント',
  RIGHT_COMMENT: '右部コメント',
  DRAWING_PDF: '図面PDF',
}

export const statusLabel = {
  DRAFT_ORDER: '起票・発注',
  WAITING_FOR_SUPPLYER: 'サプライヤー回答待ち',
  NEED_CONFIRM: '要確認',
  ARRANGING: '手配中',
  ARRIVED: '入荷済',
}

export const images = {
  BUSINESS_MAN: businessMan,
  EXECUTIVE: executive,
}

export const itemEntryProperties = {
  I_ID: 'i_id',
}

export const defaultCommentLabel = {
  NEW_ITEM: 'ITEM_DETAILS.NEW_ITEM_COMMENT',
  POST_EMPTY: 'ITEM_DETAILS.POST_EMPTY_COMMENT',
}

export const uploadPDFPattern = {
  NEW_FILE: 'PDFをアップロードする',
  OVERWRITE_FILE: 'PDFを上書きアップロードする',
}

export const uploadMassage = {
  PDF: { mimetype: 'application/pdf', err_message: 'PDFファイルをアップロードしてください' },
}

export const orderConfirmMassage = {
  CONTACT_SUPPLIER: { title: 'サプライヤーへの連絡の確認', body: 'サプライヤーへの連絡を実行してよろしいでしょうか？' },
  COPY: { title: '発注データコピーの確認', body: '発注データのコピーを実行してよろしいでしょうか？' },
  DELETE: { title: '発注データ削除の確認', body: '発注データの削除を実行してよろしいでしょうか？' },
  ARRANGEMENT: { title: '手配依頼の確認', body: '手配依頼を実行してよろしいでしょうか？' },
  ENREY: { title: '入庫の確認', body: '入庫を実行してよろしいでしょうか？' },
  DELIVERY_SLIP_DATA: { title: '納品データ作成の確認', body: '納品データを作成してよろしいでしょうか？' },
  REPLY_TO_SUPPLIER: { title: '送信確認', body: '送信を実行してよろしいでしょうか？' },
  UPDATE_DELIVERY_DATE: { title: '手配確定後納品日変更の確認', body: '納品日を変更してよろしいでしょうか？' },
}

export const customOrderValue = {
  NO: 0,
  YES: 1,
}

export const toMailActionName = {
  toSupplier: 'コメント通知（サプライヤー宛）',
  toShiroyama: 'コメント通知（白山工業宛）',
}

export const itemDeleteDefine = {
  actionId: 'backToPreviousInventoryItem' /* display_idを使用 */,
  comment: '発注アイテム削除',
}
