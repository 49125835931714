import { Observable, Action } from 'redux'
import { ofType } from 'redux-observable'
import { of, EMPTY } from 'rxjs'
import { catchError, switchMap, concatMap } from 'rxjs/operators'

import { WorkspaceActions, ProjectActions, UsersSelectors, ProjectSelectors } from '..'
import HttpService from 'app/services/httpService/httpService'
import * as storeModels from './types'
import * as apiModels from './epic.types'

import { getWorkspaceIdFromName } from 'app/utils/store'
import { hexaLabel } from 'app/constants/hexalink'
import { AppConstant } from 'app/constants/app'

const initEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(AppConstant.REHYDRATE_ACTION_TYPE),
    concatMap(() => {
      const userLogged = UsersSelectors.isUserLogged(state$.value)
      const projectId = ProjectSelectors.getCurrentProjectId(state$.value)
      if (userLogged && projectId) {
        return of(WorkspaceActions.getWorkspacesRequest())
      } else {
        return EMPTY
      }
    }),
  )

const getAllWorkspacesEpic = (action$, state$) =>
  action$.pipe(
    ofType(WorkspaceActions.getWorkspacesRequest),
    switchMap(action =>
      HttpService.GetAsync<null, apiModels.api_get_all_workspace_response>('get_all_workspaces').pipe(
        switchMap(response => {
          const workspaces = response.data ? response.data.workspaces : new Array<storeModels.Workspace>()
          const workspaceId = response.data ? getWorkspaceIdFromName(response.data, hexaLabel.WORKSPACE_NAME) : ''
          return [
            WorkspaceActions.getWorkspacesSuccess({ workspaces }),
            WorkspaceActions.setCurrentWorkspaceIdRequest({ workspaceId }),
          ]
        }),
        catchError((error: string) => {
          return of(WorkspaceActions.getWorkspacesFailed({ error }))
        }),
      ),
    ),
  )

const setCurrentWorkspaceIdEpic = (action$, state$) =>
  action$.pipe(
    ofType(WorkspaceActions.setCurrentWorkspaceIdRequest),
    switchMap((action: { payload: { workspaceId: string } }) =>
      HttpService.PostAsync<apiModels.api_post_set_current_workspace_id_request, null>(`post_set_current_workspace`, {
        workspace_id: action.payload.workspaceId,
      }).pipe(
        switchMap(result => {
          return of(
            WorkspaceActions.setCurrentWorkspaceIdSuccess(action.payload),
            ProjectActions.getProjectsRequest(action.payload),
          )
        }),
        catchError((error: string) => {
          return of(WorkspaceActions.setCurrentWorkspaceIdFailed({ error }))
        }),
      ),
    ),
  )

export default [initEpic, getAllWorkspacesEpic, setCurrentWorkspaceIdEpic]
