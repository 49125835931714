import React, { useState } from 'react'
import { FormComponentProps } from 'antd/lib/form/Form'
import { Row, Col, Button, Form, Spin, Modal } from 'antd'

import { NUMBER_OF_STATUS_TYPES, actionNameLabel, orderConfirmMassage, statusActionLabel } from 'app/constants/order'

import { StatusIndex } from 'app/components/organisms/order/type'
import { Columns } from 'app/services/store/items/types'
import { CommentData, itemStatusList } from 'app/services/store/itemDetails/types'
import { ActionFieldSettings, ItemDetailsCurrentAction } from 'app/services/store/itemDetails/types'
import { OrderDetailList, ActionName } from 'app/components/pages/order/types'
import { GroupMembers } from 'app/services/store/group/types'

import CommentDrawer from 'app/components/organisms/order/CommentDrawer'
import WorkflowBar from 'app/components/organisms/shared/WorkflowBar'
import DetailInfo from 'app/components/organisms/order/DetailInfo'

interface confirmMessageResponce {
  title: string
  body: string
}

interface OrderDetailProps extends FormComponentProps {
  orderDetailResource: {
    itemDetailsCurrentAction: ItemDetailsCurrentAction
    currentDetailList: OrderDetailList
    columns: Columns
    actionFieldSettings: ActionFieldSettings
    submit: (
      form,
      orderIDs: { d_id: string; a_id: string } | undefined,
      actionFieldSettings: ActionFieldSettings,
    ) => void
    submitOnDrawer: (form) => void
    supplierMembers: GroupMembers
    setOperationAndOpenEditMode: (operation: ActionName) => void
    isEditMode: boolean
    setIsEditMode: Function
    commentData: Array<CommentData> | undefined
    isLoading: boolean
    isHistoriesLoading: boolean
    isSupplier: boolean
    statusOptions: itemStatusList | []
    uploadProps: {}
    fileList: any
    setFileList: Function
    currentOperation: ActionName
  }
}
const InnerOrderDetail = ({
  form,
  orderDetailResource: {
    itemDetailsCurrentAction,
    supplierMembers,
    currentDetailList,
    columns,
    submit,
    actionFieldSettings,
    setOperationAndOpenEditMode,
    isEditMode,
    setIsEditMode,
    commentData,
    submitOnDrawer,
    isLoading,
    isHistoriesLoading,
    isSupplier,
    statusOptions,
    uploadProps,
    fileList,
    setFileList,
    currentOperation,
  },
}: OrderDetailProps): JSX.Element => {
  const [modalVisibility, setModalVisibility] = useState(false)
  const [modalInfoData, setModalInfoData] = useState({
    title: '',
    body: '',
    onOk: (): void => {
      return
    },
    onCancel: (): void => {
      return
    },
  })

  const [drawerVisibility, setDrawerVisibility] = useState(false)
  const { getFieldDecorator, getFieldsError, getFieldsValue } = form
  const orderIDs = itemDetailsCurrentAction
    ? { d_id: itemDetailsCurrentAction.d_id, a_id: itemDetailsCurrentAction.a_id }
    : undefined

  /**
   * 現在のステータス番号を返す（プログラムファイル値に合わせた値取得）
   * @param statusOptions: Array
   * @param currentDetailStatus: string
   * @return StatusIndexのKey: number
   */
  const getStatusIndex = (statusOptions, currentDetailStatus): number => {
    if (Array.isArray(statusOptions)) {
      const currentStatus = statusOptions.filter(option => option['data']['name'] === currentDetailStatus)
      if (currentStatus.length > 0) {
        const currentStatusName: string = currentStatus[0]['data'].name
        return StatusIndex[currentStatusName] != null ? StatusIndex[currentStatusName] : 0
      }
    }
    return 0
  }
  const statusIndex: number = getStatusIndex(statusOptions, currentDetailList['ステータス'])

  const closeEditMode = (): void => {
    setIsEditMode(false)
  }

  const handleNextSubmit = (): void => {
    submit(form, orderIDs, actionFieldSettings)
    setModalVisibility(false)
  }
  const handleCloseModal = (): void => {
    setModalVisibility(false)
  }

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault()

    let message: confirmMessageResponce
    let isEmpty = false
    switch (currentOperation) {
      case statusActionLabel.CONTACT_SUPPLIER:
        message = orderConfirmMassage.CONTACT_SUPPLIER
        break
      case actionNameLabel.COPY:
        message = orderConfirmMassage.COPY
        break
      case actionNameLabel.DELETE:
        message = orderConfirmMassage.DELETE
        break
      case actionNameLabel.ARRANGEMENT:
        message = orderConfirmMassage.ARRANGEMENT
        break
      case actionNameLabel.ENREY:
        message = orderConfirmMassage.ENREY
        break
      case statusActionLabel.CREATE_DELIVERY_SLIP_DATA:
        message = orderConfirmMassage.DELIVERY_SLIP_DATA
        break
      case statusActionLabel.REPLY_TO_SUPPLIER:
        message = orderConfirmMassage.REPLY_TO_SUPPLIER
        break
      case actionNameLabel.UPDATE_DELIVERY_DATE:
        message = orderConfirmMassage.UPDATE_DELIVERY_DATE
        break
      default:
        isEmpty = true
        message = { title: '', body: '' }
    }

    if (isEmpty) {
      submit(form, orderIDs, actionFieldSettings)
      return
    }

    setModalVisibility(true)
    setModalInfoData({
      title: message.title,
      body: message.body,
      onOk: handleNextSubmit,
      onCancel: handleCloseModal,
    })
  }

  const handleDrawerSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    submitOnDrawer(form)
  }

  const detailInfoResource = {
    isEditMode,
    currentDetailList,
    actionFieldSettings,
    getFieldDecorator,
    getFieldsError,
    getFieldsValue,
    closeEditMode,
    columns,
    handleSubmit,
    statusIndex,
    setOperationAndOpenEditMode,
    supplierMembers,
    commentData,
    isSupplier,
    uploadProps,
    fileList,
    currentOperation,
  }

  return (
    <Spin size="large" spinning={isLoading}>
      <div className="tableArea">
        <Row>
          <Col>
            <Button style={{ float: 'right' }} className="btn-white" onClick={(): void => setDrawerVisibility(true)}>
              コメント履歴
            </Button>
          </Col>
        </Row>
        <Row>
          {/* ワークフロー */}
          <WorkflowBar
            currentStatusIndex={statusIndex}
            numberOfWorkflowTypes={NUMBER_OF_STATUS_TYPES}
            statusList={StatusIndex}
          />
        </Row>
        <Row>
          {/* 詳細情報一覧 */}
          <DetailInfo detailInfoResource={detailInfoResource} />

          {/* 送信確認用モーダル */}
          <Modal
            title={modalInfoData['title']}
            visible={modalVisibility}
            onOk={modalInfoData['onOk']}
            onCancel={modalInfoData['onCancel']}
          >
            <p>{modalInfoData['body']}</p>
          </Modal>
        </Row>
        {/* コメント */}
        <CommentDrawer
          drawerVisibility={drawerVisibility}
          setDrawerVisibility={setDrawerVisibility}
          commentData={commentData}
          getFieldDecorator={getFieldDecorator}
          handleDrawerSubmit={handleDrawerSubmit}
          isHistoriesLoading={isHistoriesLoading}
        />
      </div>
    </Spin>
  )
}

const OrderDetailPage = Form.create<OrderDetailProps>()(InnerOrderDetail)

export default OrderDetailPage
