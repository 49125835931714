import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { ItemsActions } from 'app/services/store'
import { rootSelector } from 'app/services/store/rootReducer'
import { Columns } from 'app/services/store/items/types'
import { datastoreSelector } from 'app/services/store/datastore/reducers'
import {
  ItemDetailsSelectors,
  DatastoreActions,
  DatastoreSelectors,
  GroupSelectors,
  ItemsDetailsActions,
  UsersSelectors,
} from 'app/services/store'

import { getDatastoreIdFromName } from 'app/utils/store'
import { hexaLabel } from 'app/constants/hexalink'
import { routePath } from 'app/constants/router'
import { customOrderValue, inventoryActionName } from 'app/constants/inventory'
import { InventoryDetailList, InventoryDetailColumns } from 'app/components/pages/inventory/types'

import InventoryDetailPage from 'app/components/pages/inventory/InventoryDetailPage'

import { getItemIdFromKey } from 'app/utils/components/common'

interface ContainerProps {
  isActive: boolean
}
const InventoryDetailContainer = ({ isActive }: ContainerProps): JSX.Element => {
  const location = useLocation()
  const dispatch = useDispatch()

  const { currentDetailList, columns } = location.state as { currentDetailList: InventoryDetailList; columns: Columns }
  const { datastores_list } = useSelector(datastoreSelector)
  const fields = ItemDetailsSelectors.getFields(useSelector(rootSelector))
  const actions = ItemDetailsSelectors.getActions(useSelector(rootSelector))
  const supplierMembers = GroupSelectors.getSupplierGroupMembers(useSelector(rootSelector))
  const currentDid = DatastoreSelectors.getCurrentDatastoreId(useSelector(rootSelector))
  const isSupplier = UsersSelectors.isSupplier(useSelector(rootSelector))
  const isShiroyama = UsersSelectors.isShiroyama(useSelector(rootSelector))

  const isErrorDialog = ItemDetailsSelectors.isErrorDialog(useSelector(rootSelector))
  const getErrorMessage = ItemDetailsSelectors.getErrorMessage(useSelector(rootSelector))

  const [doublePreventionFlg, setDoublePreventionFlg] = useState(false)
  const [completeFlg, setCompleteFlg] = useState(false)

  const submit = useCallback(
    (form, action) => {
      form.validateFields((err, values) => {
        if (doublePreventionFlg) return
        setDoublePreventionFlg(true)

        const receiptScheduleId = getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, '入庫予定日')
        const supplier = getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, '第一発注先2')
        const inventorySharing = getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, '在庫共有先')
        const custom_order = getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, '特注部品')
        const orderPoint = getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, '発注点 for Hexa')
        if (!err) {
          if (receiptScheduleId && values[receiptScheduleId]) {
            values[receiptScheduleId] = values[receiptScheduleId].toISOString()
          }
          const changes = Object.entries(values).map(([key, value]) => {
            if (key === supplier || key === inventorySharing) {
              return { id: key, value: [value] }
            }
            if (key === custom_order) {
              const data = value ? customOrderValue.YES : customOrderValue.NO
              return { id: key, value: String(data) }
            }
            if (key === orderPoint) {
              return typeof value == 'string' ? { id: key, value: 0 } : { id: key, value: value }
            }
            return { id: key, value }
          })
          const isOrder = action.name === inventoryActionName.ORDER ? true : false
          dispatch(
            ItemsDetailsActions.orderForInventoryAction({
              itemId: currentDetailList['i_id'],
              datastoreId: currentDid,
              actionId: action.a_id,
              changes,
              isOrder: isOrder,
            }),
          )
          setCompleteFlg(true)
        } else {
          setDoublePreventionFlg(false)
        }
      })
    },
    [dispatch, columns, currentDetailList, currentDid],
  )

  const copyItem = useCallback(
    form => {
      form.validateFields((err, values) => {
        const receiptScheduleId = getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, '入庫予定日')
        const supplier = getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, '第一発注先2')
        const inventorySharing = getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, '在庫共有先')
        const customOrder = getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, '特注部品')
        const orderPoint = getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, '発注点 for Hexa')
        if (!err) {
          if (receiptScheduleId && values[receiptScheduleId]) {
            values[receiptScheduleId] = values[receiptScheduleId].toISOString()
          }
          const item = {}
          Object.entries(values).forEach(([key, value]) => {
            switch (key) {
              case supplier:
                item[key] = [value]
                break
              case inventorySharing:
                item[key] = [value]
                break
              case customOrder:
                const customOrderData = value ? customOrderValue.YES : customOrderValue.NO
                item[key] = String(customOrderData)
                break
              case orderPoint:
                item[key] = typeof value == 'string' ? 0 : value
                break
              default:
                item[key] = value
            }
          })
          dispatch(
            ItemsDetailsActions.createItemRequest({ dId: currentDid, item, navigatePath: routePath.INVENTORY_LIST }),
          )
        }
      })
    },
    [dispatch, currentDid, columns, routePath],
  )

  const deleteItem = useCallback(() => {
    dispatch(ItemsDetailsActions.deleteItemRequest({ navigatePath: routePath.INVENTORY_LIST }))
  }, [dispatch])

  const clearError = () => {
    dispatch(ItemsDetailsActions.clearError())
  }

  useEffect(() => {
    if (datastores_list && isActive) {
      dispatch(
        DatastoreActions.setCurrentDatastoreId({
          datastoreId: getDatastoreIdFromName(datastores_list, hexaLabel.INVENTORY_DB),
        }),
      )
      dispatch(ItemsActions.setCurrentItemId({ itemId: currentDetailList.i_id }))
    }
  }, [dispatch, datastores_list, currentDetailList.i_id, isActive, location.pathname])

  const inventoryDetailResource = {
    fields,
    actions,
    supplierMembers,
    currentDetailList: currentDetailList as InventoryDetailList,
    columns: columns as InventoryDetailColumns,
    submit,
    copyItem,
    deleteItem,
    isSupplier,
    isShiroyama,
    doublePreventionFlg,
    setDoublePreventionFlg,
    completeFlg,
    setCompleteFlg,
    isErrorDialog,
    getErrorMessage,
    clearError,
  }
  return <InventoryDetailPage inventoryDetailResource={inventoryDetailResource} />
}

export default InventoryDetailContainer
