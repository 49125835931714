import { DatastoresState, initialState } from './state'
import { PayloadAction } from 'redux-starter-kit'
import { Datastore } from './types'
import { RootState } from '../rootReducer'

export const setCurrentDatastoreId = (
  state: DatastoresState,
  action: PayloadAction<{ datastoreId: string }>,
): DatastoresState => {
  return {
    ...state,
    current_datastore_id: action.payload.datastoreId,
  }
}

export const getDatastoresRequest = (
  state: DatastoresState,
  action: PayloadAction<{ workspaceId: string }>,
): DatastoresState => {
  return {
    ...state,
    loading: true,
  }
}

export const getDatastoresSuccess = (
  state: DatastoresState,
  action: PayloadAction<{ datastores: Array<Datastore> }>,
): DatastoresState => {
  return {
    ...state,
    datastores_list: action.payload.datastores,
    loading: false,
    error: '',
  }
}

export const getDatastoresFailed = (
  state: DatastoresState,
  action: PayloadAction<{ error: string }>,
): DatastoresState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const reset = (state: DatastoresState): DatastoresState => {
  return {
    ...initialState,
  }
}

export const datastoreSelector = (state: RootState): DatastoresState => state.datastores
