import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { datastoreSelector } from 'app/services/store/datastore/reducers'
import { DatastoreActions, GroupSelectors, ItemsSelectors, UsersSelectors, ItemsActions } from 'app/services/store'
import { rootSelector } from 'app/services/store/rootReducer'

import { getDatastoreIdFromName } from 'app/utils/store'
import { Columns } from 'app/services/store/items/types'
import { hexaLabel } from 'app/constants/hexalink'

import AppLayout from 'app/components/templates/AppLayout'
import OrderPage from 'app/components/pages/order/OrderPage'
import { SearchCondition } from 'app/services/store/items/types'
import { OrderDetailList } from 'app/components/pages/order/types'
import {
  getItemIdFromKey,
  mergeHeaderSearchCondition,
  isTargetByConditions,
  changeConditionRule,
} from 'app/utils/components/common'
import { statusLabel } from 'app/constants/order'
import { createSupplierSearchCondition } from 'app/utils/components/order'

const OrderContainer = (): JSX.Element => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const itemsLoading = ItemsSelectors.getItemsIsLoading(useSelector(rootSelector))
  const displayableEntries = ItemsSelectors.getDisplayableEntries(useSelector(rootSelector))
  const { datastores_list } = useSelector(datastoreSelector)
  const { items, columns } = displayableEntries
  const isShiroyama = UsersSelectors.isShiroyama(useSelector(rootSelector))
  const isSupplier = UsersSelectors.isSupplier(useSelector(rootSelector))
  const totalItems = ItemsSelectors.getTotalItems(useSelector(rootSelector))
  const getUserId = UsersSelectors.getUserId(useSelector(rootSelector))
  const supplierFIeldId = getItemIdFromKey<Columns, keyof OrderDetailList>(columns, '発注先')
  const statusFieldId = getItemIdFromKey<Columns, keyof OrderDetailList>(columns, 'ステータス')
  const supplierMembers = GroupSelectors.getSupplierGroupMembers(useSelector(rootSelector))
  const statusField = ItemsSelectors.getStatusField(useSelector(rootSelector))

  useEffect(() => {
    if (datastores_list) {
      if (isSupplier && supplierFIeldId != null && getUserId != null) {
        // サプライヤ特有の検索条件付与
        const conditions = createSupplierSearchCondition(supplierFIeldId, getUserId, columns, statusFieldId, false)
        dispatch(
          DatastoreActions.setCurrentDatastoreId({
            datastoreId: getDatastoreIdFromName(datastores_list, hexaLabel.ORDER_DB),
            type: 'itemSearch',
            params: { page: 1, fieldId: '', order: '', conditions: conditions },
          }),
        )
      } else {
        dispatch(
          DatastoreActions.setCurrentDatastoreId({
            datastoreId: getDatastoreIdFromName(datastores_list, hexaLabel.ORDER_DB),
          }),
        )
      }
    }
  }, [dispatch, datastores_list, pathname, supplierFIeldId, getUserId, statusFieldId])

  const updateSearchParams = (page: number, fieldId: string, order: string, conditions: Array<SearchCondition>) => {
    // ステータス検索
    if (statusField !== undefined && statusField.statuses.length > 0) {
      let statusList = statusField.statuses
      // サプライヤーの場合は検索対象を絞り込む
      if (isSupplier) {
        statusList = statusField.statuses.filter(
          status => ![statusLabel.DRAFT_ORDER, statusLabel.NEED_CONFIRM].includes(status.name),
        )
      }
      conditions = [...mergeHeaderSearchCondition(conditions, statusFieldId, statusList, 'name', 'id')]
    }

    // サプライヤー検索 - 白山権限のみで実行可
    if (isShiroyama) {
      conditions = [
        ...mergeHeaderSearchCondition(conditions, supplierFIeldId, supplierMembers.members, 'username', 'u_id'),
      ]
    }

    // サプライヤ権限特有フィルタ
    if (isSupplier && supplierFIeldId != null && getUserId != null) {
      // サプライヤ特有の検索条件付与
      const supplierCondition = createSupplierSearchCondition(
        supplierFIeldId,
        getUserId,
        columns,
        statusFieldId,
        isTargetByConditions(conditions, statusFieldId),
      )
      conditions.push(...supplierCondition)
    }

    dispatch(ItemsActions.setSearchParams({ page: page, fieldId: fieldId, order: order, conditions: conditions }))
  }

  return (
    <AppLayout>
      <OrderPage
        updateSearchParams={updateSearchParams}
        itemsLoading={itemsLoading}
        items={items}
        totalItmes={totalItems}
        columns={columns}
        isSupplier={isSupplier}
      />
    </AppLayout>
  )
}

export default OrderContainer
