export const hexaLabel = {
  WORKSPACE_NAME: '白山工業様',
  PROJECT_NAME: '発注管理',
  ORDER_DB: '発注DB',
  DELIVERY_SLIP_DB: '納品書',
  INVENTORY_DB: '在庫管理',
  INVENTORY_HISTORY_DB: '入庫履歴',
  DELIVERY_HISTORY_DB: '出庫履歴',
  SUPPLIER_MASTER: 'サプライヤーマスタ',
}

export const groupLabel = {
  SUPPLIER: 'サプライヤー',
  SHIROYAMA: '白山工業',
}

export const dataReport = {
  inventoryReport: '5e4b920e743ab50006d1278a',
  UNCONFIRMED_DRAFT_LIST: 'unconfirmedDraftList',
  WAITING_FOR_DELIVERYDATE_REPLY: 'waitingForDeliveryDateReply',
  DELIVERY_DATE_CONFIRMED: 'deliveryDateConfirmed',
  STOCK_TODAY: 'stockToday',
  UNSTOCKED_LIST: 'unstockedList',
  DELIVERY_NOTE_LIST: '5f98dcd8d8e2bd0001db097d',
}

export const deliverySlipActionID = {
  DELIVERYSLIP_CREATED: '5e14773377dc58000633ea93',
}

export const deliverySlipSearchFieldId = {
  SUPPLIER: 'b3658e64-1a17-4534-9670-b21ef88f4064',
}
