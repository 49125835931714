import React from 'react'
import { useSelector } from 'react-redux'
import { Router, Route, Switch } from 'react-router-dom'
import { History } from 'history'

import { rootSelector } from 'app/services/store/rootReducer'
import { UsersSelectors } from 'app/services/store'

import ErrorBoundary from 'app/components/utils/errorBoundary/errorBoundary'
import { routePath } from 'app/constants/router'

import LoginContainer from 'app/container/login/LoginContainer'
import ResetPasswordContainer from 'app/container/login/ResetPasswordContainer'
import ChangePasswordContainer from 'app/container/login/ChangePasswordContainer'

import ShiroyamaRouter from 'app/config/router/ShiroyamaRouter'
import SupplierRouter from 'app/config/router/SupplierRouter'

interface CustomRouterProps {
  history: History
}

const AppRouter = ({ history }: CustomRouterProps): JSX.Element => {
  const isSupplier = UsersSelectors.isSupplier(useSelector(rootSelector))
  return (
    <Router history={history}>
      <ErrorBoundary>
        <Switch>
          <Route exact path={routePath.LOGIN} component={LoginContainer} />
          <Route path={routePath.RESET_PASSWORD} component={ResetPasswordContainer} />
          <Route exact path={routePath.CHANGE_PASSWORD} component={ChangePasswordContainer} />
          {!isSupplier && <ShiroyamaRouter />}
          {isSupplier && <SupplierRouter />}
        </Switch>
      </ErrorBoundary>
    </Router>
  )
}

export default AppRouter
