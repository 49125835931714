import { createSlice } from '@reduxjs/toolkit'
import * as reducers from './reducers'
import { initialState } from './state'

export const dataReportSlice = createSlice({
  name: 'dataReport',
  initialState: initialState,
  reducers: {
    getDataReportRequest: reducers.getDataReportRequest,
    getDataReportSuccess: reducers.getDataReportSuccess,
    getDataReportFaild: reducers.getDataReportFailed,
    getFilteredDataReportRequest: reducers.getFilteredDataReportRequest,
    getFilteredDataReportSuccess: reducers.getDataReportSuccess,
    getFilteredDataReportFaild: reducers.getDataReportFailed,
    getDataReportSearchConditionsRequest: reducers.getDataReportSearchConditionsRequest,
    getDataReportSearchConditionsSuccess: reducers.getDataReportSearchConditionsSuccess,
    reset: reducers.reset,
  },
})

export const {
  getDataReportRequest,
  getDataReportSuccess,
  getDataReportFaild,
  getFilteredDataReportRequest,
  getFilteredDataReportSuccess,
  getFilteredDataReportFaild,
  getDataReportSearchConditionsRequest,
} = dataReportSlice.actions
