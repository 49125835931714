import { User } from './types'

export interface UserState extends User {
  loading: boolean
  error: string
}

export const initialState: UserState = {
  auth_key: '',
  u_id: '',
  username: '',
  email: '',
  profile_pic: '',
  current_workspace_id: '',
  is_ws_admin: false,
  user_roles: [
    {
      r_id: '',
      role_name: '',
      p_id: '',
      application_id: '',
      application_name: '',
      application_display_order: 0,
    },
  ],
  loading: false,
  error: '',
}
