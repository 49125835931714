import React, { useState, useEffect } from 'react'
import { Row, Col, Input, Select, DatePicker, Form, InputNumber, Upload, Button } from 'antd'

import Moment from 'moment'

import { OrderDetailList, ActionName } from 'app/components/pages/order/types'
import { Columns } from 'app/services/store/items/types'
import { ActionFieldSettings } from 'app/services/store/itemDetails/types'
import { GroupMembers } from 'app/services/store/group/types'
import {
  actionNameLabel,
  dateFormat,
  errorMessage,
  errorMessageRange,
  orderColumnNameLabel,
  uploadPDFPattern,
} from 'app/constants/order'

import { mandatoryFormIdExtractor, getUserIdFromUserName } from 'app/utils/components/order'
import {
  getItemIdFromKey,
  hasFieldError,
  isItemUpdatable,
  isItemRequired,
  getSupplierOptions,
} from 'app/utils/components/common'

import LowerButtonArea from 'app/components/organisms/order/LowerButtonArea'

const { TextArea } = Input
const { Option } = Select

interface DetailInfoProps {
  detailInfoResource: {
    currentDetailList: OrderDetailList
    getFieldDecorator: Function
    getFieldsError: Function
    closeEditMode: () => void
    isEditMode: boolean
    columns: Columns
    actionFieldSettings: ActionFieldSettings
    handleSubmit: (e: React.FormEvent) => void
    setOperationAndOpenEditMode: (operation: ActionName) => void
    statusIndex: number
    supplierMembers: GroupMembers
    getFieldsValue: Function
    isSupplier
    uploadProps: {}
    fileList: any
    currentOperation: any
  }
}

const DetailInfo = ({
  detailInfoResource: {
    currentDetailList,
    getFieldDecorator,
    getFieldsError,
    isEditMode,
    columns,
    actionFieldSettings,
    handleSubmit,
    statusIndex,
    setOperationAndOpenEditMode,
    closeEditMode,
    supplierMembers,
    getFieldsValue,
    isSupplier,
    uploadProps,
    fileList,
    currentOperation,
  },
}: DetailInfoProps): JSX.Element => {
  const canUpdateItem = isItemUpdatable(actionFieldSettings, columns)
  const isRequiredItem = isItemRequired(actionFieldSettings, columns)
  const supplierOptions = getSupplierOptions(supplierMembers)
  const fieldsList = getFieldsValue()
  const existsErrMsg = hasFieldError(getFieldsError())

  const [hasErrors, setHasErrors] = useState(true)

  useEffect(() => {
    // ステータスアクション押下後入力チェック情報が削除されていない為対応
    if (currentOperation === actionNameLabel.DELETE) {
      setHasErrors(false)
      return
    }
    const mandatoryFormIdList = mandatoryFormIdExtractor(actionFieldSettings)
    const errorFormIdList: Array<string> = []
    if (mandatoryFormIdList && fieldsList) {
      mandatoryFormIdList.forEach(mandatoryFormId => {
        if (fieldsList[mandatoryFormId] === null) {
          errorFormIdList.push(mandatoryFormId)
        }
      })
    }
    const existsErrForm = errorFormIdList && errorFormIdList.length > 0
    setHasErrors(existsErrForm || existsErrMsg)
  }, [fieldsList, existsErrMsg, actionFieldSettings])

  const LowerButtonAreaResource = {
    statusIndex,
    setOperationAndOpenEditMode,
    closeEditMode,
    isEditMode,
    hasErrors,
    supplierMembers,
    isSupplier,
    currentDetailList,
  }

  return (
    <Row>
      <Col>
        <Form layout="vertical" onSubmit={handleSubmit}>
          {/* 基本情報エリア */}
          <div className="basicInfo">
            <h3>基本情報</h3>
            <Row style={{ marginBottom: '10px', borderTop: '1px solid lightGray', paddingTop: '10px' }}>
              <Col span={5} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="客先注文No"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.CUSTOMER_NO)}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.CUSTOMER_NO) || '',
                    {
                      initialValue: currentDetailList[orderColumnNameLabel.CUSTOMER_NO] || null,
                    },
                  )(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col span={5} offset={1} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="在庫ID"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.INVENTORY_ID)}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.INVENTORY_ID) || '',
                    {
                      initialValue: currentDetailList[orderColumnNameLabel.INVENTORY_ID] || null,
                    },
                  )(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col span={5} offset={1} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="在庫名"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.INVENTORY_NAME)}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.INVENTORY_NAME) ||
                      '',
                    {
                      initialValue: currentDetailList[orderColumnNameLabel.INVENTORY_NAME] || null,
                    },
                  )(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col span={5} offset={1} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="色"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.COLOR)}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.COLOR) || '',
                    {
                      initialValue: currentDetailList[orderColumnNameLabel.COLOR] || null,
                    },
                  )(<Input disabled />)}
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginBottom: '10px' }}>
              <Col span={5} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="現在庫"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(
                    columns,
                    orderColumnNameLabel.QUANTITY_OF_CURRENT_INVENTORY,
                  )}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(
                      columns,
                      orderColumnNameLabel.QUANTITY_OF_CURRENT_INVENTORY,
                    ) || '',
                    {
                      initialValue: Number(currentDetailList[orderColumnNameLabel.QUANTITY_OF_CURRENT_INVENTORY]),
                    },
                  )(<InputNumber disabled />)}
                </Form.Item>
              </Col>
              <Col span={5} offset={1} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="設定在庫"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.SET_INVENTORY)}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.SET_INVENTORY) || '',
                    {
                      initialValue: Number(currentDetailList[orderColumnNameLabel.SET_INVENTORY]),
                    },
                  )(<InputNumber disabled />)}
                </Form.Item>
              </Col>
              <Col span={5} offset={1} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="発注点"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.ORDER_PONIT)}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.ORDER_PONIT) || '',
                    {
                      initialValue: Number(currentDetailList[orderColumnNameLabel.ORDER_PONIT]),
                    },
                  )(<InputNumber disabled />)}
                </Form.Item>
              </Col>
            </Row>
          </div>
          {/* 白山工業エリア */}
          <div className="basicInfo">
            <Row type="flex" justify="start">
              <Col span={2}>
                <h3>白山工業</h3>
              </Col>
            </Row>
            <Row style={{ borderTop: '1px solid lightGray', paddingTop: '10px' }}>
              <Col span={5} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="サプライヤー"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.SUPPLIER)}
                  required={true}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.SUPPLIER) || '',
                    {
                      initialValue: getUserIdFromUserName(
                        currentDetailList[orderColumnNameLabel.SUPPLIER],
                        supplierOptions,
                      ),
                      rules: [{ required: true, message: errorMessage }],
                    },
                  )(
                    <Select disabled={!isEditMode || isSupplier || !canUpdateItem(orderColumnNameLabel.SUPPLIER)}>
                      {supplierOptions.map((supplier, idx: number) => (
                        <Option key={idx} value={supplier.u_id}>
                          {supplier.username}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={5} offset={1} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="サプライヤー直接入力"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.SUPPLIER_INPUT)}
                  required={isRequiredItem(orderColumnNameLabel.SUPPLIER_INPUT)}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.SUPPLIER_INPUT) ||
                      '',
                    {
                      initialValue: currentDetailList[orderColumnNameLabel.SUPPLIER_INPUT] || null,
                      rules: [{ required: isRequiredItem(orderColumnNameLabel.SUPPLIER_INPUT), message: errorMessage }],
                    },
                  )(<Input disabled={!isEditMode || !canUpdateItem(orderColumnNameLabel.SUPPLIER_INPUT)} />)}
                </Form.Item>
              </Col>
              <Col span={5} offset={1} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="発注日"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.ORDERED_DATE)}
                  required={isRequiredItem(orderColumnNameLabel.ORDERED_DATE)}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.ORDERED_DATE) || '',
                    {
                      initialValue: currentDetailList[orderColumnNameLabel.ORDERED_DATE]
                        ? Moment(currentDetailList[orderColumnNameLabel.ORDERED_DATE])
                        : null,
                      rules: [{ required: isRequiredItem(orderColumnNameLabel.ORDERED_DATE), message: errorMessage }],
                    },
                  )(
                    <DatePicker
                      disabled={!isEditMode || !canUpdateItem(orderColumnNameLabel.ORDERED_DATE)}
                      format={dateFormat}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={5} offset={1} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="発注個数"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.ORDERED_QUANTITY)}
                  required={isRequiredItem(orderColumnNameLabel.ORDERED_QUANTITY)}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.ORDERED_QUANTITY) ||
                      '',
                    {
                      initialValue: Number(currentDetailList[orderColumnNameLabel.ORDERED_QUANTITY]),
                      rules: [
                        { required: isRequiredItem(orderColumnNameLabel.ORDERED_QUANTITY), message: errorMessage },
                        { type: 'number', min: 1, message: '1個' + errorMessageRange },
                      ],
                    },
                  )(<InputNumber disabled={!isEditMode || !canUpdateItem(orderColumnNameLabel.ORDERED_QUANTITY)} />)}
                </Form.Item>
              </Col>
              <Col span={5} offset={1} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="特注品図面PDF"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.DRAWING_PDF)}
                >
                  {(() => {
                    const elementStr: string =
                      fileList.length > 0 ? uploadPDFPattern.OVERWRITE_FILE : uploadPDFPattern.NEW_FILE
                    return (
                      <Upload {...uploadProps} fileList={fileList} disabled={!isEditMode || isSupplier}>
                        <Button disabled={!isEditMode || isSupplier}>{elementStr}</Button>
                      </Upload>
                    )
                  })()}
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginBottom: '10px' }}>
              <Col span={5}>
                <Form.Item
                  label="希望納期"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(
                    columns,
                    orderColumnNameLabel.DESIRED_DELIVERY_DATE,
                  )}
                  required={isRequiredItem(orderColumnNameLabel.DESIRED_DELIVERY_DATE)}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(
                      columns,
                      orderColumnNameLabel.DESIRED_DELIVERY_DATE,
                    ) || '',
                    {
                      initialValue: currentDetailList[orderColumnNameLabel.DESIRED_DELIVERY_DATE]
                        ? Moment(currentDetailList[orderColumnNameLabel.DESIRED_DELIVERY_DATE])
                        : null,
                      rules: [
                        { required: isRequiredItem(orderColumnNameLabel.DESIRED_DELIVERY_DATE), message: errorMessage },
                      ],
                    },
                  )(
                    <DatePicker
                      disabled={!isEditMode || !canUpdateItem(orderColumnNameLabel.DESIRED_DELIVERY_DATE)}
                      format={dateFormat}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={5} offset={1} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="納期回答 締切"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(
                    columns,
                    orderColumnNameLabel.LIMIT_OF_ANSWERING,
                  )}
                  required={isRequiredItem(orderColumnNameLabel.LIMIT_OF_ANSWERING)}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(
                      columns,
                      orderColumnNameLabel.LIMIT_OF_ANSWERING,
                    ) || '',
                    {
                      initialValue: currentDetailList[orderColumnNameLabel.LIMIT_OF_ANSWERING]
                        ? Moment(currentDetailList[orderColumnNameLabel.LIMIT_OF_ANSWERING])
                        : null,
                      rules: [
                        { required: isRequiredItem(orderColumnNameLabel.LIMIT_OF_ANSWERING), message: errorMessage },
                      ],
                    },
                  )(<DatePicker disabled={!isEditMode || !canUpdateItem(orderColumnNameLabel.LIMIT_OF_ANSWERING)} />)}
                </Form.Item>
              </Col>
              <Col span={5} offset={1} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="希望単価"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(
                    columns,
                    orderColumnNameLabel.DESIRED_UNIT_COST,
                  )}
                  required={isRequiredItem(orderColumnNameLabel.DESIRED_UNIT_COST)}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.DESIRED_UNIT_COST) ||
                      '',
                    {
                      initialValue: Number(currentDetailList[orderColumnNameLabel.DESIRED_UNIT_COST]),
                      rules: [
                        { required: isRequiredItem(orderColumnNameLabel.DESIRED_UNIT_COST), message: errorMessage },
                      ],
                    },
                  )(<InputNumber disabled={!isEditMode || !canUpdateItem(orderColumnNameLabel.DESIRED_UNIT_COST)} />)}
                </Form.Item>
              </Col>
              <Col span={5} offset={1} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="基本単価"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.UNIT_COST)}
                  required={isRequiredItem(orderColumnNameLabel.UNIT_COST)}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.UNIT_COST) || '',
                    {
                      initialValue: Number(currentDetailList[orderColumnNameLabel.UNIT_COST]),
                      rules: [{ required: isRequiredItem(orderColumnNameLabel.UNIT_COST), message: errorMessage }],
                    },
                  )(
                    <InputNumber
                      disabled={!isEditMode || !canUpdateItem(orderColumnNameLabel.UNIT_COST)}
                      required={isRequiredItem(orderColumnNameLabel.UNIT_COST)}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
          {/* サプライヤーエリア */}
          <div className="basicInfo">
            <Row type="flex" justify="start">
              <Col span={2}>
                <h3>サプライヤー</h3>
              </Col>
            </Row>

            <Row style={{ marginBottom: '10px', borderTop: '1px solid lightGray', paddingTop: '10px' }}>
              <Col span={5} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="回答納期"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(
                    columns,
                    orderColumnNameLabel.ANSWERED_DELIVERY_DATE,
                  )}
                  required={isRequiredItem(orderColumnNameLabel.ANSWERED_DELIVERY_DATE)}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(
                      columns,
                      orderColumnNameLabel.ANSWERED_DELIVERY_DATE,
                    ) || '',
                    {
                      initialValue: currentDetailList[orderColumnNameLabel.ANSWERED_DELIVERY_DATE]
                        ? Moment(currentDetailList[orderColumnNameLabel.ANSWERED_DELIVERY_DATE])
                        : null,
                      rules: [
                        {
                          required: isRequiredItem(orderColumnNameLabel.ANSWERED_DELIVERY_DATE),
                          message: errorMessage,
                        },
                      ],
                    },
                  )(
                    <DatePicker
                      disabled={!isEditMode || !canUpdateItem(orderColumnNameLabel.ANSWERED_DELIVERY_DATE)}
                      format={dateFormat}
                    />,
                  )}
                </Form.Item>
              </Col>
              <Col span={5} offset={1} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="見積単価"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.ESTIMATED_PRICE)}
                  required={isRequiredItem(orderColumnNameLabel.ESTIMATED_PRICE)}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.ESTIMATED_PRICE) ||
                      '',
                    {
                      initialValue: Number(currentDetailList[orderColumnNameLabel.ESTIMATED_PRICE]),
                      rules: [
                        { required: isRequiredItem(orderColumnNameLabel.ESTIMATED_PRICE), message: errorMessage },
                      ],
                    },
                  )(<InputNumber disabled={!isEditMode || !canUpdateItem(orderColumnNameLabel.ESTIMATED_PRICE)} />)}
                </Form.Item>
              </Col>
              <Col span={5} offset={1} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="見積合計"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(
                    columns,
                    orderColumnNameLabel.ESTIMATED_PRICE_OF_SUM,
                  )}
                  required={isRequiredItem(orderColumnNameLabel.ESTIMATED_PRICE_OF_SUM)}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(
                      columns,
                      orderColumnNameLabel.ESTIMATED_PRICE_OF_SUM,
                    ) || '',
                    {
                      initialValue: Number(currentDetailList[orderColumnNameLabel.ESTIMATED_PRICE_OF_SUM]),
                      rules: [
                        {
                          required: isRequiredItem(orderColumnNameLabel.ESTIMATED_PRICE_OF_SUM),
                          message: errorMessage,
                        },
                      ],
                    },
                  )(<InputNumber disabled />)}
                </Form.Item>
              </Col>
              <Col span={5} offset={1} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="運賃"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.FREIGHT)}
                  required={isRequiredItem(orderColumnNameLabel.FREIGHT)}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.FREIGHT) || '',
                    {
                      initialValue: Number(currentDetailList[orderColumnNameLabel.FREIGHT]),
                      rules: [{ required: isRequiredItem(orderColumnNameLabel.FREIGHT), message: errorMessage }],
                    },
                  )(<InputNumber disabled={!isEditMode || !canUpdateItem(orderColumnNameLabel.FREIGHT)} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginBottom: '30px' }}>
              <Col span={5} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="配送方法"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(
                    columns,
                    orderColumnNameLabel.METHOD_OF_SHIPMENT,
                  )}
                  required={isRequiredItem(orderColumnNameLabel.METHOD_OF_SHIPMENT)}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(
                      columns,
                      orderColumnNameLabel.METHOD_OF_SHIPMENT,
                    ) || '',
                    {
                      initialValue: currentDetailList[orderColumnNameLabel.METHOD_OF_SHIPMENT] || null,
                      rules: [
                        { required: isRequiredItem(orderColumnNameLabel.METHOD_OF_SHIPMENT), message: errorMessage },
                      ],
                    },
                  )(<Input disabled={!isEditMode || !canUpdateItem(orderColumnNameLabel.METHOD_OF_SHIPMENT)} />)}
                </Form.Item>
              </Col>
              <Col span={5} offset={1} style={{ minWidth: '300px' }}>
                <Form.Item
                  label="出荷日"
                  key={getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.SHIPPING_DATE)}
                  required={isRequiredItem(orderColumnNameLabel.SHIPPING_DATE)}
                >
                  {getFieldDecorator(
                    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.SHIPPING_DATE) || '',
                    {
                      initialValue: currentDetailList[orderColumnNameLabel.SHIPPING_DATE]
                        ? Moment(currentDetailList[orderColumnNameLabel.SHIPPING_DATE])
                        : null,
                      rules: [{ required: isRequiredItem(orderColumnNameLabel.SHIPPING_DATE), message: errorMessage }],
                    },
                  )(
                    <DatePicker
                      disabled={!isEditMode || !canUpdateItem(orderColumnNameLabel.SHIPPING_DATE)}
                      format={dateFormat}
                    />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col style={{ visibility: isEditMode ? 'visible' : 'hidden', height: isEditMode ? 'auto' : '0px' }}>
                <Form.Item label="コメント">
                  {getFieldDecorator(orderColumnNameLabel.BOTTOM_COMMENT, {
                    initialValue: '',
                  })(<TextArea rows={3} />)}
                </Form.Item>
              </Col>
            </Row>
          </div>
          {/* 下部ボタンエリア */}
          <LowerButtonArea LowerButtonAreaResource={LowerButtonAreaResource} />
        </Form>
      </Col>
    </Row>
  )
}

export default DetailInfo
