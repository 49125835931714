import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { rootSelector } from 'app/services/store/rootReducer'
import {
  ItemsDetailsActions,
  ItemsActions,
  ItemsSelectors,
  ItemDetailsSelectors,
  UsersSelectors,
} from 'app/services/store'
import { Columns } from 'app/services/store/items/types'
import { ItemDetailsAction } from 'app/services/store/itemDetails/types'

import { DeliverySlipDetailList } from 'app/components/pages/deliverySlip/types'

import AppLayout from 'app/components/templates/AppLayout'
import DeliverySlipDetailPage from 'app/components/pages/deliverySlip/DeliverySlipDetailPage'
import { getItemIdFromKey } from 'app/utils/components/common'
import { addComma } from 'app/utils/components/deliverySlip'
import { deliverySlipActionID } from 'app/constants/hexalink'
import moment from 'moment'
import { routePath } from 'app/constants/router'

const DeliverySlipDetailContainer = (): JSX.Element => {
  const location = useLocation()
  const dispatch = useDispatch()

  const { iId } = location.state as { iId: string }
  const { selectItems } = location.state as { selectItems: [] }
  const { items, columns } = ItemsSelectors.getDisplayableEntries(useSelector(rootSelector))
  const currentDetailList = items.filter(item => item.i_id === iId)[0]
  const isSupplier = UsersSelectors.isSupplier(useSelector(rootSelector))

  const isItemsLoading = ItemsSelectors.getItemsIsLoading(useSelector(rootSelector))
  const isItemDetailsLoading = ItemDetailsSelectors.getIsLoading(useSelector(rootSelector))
  const fields = ItemDetailsSelectors.getFields(useSelector(rootSelector))
  const linkedItemData = ItemDetailsSelectors.getDisplayableLinkedItemData(useSelector(rootSelector))
  const actions = ItemDetailsSelectors.getActions(useSelector(rootSelector))
  const displayItemsIid = selectItems.map(item => item['i_id'])
  const searchConditions = {
    id: 'i_id',
    search_value: displayItemsIid,
  }

  const destinationKey = String(getItemIdFromKey<Columns, keyof DeliverySlipDetailList>(columns, '送付先'))
  const streetAddressKey = String(getItemIdFromKey<Columns, keyof DeliverySlipDetailList>(columns, '住所'))
  const telKey = String(getItemIdFromKey<Columns, keyof DeliverySlipDetailList>(columns, '電話番号'))
  const noteKey = String(getItemIdFromKey<Columns, keyof DeliverySlipDetailList>(columns, '備考'))
  const unitKey = String(getItemIdFromKey<Columns, keyof DeliverySlipDetailList>(columns, '単位'))
  const deliveryDateKey = String(getItemIdFromKey<Columns, keyof DeliverySlipDetailList>(columns, '納品日'))
  const pdfFieldKey = String(getItemIdFromKey<Columns, keyof DeliverySlipDetailList>(columns, 'PDF'))
  const keys = {
    destinationKey: destinationKey,
    streetAddressKey: streetAddressKey,
    telKey: telKey,
    noteKey: noteKey,
    unitKey: unitKey,
    deliveryDateKey: deliveryDateKey,
  }

  /** 納品書作成　列情報 */
  const deliverySlipCreateColumns = [
    {
      dataIndex: 'ID',
      key: 'ID',
      title: '在庫ID',
      editable: false,
      editing: false,
      cellType: false,
      inputWidth: '10%',
    },
    {
      dataIndex: '物品名',
      key: '物品名',
      title: '在庫名',
      editable: true,
      editing: true,
      cellType: false,
      inputWidth: '60%',
    },
    {
      dataIndex: '発注個数',
      key: '発注個数',
      title: '発注個数',
      editable: false,
      editing: false,
      cellType: false,
      inputWidth: '15%',
    },
    { dataIndex: '単位', key: '単位', title: '単位', editable: true, editing: true, cellType: true, inputWidth: '15%' },
  ]

  const fixItems = addComma(items, '発注個数')

  const downloadPdf = useCallback(
    (pdfId, fileName) => {
      dispatch(ItemsDetailsActions.getPDF({ pdfId, fileName }))
    },
    [dispatch],
  )

  const getActionId = (name: string, actionList: Array<ItemDetailsAction>): {} => {
    const actionObj = actionList.filter(a => a.name == name)
    return actionObj.length ? actionObj[0] : {}
  }

  const submit = useCallback(
    form => {
      form.validateFields((err, values) => {
        if (err) {
          window.scroll({ top: 60, behavior: 'smooth' })
          return
        }

        const itemObject: { [k: string]: any } = {}
        const commonObject: { [k: string]: string }[] = []
        Object.keys(values).forEach(key => {
          if (key.includes('-')) {
            // 0 = field_id  1 = i_id
            const keyArray = key.split('-')
            const fieldId = keyArray[0]
            const iId = keyArray[1]
            if (!itemObject[iId]) {
              itemObject[iId] = []
            }
            itemObject[iId].push({ id: fieldId, value: values[key] })
            return
          }
          if (key == deliveryDateKey) values[key] = values[key].format('YYYY-MM-DD') //moment Object
          commonObject.push({ id: key, value: values[key] })
        })

        const getActionUpdate = getActionId('内容を更新し未作成に移行', actions)

        if (Object.keys(getActionUpdate).length) {
          let lastUpdateFlag = false
          let loopCount = 0
          const updateCount = Object.keys(itemObject).length
          const lastAction = deliverySlipActionID.DELIVERYSLIP_CREATED // deliverySlipActionID.DELIVERYSLIP_CREATED
          for (const itemId in itemObject) {
            loopCount++
            const changes = [...commonObject, ...itemObject[itemId]]
            const foundRow = items.find(item => item['i_id'] == itemId)
            // 最後に更新するアイテムはaction_Idでステータス変更を行う為、フラグをtrueにする
            if (updateCount == loopCount) {
              lastUpdateFlag = true
            }

            const itemsArray: string[] = []
            Object.values(selectItems).forEach(v => itemsArray.push(v['i_id']))
            // 前回PDFファイルを削除後更新
            if (foundRow && foundRow['PDF'] != null && foundRow['PDF'] != '') {
              dispatch(
                ItemsDetailsActions.updateDeliverySlip({
                  item_id: itemId,
                  field_id: pdfFieldKey,
                  file_id: foundRow['PDF'],
                  changes: changes,
                  actions: getActionUpdate,
                  last_action: lastAction,
                  lastFlag: lastUpdateFlag,
                  type: 'delivery-slip-detail',
                }),
              )
            } else {
              dispatch(
                ItemsActions.postActionRequest({
                  itemId: itemId,
                  datastoreId: getActionUpdate['d_id'],
                  actionId: getActionUpdate['a_id'],
                  changes,
                  lastFlag: lastUpdateFlag,
                  action_Id: lastAction,
                  actionType: 'delivery-slip-detail',
                }),
              )
            }
          }
        }
      })
    },
    [dispatch, items, actions, deliverySlipActionID, deliveryDateKey, pdfFieldKey],
  )

  useEffect(() => {
    dispatch(ItemsDetailsActions.getItemDetailsRequest({ itemId: iId }))
    dispatch(ItemsActions.setSearchParams({ page: 1, fieldId: '', order: '', conditions: [searchConditions] }))
  }, [dispatch])

  const deliverySlipDetailResource = {
    fields,
    currentDetailList: currentDetailList as DeliverySlipDetailList,
    items: fixItems,
    submit,
    downloadPdf,
    isLoading: isItemsLoading || isItemDetailsLoading,
    linkedItemData,
    keys,
    deliverySlipCreateColumns,
    isSupplier: isSupplier,
  }

  return (
    <AppLayout>
      <DeliverySlipDetailPage deliverySlipDetailResource={deliverySlipDetailResource} />
    </AppLayout>
  )
}

export default DeliverySlipDetailContainer
