import React, { useState } from 'react'
import { Row, Col, Button, Input, InputNumber, Form, Select, Checkbox } from 'antd'
import { FormComponentProps } from 'antd/lib/form/Form'

import { Columns } from 'app/services/store/items/types'
import { getItemIdFromKey } from 'app/utils/components/common'
import { getErrstr } from 'app/utils/components/inventory'
import { inventoryColumnNameLabel, inventoryLabel } from 'app/constants/inventory'
import { InventoryDetailList } from './types'
import { GroupMembers } from 'app/services/store/group/types'
import { getSupplierOptions } from 'app/utils/components/inventory'
import { maxLengthMessage } from 'app/constants/deliverySlip'
import { strCount, replaceMessage } from 'app/utils/components/common'

interface InventoryCreateProps extends FormComponentProps {
  addInentoryResoce: {
    columns: Columns
    dbFields: any
    supplierMembers: GroupMembers
    createItem: (form) => void
    isSupplier: boolean
    isShiroyama: boolean
  }
}

const InventoryCreate = ({
  addInentoryResoce: { columns, dbFields, supplierMembers, isSupplier, isShiroyama, createItem },
  form,
}: InventoryCreateProps): JSX.Element => {
  const { Option } = Select
  const { getFieldDecorator } = form

  const categoryFieldId = getItemIdFromKey<Columns, keyof InventoryDetailList>(
    columns,
    inventoryColumnNameLabel.CATEGORY,
  )
  const category = dbFields.filter(x => x.field === categoryFieldId)
  const categoryOptions = category[0].options
  const supplierOptions = getSupplierOptions(supplierMembers)

  const [orderCheck, setOrderCheck] = useState(false)
  const orderPointName = String(getItemIdFromKey(columns, inventoryColumnNameLabel.ORDER_PONIT_FOR_HEXA))
  const clearValue = (name: string): void => {
    const define = {}
    define[name] = ''
    form.setFieldsValue(define)
  }
  const onCustomOrderChangehandle = (): void => {
    setOrderCheck(prev => {
      if (!prev) clearValue(orderPointName)
      return !prev
    })
  }

  return (
    <Form layout="horizontal">
      <Row type="flex" justify="center">
        <Col span={24}>
          <h2>新規登録</h2>
          <Row
            type="flex"
            align="middle"
            justify="start"
            style={{ marginBottom: '10px', borderTop: '1px solid lightGray', paddingTop: '30px' }}
          >
            <Col span={6}>
              <Form.Item
                label={inventoryLabel.PRODUCT_NAME}
                key={getItemIdFromKey(columns, inventoryColumnNameLabel.PRODUCT_NAME)}
                labelCol={{ span: 5, offset: 0 }}
                wrapperCol={{ span: 19 }}
                required={true}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.PRODUCT_NAME,
                  ) || '',
                  {
                    initialValue: '',
                    rules: [
                      {
                        required: true,
                        message: getErrstr('required', inventoryLabel.PRODUCT_NAME),
                      },
                    ],
                  },
                )(<Input />)}
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item
                label={inventoryLabel.PRODUCT_ID}
                key={getItemIdFromKey(columns, inventoryColumnNameLabel.PRODUCT_ID)}
                labelCol={{ span: 5, offset: 1 }}
                wrapperCol={{ span: 18 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, inventoryColumnNameLabel.PRODUCT_ID) ||
                    '',
                  {
                    initialValue: '',
                    rules: [
                      {
                        required: true,
                        message: getErrstr('required', inventoryLabel.PRODUCT_ID),
                      },
                    ],
                  },
                )(<Input />)}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={inventoryLabel.QUANTITY_OF_CURRENT_INVENTORY}
                key={getItemIdFromKey(columns, inventoryColumnNameLabel.QUANTITY_OF_CURRENT_INVENTORY)}
                labelCol={{ span: 7, offset: 1 }}
                wrapperCol={{ span: 16 }}
                required={true}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.QUANTITY_OF_CURRENT_INVENTORY,
                  ) || '',
                  {
                    initialValue: '',
                    rules: [
                      {
                        required: true,
                        message: getErrstr('required', inventoryLabel.QUANTITY_OF_CURRENT_INVENTORY),
                      },
                      {
                        type: 'number',
                        message: getErrstr('number', inventoryLabel.QUANTITY_OF_CURRENT_INVENTORY),
                      },
                    ],
                  },
                )(<InputNumber style={{ width: '120px' }} />)}
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item
                label={inventoryLabel.UNIT}
                key={getItemIdFromKey(columns, inventoryColumnNameLabel.UNIT)}
                labelCol={{ span: 5, offset: 1 }}
                wrapperCol={{ span: 18 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, inventoryColumnNameLabel.UNIT) || '',
                  {
                    initialValue: '',
                    rules: [
                      {
                        validator: (rule: any, value: string, callback: (msg?: string) => void) => {
                          const limit = 6
                          strCount(value) > limit
                            ? callback(replaceMessage(maxLengthMessage, '単位', String(limit / 2)))
                            : callback()
                        },
                      },
                    ],
                  },
                )(<Input maxLength={6} />)}
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item
                label={inventoryLabel.COLOR}
                key={getItemIdFromKey(columns, inventoryColumnNameLabel.COLOR)}
                labelCol={{ span: 5, offset: 1 }}
                wrapperCol={{ span: 18 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, inventoryColumnNameLabel.COLOR) || '',
                  {
                    initialValue: '',
                  },
                )(<Input />)}
              </Form.Item>
            </Col>
          </Row>

          <Row type="flex" align="middle" justify="start" style={{ margin: '0 0 10px 0' }}>
            <Col span={6}>
              <Form.Item
                label={inventoryLabel.STORAGE_SPACE}
                key={getItemIdFromKey(columns, inventoryColumnNameLabel.STORAGE_SPACE)}
                labelCol={{ span: 5, offset: 0 }}
                wrapperCol={{ span: 19 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.STORAGE_SPACE,
                  ) || '',
                  {
                    initialValue: '',
                  },
                )(<Input />)}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={inventoryLabel.CATEGORY}
                key={getItemIdFromKey(columns, inventoryColumnNameLabel.CATEGORY)}
                labelCol={{ span: 5, offset: 1 }}
                wrapperCol={{ span: 18 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, inventoryColumnNameLabel.CATEGORY) ||
                    '',
                  {
                    initialValue: '',
                  },
                )(
                  <Select>
                    {categoryOptions &&
                      categoryOptions.map((option, idx) => (
                        <Option key={idx} value={option.o_id}>
                          {option.value}
                        </Option>
                      ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={inventoryLabel.UNIT_COST}
                key={getItemIdFromKey(columns, inventoryColumnNameLabel.UNIT_COST)}
                labelCol={{ span: 5, offset: 1 }}
                wrapperCol={{ span: 18 }}
                required={true}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, inventoryColumnNameLabel.UNIT_COST) ||
                    '',
                  {
                    initialValue: '',
                    rules: [
                      {
                        required: true,
                        message: getErrstr('required', inventoryLabel.UNIT_COST),
                      },
                      {
                        type: 'number',
                        message: getErrstr('number', inventoryLabel.UNIT_COST),
                      },
                    ],
                  },
                )(<InputNumber style={{ width: '308px' }} />)}
              </Form.Item>
            </Col>

            <Col span={6} />
          </Row>

          <Row type="flex" align="middle" justify="start" style={{ margin: '0 0 10px 0' }}>
            <Col span={6}>
              <Form.Item
                label={inventoryLabel.ORDER_PONIT}
                key={getItemIdFromKey(columns, inventoryColumnNameLabel.ORDER_PONIT_FOR_HEXA)}
                labelCol={{ span: 6, offset: 1 }}
                wrapperCol={{ span: 16 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.ORDER_PONIT_FOR_HEXA,
                  ) || '',
                  {
                    initialValue: 0,
                  },
                )(<InputNumber disabled={orderCheck} />)}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={inventoryLabel.NORMAL_DELIVERY_DATE}
                key={getItemIdFromKey(columns, inventoryColumnNameLabel.NORMAL_DELIVERY_DATE)}
                labelCol={{ span: 7, offset: 1 }}
                wrapperCol={{ span: 16 }}
                required={true}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.NORMAL_DELIVERY_DATE,
                  ) || '',
                  {
                    initialValue: 0,
                    rules: [
                      {
                        required: true,
                        message: getErrstr('required', inventoryLabel.NORMAL_DELIVERY_DATE),
                      },
                      {
                        type: 'number',
                        message: getErrstr('number', inventoryLabel.NORMAL_DELIVERY_DATE),
                      },
                    ],
                  },
                )(<InputNumber />)}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label={inventoryLabel.SET_INVENTORY}
                key={getItemIdFromKey(columns, inventoryColumnNameLabel.SET_INVENTORY)}
                labelCol={{ span: 7, offset: 1 }}
                wrapperCol={{ span: 16 }}
                required={true}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.SET_INVENTORY,
                  ) || '',
                  {
                    initialValue: 0,
                    rules: [
                      {
                        required: true,
                        message: getErrstr('required', inventoryLabel.SET_INVENTORY),
                      },
                      {
                        type: 'number',
                        message: getErrstr('number', inventoryLabel.SET_INVENTORY),
                      },
                    ],
                  },
                )(<InputNumber />)}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={inventoryLabel.AMOUNT_OF_DELIVERY}
                key={getItemIdFromKey(columns, inventoryColumnNameLabel.AMOUNT_OF_DELIVERY)}
                labelCol={{ span: 6, offset: 1 }}
                wrapperCol={{ span: 16 }}
                required={true}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.AMOUNT_OF_DELIVERY,
                  ) || '',
                  {
                    initialValue: 0,
                    rules: [
                      {
                        required: true,
                        message: getErrstr('required', inventoryLabel.AMOUNT_OF_DELIVERY),
                      },
                      {
                        type: 'number',
                        message: getErrstr('number', inventoryLabel.AMOUNT_OF_DELIVERY),
                      },
                    ],
                  },
                )(<InputNumber disabled={!isShiroyama} />)}
              </Form.Item>
            </Col>
          </Row>

          <Row type="flex" align="middle" justify="start" style={{ margin: '0 0 10px 0' }}>
            <Col span={6}>
              <Form.Item
                label={inventoryColumnNameLabel.PRODUCT_CONTROL_NUMBER}
                key={getItemIdFromKey<Columns, keyof InventoryDetailList>(
                  columns,
                  inventoryColumnNameLabel.PRODUCT_CONTROL_NUMBER,
                )}
                labelCol={{ span: 5, offset: 0 }}
                wrapperCol={{ span: 19 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.PRODUCT_CONTROL_NUMBER,
                  ) || '',
                  {
                    initialValue: '',
                  },
                )(<Input />)}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={inventoryLabel.CUSTOM_ORDER}
                key={getItemIdFromKey(columns, inventoryColumnNameLabel.CUSTOM_ORDER)}
                labelCol={{ span: 8, offset: 0 }}
                wrapperCol={{ span: 8 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.CUSTOM_ORDER,
                  ) || '',
                  {
                    initialValue: orderCheck,
                  },
                )(<Checkbox checked={orderCheck} onChange={onCustomOrderChangehandle} />)}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={inventoryLabel.NOTE}
                key={getItemIdFromKey(columns, inventoryColumnNameLabel.NOTE)}
                labelCol={{ span: 5, offset: 0 }}
                wrapperCol={{ span: 19 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, inventoryColumnNameLabel.NOTE) || '',
                  {
                    initialValue: '',
                  },
                )(<Input />)}
              </Form.Item>
            </Col>
          </Row>

          <h1 style={{ marginTop: '15px' }}>発注情報</h1>
          <Row
            type="flex"
            align="middle"
            justify="start"
            style={{ marginBottom: '10px', borderTop: '1px solid lightGray', paddingTop: '10px' }}
          >
            <Col span={6}>
              <Form.Item
                label={inventoryLabel.PRIMARY_SUPPLIER}
                key={getItemIdFromKey(columns, inventoryColumnNameLabel.PRIMARY_SUPPLIER)}
                labelCol={{ span: 6, offset: 0 }}
                wrapperCol={{ span: 18 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.PRIMARY_SUPPLIER,
                  ) || '',
                  {
                    initialValue: '',
                  },
                )(
                  <Select>
                    {supplierOptions.map((supplier, idx: number) => (
                      <Option key={idx} value={supplier.u_id}>
                        {supplier.username}
                      </Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={inventoryLabel.PARTNER_WHO_SHARES_INVENTORY}
                key={getItemIdFromKey(columns, inventoryColumnNameLabel.PARTNER_WHO_SHARES_INVENTORY)}
                labelCol={{ span: 6, offset: 1 }}
                wrapperCol={{ span: 17 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.PARTNER_WHO_SHARES_INVENTORY,
                  ) || '',
                  {
                    initialValue: '',
                  },
                )(
                  <Select>
                    {supplierOptions.map((supplier, idx: number) => (
                      <Option key={idx} value={supplier.u_id}>
                        {supplier.username}
                      </Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={6} />
            <Col span={6} />
          </Row>

          <Row type="flex" justify="center">
            <Col span={8}>
              <Form.Item>
                <Button type="primary" className="btn-white" onClick={(): void => createItem(form)} size="large">
                  在庫登録
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

const InventoryCreatePage = Form.create<InventoryCreateProps>()(InventoryCreate)

export default InventoryCreatePage
