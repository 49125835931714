import { ofType } from 'redux-observable'
import { switchMap, catchError, concatMap, filter, map } from 'rxjs/operators'
import { Action, PayloadAction } from '@reduxjs/toolkit'
import { of, Observable } from 'rxjs'

import { DataReportSelectors, DataReportActions, ProjectSelectors, UsersSelectors } from '..'
import HttpService from 'app/services/httpService/httpService'
import { PostFilterDatareportRequest } from './epic.types'
import { DataReport } from './types'
import { DataReportSearchCondition } from './state'
import { AppConstant } from 'app/constants/app'
import { dataReport } from 'app/constants/hexalink'
import { Empty } from 'antd'

const initEpic = (action$, state$): Observable<Action<string>> => {
  const pId = ProjectSelectors.getCurrentProjectId(state$.value)
  const rId = dataReport.WAITING_FOR_DELIVERYDATE_REPLY
  const isLoggedIn = UsersSelectors.isUserLogged(state$.value)
  const reportFields = DataReportSelectors.getReportFields(state$.value)

  return action$.pipe(
    ofType(AppConstant.REHYDRATE_ACTION_TYPE),
    filter(_ => isLoggedIn && pId !== '' && reportFields.length !== 0),
    concatMap(() => {
      return of(DataReportActions.getFilteredDataReportRequest({ pId, rId, conditions: { conditions: [] } }))
    }),
  )
}

const getDataReportRequestEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(DataReportActions.getDataReportRequest),
    switchMap((action: PayloadAction<{ pId: string; rId: string }>) =>
      HttpService.GetAsync<null, DataReport>(
        `applications/${action.payload.pId}/reports/${action.payload.rId}`,
        null,
        HttpService.LinkerAPIBasePath,
      ).pipe(
        switchMap(response => {
          const data = response.data
          return of(DataReportActions.getDataReportSuccess(data))
        }),
        catchError((error: string) => {
          return of(DataReportActions.getDataReportFaild({ error }))
        }),
      ),
    ),
  )

const getFilteredDataReportRequestEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(DataReportActions.getFilteredDataReportRequest),
    switchMap((action: PayloadAction<{ pId: string; rId: string; conditions: PostFilterDatareportRequest }>) => {
      const pId = action.payload.pId
      const rId = action.payload.rId
      const conditions = action.payload.conditions
      return HttpService.PostAsync<PostFilterDatareportRequest, DataReport>(
        `applications/${pId}/reports/${rId}/filter`,
        conditions,
        HttpService.LinkerAPIBasePath,
      ).pipe(
        map(response => {
          const data = response.data
          return DataReportActions.getFilteredDataReportSuccess(data)
        }),
        catchError((error: string) => {
          return of(DataReportActions.getFilteredDataReportFaild({ error }))
        }),
      )
    }),
  )

const getDataReportSearchConditionsRequestEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(DataReportActions.getDataReportSearchConditionsRequest),
    switchMap((action: PayloadAction<{ pId: string; rId: string }>) =>
      HttpService.GetAsync<null, DataReportSearchCondition[]>(
        `applications/${action.payload.pId}/reports/${action.payload.rId}/conditions`,
        null,
        HttpService.LinkerAPIBasePath,
      ).pipe(
        switchMap(response => {
          const data = response.data
          return of(DataReportActions.getDataReportSearchConditionsSuccess(data))
        }),
        catchError((error: string) => {
          return of(DataReportActions.getDataReportFaild({ error }))
        }),
      ),
    ),
  )

export default [
  initEpic,
  getDataReportRequestEpic,
  getFilteredDataReportRequestEpic,
  getDataReportSearchConditionsRequestEpic,
]
