import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { RootState } from 'app/services/store/rootReducer'
import { UsersSelectors } from 'app/services/store'

const PrivateRoute = (props: RouteProps) => {
  const authKey = useSelector((state: RootState) => UsersSelectors.getUserToken(state))
  const { render: Render, component: Component, exact, ...rest }: any = props
  return (
    <Route
      exact={exact}
      {...rest}
      render={props => (authKey === '' ? <Redirect to="/login" /> : <Component {...props} />)}
    />
  )
}

export default PrivateRoute
