import * as UsersSelectors from './user/selectors'
import { userSlice } from './user/slice'

import * as GroupSelectors from './group/selectors'
import { groupSlice } from './group/slice'

import * as MainSelectors from './main/selectors'
import { mainSlice } from './main/slice'

import * as WorkspaceSelectors from './workspace/selectors'
import { workspacesSlice } from './workspace/slice'

import * as ProjectSelectors from './project/selectors'
import { projectsSlice } from './project/slice'

import * as DatastoreSelectors from './datastore/selectors'
import { datastoresSlice } from './datastore/slice'

import * as ItemsSelectors from './items/selectors'
import { itemsSlice } from './items/slice'

import * as ItemDetailsSelectors from './itemDetails/selectors'
import { itemDetailsSlice } from './itemDetails/slice'

import * as DataReportSelectors from './dataReport/selectors'
import { dataReportSlice } from './dataReport/slice'

const UsersActions = {
  ...userSlice.actions,
}

const GroupActions = {
  ...groupSlice.actions,
}

const MainActions = {
  ...mainSlice.actions,
}

const WorkspaceActions = {
  ...workspacesSlice.actions,
}

const ProjectActions = {
  ...projectsSlice.actions,
}

const DatastoreActions = {
  ...datastoresSlice.actions,
}

const ItemsActions = {
  ...itemsSlice.actions,
}

const ItemsDetailsActions = {
  ...itemDetailsSlice.actions,
}

const DataReportActions = {
  ...dataReportSlice.actions,
}

export {
  UsersSelectors,
  UsersActions,
  GroupSelectors,
  GroupActions,
  MainSelectors,
  MainActions,
  WorkspaceSelectors,
  WorkspaceActions,
  ProjectSelectors,
  ProjectActions,
  DatastoreActions,
  DatastoreSelectors,
  ItemsActions,
  ItemsSelectors,
  ItemsDetailsActions,
  ItemDetailsSelectors,
  DataReportActions,
  DataReportSelectors,
}
