import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { routePath } from 'app/constants/router'
import { rootSelector } from 'app/services/store/rootReducer'
import { getItemIdFromKey } from 'app/utils/components/common'
import { InventoryDetailList } from 'app/components/pages/inventory/types'
import { customOrderValue } from 'app/constants/inventory'
import { Columns } from 'app/services/store/items/types'
import {
  DatastoreSelectors,
  GroupSelectors,
  ItemsSelectors,
  ItemsDetailsActions,
  UsersSelectors,
} from 'app/services/store'

import AppLayout from 'app/components/templates/AppLayout'
import InventoryCreatePage from 'app/components/pages/inventory/InventoryCreatePage'

const InventoryCreateContainer = (): JSX.Element => {
  const displayableEntries = ItemsSelectors.getDisplayableEntries(useSelector(rootSelector))
  const { columns } = displayableEntries
  const dbFields = useSelector(rootSelector).items.fields
  const supplierMembers = GroupSelectors.getSupplierGroupMembers(useSelector(rootSelector))
  const currentDid = DatastoreSelectors.getCurrentDatastoreId(useSelector(rootSelector))
  const isSupplier = UsersSelectors.isSupplier(useSelector(rootSelector))
  const isShiroyama = UsersSelectors.isShiroyama(useSelector(rootSelector))

  const dispatch = useDispatch()

  /**
   * 新規在庫登録処理
   * @param form フォームからのデータ
   * @return void
   */
  let doublePreventionFlg = false
  const createItem = useCallback(
    form => {
      form.validateFields((err, values) => {
        if (doublePreventionFlg) return
        doublePreventionFlg = true

        const supplier = getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, '第一発注先2')
        const inventorySharing = getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, '在庫共有先')
        const receiptScheduleId = getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, '入庫予定日')
        const customOrder = getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, '特注部品')
        const orderPoint = getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, '発注点 for Hexa')

        if (!err) {
          if (receiptScheduleId && values[receiptScheduleId]) {
            values[receiptScheduleId] = values[receiptScheduleId].toISOString()
          }
          const item = {}
          Object.entries(values).forEach(([key, value]) => {
            // 優先発注先・共有先はUser_IdをPOSTするため配列に入れる
            item[key] = key === supplier || key === inventorySharing ? [value] : value
            if (key === customOrder) {
              const data = value ? customOrderValue.YES : customOrderValue.NO
              item[key] = String(data)
            }
            if (key === orderPoint) {
              item[key] = typeof value == 'string' ? 0 : value
            }
          })
          dispatch(
            ItemsDetailsActions.createItemRequest({
              dId: currentDid,
              item,
              navigatePath: routePath.INVENTORY_LIST,
            }),
          )
          // 「createItemRequest」内で登録成功の場合は在庫一覧に遷移する
          // 登録失敗の場合再度入力させるためflgをさげる
          doublePreventionFlg = false
        } else {
          window.scroll({ top: 0, behavior: 'smooth' })
          doublePreventionFlg = false
        }
      })
    },
    [dispatch, columns, currentDid, routePath],
  )

  const addInentoryResoce = {
    isSupplier,
    isShiroyama,
    columns,
    dbFields,
    supplierMembers,
    createItem,
  }

  return (
    <AppLayout>
      <InventoryCreatePage addInentoryResoce={addInentoryResoce} />
    </AppLayout>
  )
}

export default InventoryCreateContainer
