import { DataReportState, DataReportSearchCondition, initialState } from './state'
import { DataReport } from './types'
import { PayloadAction } from '@reduxjs/toolkit'
import { PostFilterDatareportRequest_v1 } from './epic.types'

export const getDataReportSuccess = (state: DataReportState, action: PayloadAction<DataReport>): DataReportState => {
  return {
    ...state,
    ...action.payload,
    loading: false,
    error: '',
  }
}

export const getDataReportFailed = (
  state: DataReportState,
  action: PayloadAction<{ error: string }>,
): DataReportState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const getDataReportRequest = (
  state: DataReportState,
  action: PayloadAction<{ pId: string; rId: string }>,
): DataReportState => {
  return {
    ...state,
    loading: true,
  }
}

export const reset = (): DataReportState => {
  return {
    ...initialState,
  }
}

export const getFilteredDataReportRequest = (
  state: DataReportState,
  action: PayloadAction<{ pId: string; rId: string; conditions: PostFilterDatareportRequest_v1 }>,
): DataReportState => {
  return {
    ...state,
    loading: true,
  }
}

export const getDataReportSearchConditionsRequest = (
  state: DataReportState,
  action: PayloadAction<{ pId: string; rId: string }>,
): DataReportState => {
  return {
    ...state,
    loading: true,
  }
}

export const getDataReportSearchConditionsSuccess = (
  state: DataReportState,
  action: PayloadAction<DataReportSearchCondition[]>,
): DataReportState => {
  return {
    ...state,
    search_fields: action.payload,
    loading: false,
    error: '',
  }
}
