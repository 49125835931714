import { Observable, Action } from 'redux'
import { ofType } from 'redux-observable'
import { catchError, switchMap, first, concatMap } from 'rxjs/operators'
import { of, concat, EMPTY } from 'rxjs'
import { PayloadAction } from '@reduxjs/toolkit'

import { ProjectActions, ProjectSelectors, DatastoreActions, WorkspaceSelectors, UsersSelectors } from '..'
import * as apiModels from './epic.types'
import HttpService from 'app/services/httpService/httpService'
import { getProjectIdFromName } from 'app/utils/store'
import { hexaLabel } from 'app/constants/hexalink'
import { AppConstant } from 'app/constants/app'

const initEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(AppConstant.REHYDRATE_ACTION_TYPE),
    concatMap(() => {
      const userLogged = UsersSelectors.isUserLogged(state$.value)
      const workspaceId = WorkspaceSelectors.getCurrentWorkspaceId(state$.value)
      if (userLogged && workspaceId) {
        return concat(
          of(ProjectActions.getProjectsRequest({ workspaceId })),
          action$.pipe(
            ofType(ProjectActions.getProjectsSuccess),
            first(),
            switchMap(() => {
              const datastores = ProjectSelectors.getCurrentProjectDatastores(state$.value)
              return of(DatastoreActions.getDatastoresSuccess({ datastores }))
            }),
          ),
        )
      } else {
        return EMPTY
      }
    }),
  )

const getProjectsEpic = (action$, state$) =>
  action$.pipe(
    ofType(ProjectActions.getProjectsRequest),
    switchMap(action =>
      HttpService.GetAsync<null, apiModels.api_get_applications_list_response>(`get_applications_list`).pipe(
        switchMap(projects => {
          return [
            ProjectActions.getProjectsSuccess({ projects: projects.data || [] }),
            ProjectActions.setCurrentProjectId({
              projectId: getProjectIdFromName(projects.data, hexaLabel.PROJECT_NAME),
            }),
          ]
        }),
        catchError((error: string) => {
          return of(ProjectActions.getProjectsFailed({ error }))
        }),
      ),
    ),
  )

const setCurrentProjectIdEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ProjectActions.setCurrentProjectId),
    switchMap((action: PayloadAction<{ projectId: string }>) => {
      const project = ProjectSelectors.getProjectById(state$.value)(action.payload.projectId)
      return of(DatastoreActions.getDatastoresSuccess({ datastores: project ? project.datastores : [] }))
    }),
  )

export default [initEpic, getProjectsEpic, setCurrentProjectIdEpic]
