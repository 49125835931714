export enum StatusIndex {
  '起票・発注',
  'サプライヤー回答待ち',
  '要確認',
  '手配中',
  '入荷済',
}

export const LowerConfirmMassage = {
  UPDATE_DELIVERY_DATE_CONFIRMATION: { 
    title: '手配確定後納品日変更について',
    body: '発注データの納品日のみ変更されます。納品書には反映されません。',
  },
}