import { createSlice } from "redux-starter-kit";
import * as reducers from './reducers';
import { initialState } from "./state";


export const workspacesSlice = createSlice({
  slice: "workspaces",
  initialState: initialState,
  reducers: {
    setCurrentWorkspaceIdRequest: reducers.setCurrentWorkspaceIdRequest,
    setCurrentWorkspaceIdSuccess: reducers.setCurrentWorkspaceIdSuccess,
    setCurrentWorkspaceIdFailed: reducers.setCurrentWorkspaceIdFailed,
    getWorkspacesRequest: reducers.getWorkspacesRequest,
    getWorkspacesSuccess: reducers.getWorkspacesSuccess,
    getWorkspacesFailed: reducers.getWorkspacesFailed,
    reset: reducers.reset
  }
});

export const {
  setCurrentWorkspaceIdRequest,
  setCurrentWorkspaceIdSuccess,
  setCurrentWorkspaceIdFailed,
  getWorkspacesRequest,
  getWorkspacesSuccess,
  getWorkspacesFailed,
  reset
} = workspacesSlice.actions;