import React, { useEffect, useCallback, useState } from 'react'
import { useLocation, useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { message } from 'antd'

import { rootSelector } from 'app/services/store/rootReducer'
import { datastoreSelector } from 'app/services/store/datastore/reducers'
import { getDatastoreIdFromName } from 'app/utils/store'
import { hexaLabel } from 'app/constants/hexalink'
import { routePath } from 'app/constants/router'
import {
  actionNameLabel,
  orderColumnNameLabel,
  uploadMassage,
  toMailActionName,
  itemDeleteDefine,
} from 'app/constants/order'
import { Columns } from 'app/services/store/items/types'
import { ActionFieldSettings, ItemDetailsCurrentAction } from 'app/services/store/itemDetails/types'
import { OrderDetailList, ActionName } from 'app/components/pages/order/types'
import {
  ItemDetailsSelectors,
  DatastoreActions,
  ItemsActions,
  ItemsSelectors,
  GroupSelectors,
  ItemsDetailsActions,
  UsersSelectors,
  ProjectSelectors,
} from 'app/services/store'
import {
  actionIdDeteminerByActionName,
  getCommentDataFromHistories,
  getChanges,
  isStatusAction,
} from 'app/utils/components/order'

import AppLayout from 'app/components/templates/AppLayout'
import OrderDetailPage from 'app/components/pages/order/OrderDetailPage'

const OrderDetailContainer = (): JSX.Element => {
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()

  const { iId } = location.state as { iId: string }
  const { datastores_list, current_datastore_id } = useSelector(datastoreSelector)
  const projectId = ProjectSelectors.getCurrentProjectId(useSelector(rootSelector))

  const isSupplier = UsersSelectors.isSupplier(useSelector(rootSelector))
  const isItemsLoading = ItemsSelectors.getItemsIsLoading(useSelector(rootSelector))
  const { columns, item } = ItemDetailsSelectors.getDisplayableEntry(useSelector(rootSelector))
  const actions = ItemDetailsSelectors.getActions(useSelector(rootSelector))
  const entry = ItemDetailsSelectors.getEntry(useSelector(rootSelector))
  const histories = ItemDetailsSelectors.getHistories(useSelector(rootSelector))
  const stateActions = ItemDetailsSelectors.getStateActions(useSelector(rootSelector))
  const itemDetailsCurrentAction = ItemDetailsSelectors.getCurrentAction(useSelector(rootSelector))
  const isItemDetailsLoading = ItemDetailsSelectors.getIsLoading(useSelector(rootSelector))
  const isHistoriesLoading = ItemDetailsSelectors.getHistoriesLoading(useSelector(rootSelector))
  const supplierMembers = GroupSelectors.getSupplierGroupMembers(useSelector(rootSelector))
  const statusOptions = ItemDetailsSelectors.getStatusOptions(useSelector(rootSelector))

  const actionFieldSettings = itemDetailsCurrentAction ? itemDetailsCurrentAction.action_field_settings : undefined
  const commentData = getCommentDataFromHistories(histories)
  const currentDetailList = item

  const [isEditMode, setIsEditMode] = useState(false)
  const [currentOperation, setCurrentOperation] = useState<ActionName>()
  const [fileList, setFileList] = useState([])

  const getCommentAction = (actions, isSupplier: boolean): {} => {
    if (!actions.length) return {}
    const actionName = isSupplier ? toMailActionName.toShiroyama : toMailActionName.toSupplier
    const foundAction = actions.find(action => action['name'] == actionName)
    return foundAction == null ? {} : foundAction
  }

  const submit = useCallback(
    (form, orderIDs: { d_id: string; a_id: string } | undefined, actionFieldSettings: ActionFieldSettings): void => {
      // アイテム削除はバリデーションを通さず実行する
      if (currentOperation === actionNameLabel.DELETE) {
        const payload = {
          dId: current_datastore_id,
          itemId: entry.i_id,
          actionId: itemDeleteDefine.actionId,
          param: {
            is_force_update: true,
            comment: itemDeleteDefine.comment,
          },
          navigatePath: routePath.ORDER_LIST,
        }
        dispatch(ItemsDetailsActions.orderItemDeleteRequest(payload))
        setIsEditMode(false)
        return
      }
      form.validateFields((err, values) => {
        // 複製時も入力チェックは通さない
        if (currentOperation === actionNameLabel.COPY) {
          const changes = getChanges(values, columns, actionFieldSettings)
          dispatch(
            ItemsActions.postActionRequest({
              actionId: orderIDs ? orderIDs.a_id : '',
              datastoreId: orderIDs ? orderIDs.d_id : '',
              itemId: currentDetailList['i_id'],
              revNo: entry.rev_no,
              changes,
            }),
          )
          const bottomComment = values[orderColumnNameLabel.BOTTOM_COMMENT]
          if (bottomComment.length > 0) {
            dispatch(
              ItemsDetailsActions.updateCommentHistoryRequest({
                comment: bottomComment,
              }),
            )
          }
          setIsEditMode(false)
          history.push({
            pathname: routePath.ORDER_LIST,
          })
          return
        }
        if (!err) {
          const changes = getChanges(values, columns, actionFieldSettings)
          dispatch(
            ItemsActions.postActionRequest({
              actionId: orderIDs ? orderIDs.a_id : '',
              datastoreId: orderIDs ? orderIDs.d_id : '',
              itemId: currentDetailList['i_id'],
              revNo: entry.rev_no,
              changes,
            }),
          )
          const bottomComment = values[orderColumnNameLabel.BOTTOM_COMMENT]
          if (bottomComment.length > 0) {
            dispatch(
              ItemsDetailsActions.updateCommentHistoryRequest({
                comment: bottomComment,
              }),
            )
          }
        }
      })
      setIsEditMode(false)
    },
    [dispatch, columns, currentDetailList, entry, currentOperation],
  )

  const submitOnDrawer = useCallback(
    (form): void => {
      form.validateFields((err, values) => {
        const rightComment = values[orderColumnNameLabel.RIGHT_COMMENT]
        if (rightComment.length > 0) {
          dispatch(
            ItemsDetailsActions.updateCommentHistoryRequest({
              comment: rightComment,
            }),
          )
          const mailAction = getCommentAction(actions, isSupplier)
          if (Object.keys(mailAction).length) {
            dispatch(
              ItemsDetailsActions.sendMail({
                itemId: currentDetailList['i_id'],
                actionId: mailAction['a_id'],
                datastoreId: mailAction['d_id'],
              }),
            )
          }
        }
      })
      dispatch(ItemsDetailsActions.getHistoriesRequest())
      form.resetFields()
    },
    [dispatch, actions, isSupplier],
  )

  const setOperationAndOpenEditMode = (operation: ActionName): void => {
    setCurrentOperation(operation)
    setIsEditMode(true)
  }

  const uploadProps = {
    multiple: false,
    beforeUpload: file => {
      if (file.type !== uploadMassage.PDF.mimetype) {
        message.error(uploadMassage.PDF.err_message)
      }
      return file.type === uploadMassage.PDF.mimetype
    },
    customRequest: info => {
      const pdfField = columns.find(colum => colum.title === orderColumnNameLabel.DRAWING_PDF)
      if (pdfField != null) {
        const field_id: string = pdfField!.id

        const params = new FormData()
        params.append('application_id', projectId)
        params.append('datastore_id', current_datastore_id)
        params.append('file', info.file)
        params.append('filename', info.file['name'])

        const updateAction: any = actions.find(action => action.name === '内容を更新する')
        const a_id: string = updateAction.a_id
        dispatch(ItemsDetailsActions.uploadFileRequest({ field_id, a_id, params }))
      }
    },
    onChange: info => {
      if (info.file.status === 'removed') {
        setFileList([])
      }
    },
    onDownload: e => {
      dispatch(ItemsDetailsActions.getPDF({ pdfId: e.file_id, fileName: e.name }))
    },
    onRemove: e => {
      dispatch(ItemsDetailsActions.deletePDFRequest({ field_id: e.field_id, file_id: e.file_id }))
    },
  }

  useEffect(() => {
    let actionId: string | undefined = ''
    if (stateActions.length > 0 && isStatusAction(currentOperation)) {
      const targetAction = stateActions.filter(action => action.name === currentOperation)
      actionId = targetAction[0] ? targetAction[0].a_id : undefined
    } else {
      const getActionIdFromActionName = actionIdDeteminerByActionName(actions)
      actionId = currentOperation ? getActionIdFromActionName(currentOperation) : undefined
    }
    if (isEditMode && actionId && currentOperation !== actionNameLabel.DELETE) {
      // when currentOperation is 'delete', dispatch of setting actionId will not work
      // because it uses designed request for 'delete'
      dispatch(ItemsDetailsActions.getActionSettingsRequest({ actionId }))
    }
  }, [dispatch, isEditMode, currentOperation, actions, stateActions])

  useEffect(() => {
    if (datastores_list) {
      dispatch(
        DatastoreActions.setCurrentDatastoreId({
          datastoreId: getDatastoreIdFromName(datastores_list, hexaLabel.ORDER_DB),
        }),
      )
      dispatch(ItemsActions.setCurrentItemId({ itemId: iId }))
    } else {
      dispatch(ItemsActions.setCurrentItemId({ itemId: iId }))
    }
  }, [dispatch, datastores_list, iId])

  useEffect(() => {
    dispatch(ItemsDetailsActions.getHistoriesRequest())
  }, [dispatch])

  useEffect(() => {
    let fileData: any = []
    if (Array.isArray(item[orderColumnNameLabel.DRAWING_PDF]) && item[orderColumnNameLabel.DRAWING_PDF].length) {
      // 既存アップ済みファイルを登録する
      const fId: string = item[orderColumnNameLabel.DRAWING_PDF][0]['file_id']
      const fieldId: string = item[orderColumnNameLabel.DRAWING_PDF][0]['field_id']
      const fName: string = item[orderColumnNameLabel.DRAWING_PDF][0]['filename']
      fileData = [{ uid: '1', file_id: fId, field_id: fieldId, name: fName, status: 'done', url: '' }]
    }
    setFileList(fileData)
  }, [item])

  const orderDetailResource = {
    itemDetailsCurrentAction: itemDetailsCurrentAction as ItemDetailsCurrentAction,
    currentDetailList: currentDetailList as OrderDetailList,
    columns: columns as Columns,
    actionFieldSettings: actionFieldSettings as ActionFieldSettings,
    submit,
    submitOnDrawer,
    supplierMembers,
    setOperationAndOpenEditMode: setOperationAndOpenEditMode as (operation: ActionName) => void,
    isEditMode,
    setIsEditMode,
    commentData,
    isLoading: isItemsLoading || isItemDetailsLoading,
    isHistoriesLoading,
    isSupplier,
    statusOptions,
    uploadProps,
    fileList,
    setFileList,
    currentOperation,
  }
  return (
    <AppLayout>
      <OrderDetailPage orderDetailResource={orderDetailResource} />
    </AppLayout>
  )
}

export default OrderDetailContainer
