import React from 'react'
import { useDispatch } from 'react-redux'
import { Layout, Row, Col, Button } from 'antd'
import 'antd/dist/antd.css'
import { UsersActions } from 'app/services/store'

const { Header } = Layout

const CommonHeader = () => {
  const dispatch = useDispatch()
  const logout = React.useCallback(() => {
    dispatch(UsersActions.logout())
  }, [dispatch])

  return (
    <div>
      <Header className="header">
        <Row>
          <Col span={20}>
            <div className="logo">白山工業</div>
          </Col>
          <Col span={4}>
            <Button size="large" type="primary" onClick={(): void => logout()}>
              ログアウト
            </Button>
          </Col>
        </Row>
      </Header>

      <Row>
        <Col></Col>
      </Row>
    </div>
  )
}

export default CommonHeader
