import { WorkspaceState, initialState } from "./state";
import { PayloadAction } from "redux-starter-kit";
import { Workspace } from "./types";

export const setCurrentWorkspaceIdRequest = (state: WorkspaceState, action: PayloadAction<{workspaceId: string}>): WorkspaceState => {
  return {
    ...state,
    loading: true
  }
}

export const setCurrentWorkspaceIdSuccess = (state: WorkspaceState, action: PayloadAction<{workspaceId: string}>): WorkspaceState => {
  return {
    ...state,
    current_workspace_id: action.payload.workspaceId,
    loading: false
  }
}

export const setCurrentWorkspaceIdFailed = (state: WorkspaceState, action: PayloadAction<{error: string}>): WorkspaceState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error
  }
}

export const setWorkspaceLoading = (state: WorkspaceState, action: PayloadAction<{loading: boolean}>): WorkspaceState => {
  return {
    ...state,
    loading: action.payload.loading
  }
}

export const getWorkspacesRequest = (state: WorkspaceState): WorkspaceState => {
  return {
    ...state,
    loading: true
  }
}

export const getWorkspacesSuccess = (state: WorkspaceState, action: PayloadAction<{workspaces: Array<Workspace>}>): WorkspaceState => {
  return {
    ...state,
    loading: false,
    workspaces_list: action.payload.workspaces,
    error: ''
  }
}

export const getWorkspacesFailed = (state: WorkspaceState, action: PayloadAction<{error: string}>): WorkspaceState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error
  }
}

export const reset = (state: WorkspaceState): WorkspaceState => {
  return {
    ...initialState
  }
}


  
  
  