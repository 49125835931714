import React from 'react'
import { Switch } from 'react-router-dom'

import SessionAlert from 'app/components/utils/sessionAlert/sessionAlert'
import PrivateRoute from 'app/config/router/shared/privateRoute'
import { routePath } from 'app/constants/router'

import OrderContainer from 'app/container/order/OrderContainer'
import OrderDetailContainer from 'app/container/order/OrderDetailContainer'
import DeliverySlipContainer from 'app/container/deliverySlip/DeliverySlipContainer'
import DeliverySlipDetailContainer from 'app/container/deliverySlip/DeliverySlipDetailContainer'
import DeliverySlipCreateContainer from 'app/container/deliverySlip/DeliverySlipCreateContainer'

const SupplierRouter = (): JSX.Element => {
  return (
    <Switch>
      <PrivateRoute exact path={routePath.ORDER_LIST} component={OrderContainer} />
      <PrivateRoute exact path={`${routePath.ORDER_DETAIL}`} component={OrderDetailContainer} />
      <PrivateRoute exact path={routePath.DELIVERY_SLIP} component={DeliverySlipContainer} />
      <PrivateRoute exact path={`${routePath.DELIVERY_SLIP_DETAIL}`} component={DeliverySlipDetailContainer} />
      <PrivateRoute exact path={`${routePath.DELIVERY_SLIP_CREATE}`} component={DeliverySlipCreateContainer} />
      <PrivateRoute component={OrderContainer} />
      <SessionAlert />
    </Switch>
  )
}

export default SupplierRouter
