import { ItemEntry, SearchSettings } from 'app/services/store/items/types'
import FieldsHelper from './fieldsHelper'
import { ItemDetailsField } from 'app/services/store/itemDetails/types'
import moment from 'moment'
import { routePath } from 'app/constants/router'

export default class ApiHelper {
  /**
   * @static
   * @param  {Array<any>} apiItems the items list coming from the api
   * @param  {Array<ItemDetailsField | DatastoreField>} apiFields the fields list coming from the api
   *
   * @description since the api store all the field id inside the root of the field object, there is no proper way to bind the
   * item correctly. Also, it is kind of hard to manipulate since you don't have an object that is always the same structure
   * This method will remove those fieldId stored as key from the root of the item, and store them inside the property fields of the object
   * so they will be much easier to manipulate in code
   *
   * @returns {Array<ItemEntry>} the build array of entry
   */
  static buildEntries = (apiItems: Array<any>, apiFields: Array<any>): Array<ItemEntry> => {
    const items = [...apiItems]
    const fields = [...apiFields]
    // the field if is under / fields for items / f_id for itemDetails
    // api is unconsistent...
    const fieldIds = fields.map(a => a.field || a.f_id)
    const entries = new Array<ItemEntry>()
    items.forEach(item => {
      const itemFields: object = {}
      fieldIds.forEach(key => {
        itemFields[key] = item[key] || ''
        delete item[key]
      })
      entries.push({
        ...item,
        ...{ fields: itemFields },
      })
    })
    return entries
  }

  /**
   * @static
   * @param  {{[k: string]: any}} fields the list of field as object
   *
   * @description Similar to above, it is easier to display an array of id => value than an object with all the id as key
   *
   * @returns {Array<{id: string; value: any}>} array of fields
   */
  static getFieldsAsArray = (fields: { [k: string]: any }): Array<{ id: string; value: any }> => {
    const fieldsArray = new Array<{ id: string; value: any }>()
    for (const key in fields) {
      if (Object.prototype.hasOwnProperty.call(fields, key)) {
        fieldsArray.push({
          id: key,
          value: fields[key],
        })
      }
    }
    return fieldsArray
  }

  /**
   * @static
   * @param  {Array<{id: string; value: any}>} changes    the list of all the changes to apply
   * @param  {ItemEntry} entry                            the item entry
   * @param  {{ [k: string]: ItemDetailsField }} fields   the list of the fields of this item
   *
   * @description This function will apply a list of change to an entry by keeping the correct format for each field
   * based on the fields of this item
   *
   * @todo document this better
   *
   * @returns {{[k: string]: any}} The update entry
   */
  // apiFields type ItemDetailsField
  static changeEntryField = (
    changes: Array<{ id: string; value: any }>,
    entry: ItemEntry,
    fields: { [k: string]: ItemDetailsField },
  ): { [k: string]: any } => {
    const entryFields: { [k: string]: any } = {}
    for (const key in fields) {
      if (Object.prototype.hasOwnProperty.call(fields, key)) {
        const field = fields[key]
        const change = changes.find(change => change.id === key)
        let value = change ? change.value : entry.fields[key]

        if (field.dataType === FieldsHelper.fields.select.value || field.dataType === FieldsHelper.fields.radio.value) {
          if (field.options) {
            const option = field.options.find(o => o.o_id === value)
            value = option ? option.value : value
          }
        }

        if (field.dataType === FieldsHelper.fields.date.value) {
          value = moment(value).format('YYYY/MM/DD')
        }
        entryFields[key] = value
      }
    }
    return entryFields
  }

  /**
   * アイテム一覧取得時 設定パラメーター
   * @param datastore_id string ストレージid
   * @param project_id string プロジェクトid
   * @param d_id string カレントデータストレージid
   * @return object
   */
  static getItemsRequestParam(datastore_id: string, project_id: string, d_id: string, search: SearchSettings) {
    const resultParam = {
      get_datastore_settings: {
        datastore_id,
        project_id,
      },
      get_item_list_personalize: {
        d_id: d_id,
      },
      get_sf_new_action_settings: {
        datastore_id,
        project_id,
      },
      get_datastore_colinfo: {
        datastore_id,
        project_id,
      },
      postget_paginate_items_with_search: {
        ...{
          datastore_id,
          project_id,
        },
        conditions: search.conditions,
        per_page: 100,
        page: search.page,
        sort_field_id: search.sortFieldId,
        sort_order: search.sortOrder,
        include_fields_data: search.include_fields_data,
      },
    }

    const currentPage: string = window.location.pathname
    // 発注一覧の際はアイテムの更新降順に表示する
    if (currentPage === routePath.ORDER_LIST) {
      resultParam.postget_paginate_items_with_search.sort_field_id = 'updated_at'
      resultParam.postget_paginate_items_with_search.sort_order = 'desc'
    }

    return resultParam
  }
}
