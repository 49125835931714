import React from 'react'
import { Row, Col, Button } from 'antd'

interface FilterDropdownProps {
  setSelectedKeys: (targetValues: Array<string>) => void
  confirm: () => void
  clearFilters: () => void
  handleSearch: (confirm: () => void) => void
  handleReset: (clearFilters: () => void) => void
}
const FilterDropdown = ({
  setSelectedKeys,
  confirm,
  clearFilters,
  handleSearch,
  handleReset,
}: FilterDropdownProps): JSX.Element => {
  const textInput = React.createRef<HTMLInputElement>()

  // リセットボタン押下時イベント
  const _handleReset = () => {
    // input要素のvalueを初期化
    if (textInput.current !== null) {
      textInput.current.value = ''
    }
    handleReset(clearFilters)
  }

  return (
    <div>
      <Row justify="center">
        <Col span={24}>
          <input
            className="ant-input"
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onKeyDown={e => {
              if (e.keyCode === 13 || e.key === 'Enter') {
                handleSearch(confirm)
              }
            }}
            ref={textInput}
            style={{ width: '400px', margin: '10px 45px' }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Button type="primary" onClick={() => handleSearch(confirm)} icon="search">
            Search
          </Button>
        </Col>
        <Col span={12}>
          <Button onClick={() => _handleReset()}>Reset</Button>
        </Col>
      </Row>
    </div>
  )
}

export default FilterDropdown
