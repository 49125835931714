import React from 'react'
import { createBrowserHistory } from 'history'

import 'antd/dist/antd.css'
import { PersistGate } from 'redux-persist/integration/react'

import rootStore from './app/services/store/rootStore'
import Preloader from './app/components/utils/preloader/preloader'
import AppRouter from 'app/config/router/AppRouter'

export const history = createBrowserHistory()

const App: React.FC = () => {
  return (
    <div className="App">
      <PersistGate persistor={rootStore.persistor}>
        <Preloader />
        <AppRouter history={history} />
      </PersistGate>
    </div>
  )
}

export default App
