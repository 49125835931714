import { combineReducers } from 'redux-starter-kit'
import { userSlice } from './user/slice'
import { groupSlice } from './group/slice'
import { mainSlice } from './main/slice'
import { workspacesSlice } from './workspace/slice'
import { projectsSlice } from './project/slice'
import { datastoresSlice } from './datastore/slice'
import { itemsSlice } from './items/slice'
import { itemDetailsSlice } from './itemDetails/slice'
import { dataReportSlice } from './dataReport/slice'

const rootReducer = combineReducers({
  user: userSlice.reducer,
  group: groupSlice.reducer,
  main: mainSlice.reducer,
  workspaces: workspacesSlice.reducer,
  projects: projectsSlice.reducer,
  datastores: datastoresSlice.reducer,
  items: itemsSlice.reducer,
  itemDetails: itemDetailsSlice.reducer,
  dataReport: dataReportSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer

export const rootSelector = (state: RootState): RootState => state
