import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router'
import { routePath } from 'app/constants/router'
import { Spin } from 'antd'

import { rootSelector } from 'app/services/store/rootReducer'
import {
  DatastoreActions,
  DatastoreSelectors,
  ItemsSelectors,
  ItemDetailsSelectors,
  ItemsDetailsActions,
} from 'app/services/store'

import { getItemIdFromKey, isRange, getFiscalMonth } from 'app/utils/components/common'
import { Columns } from 'app/services/store/items/types'
import { InventoryDetailList, TemporaryDelivery, temporaryJSONData } from 'app/components/pages/inventory/types'
import {
  sortUnifiedColumns,
  createUnifyDBsColumns,
  getStartYear,
  unifyDBsItems,
  TABLE_DISPLAY_NAME,
  createInventoryHistoryColumns,
  getTotalCountItems,
  createYearSelectOption,
  findInventoryCount,
  getInitial,
} from 'app/utils/components/inventory'

import InventoryHistoryPage from 'app/components/pages/inventory/InventoryHistoryPage'
import Moment from 'moment'

interface ContainerProps {
  isActive: boolean
}
const InventoryHistoryContainer = ({ isActive }: ContainerProps): JSX.Element => {
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()

  const iId = ItemsSelectors.getCurrentItemId(useSelector(rootSelector))
  const relations = ItemDetailsSelectors.getRelations(useSelector(rootSelector))
  const linkedAllDbsItems = ItemDetailsSelectors.getLinkedAllDbsItemsDisplayableEntries(useSelector(rootSelector))
  const dbList = DatastoreSelectors.getDatastores(useSelector(rootSelector))
  const tempDeliveryDb = dbList.filter(db => db.name === '仮出庫')[0]
  const { currentDetailList } = location.state as { currentDetailList: InventoryDetailList }
  const { columns } = ItemsSelectors.getDisplayableEntries(useSelector(rootSelector))

  useEffect(() => {
    if (isActive) {
      if (iId && relations) {
        dispatch(ItemsDetailsActions.getLinkedAllDbsItemsRequest({ iId, relations }))
      }
      if (dbList) {
        dispatch(
          DatastoreActions.setCurrentDatastoreId({
            datastoreId: dbList.filter(db => db.name === '発注DB')[0]['d_id'],
          }),
        )
      }
    }
  }, [dispatch, iId, relations, isActive])

  if (linkedAllDbsItems.length <= 2) {
    return <Spin spinning={true} />
  }

  // 初回表示時処理
  const startYear = getStartYear(Moment())
  const unifiedDBsItems = unifyDBsItems(
    columns,
    linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.ORDER_HISRTORY)!.items,
    linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.INVENTORY_HISTORY)!.items,
    linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.DELIVERY_HISRTORY)!.items,
    linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.TEMP_HISRTORY)!.items,
    startYear,
    Moment().format('YYYY'),
  )
  const selectYearOption = createYearSelectOption(
    columns,
    linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.ORDER_HISRTORY)!.items,
    linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.INVENTORY_HISTORY)!.items,
    linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.DELIVERY_HISRTORY)!.items,
    startYear,
  )
  const inventoryHistoryColumns = createInventoryHistoryColumns(unifiedDBsItems, Moment().format('YYYY'))
  const initialValue = getInitial(
    columns,
    linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.ORDER_HISRTORY)!.items,
    linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.INVENTORY_HISTORY)!.items,
    linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.DELIVERY_HISRTORY)!.items,
    linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.TEMP_HISRTORY)!.items,
    startYear,
    selectYearOption,
    unifiedDBsItems,
    currentDetailList,
  )
  const totalCountItems = getTotalCountItems(unifiedDBsItems, inventoryHistoryColumns, initialValue)
  const inventoryHistoryData = [...unifiedDBsItems, ...totalCountItems]

  const routeOrderDetail = (record): void => {
    const orderDb = linkedAllDbsItems.filter(db => db.dName === '発注DB')[0]
    const item = orderDb['items'].find(data => {
      return data['i_id'] == record.i_id
    })
    const colums = orderDb.columns
    history.push({
      pathname: routePath.ORDER_DETAIL,
      state: { iId: record.i_id, currentDetailList: item, colums },
    })
  }

  const saveTemporaryDeliveryhandle = (data: Array<{}>): void => {
    if (!data.length) return

    const jsonObj: temporaryJSONData = {}
    for (const i in data) {
      const rowObj = Object.entries(data[i])
      for (const key in rowObj) {
        if (!rowObj[key][0].includes('/')) continue
        if (rowObj[key][0].includes('count')) {
          const date = rowObj[key][0].substr(0, 10)
          if (!jsonObj[date]) jsonObj[date] = []
          const keyNo = date + '.no'
          const keyCount = date + '.count'
          const manageNo = data[i][keyNo] || '入力なし'
          jsonObj[date].push({ row: i, count: data[i][keyCount], no: manageNo })
        }
      }
    }

    const temporaryDeliColums = linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.TEMP_HISRTORY)!.columns
    const fieldIdinveId: string =
      getItemIdFromKey<Columns, keyof TemporaryDelivery>(temporaryDeliColums, '在庫ID') || ''
    const fieldIdJson: string =
      getItemIdFromKey<Columns, keyof TemporaryDelivery>(temporaryDeliColums, 'JSONデータ') || ''
    const item = {}
    item[fieldIdinveId] = currentDetailList['ID']
    item[fieldIdJson] = JSON.stringify(jsonObj)

    const inventoryDb = dbList.find(db => db.name === '在庫管理')
    if (inventoryDb == null) return

    // 在庫IDのレコード削除後インサート
    const conditions = JSON.stringify({
      conditions: [
        {
          id: '在庫ID',
          search_value: [currentDetailList['ID']],
          exact_match: true,
        },
      ],
    })
    dispatch(
      ItemsDetailsActions.deleteItemByConditionRequest({
        did: tempDeliveryDb.d_id,
        condition: conditions,
      }),
    )
    dispatch(
      ItemsDetailsActions.createItemRequest({
        dId: tempDeliveryDb.d_id,
        item,
        navigatePath: undefined,
        update: 'linkedDB',
        updateParams: { iId: iId, relations: relations, dId: inventoryDb['d_id'] },
      }),
    )
  }

  const deleteTemporaryDeliveryhandle = (data: Array<{}>): void => {
    saveTemporaryDeliveryhandle(data)
  }

  const handleYearChange = (selectYear): object => {
    const targetYear = isRange(Moment().format('M'), 1, getFiscalMonth('end')) ? Number(selectYear) + 1 : selectYear
    const unifiedDBsItems = unifyDBsItems(
      columns,
      linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.ORDER_HISRTORY)!.items,
      linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.INVENTORY_HISTORY)!.items,
      linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.DELIVERY_HISRTORY)!.items,
      linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.TEMP_HISRTORY)!.items,
      selectYear,
      targetYear,
    )
    const initialValue = getInitial(
      columns,
      linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.ORDER_HISRTORY)!.items,
      linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.INVENTORY_HISTORY)!.items,
      linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.DELIVERY_HISRTORY)!.items,
      linkedAllDbsItems.find(item => item.dName === TABLE_DISPLAY_NAME.TEMP_HISRTORY)!.items,
      selectYear,
      selectYearOption,
      unifiedDBsItems,
      currentDetailList,
    )
    const inventoryHistoryColumns = createInventoryHistoryColumns(unifiedDBsItems, targetYear)
    const totalCountItems = getTotalCountItems(unifiedDBsItems, inventoryHistoryColumns, initialValue)
    const inventoryHistoryData = [...unifiedDBsItems, ...totalCountItems]

    return { columns: inventoryHistoryColumns, items: inventoryHistoryData }
  }

  return (
    <InventoryHistoryPage
      tableColumns={inventoryHistoryColumns}
      tableRows={inventoryHistoryData || []}
      saveHandle={saveTemporaryDeliveryhandle}
      deleteHandle={deleteTemporaryDeliveryhandle}
      yearChangeHandle={handleYearChange}
      routeOrderDetail={routeOrderDetail}
      selectYearOption={selectYearOption}
      initialValue={initialValue}
    />
  )
}

export default InventoryHistoryContainer
