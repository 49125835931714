import { DataReport, Condition } from './types'

export interface DataReportSearchCondition {
  rpf_id: string
  display_id: string
  f_id: string
  name: string
  dataType: string
  order: number
  options?: options[]
}
interface options {
  o_id: string
  value: string
  color: string
  enabled: boolean
  sort_id: number
}

export interface DataReportState extends DataReport {
  conditions: Condition[] | []
  per_page: string | number
  page: string | number
  loading: boolean
  search_fields: DataReportSearchCondition[] | []
}

export const initialState: DataReportState = {
  report_fields: [],
  report_results: [],
  report_title: '',
  conditions: [],
  per_page: '',
  page: '',
  totalItems: '',
  error: '',
  loading: false,
  search_fields: [],
}
