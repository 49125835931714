import React from 'react'
import { useHistory } from 'react-router'
import { Row, Col, Table, Button, Form } from 'antd'

import { routePath } from 'app/constants/router'
import { Columns, SearchCondition } from 'app/services/store/items/types'
import { filterListColumns, sortInventoryColumns } from 'app/utils/components/inventory'
import { createFilterableAndSortableColumns, getCommonTableScrollSize } from 'app/utils/components/common'

interface InventoryProps {
  getItemDetail: (itemId: string) => void
  updateSearchParams: (page: number, fieldId: string, order: string, conditions: Array<SearchCondition>) => void
  itemsLoading: boolean
  items: { [k: string]: any }[]
  totalItmes: number
  columns: Columns
  isShiroyama: boolean
}

const InventoryPage = ({
  getItemDetail,
  updateSearchParams,
  itemsLoading,
  items,
  totalItmes,
  columns,
  isShiroyama,
}: InventoryProps): JSX.Element => {
  const history = useHistory()

  const columnsWithSearchAndSort = createFilterableAndSortableColumns(sortInventoryColumns(filterListColumns(columns)))

  const transitionAddInventory = (): void => {
    history.push({
      pathname: routePath.INVENTORY_CREATE,
    })
  }

  const showTotal = () => {
    return `Total ${totalItmes} items`
  }

  const tableScrollSetting = getCommonTableScrollSize()

  return (
    <div>
      <Row>
        <Col span={7}>
          <h2>在庫一覧</h2>
        </Col>
        <Col>
          {isShiroyama ? (
            <Form.Item>
              <Button
                type="primary"
                style={{ float: 'right' }}
                className="btn-white"
                onClick={transitionAddInventory}
                loading={itemsLoading}
              >
                新規登録
              </Button>
            </Form.Item>
          ) : (
            ''
          )}
        </Col>
      </Row>
      <Row>
        <div className="tableArea">
          <Table
            loading={itemsLoading}
            dataSource={items}
            pagination={{
              total: totalItmes,
              showTotal: showTotal,
              defaultPageSize: 100,
            }}
            columns={columnsWithSearchAndSort}
            onRow={record => {
              return {
                onClick: (): void => {
                  getItemDetail(record.i_id)
                  history.push({
                    pathname: `${routePath.INVENTORY_DETAIL}`,
                    state: { currentDetailList: record, columns },
                  })
                },
              }
            }}
            onChange={(pagination, filters, sorter, extra) => {
              const page = pagination.current ? pagination.current : 1
              const sortFieldId = sorter && sorter.column ? sorter.column['id'] : ''
              const sortOrder = sorter && sorter.order ? (sorter.order === 'ascend' ? 'asc' : 'desc') : ''
              const conditions = new Array<SearchCondition>()
              Object.entries(filters).forEach(([key, value]) => {
                if (Array.isArray(value) && value.length > 0) {
                  const column = columns.find(colum => colum.dataIndex === key)
                  if (column != null) {
                    conditions.push({
                      id: column.id,
                      search_value: [value[0]],
                    })
                  }
                }
              })
              updateSearchParams(page, sortFieldId, sortOrder, conditions)
            }}
            scroll={{ y: tableScrollSetting['y'], x: tableScrollSetting['x'] }}
            tableLayout="initial"
          />
        </div>
      </Row>
    </div>
  )
}

export default InventoryPage
