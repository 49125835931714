import { createSelector } from 'reselect'

import { RootState } from '../rootReducer'
import { ItemsState } from './state'
import {
  ColumnSettings,
  DatastoreField,
  ItemEntry,
  ItemDisplayableEntries,
  DatastoreColumns,
  SearchSettings,
} from './types'
import StringUtils from 'app/utils/stringUtils'

const itemState = (state: RootState): ItemsState => state.items
const itemsEntries = (state: RootState): Array<ItemEntry> => state.items.entries
const itemsFields = (state: RootState): Array<DatastoreField> => state.items.fields

/**
 * @selector
 *
 * @description Return the current items loading status
 *
 * @returns {boolean} true = items loading, false = items loaded
 */
export const getItemsIsLoading = createSelector<RootState, ItemsState, boolean>(
  itemState,
  (itemsState: ItemsState) => itemsState.loading,
)

/**
 * @selector
 *
 * @description Return the column of the item list
 *
 * @returns {DatastoreColumns} the column of the item list
 */
export const getItemsColumns = createSelector<RootState, ItemsState, DatastoreColumns>(
  itemState,
  (itemsState: ItemsState) => itemsState.columns,
)

/**
 * @selector
 *
 * @description Return the number of items
 *
 * @returns {number} the number of items
 */
export const getTotalItems = createSelector<RootState, ItemsState, number>(
  itemState,
  (itemsState: ItemsState) => itemsState.totalItems,
)

/**
 * @selector
 *
 * @description Return the current itemId selected in the list
 *
 * @returns {string} the current itemId selected in the list
 */
export const getCurrentItemId = createSelector<RootState, ItemsState, string>(
  itemState,
  (itemsState: ItemsState) => itemsState.current_item_id,
)

/**
 * @selector
 *
 * @description Return the search parameters
 *
 * @returns {string} the search parameters
 */
export const getSearchParams = createSelector<RootState, ItemsState, SearchSettings>(
  itemState,
  (itemsState: ItemsState) => itemsState.search,
)

/**
 * @selector
 *
 * @description Return the settings of the items list column
 *
 * @returns {ColumnSettings} the settings of the items list column
 */
export const getItemsColumnsSettings = createSelector<RootState, ItemsState, Map<string, ColumnSettings>>(
  itemState,
  (itemsState: ItemsState) =>
    itemsState.columns && itemsState.columns.column_settings
      ? new Map(Object.entries(itemsState.columns.column_settings))
      : new Map<string, ColumnSettings>(),
)

/**
 * @selector
 *
 * @description This selector will create a set of displayable item that are easy to manipulate
 * using the current entries and fields set in the item list store. This way all the information is in one place
 * and no need to manipulate to many different field to get what we want. It should provide you with enough data to display anything
 *
 * @returns {ItemDisplayableEntries} the created displayable entries from this list
 */
export const getDisplayableEntries = createSelector<
  RootState,
  Array<ItemEntry>,
  Array<DatastoreField>,
  ItemDisplayableEntries
>(itemsEntries, itemsFields, (entries: Array<ItemEntry>, fields: Array<DatastoreField>) => {
  const dispColumns = fields.map((field: any) => {
    let result = {
      title: field.displayName,
      dataIndex: field.displayName,
      dataType: field.dataType,
      key: field.displayID,
      id: field.field,
    }
    // ステータスリストがある場合取得
    if (field.statuses !== undefined) {
      result = { ...result, ...{ statuses: field.statuses } }
    }
    return result
  })
  const dispItems = entries.map((item: ItemEntry) => {
    const targetObj: { [k: string]: any } = {}

    dispColumns.forEach((element: any) => {
      targetObj[element.key] = item.fields[element.id]
    })

    targetObj['key'] = `${StringUtils.GenUUID()}-${item.i_id}`
    targetObj['i_id'] = item.i_id
    targetObj['unread'] = item.unread

    return targetObj
  })
  return { columns: dispColumns, items: dispItems }
})

/**
 * @selector
 *
 * @description Return the status field
 *
 * @returns {array} the status field info
 */
export const getStatusField = createSelector<RootState, ItemsState, any>(itemState, (itemsState: ItemsState) => {
  if (itemsState.fields.length > 0) {
    const result = itemsState.fields.find(f => f.dataType === 'status')
    return result !== undefined ? result : []
  }
  return []
})

/**
 * @selector
 *
 * @description Return the select type field
 *
 * @returns {array} the select type field info
 */
export const getSelectField = createSelector<RootState, ItemsState, any>(itemState, (itemsState: ItemsState) => {
  if (itemsState.fields.length > 0) {
    return itemsState.fields.filter(f => f.dataType === 'select')
  }
  return []
})

export default [
  getItemsIsLoading,
  getItemsColumns,
  getItemsColumnsSettings,
  getDisplayableEntries,
  getStatusField,
  getSelectField,
]
