import { UserState, initialState } from './state'
import { PayloadAction } from '@reduxjs/toolkit'
import { UserInfo } from './types'

export const setUserAuth = (state: UserState, action: PayloadAction<{ authKey: string }>): UserState => {
  return {
    ...state,
    auth_key: action.payload.authKey,
  }
}

export const loginRequest = (
  state: UserState,
  action: PayloadAction<{ email: string; password: string }>,
): UserState => {
  return {
    ...state,
    loading: true,
  }
}

export const loginSuccess = (state: UserState): UserState => {
  return {
    ...state,
    loading: false,
    error: '',
  }
}

export const loginFailed = (state: UserState, action: PayloadAction<{ error: string }>): UserState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const getUserinfoRequest = (state: UserState): UserState => {
  return {
    ...state,
    loading: true,
  }
}

export const getUserinfoSuccess = (state: UserState, action: PayloadAction<{ user: UserInfo }>): UserState => {
  return {
    ...state,
    ...action.payload.user,
    loading: false,
  }
}

export const getUserinfoFailed = (state: UserState, action: PayloadAction<{ error: string }>): UserState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const reset = (state: UserState): UserState => {
  return {
    ...initialState,
  }
}

export const logout = (state: UserState): UserState => {
  return {
    ...state,
    auth_key: '',
  }
}

export const navigate = (state: UserState, action: PayloadAction<{ path: string }>): UserState => {
  return {
    ...state,
  }
}

export const forgotPasswordRequest = (state: UserState, action: PayloadAction<{ email: string }>): UserState => {
  return {
    ...state,
    loading: true,
  }
}

export const changePasswordRequest = (
  state: UserState,
  action: PayloadAction<{ newPassword: string; confirmPassword: string }>,
): UserState => {
  return {
    ...state,
    loading: true,
  }
}

export const passwordValidateRequest = (state: UserState): UserState => {
  return {
    ...state,
    loading: true,
  }
}
