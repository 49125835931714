export default class ActionHelper {
  static actionTypes = {
    ADD: '1',
    EDIT: '2',
    DELETE: '3',
    COPY: '4',
  }

  /**
   * @static
   * @param  {object} obj1
   * @param  {object} obj2
   *
   * @description this method will compare two object and return ALL the difference beetween the two object at any level
   *
   * @returns {object}
   */
  static findChanges = (obj1, obj2): object => {
    // Make sure an object to compare is provided
    if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
      return obj1
    }

    const diffs = {}
    let key

    /**
     * Check if two arrays are equal
     * @param  {Array}   arr1 The first array
     * @param  {Array}   arr2 The second array
     * @return {Boolean}      If true, both arrays are equal
     */
    const arraysMatch = (arr1, arr2) => {
      // Check if the arrays are the same length
      if (arr1.length !== arr2.length) return false

      // Check if all items exist and are in the same order
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false
      }

      // Otherwise, return true
      return true
    }

    /**
     * Compare two items and push non-matches to object
     * @param  {*}      item1 The first item
     * @param  {*}      item2 The second item
     * @param  {String} key   The key in our object
     */
    const compare = (item1, item2, key): void => {
      // Get the object type
      const type1 = Object.prototype.toString.call(item1)
      const type2 = Object.prototype.toString.call(item2)

      // If type2 is undefined it has been removed
      if (type2 === '[object Undefined]') {
        diffs[key] = null
        return
      }

      // If items are different types
      if (type1 !== type2) {
        diffs[key] = item2
        return
      }

      // If an object, compare recursively
      if (type1 === '[object Object]') {
        const objDiff = ActionHelper.findChanges(item1, item2)
        if (Object.keys(objDiff).length > 1) {
          diffs[key] = objDiff
        }
        return
      }

      // If an array, compare
      if (type1 === '[object Array]') {
        if (!arraysMatch(item1, item2)) {
          diffs[key] = item2
        }
        return
      }

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (type1 === '[object Function]') {
        if (item1.toString() !== item2.toString()) {
          diffs[key] = item2
        }
      } else {
        if (item1 !== item2) {
          diffs[key] = item2
        }
      }
    }

    // Loop through the first object
    for (key in obj1) {
      if (Object.prototype.hasOwnProperty.call(obj1, key)) {
        compare(obj1[key], obj2[key], key)
      }
    }

    // Loop through the second object and find missing items
    for (key in obj2) {
      if (Object.prototype.hasOwnProperty.call(obj2, key)) {
        if (!obj1[key] && obj1[key] !== obj2[key]) {
          diffs[key] = obj2[key]
        }
      }
    }

    // Return the object of differences
    return diffs
  }
}
