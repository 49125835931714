import { createSlice } from "redux-starter-kit";
import * as reducers from './reducers';
import { initialState } from "./state";


export const projectsSlice = createSlice({
  slice: "projects",
  initialState: initialState,
  reducers: {
    setCurrentProjectId: reducers.setCurrentProjectId,
    getProjectsRequest: reducers.getProjectsRequest,
    getProjectsSuccess: reducers.getProjectsSuccess,
    getProjectsFailed: reducers.getProjectsFailed,
    reset: reducers.reset,
  }
});

export const {
  setCurrentProjectId,
  getProjectsRequest,
  getProjectsSuccess,
  getProjectsFailed,
  reset
} = projectsSlice.actions;
