export const inventoryColumnNameLabel = {
  PRODUCT_NAME: '物品名',
  PRODUCT_ID: 'ID',
  QUANTITY_OF_CURRENT_INVENTORY: '在庫数',
  UNIT: '単位',
  COLOR: '色',
  STORAGE_SPACE: '保管場所',
  CATEGORY: '分類',
  UNIT_COST: '単価',
  ORDER_PONIT_FOR_HEXA: '発注点 for Hexa',
  NORMAL_DELIVERY_DATE: '標準納期',
  SET_INVENTORY: '設定在庫',
  NOTE: '備考',
  PRIMARY_SUPPLIER: '第一発注先2',
  PARTNER_WHO_SHARES_INVENTORY: '在庫共有先',
  AMOUNT_OF_DELIVERY: '出庫数',
  PRODUCT_CONTROL_NUMBER: '管理番号',
  CUSTOM_ORDER: '特注部品',
}

export const inventoryActionName = {
  REGISTER: '新しいデータを登録する',
  UPDATE: 'Update',
  ORDER: '発注する',
  DELIVERY: '出庫',
}

export const inventoryLabel = {
  PRODUCT_NAME: '在庫名',
  PRODUCT_ID: 'ID',
  QUANTITY_OF_CURRENT_INVENTORY: '在庫数',
  UNIT: '単位',
  COLOR: '色',
  STORAGE_SPACE: '保管場所',
  CATEGORY: '分類',
  UNIT_COST: '単価',
  ORDER_PONIT: '発注点',
  NORMAL_DELIVERY_DATE: '標準納期',
  SET_INVENTORY: '設定在庫',
  AMOUNT_OF_DELIVERY: '出庫数',
  NOTE: '備考',
  PRIMARY_SUPPLIER: '優先発注先',
  PARTNER_WHO_SHARES_INVENTORY: '在庫共有先',
  CUSTOM_ORDER: '特注部品',
}

export const customOrderValue = {
  NO: 0,
  YES: 1,
}

export const orderError = {
  targetStatus: '手配済み',
  message: 'この部品 は「手配済み」です。\n部品を「コピー登録」した後「発注」してください。',
}
