import React, { useContext, useState, useEffect, useRef } from 'react'

import PropTypes from 'prop-types'
import { Table, Button, Popconfirm, Form, Input, Select, notification, Row, Col } from 'antd'

import { InventoryHistoryDetailColumns, historyTableRrows } from 'app/components/pages/inventory/types'
import { getTotalCountItems } from 'app/utils/components/inventory'
import { getCommonTableScrollSize } from 'app/utils/components/common'

interface EditableCellProps {
  title: React.ReactNode
  editable: boolean
  children: React.ReactNode
  dataIndex: string
  record: {}
  handleSave: (record: {}) => void
  routeOrderDetail: (record: {}) => void
}

// editTable 関連処理
const EditableContext = React.createContext(null)
const EditableRow = ({ ...props }) => (
  <EditableContext.Provider value={null}>
    <tr {...props} />
  </EditableContext.Provider>
)
const EditableFormRow = Form.create()(EditableRow)

// Cell編集関連処理
const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  routeOrderDetail,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef<any>()
  const form = useContext(EditableContext)

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const isInputNumArea = (): boolean => {
    return dataIndex.includes('.count')
  }

  const toggleEdit = () => {
    setEditing(!editing)
  }

  // 入力データ確認
  const inputDataValid = (value: string | undefined) => {
    if (isInputNumArea()) {
      if (value != null && isNaN(Number(value))) {
        throw '数量は数字で入力してください'
      }
      return value == null ? '0' : value
    }
    return value == null ? '　' : value
  }

  //入力アラート
  const openNotification = (errMassage: string) => {
    notification.open({
      message: '入力不備があります',
      description: errMassage,
      className: 'validation',
      style: {
        width: 300,
      },
    })
  }

  const save = async e => {
    try {
      toggleEdit()
      const saveValue = inputDataValid(inputRef.current.state.value)
      const saveCellData = { [dataIndex]: saveValue }
      handleSave({ ...record, ...saveCellData })
    } catch (errInfo) {
      // 入力不備の場合はエラーを表示
      openNotification(errInfo)
    }
  }

  // 入力を許可しているセルか否か
  const isInputAllowedArea = () => {
    if (record['category'] == '有効残') return false
    if (isInputNumArea()) return true
    if (record['dName'] == '仮出庫') return true
    return false
  }

  let childNode = children

  if (editable && isInputAllowedArea()) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }}>
        <Input ref={inputRef} defaultValue={record[dataIndex]} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ padding: 5, backgroundColor: '#ffffb8', borderRadius: 2, minHeight: '2em' }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    )
  }

  // 入庫（発注DB参照）サプライヤー欄は発注詳細へ遷移させる
  const isSupliar = record => {
    return record != null && record.hasOwnProperty('dName') && record['dName'] == '発注DB' && dataIndex.includes('.no')
  }
  if (isSupliar(record)) {
    childNode = (
      <div
        onClick={(): void => routeOrderDetail(record)}
        style={{ color: '#000080', textDecoration: 'underline', cursor: 'pointer' }}
      >
        {children}
      </div>
    )
  }

  return (
    <td {...restProps} style={{ padding: '4px' }}>
      {childNode}
    </td>
  )
}

EditableCell.propTypes = {
  title: PropTypes.node,
  editable: PropTypes.bool.isRequired,
  children: PropTypes.node,
  dataIndex: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  routeOrderDetail: PropTypes.func.isRequired,
}

const InventoryHistoryPage = ({
  tableColumns,
  tableRows,
  saveHandle,
  deleteHandle,
  yearChangeHandle,
  routeOrderDetail,
  selectYearOption,
  initialValue,
}: any): JSX.Element => {
  const initialDatas = tableRows
  const initialColumns = tableColumns
  const [dataSource, setDataSource] = useState<historyTableRrows>(initialDatas)
  const [tableColumn, setTableColumn] = useState<Array<InventoryHistoryDetailColumns>>(initialColumns)
  const { Option } = Select
  let isYearFilter = false
  let tempDatas: Array<{}> = []
  const tableScrollSetting = getCommonTableScrollSize()

  useEffect(() => {
    if (tableRows) {
      setDataSource(prev => {
        const copyDataList = [...prev]
        copyDataList.splice(0, copyDataList.length, ...tableRows)
        return copyDataList
      })
    }
    if (tableColumns) {
      setTableColumn(prev => {
        const copyColumList = [...prev]
        copyColumList.splice(0, copyColumList.length, ...tableColumns)
        return copyColumList
      })
    }
  }, [tableRows, tableColumns])

  const handleDeleteRow = key => {
    const copyDataList = dataSource
    if (isYearFilter && tempDatas.length) {
      copyDataList.splice(0, copyDataList.length, ...tempDatas)
    }

    setDataSource(dataSource.filter(item => item.key_index != key))
    deleteHandle(dataSource.filter(item => item.key_index !== key && item.dName == '仮出庫'))

    isYearFilter = false
    tempDatas = []
  }

  const handleAddRow = () => {
    const newData = {
      dName: '仮出庫',
    }

    const copyDataList = dataSource
    if (isYearFilter && tempDatas.length) {
      copyDataList.splice(0, copyDataList.length, ...tempDatas)
    }
    //出庫のレコード下に挿入
    const keyArray: number[] = []
    copyDataList.map((item, i) => {
      if (item.dName === '仮出庫') {
        keyArray.push(Number(i))
      }
    })
    const insertKey = keyArray.length ? Math.max(...keyArray) + 1 : 0
    copyDataList.splice(insertKey, 0, newData)

    const result = dataSource.map(function(value, i) {
      value.key_index = String(i)
      return value
    })
    setDataSource(result)

    isYearFilter = false
    tempDatas = []
  }

  const deleteActionCell = {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    width: '80px',
    fixed: 'right',
    render: (text, record) => {
      function deleteButton(record) {
        const deleteButton = () => {
          return record.category != '仮出庫' ? (
            <Popconfirm
              title="仮出庫行を削除してよろしいでしょうか？"
              onConfirm={() => handleDeleteRow(record.key_index)}
            >
              <Button
                shape="circle"
                icon="delete"
                size="small"
                style={{ margin: 3, background: 'red', borderColor: 'red' }}
              />
            </Popconfirm>
          ) : (
            ''
          )
        }
        if (record.dName === '仮出庫') {
          return (
            <>
              <Button
                type="primary"
                shape="circle"
                icon="plus"
                size="small"
                style={{ margin: 3 }}
                onClick={handleAddRow}
              />
              {deleteButton()}
            </>
          )
        } else {
          return null
        }
      }
      return deleteButton(record)
    },
  }

  const handleSaveRow = (row): void => {
    const newData = [...dataSource]
    const index = newData.findIndex(item => row.key_index === item.key_index)
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row,
    })

    const sumaryKey = newData.findIndex(row => row['category'] == '有効残')
    newData.splice(sumaryKey, 1, {
      ...newData[sumaryKey],
      ...getTotalCountItems(newData, tableColumn, initialValue)[0],
    })

    setDataSource(newData)
  }

  const components = {
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  }

  const addSaveHandleColumns = columnList => {
    const mapColumns = col => {
      if (!col.editable) {
        return col
      }
      const newCol = {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: handleSaveRow,
          routeOrderDetail: routeOrderDetail,
        }),
      }
      if (col.children) {
        newCol.children = col.children.map(mapColumns)
      }
      return newCol
    }

    const colum = columnList.find(column => column.dataIndex == 'operation')
    if (colum == null) columnList = [...columnList, ...[deleteActionCell]]

    return columnList.map(mapColumns)
  }

  const handleYearChange = value => {
    const filterData = yearChangeHandle(value)
    const tableColumns = addSaveHandleColumns(filterData.columns)
    let colums = tableColumns.filter(col => col.dataIndex != 'operation')
    colums = [...colums, deleteActionCell]

    setDataSource(prev => {
      const copyDataList = [...prev]
      copyDataList.splice(0, copyDataList.length, ...filterData.items)
      return copyDataList
    })
    setTableColumn(prev => {
      const copyColumList = [...prev]
      copyColumList.splice(0, copyColumList.length, ...colums)
      return copyColumList
    })
    isYearFilter = true
    tempDatas = filterData.items
  }

  return (
    <div className="tableArea" style={{ width: '90vw' }}>
      <Row style={{ marginBottom: '10px' }}>
        <Col span={8}>
          <Form.Item>
            <Select size="large" placeholder="表示年度切替" style={{ width: 310 }} onChange={handleYearChange}>
              {selectYearOption.map(item => {
                return (
                  <Option value={item.key} key={item.key}>
                    {item.value}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4} offset={12}>
          <Button
            type="default"
            className="btn-white"
            onClick={(): void => {
              saveHandle(dataSource.filter(item => item.dName === '仮出庫'))
            }}
            style={{ margin: 0 }}
          >
            仮出庫を保存
          </Button>
        </Col>
      </Row>
      <div>
        <Table
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={dataSource}
          columns={addSaveHandleColumns(tableColumn)}
          pagination={false}
          scroll={{ y: tableScrollSetting['y'], x: 'max-content' }}
          tableLayout="initial"
        />
      </div>
    </div>
  )
}

export default InventoryHistoryPage
