import { ofType } from 'redux-observable'
import { concatMap } from 'rxjs/operators'
import { DatastoreActions, ItemsActions, ProjectSelectors } from '..'
import { Observable, Action } from 'redux'
import { PayloadAction } from '@reduxjs/toolkit'
import { EMPTY, of } from 'rxjs'

const setCurrentDatastoreIdEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(DatastoreActions.setCurrentDatastoreId),
    concatMap((action: PayloadAction<{ datastoreId: string; type: string; params: { [k: string]: any } }>) => {
      const projectId = ProjectSelectors.getCurrentProjectId(state$.value)
      if (action.payload.datastoreId) {
        if (action.payload.type && action.payload.type == 'itemSearch' && action.payload.params) {
          const page = action.payload.params.page ? action.payload.params.page : 1
          const fieldId = action.payload.params.fieldId ? action.payload.params.fieldId : ''
          const order = action.payload.params.order ? action.payload.params.order : ''
          const conditions = action.payload.params.conditions ? action.payload.params.conditions : {}
          return of(
            ItemsActions.setSearchParams({ page: page, fieldId: fieldId, order: order, conditions: conditions }),
          )
        } else {
          return of(
            ItemsActions.getItemsListRequest({
              projectId,
              datastoreId: action.payload.datastoreId,
            }),
          )
        }
      } else {
        return EMPTY
      }
    }),
  )

export default [setCurrentDatastoreIdEpic]
