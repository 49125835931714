import { Columns, ColumnsWithOrder } from 'app/services/store/items/types'
import { ActionFieldSettings, ItemDetailsAction, CommentData, History } from 'app/services/store/itemDetails/types'
import { getItemIdFromKey } from 'app/utils/components/common'
import { OrderDetailList } from 'app/components/pages/order/types'
import { SearchCondition } from 'app/services/store/items/types'

import Moment from 'moment'
import {
  datetimeFormat,
  orderColumnNameLabel,
  defaultCommentLabel,
  statusActionLabel,
  statusLabel,
} from 'app/constants/order'

type SupplierOption = {
  u_id: string
  username: string
}[]

export const mandatoryFormIdExtractor = (actionFieldSettings: ActionFieldSettings): string[] | undefined => {
  const mandatoryFormIdList: Array<string> = []
  if (actionFieldSettings) {
    Object.entries(actionFieldSettings).forEach(([key]) => {
      if (actionFieldSettings[key] && actionFieldSettings[key].mandatory === true) {
        mandatoryFormIdList.push(key)
      }
    })
  }
  return mandatoryFormIdList
}

export const getUserIdFromUserName = (userName: string, supplierOptions: SupplierOption): string | undefined => {
  const supplier = supplierOptions.find(supplier => supplier.username === userName)
  return supplier ? supplier.u_id : undefined
}

export const actionIdDeteminerByActionName = (actions: ItemDetailsAction[]) => (
  actionName: string,
): string | undefined => {
  const action = actions.find((action: ItemDetailsAction) => action.name === actionName)
  return action ? action.a_id : undefined
}

const labelCellWidth = (): Array<{}> => {
  return [
    { title: 'ステータス', width: 120 },
    { title: '在庫ID', width: 80 },
    { title: '管理番号', width: 100 },
    { title: '在庫名', width: 150 },
    { title: '発注個数', width: 80 },
    { title: 'サプライヤー', width: 130 },
    { title: '発注日', width: 100 },
    { title: '希望納期', width: 100 },
    { title: '納期回答 締切', width: 100 },
    { title: '希望単価', width: 80 },
    { title: '回答納期', width: 100 },
    { title: '出荷日', width: 100 },
    { title: '基本単価', width: 80 },
  ]
}

export const filterListsupplier = (): Array<{ [k: string]: string }> => {
  return [{ title: 'サプライヤー', sortAndSearch: 'none' }]
}

export const filterListColumns = (columns: Columns): Columns => {
  const labelsWidth = labelCellWidth()
  const resultColums = columns
    .filter(
      item =>
        item.title === 'ステータス' ||
        item.title === '在庫ID' ||
        item.title === '管理番号' ||
        item.title === '在庫名' ||
        item.title === '発注個数' ||
        item.title === 'サプライヤー' ||
        item.title === '発注日' ||
        item.title === '希望納期' ||
        item.title === '納期回答 締切' ||
        item.title === '希望単価' ||
        item.title === '回答納期' ||
        item.title === '出荷日' ||
        item.title === '基本単価',
    )
    .map(item => {
      const data = labelsWidth.find(record => record['title'] == item.title)
      if (data) {
        item.width = data['width']
      }
      if (item.dataIndex !== item.key) {
        item.dataIndex = item.key
      }
      return item
    })
  return resultColums
}

export const getCommentDataFromHistories = (itemDetailsPost: Array<History> | null): Array<CommentData> | undefined => {
  const histories = itemDetailsPost ? itemDetailsPost.filter(history => history.history.comment !== '') : undefined

  if (histories) {
    const commentData: Array<CommentData> = []
    histories.forEach(history => {
      if (history.history.comment === defaultCommentLabel.POST_EMPTY) {
        return
      }

      let comment: string
      if (history.history.comment === defaultCommentLabel.NEW_ITEM) {
        comment = '新規アイテムが作成されました。'
      } else {
        comment = history.history.comment
      }

      commentData.push({
        author: history.history.username,
        content: comment,
        datetime: Moment(history.history.created_at).format(datetimeFormat),
      })
    })
    return commentData
  } else {
    return undefined
  }
}

export const getDatepickerFormId = (columns: Columns): Array<string | undefined> => {
  const datepickerFormIds = [
    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.ORDERED_DATE),
    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.DESIRED_DELIVERY_DATE),
    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.LIMIT_OF_ANSWERING),
    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.ANSWERED_DELIVERY_DATE),
    getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.SHIPPING_DATE),
  ]
  return datepickerFormIds
}

export const getUpdatableFormIds = (actionFieldSettings: ActionFieldSettings): Array<string> => {
  const updatableFormIds = new Array<string>()

  Object.entries(actionFieldSettings).forEach(([key, value]) => {
    if (value.update === true) updatableFormIds.push(key)
  })
  return updatableFormIds
}

export const getChanges = (
  values,
  columns: Columns,
  actionFieldSettings: ActionFieldSettings,
): Array<{ id: string; value: string }> => {
  const supplier = getItemIdFromKey<Columns, keyof OrderDetailList>(columns, orderColumnNameLabel.SUPPLIER)
  const datepickerFromIds = getDatepickerFormId(columns)
  const updatableFormIds = getUpdatableFormIds(actionFieldSettings)

  const changes = new Array<{ id: string; value: any }>()
  // NOTE: Format the value of date
  // e.g. 2018/01/11 -> "2018-01-10T15:00:00.000Z"
  Object.entries(values).forEach(([key, value]) => {
    if (updatableFormIds.some((updatableId: string) => updatableId === key)) {
      if (key === supplier) {
        changes.push({ id: key, value: [value] })
      } else if (datepickerFromIds.some((formId: string | undefined) => formId === key)) {
        changes.push({ id: key, value: values[key] ? values[key]._d.toISOString() : null })
      } else {
        changes.push({ id: key, value })
      }
    }
  })
  return changes
}

export const sortOrderColumns = (columns: Columns): ColumnsWithOrder => {
  columns.forEach((column): void => {
    if (column.key === 'ステータス') {
      column['order'] = 0
    } else if (column.key === 'ID') {
      column['order'] = 1
    } else if (column.key === '管理番号') {
      column['order'] = 2
    } else if (column.key === '物品名') {
      column['order'] = 3
    } else if (column.key === '発注個数') {
      column['order'] = 4
    } else if (column.key === '発注先') {
      column['order'] = 5
    } else if (column.key === '発注日') {
      column['order'] = 6
    } else if (column.key === '希望納期') {
      column['order'] = 7
    } else if (column.key === '納期回答 締切') {
      column['order'] = 8
    } else if (column.key === '希望単価') {
      column['order'] = 9
    } else if (column.key === '単価') {
      column['order'] = 10
    } else if (column.key === '回答納期') {
      column['order'] = 11
    } else if (column.key === '出荷日') {
      column['order'] = 12
    }
  })
  columns.sort((a, b): number => {
    if (a['order'] > b['order']) {
      return 1
    } else {
      return -1
    }
  })
  return columns as ColumnsWithOrder
}

export const isStatusAction = (actionName: string | undefined): boolean =>
  Object.values(statusActionLabel).some(statusActionName => statusActionName === actionName)

const isCheckStr = (str: string): boolean => {
  return typeof str === 'string' && str.length > 0
}

export const createSupplierSearchCondition = (
  supplierFIeldId: string,
  getUserId: string,
  columns: Columns,
  statusFieldId?: string,
  hasStatus?: boolean,
): Array<SearchCondition> => {
  const conditions = new Array<SearchCondition>()
  // サプライヤ縛りを適応
  if (isCheckStr(supplierFIeldId) && isCheckStr(getUserId)) {
    conditions.push({ id: supplierFIeldId, search_value: [getUserId] })
  }
  // 起票・発注、要確認以外のステータスを表示する
  if (statusFieldId !== undefined && isCheckStr(statusFieldId) && !hasStatus) {
    const statusColumn = columns.find(field => field.id === statusFieldId)
    if (statusColumn !== undefined && statusColumn.statuses) {
      const statusCondition = { id: statusFieldId, search_value: [] as string[] }
      for (const key in statusColumn.statuses) {
        if (![statusLabel.DRAFT_ORDER, statusLabel.NEED_CONFIRM].includes(statusColumn.statuses[key].name))
          statusCondition.search_value.push(statusColumn.statuses[key].id)
      }
      conditions.push(statusCondition)
    }
  }
  return conditions
}
