import React, { ReactNode } from 'react'
import { Layout } from 'antd'

import CommonHeader from 'app/components/organisms/CommonHeader'
import PrimaryMenu from 'app/components/organisms/PrimaryMenu'

const { Sider, Content } = Layout

interface AppContainerProps {
  children: ReactNode
}
const AppLayout = ({ children }: AppContainerProps): JSX.Element => {
  return (
    <div>
      <CommonHeader />
      <PrimaryMenu />

      <Layout>
        <Sider width={0} style={{ background: '#fff' }}></Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content
            style={{
              background: '#fff',
              padding: 24,
              margin: 0,
              minHeight: '89vh',
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </div>
  )
}

export default AppLayout
