import { createSelector } from 'reselect'
import memoize from 'lodash.memoize'

import { RootState } from '../rootReducer'
import { Project } from './types'
import { ProjectState } from './state'
import { hexaLabel } from 'app/constants/hexalink'

const projectState = (state: RootState) => state.projects
const projectList = (state: RootState) => state.projects.project_list
const projectCurrentId = (state: RootState) => state.projects.current_project_id

export const getProjectList = createSelector(projectList, (projectList: Array<Project>) => projectList)

export const getProjectIsLoading = createSelector(projectState, (projectId: ProjectState) => projectId.loading)

export const getCurrentProjectId = createSelector(projectCurrentId, (projectId: string) => projectId)

export const getCurrentProject = createSelector(projectList, projectCurrentId, (projects: Array<Project>) => {
  return projects.find(pj => pj.name === hexaLabel.PROJECT_NAME) || ({} as Project)
})

export const getCurrentProjectDatastores = createSelector(getCurrentProject, (project: Project | null) => {
  return project ? project.datastores : []
})

export const getProjectById = createSelector<RootState, Array<Project>, (pj_id: string) => Project>(
  projectList,
  (projectList: Array<Project>) => memoize<Project>(pj_id => projectList.find(pj => pj.application_id === pj_id)),
)
