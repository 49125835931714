import { createSlice } from '@reduxjs/toolkit'
import * as reducers from './reducers'
import { initialState } from './state'

export const groupSlice = createSlice({
  name: 'group',
  initialState: initialState,
  reducers: {
    getGroupsRequest: reducers.getGroupsRequest,
    getGroupsSuccess: reducers.getGroupsSuccess,
    getGroupsFaild: reducers.getGroupsFailed,
    getSupplierGroupRequest: reducers.getSupplierGroupsRequest,
    getSupplierGroupSuccess: reducers.getSupplierGroupsSuccess,
    getSupplierGroupFaild: reducers.getGroupsFailed,
    reset: reducers.reset,
  },
})

export const { getGroupsRequest, getGroupsSuccess, getGroupsFaild } = groupSlice.actions
