import { createSelector } from 'reselect'
import { RootState } from '../rootReducer'
import { DataReportState, DataReportSearchCondition } from './state'
import { ReportDisplayableEntries, ReportField, ReportResult } from './types'
import StringUtils from 'app/utils/stringUtils'

export const dataReportState = (state: RootState): DataReportState => state.dataReport
export const reportFields = (state: RootState): ReportField[] => state.dataReport.report_fields
export const reportResults = (state: RootState): ReportResult[] => state.dataReport.report_results

export const getDataReportIsLoading = createSelector<RootState, DataReportState, boolean>(
  dataReportState,
  (dataReportState: DataReportState) => dataReportState.loading,
)

export const getReportFields = createSelector<RootState, DataReportState, DataReportState['report_fields']>(
  dataReportState,
  (dataReportState: DataReportState) => dataReportState.report_fields,
)

export const getDisplayableEntries = createSelector<RootState, ReportField[], ReportResult[], ReportDisplayableEntries>(
  reportFields,
  reportResults,
  (reportFields: ReportField[], reportResults: ReportResult[]) => {
    const displayColumns =
      (reportFields &&
        reportFields.map((field: ReportField) => {
          return {
            title: field.title,
            dataIndex: field.title,
            key: field.title,
            id: field.display_id,
            width: 100,
          }
        })) ||
      []

    const displayItems =
      reportResults &&
      reportResults.map((result: ReportResult) => {
        const tempObj: { [k: string]: string | number | Array<{}> } = {}
        displayColumns.forEach(column => {
          Object.keys(result).forEach((key: string) => {
            if (key === column.id) {
              tempObj[column.title] = result[key]
              if (column.title === 'ステータス') {
                tempObj['status'] = result[key]
              }
            }
          })
        })

        // グルーピングの場合はi_idが配列で入ってくるので格納
        if (result.items) {
          tempObj['items'] = result.items
        }

        tempObj['key'] = `${StringUtils.GenUUID()}`
        tempObj['i_id'] = result.i_id

        return tempObj
      })
    return { columns: displayColumns, items: displayItems }
  },
)

export const getDataReportTotalItems = createSelector<RootState, DataReportState, string>(
  dataReportState,
  (dataReportState: DataReportState) => dataReportState.totalItems,
)

export const getDataReportSearchFields = createSelector<RootState, DataReportState, DataReportSearchCondition[]>(
  dataReportState,
  (dataReportState: DataReportState) => dataReportState.search_fields,
)
