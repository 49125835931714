import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { Row, Col, DatePicker, Input, Button, Form, Spin, Select, Table, Modal } from 'antd'
import { FormComponentProps } from 'antd/lib/form/Form'
import PropTypes from 'prop-types'

import { formKeys, destinationData, selectSendType, maxLengthMessage, errorMessages } from 'app/constants/deliverySlip'
import { strCount, replaceMessage } from 'app/utils/components/common'

import { DeliverySlipDetailList } from './types'
import { ItemDetailsField } from 'app/services/store/itemDetails/types'

const { Item } = Form
const { Option } = Select
const dateFormat = 'YYYY-MM-DD'

interface InnerDeliverySlipDetailProps extends FormComponentProps {
  deliverySlipDetailResource: {
    fields: { [k: string]: ItemDetailsField }
    currentDetailList: DeliverySlipDetailList
    items: Array<{ [k: string]: any }>
    isLoading: boolean
    submit: (form) => void
    downloadPdf: (pdfId: string, fileName: string) => void
    linkedItemData: Array<{ [k: string]: any }>
    keys: { [k: string]: string }
    deliverySlipCreateColumns: Array<{ [k: string]: any }>
    isSupplier: boolean
  }
}

const EditableContext = React.createContext(null)
const EditableRow = ({ ...props }) => (
  <EditableContext.Provider value={null}>
    <tr {...props} />
  </EditableContext.Provider>
)
const EditableFormRow = Form.create()(EditableRow)

interface EditableCellProps {
  title: React.ReactNode
  editable: boolean
  children: React.ReactNode
  dataIndex: string
  record: {}
  handleSave: (record: {}) => void
  cellType: boolean
  editing: boolean
  inputWidth: string
  isAllowEdit: boolean
  keys: {}
  form: any
  isEditMode: boolean
}
const EditableCell: React.FC<EditableCellProps> = ({
  editable,
  editing,
  dataIndex,
  title,
  cellType,
  record,
  children,
  inputWidth,
  handleSave,
  isAllowEdit,
  keys,
  form,
  isEditMode,
  ...restProps
}) => {
  const [editing2, setEditing] = useState(false)
  const inputRef = useRef<any>()
  const { getFieldDecorator } = form
  useEffect(() => {
    if (editing2) {
      inputRef.current.focus()
    }
  }, [editing2])

  const toggleEdit = () => {
    setEditing(!editing2)
  }

  const saveRemarks = async e => {
    toggleEdit()
    const saveKey = dataIndex == '物品名' ? '備考' : dataIndex
    const saveCellData = { [saveKey]: inputRef.current.state.value }
    handleSave({ ...record, ...saveCellData })
  }

  const saveUnit = async e => {
    toggleEdit()
    const saveCellData = { [dataIndex]: inputRef.current.state.value }
    handleSave({ ...record, ...saveCellData })
  }

  return (
    <td {...restProps} style={{ paddingTop: '0px', paddingBottom: '0px', marginBottom: '0px', width: inputWidth }}>
      {editing ? (
        !cellType ? (
          <Form.Item>
            <Col>{record[dataIndex]}</Col>
            {getFieldDecorator(keys['noteKey'] + '-' + record['i_id'], {
              initialValue: record['備考'],
              rules: [
                {
                  validator: (rule: any, value: string, callback: (msg?: string) => void) => {
                    const limit = 34
                    strCount(value) > limit
                      ? callback(replaceMessage(maxLengthMessage, '備考', String(limit / 2)))
                      : callback()
                  },
                },
              ],
            })(<Input ref={inputRef} onPressEnter={saveRemarks} onBlur={saveRemarks} disabled={!isEditMode} />)}
          </Form.Item>
        ) : (
          <Form.Item>
            {getFieldDecorator(keys['unitKey'] + '-' + record['i_id'], {
              initialValue: record['単位'],
              rules: [
                {
                  required: true,
                  message: '単位の入力は必須です。',
                },
                {
                  validator: (rule: any, value: string, callback: (msg?: string) => void) => {
                    const limit = 6
                    strCount(value) > limit
                      ? callback(replaceMessage(maxLengthMessage, '単位', String(limit / 2)))
                      : callback()
                  },
                },
              ],
            })(<Input ref={inputRef} onPressEnter={saveUnit} onBlur={saveUnit} disabled={!isEditMode} maxLength={6} />)}
          </Form.Item>
        )
      ) : (
        children
      )}
    </td>
  )
}
EditableCell.propTypes = {
  title: PropTypes.node,
  editable: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  children: PropTypes.node,
  dataIndex: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  cellType: PropTypes.bool.isRequired,
  inputWidth: PropTypes.string.isRequired,
  isAllowEdit: PropTypes.bool.isRequired,
  keys: PropTypes.object.isRequired,
  form: PropTypes.any.isRequired,
  isEditMode: PropTypes.bool.isRequired,
}

const InnerDeliverySlipDetailPage = ({
  form,
  deliverySlipDetailResource: {
    currentDetailList,
    items,
    submit,
    downloadPdf,
    isLoading,
    keys,
    deliverySlipCreateColumns,
    isSupplier,
  },
}: InnerDeliverySlipDetailProps): JSX.Element => {
  const { getFieldDecorator } = form

  const [dataSource, setDataSource] = useState(items)

  const handleSaveRow = (row): void => {
    const newData = [...dataSource]
    const index = newData.findIndex(item => row.key === item['key'])
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row,
    })
    setDataSource(newData)
  }

  const selectSendFactoryData = {
    ...destinationData,
    その他: {
      address: currentDetailList == null ? '' : currentDetailList['住所'],
      telephone: currentDetailList == null ? '' : currentDetailList['電話番号'],
    },
  }

  const [isEditMode, setIsEditMode] = useState(false)
  const [selectSend, setSelectSend] = useState(currentDetailList == null ? '' : currentDetailList['送付先'])
  const [selectValues, setSelectValues] = useState(Object.values(selectSendType))
  const [selectAddress, setSelectAddress] = useState(currentDetailList == null ? '' : currentDetailList['住所'])
  const [selectTelephone, setSelectTelephone] = useState(currentDetailList == null ? '' : currentDetailList['電話番号'])

  useEffect(() => {
    if (currentDetailList != null) {
      setSelectAddress(currentDetailList['住所'])
      setSelectTelephone(currentDetailList['電話番号'])
    }
  }, [currentDetailList])

  const handleChange = event => {
    setSelectSend(event)
    switch (event) {
      case selectSendType.HEADOFFICEFACTORY:
        setSelectAddress(selectSendFactoryData['白山工業本社工場'].address)
        setSelectTelephone(selectSendFactoryData['白山工業本社工場'].telephone)
        break
      case selectSendType.SECONDOFFICEFACTORY:
        setSelectAddress(selectSendFactoryData['白山工業茨城第二工場'].address)
        setSelectTelephone(selectSendFactoryData['白山工業茨城第二工場'].telephone)
        break
      default:
        setSelectAddress(currentDetailList == null ? '' : currentDetailList['住所'])
        setSelectTelephone(currentDetailList == null ? '' : currentDetailList['電話番号'])
        break
    }
  }

  const changeTextAddress = event => {
    setSelectAddress(event.target.value)
  }

  const changeTextTelephone = event => {
    setSelectTelephone(event.target.value)
  }

  const initialDate = moment(new Date(), dateFormat)
  const [selectDeliveryDate, setSelectDeliveryDate] = useState(initialDate)
  const dateHandleChange = date => {
    setSelectDeliveryDate(date)
  }

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    submit(form)
  }

  const isDeliveryDatePastThanToDay = (): boolean => {
    const nowDate = moment(new Date()).format()
    const deliveryDate = moment(currentDetailList == null ? new Date() : currentDetailList['納品日']).format()
    return deliveryDate > nowDate
  }
  const isAllowEdit = isDeliveryDatePastThanToDay()

  const mergedColumns = deliverySlipCreateColumns.map(col => {
    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: col.editing,
        cellType: col.cellType,
        inputWidth: col.inputWidth,
        handleSave: handleSaveRow,
        isAllowEdit: isAllowEdit,
        keys: keys,
        form: form,
        isEditMode: isEditMode,
      }),
    }
  })

  const inputSendType = inputStr => {
    setSelectValues(prev => {
      const copyDataList = [...prev]
      copyDataList.splice(2, 1, inputStr)
      return copyDataList
    })
  }

  useEffect(() => {
    if (items) {
      setDataSource(prev => {
        const copyDataList = [...prev]
        copyDataList.splice(0, copyDataList.length, ...items)
        return copyDataList
      })
    }
  }, [items])

  const closeEditMode = () => {
    setIsEditMode(false)
  }

  const openEditMode = () => {
    setIsEditMode(true)
  }

  const [modalVisibility, setModalVisibility] = useState(false)

  const handleNextSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    setModalVisibility(false)
    submit(form)
  }
  const handleCloseModal = (): void => {
    setModalVisibility(false)
  }

  const modalContent = {
    title: '納品書修正',
    body: '納品書を更新してよろしいですか',
    onOk: handleNextSubmit,
    onCancel: handleCloseModal,
  }

  return (
    <Spin size="large" spinning={isLoading}>
      <div className="tableArea">
        <Form layout="vertical">
          <div className="basicInfo">
            <Row style={{ marginBottom: '10px', borderTop: '1px solid lightGray', paddingTop: '30px' }}>
              <Col span={10} style={{ minWidth: '300px' }}>
                <Col>納品書番号</Col>
                <Col span={20}>{currentDetailList == null ? '' : currentDetailList['納品書番号']}</Col>
              </Col>
              <Col span={10} style={{ minWidth: '300px' }}>
                <Form.Item label="納品日">
                  {getFieldDecorator(keys['deliveryDateKey'], {
                    initialValue: moment(currentDetailList == null ? new Date() : currentDetailList['納品日']),
                  })(<DatePicker onChange={dateHandleChange} format={dateFormat} disabled={!isEditMode} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={10} style={{ minWidth: '300px' }}>
                <Form.Item label="送付先">
                  <Col span={14} style={{ width: '300px' }}>
                    {getFieldDecorator(keys['destinationKey'], {
                      initialValue: String(currentDetailList == null ? '' : currentDetailList['送付先']),
                      rules: [
                        {
                          required: true,
                          message: '送付先は必須です。',
                        },
                        {
                          validator: (rule: any, value: string, callback: (msg?: string) => void) => {
                            const limit = 20
                            strCount(value) > limit
                              ? callback(replaceMessage(maxLengthMessage, '送付先', String(limit / 2)))
                              : callback()
                          },
                        },
                      ],
                    })(
                      <Select
                        id="Destination"
                        style={{ width: '300px' }}
                        onChange={handleChange}
                        disabled={!isEditMode}
                        showSearch={true}
                        onSearch={inputSendType}
                      >
                        {selectValues.map(option => (
                          <Option value={option} key={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>,
                    )}
                    <span style={{ fontSize: '0.8rem', display: 'block' }}>その他の場合は入力してください</span>
                  </Col>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
              <Col span={10} style={{ minWidth: '300px' }}>
                <Item label="住所">
                  {getFieldDecorator(keys['streetAddressKey'], {
                    initialValue: selectAddress,
                    rules: [
                      {
                        required: true,
                        message: '住所の入力は必須です。',
                      },
                      {
                        validator: (rule: any, value: string, callback: (msg?: string) => void) => {
                          const limit = 60
                          strCount(value) > limit
                            ? callback(replaceMessage(maxLengthMessage, '住所', String(limit / 2)))
                            : callback()
                        },
                      },
                    ],
                  })(<Input onChange={changeTextAddress} disabled={!isEditMode}></Input>)}
                </Item>
              </Col>
              <Col span={10} style={{ minWidth: '300px' }}>
                <Form.Item label="電話番号">
                  {getFieldDecorator(keys['telKey'], {
                    initialValue: selectTelephone,
                    rules: [
                      {
                        required: true,
                        message: '電話番号の入力は必須です。',
                      },
                      {
                        max: 13,
                        message: '最大13文字までです。',
                      },
                      {
                        validator: (rule: any, value: string, callback: (msg?: string) => void) => {
                          !/^[0-9-]+$/.test(value) ? callback(errorMessages.TEL) : callback()
                        },
                      },
                    ],
                  })(<Input onChange={changeTextTelephone} disabled={!isEditMode}></Input>)}
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="tableArea">
            <Row>
              <Col style={{ marginBottom: '50px' }}>
                <Table
                  components={{
                    body: {
                      row: EditableFormRow,
                      cell: EditableCell,
                    },
                  }}
                  loading={isLoading}
                  dataSource={dataSource}
                  columns={mergedColumns}
                  pagination={false}
                  tableLayout="initial"
                />
              </Col>
            </Row>
          </div>

          <div className="btnArea">
            <Row type="flex" justify="center">
              <Button
                type="dashed"
                size="large"
                shape="round"
                icon="download"
                disabled={currentDetailList == null || !currentDetailList[formKeys.PDF]}
                onClick={(): void => {
                  if (currentDetailList == null) return
                  return downloadPdf(currentDetailList[formKeys.PDF], currentDetailList[formKeys.DELIVERY_ID] + '.pdf')
                }}
              >
                納品書ダウンロード
              </Button>
            </Row>
            <Row type="flex" justify="center">
              <Col>
                {isSupplier && isAllowEdit && !isEditMode && (
                  <Button className="btn-white" htmlType="submit" onClick={openEditMode}>
                    内容修正
                  </Button>
                )}
              </Col>
            </Row>
            {isEditMode && (
              <Row type="flex" justify="center" style={{ marginTop: '20px' }}>
                <Col span={4}>
                  <Button htmlType="submit" onClick={() => setModalVisibility(true)}>
                    送信
                  </Button>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    <Button onClick={closeEditMode}>キャンセル</Button>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </div>
          <Modal
            title={modalContent['title']}
            visible={modalVisibility}
            onOk={modalContent['onOk']}
            onCancel={modalContent['onCancel']}
          >
            <p>{modalContent['body']}</p>
          </Modal>
        </Form>
      </div>
    </Spin>
  )
}

const DeliverySlipDetailPage = Form.create<InnerDeliverySlipDetailProps>()(InnerDeliverySlipDetailPage)
export default DeliverySlipDetailPage
