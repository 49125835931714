import React from 'react'
import { Form, Input } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'

interface PasswordInputProps {
  getFieldDecorator: WrappedFormUtils['getFieldDecorator']
  passwordError: boolean | string[] | undefined
  setPassword: (e: React.ChangeEvent<HTMLInputElement>) => void
  label?: string
  fieldId?: string
}

const PasswordInput = ({
  getFieldDecorator,
  passwordError,
  setPassword,
  fieldId,
  label,
}: PasswordInputProps): JSX.Element => {
  return (
    <Form.Item
      validateStatus={passwordError ? 'error' : ''}
      help={passwordError || ''}
      label={label ? label : 'パスワード'}
    >
      {getFieldDecorator(fieldId ? fieldId : 'password', {
        rules: [{ required: true, message: 'パスワードの入力は必須です' }],
      })(
        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setPassword(e)}
          type="password"
          placeholder="パスワード"
          size="large"
        />,
      )}
    </Form.Item>
  )
}

export default PasswordInput
