import React, { useState, useCallback, useEffect } from 'react'
import { Row, Col, Button, Input, InputNumber, Form, Select, Checkbox, Modal, Divider } from 'antd'
import { FormComponentProps } from 'antd/lib/form/Form'

import { Columns } from 'app/services/store/items/types'
import { ItemDetailsField, ItemDetailsAction } from 'app/services/store/itemDetails/types'
import { GroupMembers } from 'app/services/store/group/types'
import { InventoryDetailList, InventoryDetailColumns } from './types'
import { inventoryColumnNameLabel, inventoryActionName, inventoryLabel } from 'app/constants/inventory'
import { maxLengthMessage } from 'app/constants/deliverySlip'

import { getItemIdFromKey, strCount, replaceMessage } from 'app/utils/components/common'
import { getCategoryOptions, getSupplierOptions, getActionIdAndDid } from 'app/utils/components/inventory'

const { Option } = Select

interface InventoryDetailProps extends FormComponentProps {
  inventoryDetailResource: {
    fields: { [k: string]: ItemDetailsField }
    actions: ItemDetailsAction[]
    supplierMembers: GroupMembers
    currentDetailList: InventoryDetailList
    columns: InventoryDetailColumns
    submit: (form, postActionIds) => void
    copyItem: (form) => void
    deleteItem: () => void
    isSupplier: boolean
    isShiroyama: boolean
    doublePreventionFlg: boolean
    setDoublePreventionFlg: (bool) => void
    completeFlg: boolean
    setCompleteFlg: (bool) => void
    isErrorDialog: boolean
    getErrorMessage: string
    clearError: () => void
  }
}

const InnerInventoryDetail = ({
  form,
  inventoryDetailResource: {
    fields,
    actions,
    supplierMembers,
    currentDetailList,
    columns,
    submit,
    copyItem,
    deleteItem,
    isSupplier,
    isShiroyama,
    doublePreventionFlg,
    setDoublePreventionFlg,
    completeFlg,
    setCompleteFlg,
    isErrorDialog,
    getErrorMessage,
    clearError,
  },
}: InventoryDetailProps): JSX.Element => {
  const [postActionIds, setPostActionIds] = useState<{ a_id: string; d_id: string; name: string }>()
  const { getFieldDecorator } = form

  const check = currentDetailList[inventoryColumnNameLabel.CUSTOM_ORDER] == '1' ? true : false
  const [orderCheck, setOrderCheck] = useState(check)

  const categoryOptions = getCategoryOptions(fields)
  const supplierOptions = getSupplierOptions(supplierMembers)

  const handleSubmit = useCallback(
    (e: React.FormEvent): void => {
      e.preventDefault()
      if (postActionIds) {
        submit(form, postActionIds)
      }
    },
    [postActionIds, form, submit],
  )
  const orderPointName = String(getItemIdFromKey(columns, inventoryColumnNameLabel.ORDER_PONIT_FOR_HEXA))
  const clearValue = (name: string): void => {
    const define = {}
    define[name] = ''
    form.setFieldsValue(define)
  }
  const onCustomOrderChangehandle = (): void => {
    setOrderCheck(prev => {
      if (!prev) clearValue(orderPointName)
      return !prev
    })
  }

  const getCategoryValue = (): string => {
    if (Array.isArray(categoryOptions) && categoryOptions.length) {
      const find = categoryOptions.find(i => i.value == currentDetailList[inventoryColumnNameLabel.CATEGORY]) || []
      return find['o_id'] || ''
    }
    return ''
  }

  function setActionCompleteFlg() {
    setCompleteFlg(false)
    setDoublePreventionFlg(false)
  }

  function actionComplete() {
    if (!postActionIds) {
      setActionCompleteFlg()
      return
    }
    const crtAction = actions.find(a => a.a_id === postActionIds.a_id)
    if (crtAction && crtAction.name === '出庫') {
      const amountId = getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, '出庫数') || ''
      const deliveryAmount = form.getFieldValue(amountId) || 0
      const stockQuantity = parseInt(currentDetailList['在庫数']) - deliveryAmount
      currentDetailList['在庫数'] = String(stockQuantity)
      clearValue(amountId)
      const define = {}
      define[amountId] = 0
      form.setFieldsValue(define)
      Modal.success({
        content: '出庫しました。',
        onOk() {
          setActionCompleteFlg()
        },
      })
    } else {
      setActionCompleteFlg()
    }
  }

  useEffect(() => {
    if (completeFlg === true) {
      actionComplete()
    }
  }, [completeFlg])

  const getInitialValue = (name): any => {
    const user = supplierOptions.find(sp => sp.username == name)
    return user ? user.u_id : ''
  }

  return (
    <Form layout="horizontal" onSubmit={handleSubmit}>
      <Row type="flex" justify="center">
        <Col span={24}>
          <h3 style={{ marginTop: '15px' }}>在庫情報</h3>
          <Row
            type="flex"
            align="middle"
            justify="start"
            style={{ marginBottom: '10px', borderTop: '1px solid lightGray', paddingTop: '30px' }}
          >
            <Col span={6}>
              <Form.Item
                label="在庫名"
                key={getItemIdFromKey<Columns, keyof InventoryDetailList>(
                  columns,
                  inventoryColumnNameLabel.PRODUCT_NAME,
                )}
                labelCol={{ span: 5, offset: 0 }}
                wrapperCol={{ span: 19 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.PRODUCT_NAME,
                  ) || '',
                  {
                    initialValue: currentDetailList[inventoryColumnNameLabel.PRODUCT_NAME],
                  },
                )(<Input />)}
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item
                label="ID"
                key={getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, inventoryColumnNameLabel.PRODUCT_ID)}
                labelCol={{ span: 5, offset: 1 }}
                wrapperCol={{ span: 18 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, inventoryColumnNameLabel.PRODUCT_ID) ||
                    '',
                  {
                    initialValue: currentDetailList[inventoryColumnNameLabel.PRODUCT_ID],
                    rules: [
                      {
                        required: true,
                        message: `${inventoryColumnNameLabel.PRODUCT_ID}の入力は必須です。`,
                      },
                    ],
                  },
                )(<Input />)}
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item
                label="在庫数"
                key={getItemIdFromKey<Columns, keyof InventoryDetailList>(
                  columns,
                  inventoryColumnNameLabel.QUANTITY_OF_CURRENT_INVENTORY,
                )}
                labelCol={{ span: 6, offset: 2 }}
                wrapperCol={{ span: 16 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.QUANTITY_OF_CURRENT_INVENTORY,
                  ) || '',
                  {
                    initialValue: Number(currentDetailList[inventoryColumnNameLabel.QUANTITY_OF_CURRENT_INVENTORY]),
                  },
                )(<InputNumber style={{ width: '145px' }} />)}
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item
                label="単位"
                key={getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, inventoryColumnNameLabel.UNIT)}
                labelCol={{ span: 5, offset: 1 }}
                wrapperCol={{ span: 18 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, inventoryColumnNameLabel.UNIT) || '',
                  {
                    initialValue: currentDetailList[inventoryColumnNameLabel.UNIT],
                    rules: [
                      {
                        validator: (rule: any, value: string, callback: (msg?: string) => void) => {
                          const limit = 6
                          strCount(value) > limit
                            ? callback(replaceMessage(maxLengthMessage, '単位', String(limit / 2)))
                            : callback()
                        },
                      },
                    ],
                  },
                )(<Input maxLength={6} />)}
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item
                label="色"
                key={getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, inventoryColumnNameLabel.COLOR)}
                labelCol={{ span: 5, offset: 1 }}
                wrapperCol={{ span: 18 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, inventoryColumnNameLabel.COLOR) || '',
                  {
                    initialValue: currentDetailList[inventoryColumnNameLabel.COLOR],
                  },
                )(<Input />)}
              </Form.Item>
            </Col>
          </Row>

          <Row type="flex" align="middle" justify="start" style={{ margin: '0 0 10px 0' }}>
            <Col span={6}>
              <Form.Item
                label="保管場所"
                key={getItemIdFromKey<Columns, keyof InventoryDetailList>(
                  columns,
                  inventoryColumnNameLabel.STORAGE_SPACE,
                )}
                labelCol={{ span: 5, offset: 0 }}
                wrapperCol={{ span: 19 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.STORAGE_SPACE,
                  ) || '',
                  {
                    initialValue: currentDetailList[inventoryColumnNameLabel.STORAGE_SPACE],
                  },
                )(<Input />)}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="分類"
                key={getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, inventoryColumnNameLabel.CATEGORY)}
                labelCol={{ span: 5, offset: 1 }}
                wrapperCol={{ span: 18 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, inventoryColumnNameLabel.CATEGORY) ||
                    '',
                  {
                    initialValue: getCategoryValue(),
                  },
                )(
                  <Select>
                    {categoryOptions &&
                      categoryOptions.map(option => <Option key={option.o_id}>{option.value}</Option>)}
                  </Select>,
                )}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="単価"
                key={getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, inventoryColumnNameLabel.UNIT_COST)}
                labelCol={{ span: 5, offset: 1 }}
                wrapperCol={{ span: 18 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, inventoryColumnNameLabel.UNIT_COST) ||
                    '',
                  {
                    initialValue: Number(currentDetailList[inventoryColumnNameLabel.UNIT_COST]),
                  },
                )(<InputNumber style={{ width: '308px' }} />)}
              </Form.Item>
            </Col>

            <Col span={6} />
          </Row>

          <Row type="flex" align="middle" justify="start" style={{ margin: '0 0 10px 0' }}>
            <Col span={6}>
              <Form.Item
                label="発注点"
                key={getItemIdFromKey<Columns, keyof InventoryDetailList>(
                  columns,
                  inventoryColumnNameLabel.ORDER_PONIT_FOR_HEXA,
                )}
                labelCol={{ span: 5, offset: 0 }}
                wrapperCol={{ span: 19 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.ORDER_PONIT_FOR_HEXA,
                  ) || '',
                  {
                    initialValue: Number(currentDetailList[inventoryColumnNameLabel.ORDER_PONIT_FOR_HEXA]),
                  },
                )(<InputNumber disabled={orderCheck} />)}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="標準納期"
                key={getItemIdFromKey<Columns, keyof InventoryDetailList>(
                  columns,
                  inventoryColumnNameLabel.NORMAL_DELIVERY_DATE,
                )}
                labelCol={{ span: 5, offset: 1 }}
                wrapperCol={{ span: 18 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.NORMAL_DELIVERY_DATE,
                  ) || '',
                  {
                    initialValue: Number(currentDetailList[inventoryColumnNameLabel.NORMAL_DELIVERY_DATE]),
                  },
                )(<InputNumber />)}
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="設定在庫"
                key={getItemIdFromKey<Columns, keyof InventoryDetailList>(
                  columns,
                  inventoryColumnNameLabel.SET_INVENTORY,
                )}
                labelCol={{ span: 5, offset: 1 }}
                wrapperCol={{ span: 18 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.SET_INVENTORY,
                  ) || '',
                  {
                    initialValue: Number(currentDetailList[inventoryColumnNameLabel.SET_INVENTORY]),
                  },
                )(<InputNumber />)}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="出庫数"
                key={getItemIdFromKey<Columns, keyof InventoryDetailList>(
                  columns,
                  inventoryColumnNameLabel.AMOUNT_OF_DELIVERY,
                )}
                labelCol={{ span: 5, offset: 1 }}
                wrapperCol={{ span: 18 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.AMOUNT_OF_DELIVERY,
                  ) || '',
                  {
                    initialValue: Number(currentDetailList[inventoryColumnNameLabel.AMOUNT_OF_DELIVERY]),
                  },
                )(<InputNumber disabled={!isShiroyama} />)}
              </Form.Item>
            </Col>
          </Row>

          <Row type="flex" align="middle" justify="start" style={{ margin: '0 0 10px 0' }}>
            <Col span={6}>
              <Form.Item
                label="管理番号"
                key={getItemIdFromKey<Columns, keyof InventoryDetailList>(
                  columns,
                  inventoryColumnNameLabel.PRODUCT_CONTROL_NUMBER,
                )}
                labelCol={{ span: 5, offset: 0 }}
                wrapperCol={{ span: 19 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.PRODUCT_CONTROL_NUMBER,
                  ) || '',
                  {
                    initialValue: currentDetailList[inventoryColumnNameLabel.PRODUCT_CONTROL_NUMBER],
                  },
                )(<Input />)}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label={inventoryLabel.CUSTOM_ORDER}
                key={getItemIdFromKey(columns, inventoryColumnNameLabel.CUSTOM_ORDER)}
                labelCol={{ span: 8, offset: 0 }}
                wrapperCol={{ span: 8 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.CUSTOM_ORDER,
                  ) || '',
                  {
                    initialValue: orderCheck,
                  },
                )(<Checkbox checked={orderCheck} onChange={onCustomOrderChangehandle} />)}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="備考"
                key={getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, inventoryColumnNameLabel.NOTE)}
                labelCol={{ span: 5, offset: 0 }}
                wrapperCol={{ span: 19 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(columns, inventoryColumnNameLabel.NOTE) || '',
                  {
                    initialValue: currentDetailList[inventoryColumnNameLabel.NOTE],
                  },
                )(<Input />)}
              </Form.Item>
            </Col>
          </Row>

          <h1 style={{ marginTop: '15px' }}>発注情報</h1>
          <Row
            type="flex"
            align="middle"
            justify="start"
            style={{ marginBottom: '10px', borderTop: '1px solid lightGray', paddingTop: '10px' }}
          >
            <Col span={6}>
              <Form.Item
                label="優先発注先"
                key={getItemIdFromKey<Columns, keyof InventoryDetailList>(
                  columns,
                  inventoryColumnNameLabel.PRIMARY_SUPPLIER,
                )}
                labelCol={{ span: 6, offset: 0 }}
                wrapperCol={{ span: 18 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.PRIMARY_SUPPLIER,
                  ) || '',
                  {
                    initialValue: getInitialValue(currentDetailList[inventoryColumnNameLabel.PRIMARY_SUPPLIER]),
                  },
                )(
                  <Select>
                    {supplierOptions.map((supplier, idx: number) => (
                      <Option key={idx} value={supplier.u_id}>
                        {supplier.username}
                      </Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="在庫共有先"
                key={getItemIdFromKey<Columns, keyof InventoryDetailList>(
                  columns,
                  inventoryColumnNameLabel.PARTNER_WHO_SHARES_INVENTORY,
                )}
                labelCol={{ span: 6, offset: 1 }}
                wrapperCol={{ span: 17 }}
              >
                {getFieldDecorator(
                  getItemIdFromKey<Columns, keyof InventoryDetailList>(
                    columns,
                    inventoryColumnNameLabel.PARTNER_WHO_SHARES_INVENTORY,
                  ) || '',
                  {
                    initialValue: getInitialValue(
                      currentDetailList[inventoryColumnNameLabel.PARTNER_WHO_SHARES_INVENTORY],
                    ),
                  },
                )(
                  <Select>
                    {supplierOptions.map((supplier, idx: number) => (
                      <Option key={idx} value={supplier.u_id}>
                        {supplier.username}
                      </Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row type="flex" justify="center">
            <Col span={4}>
              <Form.Item>
                <Button type="primary" className="btn-white" onClick={(): void => copyItem(form)} size="large">
                  コピー登録
                </Button>
              </Form.Item>
            </Col>
            <Col span={4} offset={1}>
              <Form.Item>
                <Button
                  type="primary"
                  className="btn-white"
                  onClick={(): void => setPostActionIds(getActionIdAndDid(actions, inventoryActionName.UPDATE))}
                  htmlType="submit"
                  size="large"
                  disabled={doublePreventionFlg}
                >
                  内容修正
                </Button>
              </Form.Item>
            </Col>
            <Col span={3} offset={1} style={{ display: isSupplier ? 'none' : 'block' }}>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={(): void => setPostActionIds(getActionIdAndDid(actions, inventoryActionName.DELIVERY))}
                  htmlType="submit"
                  size="large"
                  disabled={doublePreventionFlg}
                >
                  出庫
                </Button>
              </Form.Item>
            </Col>
            <Col span={3} offset={1}>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={(): void => setPostActionIds(getActionIdAndDid(actions, inventoryActionName.ORDER))}
                  htmlType="submit"
                  size="large"
                  disabled={doublePreventionFlg}
                >
                  発注
                </Button>
              </Form.Item>
            </Col>
            <Col span={3} offset={1}>
              <Form.Item>
                <Button type="primary" className="btn-white" onClick={(): void => deleteItem()} size="large">
                  削除
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal title="実行確認" visible={isErrorDialog} onCancel={clearError} footer={null}>
        <p style={{ whiteSpace: 'pre-wrap' }}>{getErrorMessage}</p>
        <Divider />
        <div>
          <Button type="default" onClick={clearError} style={{ margin: '0 auto', display: 'block' }}>
            OK
          </Button>
        </div>
      </Modal>
    </Form>
  )
}

const InventoryDetailPage = Form.create<InventoryDetailProps>()(InnerInventoryDetail)

export default InventoryDetailPage
