export const errorMessages = {
  invalidEmail: '入力されたメールアドレスは登録されていません。',
  usedLink: 'すでにパスワード変更を実行済みのリンクがクリックされました。',
  expiredLink: 'パスワード変更の有効期限が切れています。再度リセットを行って下さい。',
}

export const messages = {
  sendEmail: 'パスワード変更用のメールを送信しました。',
  changePassword: 'パスワードを変更しました。新しいパスワードでログインを行なってください。',
}

export const CHANGE_PASSWORD_ID = 2
