import { ItemDetailsState, initialState } from './state'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  ItemDetails,
  ItemDetailMode,
  ItemDetailsCurrentAction,
  History,
  LinkedAllDbsItems,
  autoNumberItem,
} from './types'
import * as ApiModels from './epic.types'

export const getAutoNumberRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ field_Id: string; supplier_Id: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const getAutoNumberSuccess = (
  state: ItemDetailsState,
  action: PayloadAction<{ autoNumberItem: any }>,
): ItemDetailsState => {
  return {
    ...state,
    autoNumberItem: action.payload.autoNumberItem.result.value,
    loading: false,
  }
}

export const getAutoNumberFailed = (
  state: ItemDetailsState,
  action: PayloadAction<{ error: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const getDatastoreFieldsRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ d_Id: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const getDatastoreFieldsSuccess = (
  state: ItemDetailsState,
  action: PayloadAction<{ dataStoreItemField: any }>,
): ItemDetailsState => {
  return {
    ...state,
    dataStoreItemField: action.payload.dataStoreItemField.fields,
    loading: false,
  }
}

export const getDatastoreFieldsFailed = (
  state: ItemDetailsState,
  action: PayloadAction<{ error: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const getItemDetailsRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ itemId: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const getItemDetailsSuccess = (
  state: ItemDetailsState,
  action: PayloadAction<ItemDetails>,
): ItemDetailsState => {
  return {
    ...state,
    ...action.payload,
    loading: false,
    error: '',
  }
}

export const getItemDetailsFailed = (
  state: ItemDetailsState,
  action: PayloadAction<{ error: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const setMode = (state: ItemDetailsState, action: PayloadAction<{ mode: ItemDetailMode }>): ItemDetailsState => {
  return {
    ...state,
    mode: action.payload.mode,
  }
}

export const selectAction = (
  state: ItemDetailsState,
  action: PayloadAction<{ actionId: string }>,
): ItemDetailsState => {
  return {
    ...state,
  }
}

export const getActionSettingsRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ actionId: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const getActionSettingsSuccess = (
  state: ItemDetailsState,
  action: PayloadAction<{ action: ItemDetailsCurrentAction }>,
): ItemDetailsState => {
  return {
    ...state,
    currentAction: action.payload.action,
    loading: false,
    error: '',
  }
}

export const getActionSettingsFailed = (
  state: ItemDetailsState,
  action: PayloadAction<{ error: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const goToItem = (
  state: ItemDetailsState,
  action: PayloadAction<{ projectId: string; datastoreId: string; itemId: string }>,
): ItemDetailsState => {
  return {
    ...state,
  }
}

export const updateItemRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ actionId: string; changes: { [k: string]: any } }>,
): ItemDetailsState => {
  return {
    ...state,
  }
}

export const updateItemSuccess = (
  state: ItemDetailsState,
  action: PayloadAction<{ changes: Array<{ id: string; value: string }> }>,
): ItemDetailsState => {
  action.payload.changes.forEach(change => {
    state.entry.fields[change.id] = change.value
  })
  return state
}

export const newItemFailed = (state: ItemDetailsState, action: PayloadAction<{ error: string }>): ItemDetailsState => {
  return {
    ...state,
  }
}

export const newItemRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ actionId: string; fields: { [k: string]: any } }>,
): ItemDetailsState => {
  return {
    ...state,
  }
}

export const newItemSuccess = (
  state: ItemDetailsState,
  action: PayloadAction<{ changes: Array<{ id: string; value: string }> }>,
): ItemDetailsState => {
  return {
    ...state,
  }
}

export const updateItemFailed = (
  state: ItemDetailsState,
  action: PayloadAction<{ error: string }>,
): ItemDetailsState => {
  return {
    ...state,
  }
}

export const postActionItemFailed = (
  state: ItemDetailsState,
  action: PayloadAction<{ error: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const setRevisionNumber = (
  state: ItemDetailsState,
  action: PayloadAction<{ revisionNumber: number }>,
): ItemDetailsState => {
  return {
    ...state,
    entry: {
      ...state.entry,
      rev_no: action.payload.revisionNumber,
    },
  }
}

export const newItemModeRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ keepFieldsValue: boolean }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const newItemModeSuccess = (
  state: ItemDetailsState,
  action: PayloadAction<{ fields: { [k: string]: any }; newItemId: string }>,
): ItemDetailsState => {
  return {
    ...state,
    entry: {
      ...state.entry,
      i_id: action.payload.newItemId,
      fields: action.payload.fields,
    },
    loading: false,
    error: '',
  }
}

export const newItemModeFailed = (
  state: ItemDetailsState,
  action: PayloadAction<{ error: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const getPDF = (
  state: ItemDetailsState,
  action: PayloadAction<{ pdfId: string; fileName: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const getPDFSuccess = (state: ItemDetailsState): ItemDetailsState => {
  return {
    ...state,
    loading: false,
  }
}

export const reset = (state: ItemDetailsState): ItemDetailsState => {
  return {
    ...initialState,
  }
}

export const updateCommentHistoryRequest = (
  state: ItemDetailsState,
  action: PayloadAction<ApiModels.updateCommentHistoryPayload>,
): ItemDetailsState => {
  return {
    ...state,
    historiesLoading: true,
  }
}

export const updateCommentHistorySuccess = (state: ItemDetailsState): ItemDetailsState => {
  return {
    ...state,
    historiesLoading: false,
    error: '',
  }
}

export const updateCommentHistoryFailed = (
  state: ItemDetailsState,
  action: PayloadAction<{ error: string }>,
): ItemDetailsState => {
  return {
    ...state,
    historiesLoading: false,
    error: action.payload.error,
  }
}

export const getHistoriesRequest = (state: ItemDetailsState): ItemDetailsState => {
  return {
    ...state,
    historiesLoading: true,
  }
}

export const getHistoriesSuccess = (
  state: ItemDetailsState,
  action: PayloadAction<{ histories: Array<History> | null; unread: number }>,
): ItemDetailsState => {
  return {
    ...state,
    histories: action.payload.histories,
    unread: action.payload.unread,
    historiesLoading: false,
    error: '',
  }
}

export const getHistoriesFailed = (
  state: ItemDetailsState,
  action: PayloadAction<{ error: string }>,
): ItemDetailsState => {
  return {
    ...state,
    historiesLoading: false,
    error: action.payload.error,
  }
}

export const createItemRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ dId: string; item: { [key: string]: any }; navigatePath: string | undefined }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const updateItem_v0_Request = (
  state: ItemDetailsState,
  action: PayloadAction<{ dId: string; item: { [key: string]: any } }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const deleteItemRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ navigatePath: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const deleteItemByConditionRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ did: string; condition: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const deleteItemSuccess = (state: ItemDetailsState): ItemDetailsState => {
  return {
    ...state,
    error: '',
    loading: false,
  }
}

export const deleteItemFailed = (
  state: ItemDetailsState,
  action: PayloadAction<{ error: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const orderItemDeleteRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{
    dId: string
    itemId: string
    actionId: string
    param: { rev_no?: number; is_force_update?: boolean; comment: string }
    navigatePath: string
  }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const getLinkedItemRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ iId: string; linkedDatastoreName: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const getLinkedItemSuccess = (
  state: ItemDetailsState,
  action: PayloadAction<{
    linkedDbItems: {
      d_id: string
      fields: { [k: string]: any } | null
      items: { [k: string]: any } | null
      columnsSettings: { [k: string]: any } | null
    } | null
  }>,
): ItemDetailsState => {
  return {
    ...state,
    linkedDbItems: action.payload.linkedDbItems,
    error: '',
    loading: false,
  }
}

export const getLinkedItemFailed = (
  state: ItemDetailsState,
  action: PayloadAction<{ error: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const getLinkedAllDbsItemsRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ iId: string; relations: ItemDetailsState['relations'] }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const getLinkedAllDbsItemsSuccess = (
  state: ItemDetailsState,
  action: PayloadAction<{ linkedAllDbsItems: LinkedAllDbsItems }>,
): ItemDetailsState => {
  return {
    ...state,
    linkedAllDbsItems: action.payload.linkedAllDbsItems,
    loading: false,
  }
}

export const uploadFileRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ field_id: string; a_id: string; params: any }>,
): any => {
  return {
    ...state,
  }
}

export const updateDetailsRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ a_id: string; updateParams: any }>,
): any => {
  return {
    ...state,
  }
}

export const deletePDFRequest = (
  state: ItemDetailsState,
  action: PayloadAction<{ field_id: string; file_id: string }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const deletePDFSuccess = (state: ItemDetailsState): ItemDetailsState => {
  return {
    ...state,
    loading: false,
  }
}

export const updateDeliverySlip = (
  state: ItemDetailsState,
  action: PayloadAction<{
    item_id: string
    field_id: string
    file_id: string
    changes: any
    actions: any
    last_action: string
    lastFlag: boolean
    type: string
  }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const sendMail = (state: ItemDetailsState, action: PayloadAction<{ actionId: string }>): ItemDetailsState => {
  return {
    ...state,
  }
}

export const orderForInventoryAction = (
  state: ItemDetailsState,
  action: PayloadAction<{
    itemId: string
    datastoreId: string
    actionId: string
    changes: any
    isOrder: boolean
  }>,
): ItemDetailsState => {
  return {
    ...state,
    loading: true,
  }
}

export const getError = (state: ItemDetailsState, action: PayloadAction<{ error: string }>): ItemDetailsState => {
  return {
    ...state,
    loading: false,
    isErrorDialog: true,
    error: action.payload.error,
  }
}

export const clearError = (state: ItemDetailsState): ItemDetailsState => {
  return {
    ...state,
    loading: false,
    isErrorDialog: false,
    error: '',
  }
}
