import { createSelector } from 'reselect'
import { RootState } from '../rootReducer'
import { WorkspaceState } from './state'
import { hexaLabel } from 'app/constants/hexalink'

const workspacesState = (state: RootState) => state.workspaces

export const getCurrentWorkspace = createSelector(workspacesState, (state: WorkspaceState) => {
  if (state.current_workspace_id) {
    return state.workspaces_list.find(ws => ws.w_id === state.current_workspace_id)
  } else {
    return null
  }
})

export const getWorkspaceIsLoading = createSelector(workspacesState, (state: WorkspaceState) => {
  return state.loading
})

export const getCurrentWorkspaceId = createSelector(workspacesState, (state: WorkspaceState) => {
  const currentWorkspace = state.workspaces_list.find(workspace => workspace.name === hexaLabel.WORKSPACE_NAME)
  if (currentWorkspace) {
    return currentWorkspace.w_id
  } else {
    return null
  }
})

export const getWorkspaceList = createSelector(workspacesState, (state: WorkspaceState) => {
  return state.workspaces_list || []
})
