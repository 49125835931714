import { createSelector } from 'reselect'
import { RootState } from '../rootReducer'
import { GroupState } from './state'
import { groupLabel } from 'app/constants/hexalink'

export const groupState = (state: RootState): GroupState => state.group

export const getGroupIsLoading = createSelector<RootState, GroupState, boolean>(
  groupState,
  (groupState: GroupState) => groupState.loading,
)

export const getParentGroupId = createSelector(groupState, (groupState: GroupState) => groupState.group.g_id)

export const getSupplierGroupId = createSelector(groupState, (groupState: GroupState) => {
  const supplier = groupState.children.find(group => group.name === groupLabel.SUPPLIER)

  // TODO: error handling
  return supplier ? supplier.g_id : ''
})

export const getSupplierGroupMembers = createSelector(
  groupState,
  (groupState: GroupState): GroupState['supplierGroupMembers'] => {
    return groupState.supplierGroupMembers
  },
)
