import { createSelector } from 'reselect'
import { RootState } from '../rootReducer'
import { UserState } from './state'
import { UserInfo } from './types'

const userState = (state: RootState): UserState => state.user

/**
 * @selector
 *
 * @description Return the token whith which the user logged
 *
 * @returns {string} the token whith which the user logged
 */
export const getUserToken = createSelector<RootState, UserState, string>(
  userState,
  (state: UserState) => state.auth_key,
)

/**
 * @selector
 *
 * @description Return the id of the user that is currently logged
 *
 * @returns {string} the id of the user currently logged
 */
export const getUserId = createSelector<RootState, UserState, string>(userState, (state: UserState) => state.u_id)

/**
 * @selector
 *
 * @description Return the username of the current user
 *
 * @returns {string} the username of the user currently logged
 */
export const getUsername = createSelector<RootState, UserState, string>(userState, (state: UserState) => state.username)

/**
 * @selector
 *
 * @description Return the current user loading status
 *
 * @returns {boolean} true = user loading, false = user not loading
 */
export const getUserLoading = createSelector<RootState, UserState, boolean>(
  userState,
  (state: UserState) => state.loading,
)

/**
 * @selector
 *
 * @description Return the current user loggin status
 *
 * @returns {boolean} true = user logged, false = user not logged
 */
export const isUserLogged = createSelector<RootState, UserState, boolean>(
  userState,
  (state: UserState) => state.auth_key !== '',
)

export const getUserRoles = createSelector<RootState, UserState, UserInfo['user_roles']>(
  userState,
  (state: UserState) => state.user_roles,
)

export const isLoading = createSelector<RootState, UserState, boolean>(userState, (state: UserState) => state.loading)

export const isSupplier = createSelector<RootState, UserState, boolean>(userState, (state: UserState) =>
  state.user_roles.some(userRole => userRole.role_name === 'サプライヤー'),
)

export const isShiroyama = createSelector<RootState, UserState, boolean>(userState, (state: UserState) =>
  state.user_roles.some(userRole => userRole.role_name === '白山工業様'),
)
