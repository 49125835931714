import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { Row, Col, Form, Button, Card, Typography } from 'antd'
import { FormComponentProps } from 'antd/lib/form/Form'
import { Redirect } from 'react-router'

import { hasErrors } from '../../utils/form/form'
import { routePath } from '../../../constants/router'
import useTextInput from '../../../hooks/useTextInput'
import EmailInput from '../../molecules/form/EmailInput'
import PasswordInput from '../../molecules/form/PasswordInput'

const { Text } = Typography
interface LoginProps extends FormComponentProps {
  userToken: string
  loginRequest: (email: string, password: string) => void
}

const Login = (props: LoginProps): JSX.Element => {
  const history = useHistory()
  const [email, setEmail] = useTextInput('')
  const [password, setPassword] = useTextInput('')
  const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = props.form

  const emailError = isFieldTouched('email') && getFieldError('email')
  const passwordError = isFieldTouched('password') && getFieldError('password')

  const handleSubmit = (): void => {
    props.form.validateFields(err => {
      if (!err) {
        props.loginRequest(email, password)
      }
    })
  }

  useEffect(() => {
    props.form.validateFields()
    // eslint-disable-next-line
  }, [])

  return (
    <div style={{ background: '#F3F3F3' }}>
      {props.userToken && <Redirect to="/" />}
      <Row type="flex" justify="center" align="middle" style={{ height: '100vh' }}>
        <Row type="flex" justify="center">
          <Col span={50}>
            <Card className="loginForm">
              <Row
                type="flex"
                justify="center"
                style={{
                  fontSize: '20px',
                  color: '#1589d6',
                  marginTop: '26px',
                  marginBottom: '25px',
                }}
              >
                ログイン
              </Row>
              <Form>
                <EmailInput getFieldDecorator={getFieldDecorator} emailError={emailError} setEmail={setEmail} />
                <PasswordInput
                  getFieldDecorator={getFieldDecorator}
                  passwordError={passwordError}
                  setPassword={setPassword}
                />
                <Form.Item>
                  <Row justify="center" type="flex" style={{ marginTop: '15px' }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      block
                      disabled={hasErrors(getFieldsError())}
                      onClick={(): void => handleSubmit()}
                    >
                      ログイン
                    </Button>
                  </Row>
                </Form.Item>
              </Form>
              <Row type="flex" justify="center" style={{ fontSize: '11px' }}>
                <Button type="primary" onClick={(): void => history.push(routePath.RESET_PASSWORD)}>
                  <Text underline style={{ color: 'white' }}>
                    パスワードを忘れた方はこちら
                  </Text>
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
      </Row>
    </div>
  )
}

const LoginPage = Form.create<LoginProps>()(Login)
export default LoginPage
