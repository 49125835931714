import React from 'react'
import { useHistory } from 'react-router'
import { Row, Col, Table } from 'antd'
import { Columns, SearchCondition } from 'app/services/store/items/types'
import { routePath } from 'app/constants/router'
import { customOrderValue } from 'app/constants/order'
import {
  createFilterableAndSortableColumns,
  getCommonTableScrollSize,
  getFilterDisplayManegeNoItems,
  changeNumberToString,
} from 'app/utils/components/common'
import { PagenationValue } from 'app/components/pages/favorite/types'

interface FavoritePageProps {
  itemsLoading: boolean
  items: { [k: string]: any }[]
  columns: Columns
  sendChangePageHandle: Function
  pagination: PagenationValue
}

const FavoritePage = ({
  itemsLoading,
  items,
  columns,
  sendChangePageHandle,
  pagination,
}: FavoritePageProps): JSX.Element => {
  const history = useHistory()
  const columnsWithSearchAndSort = createFilterableAndSortableColumns(columns)
  const filtercolumns = columnsWithSearchAndSort.filter(column => column.title != '特注部品')
  const filterManegeNoItems = changeNumberToString(
    getFilterDisplayManegeNoItems(items, { look: '特注部品', target: '管理番号' }, customOrderValue),
  )

  const defaultPageSize = pagination.defaultSize
  const totalCount = pagination.totalCount
  const tableScrollSetting = getCommonTableScrollSize()

  return (
    <div className="tableArea">
      <Row>
        <Col>
          <Table
            loading={itemsLoading}
            dataSource={filterManegeNoItems}
            pagination={{
              total: totalCount,
              defaultPageSize: defaultPageSize,
              showTotal: () => `Total ${totalCount} items`,
            }}
            columns={filtercolumns}
            rowClassName={(record): string => {
              if (record.status === '起票・発注') {
                return 'draft'
              } else if (record.status === 'サプライヤー回答待ち') {
                return 'waiting'
              } else if (record.status === '要確認') {
                return 'checking'
              } else {
                return 'arrangement'
              }
            }}
            onRow={record => {
              return {
                onClick: (): void => {
                  history.push({
                    pathname: routePath.ORDER_DETAIL,
                    state: { iId: record.i_id },
                  })
                },
              }
            }}
            onChange={(page, filters, sorter) => {
              const currentPage = page.current
              const sortFieldId = sorter && sorter.column ? sorter.column['id'] : ''
              const sortOrder = sorter && sorter.order ? (sorter.order === 'ascend' ? 'asc' : 'desc') : ''
              const conditions = new Array<SearchCondition>()
              Object.entries(filters).forEach(([key, value]) => {
                if (Array.isArray(value) && value.length > 0) {
                  const column = columns.find(colum => colum.dataIndex === key)
                  if (column != null) {
                    conditions.push({
                      id: column.id,
                      search_value: [value[0]],
                    })
                  }
                }
              })
              sendChangePageHandle(conditions, currentPage, sortFieldId, sortOrder)
            }}
            scroll={{ y: tableScrollSetting['y'], x: tableScrollSetting['x'] }}
            tableLayout="initial"
          />
        </Col>
      </Row>
    </div>
  )
}

export default FavoritePage
