import { ItemsState, initialState } from './state'
import { PayloadAction } from '@reduxjs/toolkit'
import { Items, ItemEntry, SearchCondition } from './types'
import { RootState } from '../rootReducer'
import * as ApiModels from './epic.types'

export const setCurrentItemId = (state: ItemsState, action: PayloadAction<{ itemId: string }>): ItemsState => {
  return {
    ...state,
    current_item_id: action.payload.itemId,
  }
}

export const setSearchParams = (
  state: ItemsState,
  action: PayloadAction<{ page: number; fieldId: string; order: string; conditions: Array<SearchCondition> }>,
): ItemsState => {
  return {
    ...state,
    search: {
      page: action.payload.page,
      sortFieldId: action.payload.fieldId,
      sortOrder: action.payload.order,
      conditions: action.payload.conditions,
      include_fields_data: true,
    },
    loading: true,
  }
}

export const getItemsListRequest = (
  state: ItemsState,
  action: PayloadAction<{ projectId: string; datastoreId: string }>,
): ItemsState => {
  return {
    ...state,
    loading: true,
  }
}

export const getItemsListSuccess = (state: ItemsState, action: PayloadAction<Items>): ItemsState => {
  return {
    ...state,
    ...action.payload,
    loading: false,
    error: '',
  }
}

export const getItemsListFailed = (state: ItemsState, action: PayloadAction<{ error: string }>): ItemsState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const deleteItemRequest = (
  state: ItemsState,
  action: PayloadAction<{ itemId: string; actionId: string }>,
): ItemsState => {
  return {
    ...state,
  }
}

export const deleteItemSuccess = (state: ItemsState, action: PayloadAction<{ itemId: string }>): ItemsState => {
  const index = state.entries.findIndex(entry => entry.i_id === action.payload.itemId)
  if (index !== -1) {
    state.entries.splice(index, 1)
  }
  return state
}

export const deleteItemFailed = (state: ItemsState, action: PayloadAction<{ error: string }>): ItemsState => {
  return {
    ...state,
  }
}

export const addEntry = (state: ItemsState, action: PayloadAction<{ entry: ItemEntry }>): ItemsState => {
  state.entries.unshift(action.payload.entry)
  return state
}

export const updateEntry = (
  state: ItemsState,
  action: PayloadAction<{ fieldId: string; changes: Partial<ItemEntry> }>,
): ItemsState => {
  const entry = state.entries.find(entry => entry.i_id === action.payload.fieldId)
  const entryIndex = state.entries.findIndex(entry => entry.i_id === action.payload.fieldId)

  if (entry) {
    const updatedEntry = {
      ...entry,
      ...action.payload.changes,
    }
    state.entries[entryIndex] = updatedEntry
  }
  return state
}

export const reset = (state: ItemsState): ItemsState => {
  return {
    ...initialState,
  }
}

export const postActionRequest = (
  state: ItemsState,
  action: PayloadAction<ApiModels.postActionPayload>,
): ItemsState => {
  return {
    ...state,
    loading: true,
  }
}

export const postExecuteActionRequest = (
  state: ItemsState,
  action: PayloadAction<ApiModels.postActionPayload>,
): ItemsState => {
  return {
    ...state,
    loading: true,
  }
}

export const postActionSuccess = (state: ItemsState): ItemsState => {
  return {
    ...state,
    loading: false,
    error: '',
  }
}

export const postActionFaild = (state: ItemsState, action: PayloadAction<{ error: string }>): ItemsState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const loadItem = (
  state: ItemsState,
  action: PayloadAction<{ itemId: string; projectId: string; datastoreId: string }>,
): ItemsState => {
  return {
    ...state,
    loading: true,
  }
}

export const ItemsSelector = (state: RootState): ItemsState => state.items
