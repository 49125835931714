import React from 'react'
import CSS from 'csstype'
import { Form, Input } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'

interface EmailInputProps {
  getFieldDecorator: WrappedFormUtils['getFieldDecorator']
  emailError: boolean | string[] | undefined
  setEmail: (e: React.ChangeEvent<HTMLInputElement>) => void
  inputStyle?: CSS.Properties
}

const EmailInput = ({ getFieldDecorator, emailError, setEmail, inputStyle }: EmailInputProps): JSX.Element => {
  return (
    <Form.Item validateStatus={emailError ? 'error' : ''} help={emailError || ''} label="メールアドレス">
      {getFieldDecorator('email', {
        rules: [
          {
            type: 'email',
            message: 'メールアドレスの形式に誤りがあります',
          },
          {
            required: true,
            message: 'メールアドレスの入力は必須です',
          },
        ],
      })(
        <Input
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setEmail(e)}
          type="email"
          placeholder="メールアドレス"
          size="large"
          style={inputStyle}
        />,
      )}
    </Form.Item>
  )
}

export default EmailInput
