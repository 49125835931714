import { Columns, displayColumnsDefine } from 'app/services/store/items/types'
import { ItemDetailsAction } from 'app/services/store/itemDetails/types'
import { status as workflowStatus, statusActionName } from 'app/constants/deliverySlip'

const labelCellWidth = (): displayColumnsDefine => {
  return [
    { title: 'ステータス', width: 80, columnOrder: 1 },
    { title: '納品書番号', width: 100, columnOrder: 2 },
    { title: '在庫ID', width: 80, columnOrder: 3 },
    { title: '在庫名', width: 170, columnOrder: 4 },
    { title: '発注個数', width: 80, columnOrder: 5 },
    { title: 'サプライヤー', width: 170, columnOrder: 6 },
    { title: '送付先', width: 170, columnOrder: 7 },
    { title: '出荷日', width: 100, columnOrder: 8 },
    { title: '納品日', width: 100, columnOrder: 9 },
  ]
}

export const filterListColumns = (columns: Columns): Columns => {
  const labelsWidth = labelCellWidth()
  const allowTitles = labelsWidth.map(i => i.title)
  const resultColums = columns
    .filter(item => allowTitles.includes(item.title))
    .map(item => {
      const data = labelsWidth.find(record => record.title == item.title)
      if (data) {
        item.width = data.width
        item.columnOrder = data.columnOrder
      }
      if (item.dataIndex !== item.key) {
        item.dataIndex = item.key
      }
      return item
    })
    .sort((a, b): number => {
      if (a.columnOrder && b.columnOrder && a.columnOrder > b.columnOrder) {
        return 1
      } else {
        return -1
      }
    })
  return resultColums
}

type Status = typeof workflowStatus.CREATED | typeof workflowStatus.NON_CREATED
export const getActionIdAndDid = (
  actions: ItemDetailsAction[],
  status: Status,
): { a_id: string; d_id: string } | undefined => {
  const currentAction = actions ? actions.find(action => action.name === statusActionName[status]) : ''
  if (currentAction) {
    return { a_id: currentAction.a_id, d_id: currentAction.d_id }
  }
  return
}

export const addComma = (items: Array<{ [k: string]: any }>, name: string): Array<{ [k: string]: any }> => {
  if (!Array.isArray(items) || !items.length) return []
  return items.map(item => {
    if (item[name] && typeof item[name] === 'string' && item[name].length > 3) {
      const target = item[name].includes(',') ? item[name] : Number(item[name]).toLocaleString()
      item[name] = target
    }
    return item
  })
}
