import moment from 'moment'
import { Columns, ColumnsWithOrder, SearchCondition } from 'app/services/store/items/types'
import { ActionFieldSettings } from 'app/services/store/itemDetails/types'
import { DataReportSearchCondition } from 'app/services/store/dataReport/state'
import { GroupMembers } from 'app/services/store/group/types'
import FilterDropdown from 'app/components/molecules/FilterDropdown'
import FilterIcon from 'app/components/atoms/FilterIcon'

type Items = {
  [k: string]: any
}[]

type SupplierOption = {
  u_id: string
  username: string
}[]

// 事業年度
export const fiscal = {
  start: '07/01',
  end: '06/30',
}

export const getFiscalMonth = (term: string): number => {
  return fiscal[term] !== undefined ? Number(fiscal[term].substring(1, 2)) : 0
}

export const createDateFormattedItems = (items: Items, dateTypeKeyList: string[]): Items | [] => {
  if (!Array.isArray(items) || !items.length) return []
  return items.map((item: { [key: string]: any }) => {
    const obj = {}

    Object.keys(item).map((key: string) => {
      if (dateTypeKeyList.some(item => item === key)) {
        return item[key] ? (obj[key] = moment(item[key]).format('YYYY-MM-DD')) : ''
      }
      return (obj[key] = item[key])
    })
    return obj
  })
}

export const getItemIdFromKey = <S, T>(columns, key): string | undefined => {
  const selectedColmun = columns.filter(column => column.key === key)
  if (selectedColmun[0]) {
    return selectedColmun[0].id
  }
}

export const hasFieldError = (fieldsError: Function): boolean => {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

export const isItemUpdatable = (actionFieldSettings: ActionFieldSettings, columns: Columns): Function => <S>(
  key,
): boolean | undefined => {
  const itemId = getItemIdFromKey<Columns, keyof S>(columns, key)
  return itemId && actionFieldSettings && actionFieldSettings[itemId] ? actionFieldSettings[itemId].update : undefined
}

export const isItemRequired = (actionFieldSettings: ActionFieldSettings, columns: Columns): Function => <S>(
  key,
): boolean | undefined => {
  const itemId = getItemIdFromKey<Columns, keyof S>(columns, key)
  return itemId && actionFieldSettings && actionFieldSettings[itemId]
    ? actionFieldSettings[itemId].mandatory
    : undefined
}

export const getUserIdFromUserName = (userName: string, supplierOptions: SupplierOption): string | undefined => {
  const supplier = supplierOptions.find(supplier => supplier.username === userName)
  return supplier ? supplier.u_id : undefined
}

export const getUserNameFromUserId = (userId: string, supplierOptions: SupplierOption): string | undefined => {
  const supplier = supplierOptions.find(supplier => supplier.u_id === userId)
  return supplier ? supplier.username : undefined
}

export const getSupplierOptions = (supplierMembers: GroupMembers): SupplierOption =>
  supplierMembers.members.map(member => {
    return {
      u_id: member.u_id,
      username: member.username,
    }
  })

const handleSearch = (confirm: () => void) => {
  confirm()
}

const handleReset = (clearFilters: () => void): void => {
  clearFilters()
}

const addFilterAndSortFunctionToColumns = (
  dataIndex: string,
  handleSearch: (confirm: () => void) => void,
  handleReset: (clearFilters: () => void) => void,
) => ({
  filterDropdown: ({ setSelectedKeys, confirm, clearFilters }): JSX.Element =>
    FilterDropdown({ setSelectedKeys, confirm, clearFilters, handleSearch, handleReset }),
  filterIcon: (filtered: boolean): JSX.Element => FilterIcon(filtered),
  onFilter: (value: string, record: Columns): Columns[] | undefined =>
    typeof record[dataIndex] === 'string'
      ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      : undefined,
  sorter: (a, b) => {
    if (!a[dataIndex] && !b[dataIndex]) {
      return 0
    } else if (!a[dataIndex]) {
      return 1
    } else if (!b[dataIndex]) {
      return -1
    } else if (!isNaN(Number(a[dataIndex])) && !isNaN(Number(b[dataIndex]))) {
      return a[dataIndex] - b[dataIndex]
    } else if (a[dataIndex] > b[dataIndex]) {
      return -1
    } else if (a[dataIndex] < b[dataIndex]) {
      return 1
    } else {
      return 0
    }
  },
  sortDirection: ['descend', 'ascend'],
})

export const createFilterableAndSortableColumns = (columns: Columns): Record<string, any>[] => {
  const columnsWithSearchAndSort = new Array<Record<string, any>>()
  columns.forEach(column => {
    const pushColumn =
      column.sortAndSearch !== undefined && column.sortAndSearch === 'none'
        ? column
        : {
            ...column,
            ...addFilterAndSortFunctionToColumns(column.dataIndex, handleSearch, handleReset),
          }
    columnsWithSearchAndSort.push(pushColumn)
  })
  return columnsWithSearchAndSort
}

export const getFilterDisplayManegeNoItems = (
  items: { [k: string]: any }[],
  reference: { [k: string]: string },
  define: { [k: string]: number },
): Items | [] => {
  if (!Array.isArray(items) || !items.length) return []
  return items.map(item => {
    if (item[reference.look] && item[reference.look] != define.YES) {
      item[reference.target] = ''
    }
    return item
  })
}

export const getCommonTableScrollSize = (): object => {
  return { y: '55vh', x: 'max-content' }
}

export const nl2br = (str: string): string => {
  return typeof str == 'string' ? str.replace(/\n/g, '<br>') : str
}

export const strCount = str => {
  let totalCount = 0
  const fullWidth = 2
  const halfWidth = 1
  for (let i = 0; i < str.length; i++) {
    totalCount += str[i].match(/[ -~]/) ? halfWidth : fullWidth
  }
  return totalCount
}

export const replaceMessage = (base: string, str: string, num = ''): string => {
  return base.replace('%s', str).replace('%d', num)
}

export const isRange = (x: string | number, min: number, max: number): boolean => {
  if (x == null) return false
  if (typeof x === 'string') x = Number(x)
  return (x - min) * (x - max) <= 0
}

export const ambiguousSearch = (
  target: { [k: string]: any }[],
  targetProp: string,
  resultProp: string,
  search: string,
) => {
  if (target !== undefined && target.length > 0) {
    return target
      .map(item => {
        if ((item[targetProp] as string).includes(search)) {
          return item[resultProp]
        }
        return null
      })
      .filter(v => v)
  }
  return []
}

export const execSupplierFilter = (columns: Columns, filter: Array<{ [k: string]: string }>): ColumnsWithOrder => {
  if (columns.length === 0) return []
  return columns.map(column => {
    const findFilter = filter.find(rec => rec.title === column.title)
    if (findFilter !== undefined) column = { ...column, ...findFilter }
    return column
  }) as ColumnsWithOrder
}

export const getField = (target: string, fields: { [k: string]: any }[]): { [k: string]: any } | null => {
  const findField = fields.find(field => field.name === target)
  return findField === undefined ? null : findField
}

export const getFieldIdByColumn = (target: string, columns: { [k: string]: any }[]) => {
  const findRecord = columns.find(column => column.key === target)
  return findRecord === undefined ? '' : findRecord.id
}

export const getSelectField = (selectField, key: string) => {
  return selectField.find(field => field.displayID === key)
}

export const mergeHeaderSearchCondition = (
  condittion: Array<SearchCondition>,
  targetId: string | undefined,
  searchList,
  targetStr: string,
  resultStr: string,
) => {
  if (targetId === undefined) return condittion
  const macthIndex = condittion.findIndex(v => v.id === targetId)
  if (macthIndex > -1) {
    const find = ambiguousSearch(searchList, targetStr, resultStr, condittion[macthIndex].search_value[0])
    if (find.length > 0) {
      condittion[macthIndex].search_value.splice(0, 1, ...find)
    }
  }
  return condittion
}

// 検索コンディションに指定フィールド検索が存在するか
export const isTargetByConditions = (condittion: Array<SearchCondition>, targetId?: string) => {
  const macthIndex = condittion.findIndex(v => v.id === targetId)
  return macthIndex > -1
}

export const changeNumberToString = (dataList: Items | []) => {
  if (dataList.length === 0) return []
  for (const i in dataList) {
    Object.entries(dataList[i]).forEach(value => {
      const hash = value[0]
      const val = value[1]
      dataList[i][hash] = typeof val === 'number' ? String(val) : val
    })
  }
  return dataList
}

export const changeConditionRule = (conditions: any, fields: DataReportSearchCondition[] | Columns) => {
  fields.forEach(field => {
    if (field.dataType && field.dataType === 'number') {
      const id = field.display_id !== undefined ? field.display_id : field.id
      const macthIndex = conditions.findIndex(v => v.id === id)
      if (macthIndex > -1) {
        conditions[macthIndex].search_value[1] = conditions[macthIndex].search_value[0]
      }
    }
  })
  return conditions
}
