import React from 'react'
import {Modal} from 'antd';

import { connect } from 'react-redux';
import { RootState } from 'app/services/store/rootReducer';

interface SessionAlertProps {
    main: RootState
}

class SessionAlert extends React.Component<SessionAlertProps> {
    onOkHandler = (display: boolean) => {
    }

    componentDidMount() {
    }

    componentDidUpdate(p: any) {
    }

    render() {
        return(
            <div>
                <Modal 
                    onOk={() => this.onOkHandler(true)}
                    >
                    {

                    }
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    main: state
});

const mapDispatchToProps = {};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SessionAlert);

// export default SessionAlert;