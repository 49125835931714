import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { Row, Col, Form, Button, Card, Typography, Spin } from 'antd'
import { FormComponentProps } from 'antd/lib/form/Form'

import { hasErrors } from '../../utils/form/form'
import useTextInput from '../../../hooks/useTextInput'
import { routePath } from '../../../constants/router'
import EmailInput from '../../molecules/form/EmailInput'

interface ResetPasswordProps extends FormComponentProps {
  forgotPasswordRequest: (email: string) => void
  isLoading: boolean
}

const { Text } = Typography

const ResetPassword = (props: ResetPasswordProps): JSX.Element => {
  const history = useHistory()
  const [email, setEmail] = useTextInput('')
  const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = props.form

  const emailError = isFieldTouched('email') && getFieldError('email')

  const handleSubmit = (): void => {
    props.form.validateFields(err => {
      if (!err) {
        props.forgotPasswordRequest(email)
      }
    })
  }

  useEffect(() => {
    props.form.validateFields()
    // eslint-disable-next-line
  }, [])

  return (
    <Spin size="large" spinning={props.isLoading}>
      <div style={{ background: '#F3F3F3' }}>
        <Row type="flex" justify="center" align="middle" style={{ height: '100vh' }}>
          <Row type="flex" justify="center">
            <Col span={50}>
              <Card className="loginForm">
                <Row
                  type="flex"
                  justify="center"
                  style={{
                    fontSize: '20px',
                    color: '#1589d6',
                    marginTop: '26px',
                    marginBottom: '25px',
                  }}
                >
                  パスワードのリセット
                </Row>
                <Row type="flex" justify="center" style={{ margin: '20px 0', textAlign: 'center' }}>
                  <Text>リセットするアカウントのメールアドレスを入力してください</Text>
                </Row>
                <Form>
                  <EmailInput
                    getFieldDecorator={getFieldDecorator}
                    emailError={emailError}
                    setEmail={setEmail}
                    inputStyle={{ marginTop: '0px' }}
                  />
                  <Form.Item>
                    <Row justify="center" type="flex" style={{ marginTop: '40px' }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        block
                        disabled={hasErrors(getFieldsError())}
                        onClick={handleSubmit}
                      >
                        パスワードのリセット
                      </Button>
                    </Row>
                  </Form.Item>
                </Form>
                <Row type="flex" justify="center" style={{ fontSize: '11px' }} />
                <Button
                  type="primary"
                  size="large"
                  onClick={(): void => history.push(routePath.LOGIN)}
                  style={{ width: '294px' }}
                >
                  戻る
                </Button>
              </Card>
            </Col>
          </Row>
        </Row>
      </div>
    </Spin>
  )
}

const ResetPasswordPage = Form.create<ResetPasswordProps>()(ResetPassword)
export default ResetPasswordPage
