import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Row, Col, Table, Button, Form } from 'antd'

import { routePath } from 'app/constants/router'
import { Columns, SearchCondition } from 'app/services/store/items/types'
import { statusLabel, dateTypeKeyListOfOrder, customOrderValue } from 'app/constants/order'
import { filterListColumns, sortOrderColumns } from 'app/utils/components/order'
import {
  createDateFormattedItems,
  createFilterableAndSortableColumns,
  getFilterDisplayManegeNoItems,
  getCommonTableScrollSize,
  execSupplierFilter,
} from 'app/utils/components/common'

interface OrderProps {
  updateSearchParams: (page: number, fieldId: string, order: string, conditions: Array<SearchCondition>) => void
  itemsLoading: boolean
  items: { [k: string]: any }[]
  totalItmes: number
  columns: Columns
  isSupplier: boolean
}

const OrderPage = ({
  updateSearchParams,
  itemsLoading,
  items,
  totalItmes,
  columns,
  isSupplier,
}: OrderProps): JSX.Element => {
  const history = useHistory()

  let sortColumns = sortOrderColumns(filterListColumns(columns))
  if (isSupplier) sortColumns = execSupplierFilter(sortColumns, [{ title: 'サプライヤー', sortAndSearch: 'none' }])

  const columnsWithSearchAndSort = createFilterableAndSortableColumns(sortColumns)
  const formattedItems = createDateFormattedItems(items, dateTypeKeyListOfOrder)
  const filterManegeNoItems = getFilterDisplayManegeNoItems(
    formattedItems,
    { look: '特注部品', target: '管理番号' },
    customOrderValue,
  )

  // 発注一覧は「未読コメント数」を表示するため
  columnsWithSearchAndSort.push({ title: '未読コメント件数　', key: 'unread', dataIndex: 'unread', width: 110 })

  // チェックボックスの状態とチェックボックスで選択された情報
  const [buttonCheckbox, setButtonCheckbox] = useState(true)
  const [selectedCheckBoxDeliveryItemRowKeys, setSelectedCheckBoxDeliveryItemRowKeys] = useState([])
  const [selectedCheckBoxDeliveryItemRows, setSelectedCheckBoxDeliveryItemRows] = useState([])

  const onDeliverySlipCreate = (): void => {
    history.push({
      pathname: routePath.DELIVERY_SLIP_CREATE,
      state: {
        selectedCheckBoxDeliveryItemRowKeys: selectedCheckBoxDeliveryItemRowKeys,
        selectedCheckBoxDeliveryItemRows: selectedCheckBoxDeliveryItemRows,
      },
    })
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRowKeys.length != 0) {
        setButtonCheckbox(false)
        setSelectedCheckBoxDeliveryItemRowKeys(selectedRowKeys)
        setSelectedCheckBoxDeliveryItemRows(selectedRows)
      } else {
        setButtonCheckbox(true)
        setSelectedCheckBoxDeliveryItemRowKeys([])
        setSelectedCheckBoxDeliveryItemRows([])
      }
    },
    getCheckboxProps: record => ({
      disabled: record.ステータス === statusLabel.ARRIVED,
    }),
  }

  const showTotal = () => {
    return `Total ${totalItmes} items`
  }

  const tableScrollSetting = getCommonTableScrollSize()

  return (
    <div>
      <Row>
        <Col span={10}>
          <h2>発注一覧</h2>
        </Col>
        <Col>
          {isSupplier ? (
            <Form.Item>
              <Button
                type="primary"
                className="btn-white"
                style={{ float: 'right' }}
                disabled={buttonCheckbox}
                onClick={onDeliverySlipCreate}
              >
                納品書作成
              </Button>
            </Form.Item>
          ) : (
            ''
          )}
        </Col>
      </Row>
      <Row>
        <div className="tableArea">
          <Row>
            <Col>
              {!isSupplier ? (
                <Table
                  loading={itemsLoading}
                  dataSource={filterManegeNoItems}
                  pagination={{
                    total: totalItmes,
                    showTotal: showTotal,
                    defaultPageSize: 100,
                  }}
                  columns={columnsWithSearchAndSort}
                  rowClassName={(record): string => {
                    if (record.unread > 0) {
                      // rowColor: #e25f79
                      return 'unread'
                    } else if (record.ステータス === statusLabel.DRAFT_ORDER) {
                      // rowColor: bisque
                      return 'draft'
                    } else if (record.ステータス === statusLabel.WAITING_FOR_SUPPLYER) {
                      // rowColor: cornSilk
                      return 'waiting'
                    } else if (record.ステータス === statusLabel.NEED_CONFIRM) {
                      // rowColor: honeyDew
                      return 'checking'
                    } else {
                      // rowColor: azure
                      return 'arrangement'
                    }
                  }}
                  onRow={record => {
                    return {
                      onClick: (): void => {
                        history.push({
                          pathname: routePath.ORDER_DETAIL,
                          state: { iId: record.i_id },
                        })
                      },
                    }
                  }}
                  onChange={(pagination, filters, sorter, extra) => {
                    const page = pagination.current ? pagination.current : 1
                    const sortFieldId = sorter && sorter.column ? sorter.column['id'] : ''
                    const sortOrder = sorter && sorter.order ? (sorter.order === 'ascend' ? 'asc' : 'desc') : ''
                    const conditions = new Array<SearchCondition>()
                    Object.entries(filters).forEach(([key, value]) => {
                      if (Array.isArray(value) && value.length > 0) {
                        const column = columns.find(colum => colum.dataIndex === key)
                        if (column != null) {
                          conditions.push({
                            id: column.id,
                            search_value: [value[0]],
                          })
                        }
                      }
                    })
                    updateSearchParams(page, sortFieldId, sortOrder, conditions)
                  }}
                  scroll={{ y: tableScrollSetting['y'], x: tableScrollSetting['x'] }}
                  tableLayout="initial"
                />
              ) : (
                <Table
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                  }}
                  loading={itemsLoading}
                  dataSource={filterManegeNoItems}
                  pagination={{
                    total: totalItmes,
                    showTotal: showTotal,
                    defaultPageSize: 100,
                  }}
                  className="order-list-supplier"
                  columns={columnsWithSearchAndSort}
                  rowClassName={(record): string => {
                    if (record.unread > 0) {
                      // rowColor: #e25f79
                      return 'unread'
                    } else if (record.ステータス === statusLabel.DRAFT_ORDER) {
                      // rowColor: bisque
                      return 'draft'
                    } else if (record.ステータス === statusLabel.WAITING_FOR_SUPPLYER) {
                      // rowColor: cornSilk
                      return 'waiting'
                    } else if (record.ステータス === statusLabel.NEED_CONFIRM) {
                      // rowColor: honeyDew
                      return 'checking'
                    } else {
                      // rowColor: azure
                      return 'arrangement'
                    }
                  }}
                  onRow={record => {
                    return {
                      onClick: (): void => {
                        history.push({
                          pathname: routePath.ORDER_DETAIL,
                          state: { iId: record.i_id },
                        })
                      },
                    }
                  }}
                  onChange={(pagination, filters, sorter, extra) => {
                    const page = pagination.current ? pagination.current : 1
                    const sortFieldId = sorter && sorter.column ? sorter.column['id'] : ''
                    const sortOrder = sorter && sorter.order ? (sorter.order === 'ascend' ? 'asc' : 'desc') : ''
                    const conditions = new Array<SearchCondition>()
                    Object.entries(filters).forEach(([key, value]) => {
                      if (Array.isArray(value) && value.length > 0) {
                        const column = columns.find(colum => colum.dataIndex === key)
                        if (column != null) {
                          conditions.push({
                            id: column.id,
                            search_value: [value[0]],
                          })
                        }
                      }
                    })
                    updateSearchParams(page, sortFieldId, sortOrder, conditions)
                  }}
                  scroll={{ y: tableScrollSetting['y'], x: tableScrollSetting['x'] }}
                  tableLayout="initial"
                />
              )}
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  )
}

export default OrderPage
