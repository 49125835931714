import { createSlice } from '@reduxjs/toolkit'
import * as reducers from './reducers'
import { initialState } from './state'

/**
 * @namespace
 * @property {Function} getAutoNumberRequest      - Request to get the item details
 * @property {Function} getAutoNumberSuccess      - Above on success (Do not call outside of epic)
 * @property {Function} getAutoNumberFailed       - Above on success (Do not call outside of epic)
 * @property {Function} getDatastoreFieldsRequest - Request to get the item details
 * @property {Function} getDatastoreFieldsSuccess - Above on success (Do not call outside of epic)
 * @property {Function} getDatastoreFieldsFailed  - Above on success (Do not call outside of epic)
 * @property {Function} getItemDetailsRequest     - Request to get the item details
 * @property {Function} getItemDetailsSuccess     - Above on success (Do not call outside of epic)
 * @property {Function} getItemDetailsFailed      - Above on failure (Do not call outside of epic)
 * @property {Function} getActionSettingsRequest  - Request to get the action settings
 * @property {Function} getActionSettingsSuccess  - Above on success (Do not call outside of epic)
 * @property {Function} getActionSettingsFailed   - Above on failure (Do not call outside of epic)
 * @property {Function} updateItemRequest         - Request to update the item
 * @property {Function} updateItemSuccess         - Above on success (Do not call outside of epic)
 * @property {Function} updateItemFailed          - Above on failure (Do not call outside of epic)
 * @property {Function} newItemRequest            - Request to execute the action to create a new item (used to save a new item)
 * @property {Function} newItemSuccess            - Above on success (Do not call outside of epic)
 * @property {Function} newItemFailed             - Above on failure (Do not call outside of epic)
 * @property {Function} newItemModeRequest        - Request to enter in the mode New Item (used to get temporary item id)
 * @property {Function} newItemModeSuccess        - Above on success (Do not call outside of epic)
 * @property {Function} newItemModeFailed         - Above on failure (Do not call outside of epic)
 * @property {Function} goToItem                  - Go to another item in the workspace but in another project or datastore
 * @property {Function} selectAction              - Select an action
 * @property {Function} setRevisionNumber         - Set the item revision number
 * @property {Function} setMode                   - Set the item detail mode (open, close ...)
 * @property {Function} reset                     - Reset the store to initial state
 */

export const itemDetailsSlice = createSlice({
  name: 'itemDetails',
  initialState: initialState,
  reducers: {
    getAutoNumberRequest: reducers.getAutoNumberRequest,
    getAutoNumberSuccess: reducers.getAutoNumberSuccess,
    getAutoNumberFailed: reducers.getAutoNumberFailed,
    getDatastoreFieldsRequest: reducers.getDatastoreFieldsRequest,
    getDatastoreFieldsSuccess: reducers.getDatastoreFieldsSuccess,
    getDatastoreFieldsFailed: reducers.getDatastoreFieldsFailed,
    getItemDetailsRequest: reducers.getItemDetailsRequest,
    getItemDetailsSuccess: reducers.getItemDetailsSuccess,
    getItemDetailsFailed: reducers.getItemDetailsFailed,
    selectAction: reducers.selectAction,
    getActionSettingsRequest: reducers.getActionSettingsRequest,
    getActionSettingsSuccess: reducers.getActionSettingsSuccess,
    getActionSettingsFailed: reducers.getActionSettingsFailed,
    getPDF: reducers.getPDF,
    getPDFSucess: reducers.getPDFSuccess,
    getPDFFailed: reducers.getItemDetailsFailed,
    updateItemRequest: reducers.updateItemRequest,
    updateItemSuccess: reducers.updateItemSuccess,
    updateItemFailed: reducers.updateItemFailed,
    postActionItemFailed: reducers.postActionItemFailed,
    newItemRequest: reducers.newItemRequest,
    newItemSuccess: reducers.newItemSuccess,
    newItemFailed: reducers.newItemFailed,
    setRevisionNumber: reducers.setRevisionNumber,
    newItemModeRequest: reducers.newItemModeRequest,
    newItemModeSuccess: reducers.newItemModeSuccess,
    newItemModeFailed: reducers.newItemModeFailed,
    goToItem: reducers.goToItem,
    setMode: reducers.setMode,
    updateCommentHistoryRequest: reducers.updateCommentHistoryRequest,
    updateCommentHistorySuccess: reducers.updateCommentHistorySuccess,
    updateCommentHistoryFailed: reducers.updateCommentHistoryFailed,
    getHistoriesRequest: reducers.getHistoriesRequest,
    getHistoriesSuccess: reducers.getHistoriesSuccess,
    getHistoriesFailed: reducers.getHistoriesFailed,
    createItemRequest: reducers.createItemRequest,
    createItemSuccess: reducers.deleteItemSuccess,
    createItemFailed: reducers.deleteItemFailed,
    updateItem_v0_Request: reducers.updateItem_v0_Request,
    updateItem_v0_Success: reducers.deleteItemSuccess,
    updateItem_v0_Failed: reducers.deleteItemFailed,
    deleteItemRequest: reducers.deleteItemRequest,
    deleteItemByConditionRequest: reducers.deleteItemByConditionRequest,
    deleteItemSuccess: reducers.deleteItemSuccess,
    deleteItemFailed: reducers.deleteItemFailed,
    orderItemDeleteRequest: reducers.orderItemDeleteRequest,
    getLinkedItemRequest: reducers.getLinkedItemRequest,
    getLinkedItemSuccess: reducers.getLinkedItemSuccess,
    getLinkedItemFailed: reducers.getLinkedItemFailed,
    getLinkedAllDbsItemsRequest: reducers.getLinkedAllDbsItemsRequest,
    getLinkedAllDbsItemsSuccess: reducers.getLinkedAllDbsItemsSuccess,
    getLinkedAllDbsItemsFaild: reducers.getLinkedItemFailed,
    reset: reducers.reset,
    uploadFileRequest: reducers.uploadFileRequest,
    updateDetailsRequest: reducers.updateDetailsRequest,
    deletePDFRequest: reducers.deletePDFRequest,
    deletePDFSuccess: reducers.deletePDFSuccess,
    updateDeliverySlip: reducers.updateDeliverySlip,
    sendMail: reducers.sendMail,
    orderForInventoryAction: reducers.orderForInventoryAction,
    getError: reducers.getError,
    clearError: reducers.clearError,
  },
})

export const {
  getAutoNumberRequest,
  getAutoNumberSuccess,
  getAutoNumberFailed,
  getDatastoreFieldsRequest,
  getDatastoreFieldsSuccess,
  getDatastoreFieldsFailed,
  getItemDetailsRequest,
  getItemDetailsSuccess,
  getItemDetailsFailed,
  selectAction,
  getActionSettingsRequest,
  getActionSettingsSuccess,
  getActionSettingsFailed,
  getPDF,
  getPDFSucess,
  getPDFFailed,
  updateItemRequest,
  updateItemSuccess,
  updateItemFailed,
  newItemRequest,
  newItemSuccess,
  newItemFailed,
  setRevisionNumber,
  newItemModeRequest,
  newItemModeSuccess,
  newItemModeFailed,
  setMode,
  updateCommentHistoryRequest,
  updateCommentHistorySuccess,
  updateCommentHistoryFailed,
  getHistoriesRequest,
  getHistoriesSuccess,
  getHistoriesFailed,
  deleteItemRequest,
  deleteItemSuccess,
  deleteItemFailed,
  orderItemDeleteRequest,
  createItemRequest,
  createItemSuccess,
  createItemFailed,
  getLinkedItemRequest,
  getLinkedItemSuccess,
  getLinkedItemFailed,
  getLinkedAllDbsItemsRequest,
  getLinkedAllDbsItemsSuccess,
  getLinkedAllDbsItemsFaild,
  reset,
  sendMail,
  orderForInventoryAction,
  getError,
  clearError,
} = itemDetailsSlice.actions
