import { ofType } from 'redux-observable'
import { switchMap, catchError, mergeMap, map, concatMap, tap, ignoreElements } from 'rxjs/operators'
import { Action, PayloadAction } from '@reduxjs/toolkit'
import { of, Observable, EMPTY } from 'rxjs'
import { notification } from 'antd'

import {
  UsersActions,
  WorkspaceActions,
  UsersSelectors,
  GroupActions,
  ProjectActions,
  ItemsActions,
  ItemsDetailsActions,
  DataReportActions,
  DatastoreActions,
} from '..'
import HttpService from 'app/services/httpService/httpService'
import rootStore from '../rootStore'
import * as apiModels from './epic.types'
import { UserInfo } from './types'
import { AppConstant } from 'app/constants/app'
import { history } from 'App'
import { routePath } from 'app/constants/router'
import { errorMessages, messages, CHANGE_PASSWORD_ID } from 'app/constants/login'
import { ErrorHandlerService } from 'app/services/errorHandler/errorHandler'

/**
 * @action INIT
 * @in [PERSIST-STORE] REHYDRATE
 *
 * @description This is an init action called automatically  when the
 * store have been rehydrated. Here used to get the current user
 * If you want to know why this is called : https://github.com/b-eee/hexalite/wiki/Store-Architecture (check EPIC subpart)
 *
 * @outSuccess [User] getUserinfoRequest
 */
const initEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(AppConstant.REHYDRATE_ACTION_TYPE),
    concatMap(() => {
      const userLogged = UsersSelectors.isUserLogged(state$.value)
      if (userLogged) {
        return of(UsersActions.getUserinfoRequest(), UsersActions.navigate({ path: routePath.FAVORITE }))
      } else {
        return EMPTY
      }
    }),
  )

/**
 * @action HTTP
 * @in [User] loginRequest
 *
 * @param {string} email the mail we would like to use to login
 * @param {string} password the password we would like to use to login
 *
 * @description Request authentification of a user, on success, set the
 * token in store and request loading of the user workspaces
 *
 * @outSuccess [User] setUserAuth
 * @outSuccess [User] loginSuccess
 * @outSuccess [Workspace] getWorkspacesRequest
 *
 * @outFailed [User] loginFailed
 */
const loginEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(UsersActions.loginRequest),
    switchMap((action: PayloadAction<{ email: string; password: string }>) =>
      HttpService.PostAsync<apiModels.api_token_auth_request, apiModels.api_token_auth_response>(
        'token-auth',
        action.payload,
      ).pipe(
        mergeMap(response => {
          const token = response && response.data && response.data.token ? response.data.token : ''
          return of(
            UsersActions.setUserAuth({ authKey: token }),
            UsersActions.loginSuccess(),
            UsersActions.getUserinfoRequest(),
            WorkspaceActions.getWorkspacesRequest(),
            GroupActions.getGroupsRequest(),
          )
        }),
        catchError((error: string) => {
          return of(UsersActions.loginFailed({ error }))
        }),
      ),
    ),
  )

/**
 * @action HTTP
 * @in [User] getUserinfoRequest
 *
 * @description Request to retrieve user information from the API
 * and store it in the user store
 *
 * @outSuccess [User] getUserinfoSuccess
 *
 * @outFailed [User] getUserinfoFailed
 */
const getUserinfoEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(UsersActions.getUserinfoRequest),
    switchMap(() =>
      HttpService.GetAsync<null, UserInfo>('userinfo', null, HttpService.LinkerAPIBasePath).pipe(
        map(response => {
          return UsersActions.getUserinfoSuccess({ user: response.data })
        }),
        catchError((error: string) => {
          return of(UsersActions.getUserinfoFailed({ error }))
        }),
      ),
    ),
  )

/**
 * @action LINEAR
 * @in [User] logout
 *
 * @description Logout the user, purge the locally stored data
 * and redirect to login. This will also clear the user auth token.
 *
 * @outSuccess [User] setUserAuth
 */
const logoutEpic = action$ =>
  action$.pipe(
    ofType(UsersActions.logout),
    switchMap(() => {
      rootStore.persistor.purge()
      window.location.href = '/login'
      return of(
        UsersActions.reset(),
        WorkspaceActions.reset(),
        ProjectActions.reset(),
        GroupActions.reset(),
        ItemsActions.reset(),
        ItemsDetailsActions.reset(),
        DataReportActions.reset(),
        DatastoreActions.reset(),
      )
    }),
  )

const navigateEpic = action$ =>
  action$.pipe(
    ofType(UsersActions.navigate),
    tap((action: PayloadAction<{ path: string }>) => history.push(action.payload.path)),
    ignoreElements(),
  )

const forgotPasswordEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(UsersActions.forgotPasswordRequest),
    switchMap((action: PayloadAction<{ email: string }>) =>
      HttpService.PostAsync<apiModels.forgot_password_request, apiModels.forgot_password_response>(
        'users/password/forgot',
        { email: action.payload.email, host: window.location.origin },
        HttpService.LinkerAPIBasePath,
      ).pipe(
        mergeMap(response => {
          if (response.data.valid_email) {
            notification['info']({
              message: messages.sendEmail,
              duration: 4000,
            })
            return of(UsersActions.forgotPassowrdSuccess())
          } else {
            ErrorHandlerService.addError({ message: errorMessages.invalidEmail, time: Date.now() }, true)
            return of(UsersActions.forgotPasswordFailed({ error: errorMessages.invalidEmail }))
          }
        }),
        catchError((error: string) => {
          return of(UsersActions.forgotPasswordFailed({ error }))
        }),
      ),
    ),
  )

const changePasswordEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(UsersActions.changePasswordRequest),
    switchMap((action: PayloadAction<{ newPassword: string; confirmPassword: string }>) =>
      HttpService.PutAsync<apiModels.change_password_request, null>(
        'users/password/forgot',
        {
          new_password: action.payload.newPassword,
          confirm_password: action.payload.confirmPassword,
          id: window.location.pathname.split('/')[CHANGE_PASSWORD_ID],
        },
        HttpService.LinkerAPIBasePath,
      ).pipe(
        mergeMap(response => {
          notification['info']({
            message: messages.changePassword,
            duration: 4000,
          })
          return of(UsersActions.changePassowrdSuccess(), UsersActions.navigate({ path: routePath.LOGIN }))
        }),
        catchError((error: string) => {
          return of(UsersActions.forgotPasswordFailed({ error }))
        }),
      ),
    ),
  )

const passwordValidateEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(UsersActions.passwordValidateRequest),
    switchMap(() =>
      HttpService.GetAsync<apiModels.password_validate_request, apiModels.password_validate_response>(
        'users/password/validate',
        {
          id: window.location.pathname.split('/')[2],
        },
        HttpService.LinkerAPIBasePath,
      ).pipe(
        mergeMap(response => {
          if (response.data.accessed) {
            notification['info']({
              message: errorMessages.usedLink,
              duration: 4000,
            })
            return of(UsersActions.passowrdValidateSuccess(), UsersActions.navigate({ path: routePath.LOGIN }))
          }
          if (response.data.isElapsed) {
            ErrorHandlerService.addError({ message: errorMessages.expiredLink, time: Date.now() }, true)
            return of(UsersActions.passowrdValidateSuccess(), UsersActions.navigate({ path: routePath.RESET_PASSWORD }))
          }
          return of(UsersActions.passowrdValidateSuccess())
        }),
        catchError((error: string) => {
          return of(UsersActions.passwordValidateFailed({ error }))
        }),
      ),
    ),
  )

export default [
  initEpic,
  getUserinfoEpic,
  loginEpic,
  logoutEpic,
  navigateEpic,
  forgotPasswordEpic,
  changePasswordEpic,
  passwordValidateEpic,
]
