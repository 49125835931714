import StringUtils from './stringUtils'

enum LogType {
  LOG,
  INFO,
  WARN,
  ERROR,
}

/**
 * @namespace
 * @property {number} date  - The date the log was request
 * @property {string} id    - The id of the log
 * @property {LogType} type - The type of log
 * @property {any} message  - The message to display
 */
interface Log {
  date: number
  id: string
  type: LogType
  message: any
}

class Logger {
  /**@static @property {Array<Log>} logList    - The list of logs */
  static logList = new Array<Log>()

  /**@static @property {boolean} production - True if production mode*/
  static production = !process.env.NODE_ENV || process.env.NODE_ENV === 'production'

  /**
   * @static
   * @param {any}
   * @description override the logger
   * @example Logger.console.log('myLog)
   * @returns {Function}
   */
  static console = ((wConsole): { log: Function; info: Function; warn: Function; error: Function } => {
    return {
      log: (...args): void => {
        Logger.add({
          date: Date.now(),
          id: StringUtils.GenUUID(),
          type: LogType.LOG,
          message: args,
        })
        if (!Logger.production) {
          wConsole.log(args)
        }
      },
      info: (...args): void => {
        Logger.add({
          date: Date.now(),
          id: StringUtils.GenUUID(),
          type: LogType.INFO,
          message: args,
        })
        if (!Logger.production) {
          wConsole.info(args)
        }
      },
      warn: (...args): void => {
        Logger.add({
          date: Date.now(),
          id: StringUtils.GenUUID(),
          type: LogType.WARN,
          message: args,
        })
        if (!Logger.production) {
          wConsole.warn(args)
        }
      },
      error: (...args): void => {
        Logger.add({
          date: Date.now(),
          id: StringUtils.GenUUID(),
          type: LogType.ERROR,
          message: args,
        })
        if (!Logger.production) {
          wConsole.error(args)
        }
      },
    }
  })(window.console)

  /**
   * @static
   * @param  {Log} log the log to display
   *
   * @description add the a log to the log list
   *
   * @returns {void}
   */
  static add(log: Log): void {
    this.logList.push(log)
  }

  /**
   * @static
   * @param  {dtring} id the id of the log
   *
   * @description remove a log from the list
   *
   * @returns {void}
   */
  static remove(id: string): void {
    const index = this.logList.findIndex(log => log.id === id)
    if (index > -1) {
      this.logList.splice(index, 1)
    }
  }
}

export default Logger
