import { Observable } from 'redux'
import { ofType } from 'redux-observable'
import { map, catchError, switchMap, concatMap, filter, delay } from 'rxjs/operators'
import { PayloadAction, Action } from '@reduxjs/toolkit'
import { EMPTY, of } from 'rxjs'

import {
  ItemsActions,
  DatastoreSelectors,
  ItemsDetailsActions,
  UsersSelectors,
  ProjectSelectors,
  ItemsSelectors,
  UsersActions,
} from '..'
import HttpService from 'app/services/httpService/httpService'
import * as apiModels from './epic.types'
import { Changes, SearchSettings, SearchCondition } from './types'
import { ItemDetailMode } from '../itemDetails/types'
import APIHelper from 'app/utils/apiHelper'

import { AppConstant } from 'app/constants/app'
import ApiHelper from 'app/utils/apiHelper'
import { ItemsSelector } from './reducers'
import Search from 'antd/lib/input/Search'
import { routePath } from 'app/constants/router'

const initEpic = (action$, state$): Observable<Action<string>> => {
  const userLogged = UsersSelectors.isUserLogged(state$.value)
  const projectId = ProjectSelectors.getCurrentProjectId(state$.value)
  const datastoreId = DatastoreSelectors.getCurrentDatastoreId(state$.value)
  return action$.pipe(
    ofType(AppConstant.REHYDRATE_ACTION_TYPE),
    filter(_ => userLogged && !!projectId && !!datastoreId),
    concatMap(() => {
      return of(ItemsActions.getItemsListRequest({ projectId, datastoreId }))
    }),
  )
}

const getItemsListEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsActions.getItemsListRequest),
    switchMap(action => {
      const datastore_id = DatastoreSelectors.getCurrentDatastoreId(state$.value)
      const project_id = ProjectSelectors.getCurrentProjectId(state$.value)
      const currentDsId = DatastoreSelectors.getCurrentDatastoreId(state$.value)
      const requestParam: apiModels.api_aggregated_api_datastore_items_request = ApiHelper.getItemsRequestParam(
        datastore_id,
        project_id,
        currentDsId,
        {
          page: 1,
          sortFieldId: '',
          sortOrder: '',
          conditions: [],
          include_fields_data: true,
        },
      )

      return HttpService.PostAsync<
        apiModels.api_aggregated_api_datastore_items_request,
        apiModels.api_aggregated_api_datastore_items_response
      >(`aggregated_api_datastore_items`, requestParam, HttpService.HexaLinkBasePath).pipe(
        map(result => {
          const response = result.data
          const entries = APIHelper.buildEntries(
            response.postget_paginate_items_with_search.items,
            response.postget_paginate_items_with_search.fields,
          )

          return ItemsActions.getItemsListSuccess({
            entries: entries,
            columns: response.get_datastore_colinfo,
            fields: response.postget_paginate_items_with_search.fields,
            new_actions: response.get_sf_new_action_settings,
            settings: response.get_datastore_settings,
            personalization: response.get_item_list_personalize,
            totalItems: response.postget_paginate_items_with_search.totalItems,
          })
        }),
        catchError((error: string) => {
          return of(ItemsActions.getItemsListFailed({ error }))
        }),
      )
    }),
  )

const deleteItemEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsActions.deleteItemRequest),
    switchMap((action: PayloadAction<{ actionId: string; itemId: string }>) => {
      const datastore_id = DatastoreSelectors.getCurrentDatastoreId(state$.value)
      const user_id = UsersSelectors.getUserId(state$.value)
      return HttpService.PostAsync<apiModels.post_delete_dt_item_request, apiModels.post_delete_dt_item_response>(
        `post_delete_dt_item`,
        {
          a_id: action.payload.actionId,
          d_id: datastore_id,
          i_id: action.payload.itemId,
          u_id: user_id,
        },
      ).pipe(
        switchMap(() => {
          return [
            ItemsActions.deleteItemSuccess({ itemId: action.payload.itemId }),
            ItemsDetailsActions.setMode({ mode: ItemDetailMode.CLOSE }),
          ]
        }),
        catchError((error: string) => of(ItemsActions.getItemsListFailed({ error }))),
      )
    }),
  )

const setCurrentItemIdEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsActions.setCurrentItemId),
    switchMap((action: PayloadAction<{ itemId: string }>) => {
      const selectingItemId = action && action.payload.itemId
      if (selectingItemId) {
        return [
          ItemsDetailsActions.getItemDetailsRequest({ itemId: action.payload.itemId }),
          ItemsDetailsActions.setMode({ mode: ItemDetailMode.DISPLAY }),
        ]
      } else {
        return [ItemsDetailsActions.setMode({ mode: ItemDetailMode.CLOSE }), ItemsDetailsActions.reset()]
      }
    }),
  )

const postActionEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsActions.postActionRequest),
    concatMap(
      (action: {
        payload: {
          actionId: string
          datastoreId: string
          itemId: string
          changes: Changes
          lastFlag: boolean
          action_Id: string
          actionType: string
          navigatePath: string
        }
      }) => {
        return HttpService.PostAsync<apiModels.postActionRequest, null>(
          `items/${action.payload.itemId}/actions/${action.payload.actionId}`,
          {
            is_force_update: true,
            history: {
              datastore_id: action.payload.datastoreId,
              comment: '',
            },
            changes: action.payload.changes,
          },
          HttpService.LinkerAPIBasePath,
        )
          .pipe(
            concatMap(response => {
              const projectId = ProjectSelectors.getCurrentProjectId(state$.value)

              if (action.payload.action_Id && action.payload.lastFlag) {
                // 納品書作成画面で最後のアイテム登録後にaction_Idでステータス変更を行い、
                // Hexabase側のアクションスクリプトでSVFを実行し、PDFを作成する。
                const changes = []
                return of(
                  ItemsActions.postExecuteActionRequest({
                    itemId: action.payload.itemId,
                    datastoreId: action.payload.datastoreId,
                    actionId: action.payload.action_Id,
                    changes,
                    navigatePath: routePath.DELIVERY_SLIP,
                  }),
                )
              } else if (action.payload.actionType && action.payload.actionType == 'delivery-slip-detail') {
                return of(ItemsDetailsActions.getItemDetailsRequest({ itemId: action.payload.itemId }))
              } else if (action.payload.navigatePath) {
                return of(ItemsActions.postActionSucess(), UsersActions.navigate({ path: action.payload.navigatePath }))
              } else {
                return of(
                  ItemsActions.loadItem({
                    itemId: action.payload.itemId,
                    projectId: projectId,
                    datastoreId: action.payload.datastoreId,
                  }),
                )
              }
            }),
            catchError((error: string) => {
              return of(ItemsActions.postActionFailed({ error }))
            }),
          )
          .toPromise()
      },
    ),
  )

const postExecuteActionEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsActions.postExecuteActionRequest),
    concatMap(
      (action: {
        payload: {
          actionId: string
          datastoreId: string
          itemId: string
          changes: Changes
          navigatePath: string
        }
      }) => {
        const projectId = ProjectSelectors.getCurrentProjectId(state$.value)
        const datastoreId = action.payload.datastoreId
          ? action.payload.datastoreId
          : DatastoreSelectors.getCurrentDatastoreId(state$.value)
        return HttpService.PostAsync<apiModels.postActionRequest, null>(
          `applications/${projectId}/datastores/${datastoreId}/items/action/${action.payload.itemId}/${action.payload.actionId}`,
          {
            is_force_update: true,
            history: {
              datastore_id: datastoreId,
              comment: '',
            },
            changes: action.payload.changes,
          },
          HttpService.LinkerAPIBasePath,
        )
          .pipe(
            concatMap(response => {
              if (action.payload.navigatePath) {
                return of(ItemsActions.postActionSucess(), UsersActions.navigate({ path: action.payload.navigatePath }))
              } else {
                return of(ItemsActions.postActionSucess())
              }
            }),
            catchError((error: string) => {
              return of(ItemsActions.postActionFailed({ error }), ItemsDetailsActions.postActionItemFailed({ error }))
            }),
          )
          .toPromise()
      },
    ),
  )

const loadItemEpic = (action$, state$) =>
  action$.pipe(
    ofType(ItemsActions.loadItem),
    concatMap(
      (action: {
        payload: {
          itemId: string
          datastoreId: string
          projectId: string
        }
      }) => {
        return of(
          ItemsActions.postActionSucess(),
          ItemsDetailsActions.getItemDetailsRequest({ itemId: action.payload.itemId }),
          ItemsActions.getItemsListRequest({
            projectId: action.payload.projectId,
            datastoreId: action.payload.datastoreId,
          }),
        )
      },
    ),
  )

const getItemsListBySearchParams = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(ItemsActions.setSearchParams),
    switchMap(action => {
      const datastore_id = DatastoreSelectors.getCurrentDatastoreId(state$.value)
      const project_id = ProjectSelectors.getCurrentProjectId(state$.value)
      const currentDsId = DatastoreSelectors.getCurrentDatastoreId(state$.value)
      const searchParams = ItemsSelectors.getSearchParams(state$.value)
      // const searchParams =
      const requestParam: apiModels.api_aggregated_api_datastore_items_request = ApiHelper.getItemsRequestParam(
        datastore_id,
        project_id,
        currentDsId,
        searchParams,
      )

      return HttpService.PostAsync<
        apiModels.api_aggregated_api_datastore_items_request,
        apiModels.api_aggregated_api_datastore_items_response
      >(`aggregated_api_datastore_items`, requestParam, HttpService.HexaLinkBasePath).pipe(
        map(result => {
          const response = result.data
          const entries = APIHelper.buildEntries(
            response.postget_paginate_items_with_search.items,
            response.postget_paginate_items_with_search.fields,
          )

          return ItemsActions.getItemsListSuccess({
            entries: entries,
            columns: response.get_datastore_colinfo,
            fields: response.postget_paginate_items_with_search.fields,
            new_actions: response.get_sf_new_action_settings,
            settings: response.get_datastore_settings,
            personalization: response.get_item_list_personalize,
            totalItems: response.postget_paginate_items_with_search.totalItems,
          })
        }),
        catchError((error: string) => {
          return of(ItemsActions.getItemsListFailed({ error }))
        }),
      )
    }),
  )

export default [
  initEpic,
  getItemsListEpic,
  setCurrentItemIdEpic,
  deleteItemEpic,
  postActionEpic,
  postExecuteActionEpic,
  getItemsListBySearchParams,
  loadItemEpic,
]
