import { GroupState, initialState } from './state'
import { Group, GroupMembers } from './types'
import { PayloadAction } from '@reduxjs/toolkit'

export const getGroupsSuccess = (state: GroupState, action: PayloadAction<Group>): GroupState => {
  return {
    ...state,
    ...action.payload,
    loading: false,
    error: '',
  }
}

export const getGroupsFailed = (state: GroupState, action: PayloadAction<{ error: string }>): GroupState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

export const getGroupsRequest = (state: GroupState): GroupState => {
  return {
    ...state,
    loading: true,
  }
}

export const getSupplierGroupsSuccess = (state: GroupState, action: PayloadAction<GroupMembers>): GroupState => {
  return {
    ...state,
    supplierGroupMembers: action.payload,
    loading: false,
    error: '',
  }
}

export const getSupplierGroupsFailed = (state: GroupState, action: PayloadAction<{ error: string }>): GroupState => {
  return {
    ...state,
    loading: false,
    error: action.payload.error,
  }
}

// export const getSupplierGroupsRequest = (state: GroupState, action: PayloadAction<{ groupId: string }>): GroupState => {
export const getSupplierGroupsRequest = (state: GroupState): GroupState => {
  return {
    ...state,
    loading: true,
  }
}

export const reset = (): GroupState => {
  return {
    ...initialState,
  }
}
