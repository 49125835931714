import { Group, GroupMembers } from './types'

export interface GroupState extends Group {
  loading: boolean
  error: string
  supplierGroupMembers: GroupMembers
}

export const initialState: GroupState = {
  group: {
    g_id: '',
    group_id: '',
    name: '',
    index: 0,
  },
  children: [
    {
      g_id: '',
      group_id: '',
      name: '',
      index: 0,
    },
  ],
  count: 0,
  loading: false,
  error: '',
  supplierGroupMembers: {
    members: [
      {
        u_id: '',
        username: '',
        email: '',
        profile_pic: '',
        confirmed: true,
        email_sent: true,
        user_roles: [
          {
            r_id: '',
            role_name: '',
            role_id: '',
            p_id: '',
            application_id: '',
            application_name: '',
            application_display_order: 0,
          },
        ],
      },
    ],
    count: 0,
  },
}
