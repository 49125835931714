import { createSlice } from "redux-starter-kit";
import * as reducers from './reducers';
import { initialState } from "./state";


export const mainSlice = createSlice({
  slice: "main",
  initialState: initialState,
  reducers: {
    setMainLoading: reducers.setMainLoading,
  }
});

export const {
  setMainLoading
} = mainSlice.actions;