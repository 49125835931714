export const status = {
  NON_CREATED: '未作成',
  CREATED: '作成済み',
}

export enum statusActionName {
  未作成 = '次のステータスに進める',
  作成済み = 'DEFAULTS_FOR_IMPORTER.DEFAULT_ACTION_NAME_PREVIOUS',
}

export const NUMBER_OF_STATUS_TYPES = 2

export const dateTypeKeyListOfDeliverySlip = ['回答納期', '出荷日']

export const formKeys = {
  STATUS: 'ステータス',
  ANSWER_DELIVERY_DATE: '回答納期',
  SUPPLIER_ID: '発注先ID',
  PDF: 'PDF',
  DRAFT_DATE: '起票日',
  SUPPLIER_USERTYPE: '発注先(ユーザー型)',
  ITEM_NAME: '物品名',
  ID: 'ID',
  SHIP_DATE: '出荷日',
  ORDER_QUANTITY: '発注個数',
  DELIVERY_ID: '納品書番号',
}

export const destinationData = {
  白山工業本社工場: {
    address: '〒194-0004　東京都町田市鶴間5-5-1',
    telephone: '042-795-1323',
  },
  白山工業茨城第二工場: {
    address: '〒306-0654　茨城県坂東市上出島1216-29',
    telephone: '0297-44-4781',
  },
}

export const selectSendType = {
  /** 白山工業本社工場 */
  HEADOFFICEFACTORY: '白山工業本社工場',
  /** 白山工業茨城第二工場 */
  SECONDOFFICEFACTORY: '白山工業茨城第二工場',
}

export const maxLengthMessage = '%sは%d文字以内（全角換算）で入力して下さい'

export const errorMessages = {
  TEL: '半角数字とハイフンのみ入力可能です',
}

export const exceptSearchKeys = {
  statusKey: 'ステータス',
}
