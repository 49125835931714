import React from 'react';
import ReactDOM from 'react-dom';
import { Provider} from 'react-redux';

import './i18n';
import './index.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import './app/interceptors/'

import App from './App';
import * as serviceWorker from './serviceWorker';
import rootStore from './app/services/store/rootStore';


const Root = (): JSX.Element => {
    return (
        <Provider store={rootStore.store}>
            <App/>
        </Provider>
    )
};

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
