import React, { useState, useEffect } from 'react'
import { Row, Col, Tabs } from 'antd'

import { useDispatch, useSelector } from 'react-redux'

import { getDatastoreIdFromName } from 'app/utils/store'
import { hexaLabel, dataReport } from 'app/constants/hexalink'
import { FavotiteSearchLabel } from 'app/constants/app'

import {
  DataReportActions,
  DataReportSelectors,
  DatastoreActions,
  GroupSelectors,
  ProjectSelectors,
} from 'app/services/store'
import { datastoreSelector } from 'app/services/store/datastore/reducers'
import { PostFilterDatareportRequest_v1 } from 'app/services/store/dataReport/epic.types'

import { rootSelector } from 'app/services/store/rootReducer'
import {
  getField,
  getFieldIdByColumn,
  mergeHeaderSearchCondition,
  changeConditionRule,
} from 'app/utils/components/common'

import AppLayout from 'app/components/templates/AppLayout'
import FavoritePage from 'app/components/pages/favorite/FavoritePage'
import { PagenationValue } from 'app/components/pages/favorite/types'

const { TabPane } = Tabs

const FavoriteContainer = (): JSX.Element => {
  const dispatch = useDispatch()
  const pId = ProjectSelectors.getCurrentProjectId(useSelector(rootSelector))
  const isLoading = DataReportSelectors.getDataReportIsLoading(useSelector(rootSelector))
  const { columns, items } = DataReportSelectors.getDisplayableEntries(useSelector(rootSelector))
  const [currentTab, setCurrentTab] = useState(dataReport.WAITING_FOR_DELIVERYDATE_REPLY)
  const getTotalCount = DataReportSelectors.getDataReportTotalItems(useSelector(rootSelector))
  const getSearchFields = DataReportSelectors.getDataReportSearchFields(useSelector(rootSelector))
  const supplierMembers = GroupSelectors.getSupplierGroupMembers(useSelector(rootSelector))

  const { datastores_list } = useSelector(datastoreSelector)
  const defaultPerPage = 100
  const defaultConditions = { conditions: [], per_page: defaultPerPage, page: 1 }
  const pagination: PagenationValue = { defaultSize: defaultPerPage, totalCount: Number(getTotalCount) }

  const sendChangePageHandle = (conditions, currentPage: number, sortFieldId: string, sortOrder: string): void => {
    if (currentPage < 0) return

    const param: PostFilterDatareportRequest_v1 = { conditions: [] }
    param['conditions'] = conditions
    param['per_page'] = defaultPerPage
    param['page'] = currentPage

    if (sortFieldId != '' && sortOrder != '') {
      param['sort_field_id'] = sortFieldId
      param['sort_order'] = sortOrder
    }

    // ステータス検索
    const statusField = getField(FavotiteSearchLabel.status, getSearchFields)
    if (statusField !== null && statusField.statuses.length > 0) {
      param.conditions = [
        ...mergeHeaderSearchCondition(
          param.conditions,
          getFieldIdByColumn(FavotiteSearchLabel.status, columns),
          statusField.statuses,
          'name',
          's_id',
        ),
      ]
    }

    // サプライヤー検索
    const usersField = getField(FavotiteSearchLabel.supplier, getSearchFields)
    if (usersField !== null) {
      param.conditions = [
        ...mergeHeaderSearchCondition(
          param.conditions,
          getFieldIdByColumn(FavotiteSearchLabel.supplier, columns),
          supplierMembers.members,
          'username',
          'u_id',
        ),
      ]
    }

    dispatch(
      DataReportActions.getFilteredDataReportRequest({
        pId,
        rId: currentTab,
        conditions: param,
      }),
    )
  }

  // よく使う一覧ページから発注詳細ページに遷移するために、ここで発注DBを取得しておく
  useEffect(() => {
    if (datastores_list) {
      dispatch(
        DatastoreActions.setCurrentDatastoreId({
          datastoreId: getDatastoreIdFromName(datastores_list, hexaLabel.ORDER_DB),
        }),
      )
    }
    if (pId && currentTab) {
      dispatch(
        DataReportActions.getFilteredDataReportRequest({
          pId,
          rId: currentTab,
          conditions: defaultConditions,
        }),
      )
      dispatch(
        DataReportActions.getDataReportSearchConditionsRequest({
          pId,
          rId: currentTab,
        }),
      )
    }
  }, [dispatch, pId, currentTab, datastores_list])

  return (
    <AppLayout>
      <div>
        <Row>
          <Col>
            <h2>よく使う一覧</h2>
          </Col>
        </Row>

        <Row>
          <Col>
            <Tabs type="card" onChange={(key: string): void => setCurrentTab(key)}>
              <TabPane tab="納期回答待ち" key={dataReport.WAITING_FOR_DELIVERYDATE_REPLY}>
                <FavoritePage
                  items={items}
                  columns={columns}
                  itemsLoading={isLoading}
                  sendChangePageHandle={sendChangePageHandle}
                  pagination={pagination}
                />
              </TabPane>
              <TabPane tab="未確認起票一覧" key={dataReport.UNCONFIRMED_DRAFT_LIST}>
                <FavoritePage
                  items={items}
                  columns={columns}
                  itemsLoading={isLoading}
                  sendChangePageHandle={sendChangePageHandle}
                  pagination={pagination}
                />
              </TabPane>
              <TabPane tab="本日入庫分" key={dataReport.STOCK_TODAY}>
                <FavoritePage
                  items={items}
                  columns={columns}
                  itemsLoading={isLoading}
                  sendChangePageHandle={sendChangePageHandle}
                  pagination={pagination}
                />
              </TabPane>
              <TabPane tab="納期確認済" key={dataReport.DELIVERY_DATE_CONFIRMED}>
                <FavoritePage
                  items={items}
                  columns={columns}
                  itemsLoading={isLoading}
                  sendChangePageHandle={sendChangePageHandle}
                  pagination={pagination}
                />
              </TabPane>
              <TabPane tab="未入荷一覧" key={dataReport.UNSTOCKED_LIST}>
                <FavoritePage
                  items={items}
                  columns={columns}
                  itemsLoading={isLoading}
                  sendChangePageHandle={sendChangePageHandle}
                  pagination={pagination}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
    </AppLayout>
  )
}

export default FavoriteContainer
