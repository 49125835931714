import React, { useEffect } from 'react'
import { Row, Col, Form, Button, Card, Typography, Spin } from 'antd'
import { FormComponentProps } from 'antd/lib/form/Form'

import { hasErrors } from '../../utils/form/form'
import useTextInput from '../../../hooks/useTextInput'
import PasswordInput from '../../molecules/form/PasswordInput'

interface ChangePasswordProps extends FormComponentProps {
  isLoading: boolean
  changePassword: (newPassword: string, confirmPassword: string) => void
}

const { Text } = Typography

const ChangePassword = (props: ChangePasswordProps): JSX.Element => {
  const [newPassword, setNewPassword] = useTextInput('')
  const [confirmPassword, setConfirmPassword] = useTextInput('')
  const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = props.form

  const newPasswordError = isFieldTouched('newPassword') && getFieldError('newPassword')
  const confirmPasswordError = isFieldTouched('confirmPassword') && getFieldError('confirmPassword')

  const handleSubmit = (): void => {
    props.form.validateFields(err => {
      if (!err) {
        props.changePassword(newPassword, confirmPassword)
      }
    })
  }

  useEffect(() => {
    props.form.validateFields()
    // eslint-disable-next-line
  }, [])

  return (
    <Spin size="large" spinning={props.isLoading}>
      <div style={{ background: '#F3F3F3' }}>
        <Row type="flex" justify="center" align="middle" style={{ height: '100vh' }}>
          <Row type="flex" justify="center">
            <Col span={50}>
              <Card className="loginForm" style={{ width: '488.65px' }}>
                <Row
                  type="flex"
                  justify="center"
                  style={{
                    fontSize: '20px',
                    color: '#1589d6',
                    marginTop: '26px',
                    marginBottom: '25px',
                  }}
                >
                  パスワードのリセット
                </Row>
                <Row type="flex" justify="center" style={{ margin: '20px 0', textAlign: 'center' }}>
                  <Text>新しいパスワードを入力してください</Text>
                </Row>
                <Form>
                  <PasswordInput
                    getFieldDecorator={getFieldDecorator}
                    passwordError={newPasswordError}
                    setPassword={setNewPassword}
                    label="新しいパスワード"
                    fieldId="newPassword"
                  />
                  <PasswordInput
                    getFieldDecorator={getFieldDecorator}
                    passwordError={confirmPasswordError}
                    setPassword={setConfirmPassword}
                    label="新しいパスワード（確認）"
                    fieldId="confirmPassword"
                  />
                  <Form.Item>
                    <Row justify="center" type="flex" style={{ marginTop: '15px' }}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        block
                        disabled={hasErrors(getFieldsError()) || newPassword !== confirmPassword}
                        onClick={(): void => handleSubmit()}
                      >
                        適用
                      </Button>
                    </Row>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Row>
      </div>
    </Spin>
  )
}

const ChangePasswordPage = Form.create<ChangePasswordProps>()(ChangePassword)
export default ChangePasswordPage
