import React from 'react'
import { FormComponentProps } from 'antd/lib/form/Form'
import { Drawer, Button, Input, Col, Row, List, Comment, Form, Spin } from 'antd'

import { CommentData } from 'app/services/store/itemDetails/types'
import { orderColumnNameLabel } from 'app/constants/order'

const { TextArea } = Input

interface CommentDrawerProps extends FormComponentProps {
  drawerVisibility: boolean
  setDrawerVisibility: Function
  commentData: Array<CommentData> | undefined
  getFieldDecorator: Function
  handleDrawerSubmit: (e: React.FormEvent) => void
  isHistoriesLoading: boolean
}
const InnerCommentDrawer = ({
  drawerVisibility,
  setDrawerVisibility,
  commentData,
  getFieldDecorator,
  handleDrawerSubmit,
  isHistoriesLoading,
}: CommentDrawerProps): JSX.Element => {
  return (
    <Drawer
      placement="right"
      visible={drawerVisibility}
      onClose={(): void => {
        setDrawerVisibility(false)
      }}
      width="500"
    >
      <Spin size="large" spinning={isHistoriesLoading}>
        <Row type="flex" align="top" style={{ margin: '10px 0', overflowY: 'auto', height: '70vh' }}>
          <Col>
            <List
              itemLayout="horizontal"
              dataSource={commentData ? commentData : []}
              renderItem={(commentData: CommentData) => (
                <Col span={24} style={{ borderBottom: '1px solid lightGray' }}>
                  <List>
                    <Comment
                      author={commentData.author}
                      content={commentData.content}
                      datetime={commentData.datetime}
                    />
                  </List>
                </Col>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item label="コメント">
              {getFieldDecorator(orderColumnNameLabel.RIGHT_COMMENT, {
                initialValue: '',
              })(<TextArea rows={3} />)}
            </Form.Item>
          </Col>
        </Row>
        <Form onSubmit={handleDrawerSubmit}>
          <Row style={{ margin: '30px 0' }}>
            <Col style={{ textAlign: 'center' }}>
              <Button htmlType="submit">送信</Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Drawer>
  )
}

const CommentDrawer = Form.create<CommentDrawerProps>()(InnerCommentDrawer)

export default CommentDrawer
