import React, { useState } from 'react'
import { Row, Col, Button, Form, Modal, message } from 'antd'

import { statusLabel, actionNameLabel, statusActionLabel } from 'app/constants/order'
import { StatusIndex, LowerConfirmMassage } from 'app/components/organisms/order/type'
import { ActionName } from 'app/components/pages/order/types'
interface LowerButtonAreaProps {
  LowerButtonAreaResource: {
    statusIndex: number
    setOperationAndOpenEditMode: (operation: ActionName) => void
    closeEditMode: () => void
    isEditMode: boolean
    hasErrors: boolean
    isSupplier: boolean
    currentDetailList,
  }
}
const LowerButtonArea = ({
  LowerButtonAreaResource: {
    statusIndex,
    setOperationAndOpenEditMode,
    closeEditMode,
    isEditMode,
    isSupplier,
    hasErrors,
    currentDetailList,
  },
}: LowerButtonAreaProps): JSX.Element => {

  function info() {
    const message = LowerConfirmMassage.UPDATE_DELIVERY_DATE_CONFIRMATION
    Modal.info({
      title: message['title'],
      content: (
        <div>
          <p>{message['body']}</p>
          <p>納品書番号：{currentDetailList['納品書番号']}</p>
        </div>
      ),
      onOk() {modalClose()},
    });
  }

  const modalClose = (): void => {
    setOperationAndOpenEditMode(actionNameLabel.UPDATE_DELIVERY_DATE as ActionName)
  }

  const EditModeButtonComponent = (): JSX.Element => {
    return (
      <Col span={21} style={{ display: isEditMode ? 'block' : 'none', textAlign: 'center' }}>
        <Row type="flex" justify="center">
          <Col span={4}>
            <Button htmlType="submit" disabled={hasErrors}>
              送信
            </Button>
          </Col>
          <Col offset={1} span={4}>
            <Form.Item>
              <Button onClick={closeEditMode}>キャンセル</Button>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    )
  }

  return (
    <div className="lower-action-button">
      {StatusIndex[statusIndex] === statusLabel.DRAFT_ORDER && (
        <div>
          <Row type="flex" justify="center">
            <Col style={{ textAlign: 'center', display: isSupplier ? 'none' : 'block' }}>
              <Button
                disabled={isEditMode}
                className="btn-white"
                onClick={(): void => setOperationAndOpenEditMode(actionNameLabel.COPY as ActionName)}
              >
                発注データをコピー
              </Button>
            </Col>
            <Col style={{ textAlign: 'center', display: isSupplier ? 'none' : 'block' }}>
              <Button
                disabled={isEditMode}
                className="btn-white"
                onClick={(): void => setOperationAndOpenEditMode(actionNameLabel.DELETE as ActionName)}
              >
                発注データを削除
              </Button>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col style={{ display: isEditMode || isSupplier ? 'none' : 'block', textAlign: 'center' }}>
              <Button
                disabled={isEditMode}
                onClick={(): void => setOperationAndOpenEditMode(statusActionLabel.CONTACT_SUPPLIER as ActionName)}
              >
                サプライヤーへ連絡
              </Button>
            </Col>
            <EditModeButtonComponent />
          </Row>
        </div>
      )}
      {StatusIndex[statusIndex] === statusLabel.WAITING_FOR_SUPPLYER && (
        <div>
          <Row type="flex" justify="center">
            <Col style={{ textAlign: 'center', display: isSupplier ? 'none' : 'block' }}>
              <Button
                disabled={isEditMode}
                className="btn-white"
                onClick={(): void => setOperationAndOpenEditMode(actionNameLabel.COPY as ActionName)}
              >
                発注データをコピー
              </Button>
            </Col>
            <Col style={{ textAlign: 'center', display: isSupplier ? 'none' : 'block' }}>
              <Button
                disabled={isEditMode}
                className="btn-white"
                onClick={(): void => setOperationAndOpenEditMode(actionNameLabel.DELETE as ActionName)}
              >
                発注データを削除
              </Button>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col
              style={{
                display: !isEditMode && isSupplier ? 'block' : 'none',
                textAlign: 'center',
              }}
            >
              <Button
                disabled={isEditMode}
                onClick={(): void => setOperationAndOpenEditMode(actionNameLabel.REPLY_TO_SUPPLIER as ActionName)}
              >
                発注者へ回答
              </Button>
            </Col>
            <EditModeButtonComponent />
          </Row>
        </div>
      )}
      {StatusIndex[statusIndex] === statusLabel.NEED_CONFIRM && (
        <div>
          <Row type="flex" justify="center">
            <Col style={{ textAlign: 'center', display: isSupplier ? 'none' : 'block' }}>
              <Button
                disabled={isEditMode}
                className="btn-white"
                onClick={(): void => setOperationAndOpenEditMode(actionNameLabel.COPY as ActionName)}
              >
                発注データをコピー
              </Button>
            </Col>
            <Col style={{ textAlign: 'center', display: isEditMode || isSupplier ? 'none' : 'block' }}>
              <Button
                disabled={isEditMode}
                onClick={(): void => setOperationAndOpenEditMode(statusActionLabel.ARRANGEMENT as ActionName)}
              >
                手配依頼
              </Button>
            </Col>
            <Col style={{ textAlign: 'center', display: isSupplier ? 'none' : 'block' }}>
              <Button
                disabled={isEditMode}
                className="btn-white"
                onClick={(): void => setOperationAndOpenEditMode(actionNameLabel.DELETE as ActionName)}
              >
                発注データを削除
              </Button>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <EditModeButtonComponent />
          </Row>
        </div>
      )}
      {StatusIndex[statusIndex] === statusLabel.ARRANGING && (
        <div>
          <Row type="flex" justify="center">
            <Col style={{ textAlign: 'center', display: isSupplier ? 'none' : 'block' }}>
              <Button
                disabled={isEditMode}
                className="btn-white"
                onClick={(): void => setOperationAndOpenEditMode(actionNameLabel.COPY as ActionName)}
              >
                発注データをコピー
              </Button>
            </Col>
            <Col style={{ textAlign: 'center', display: isSupplier ? 'none' : 'block' }}>
{/*                
              <Button
                disabled={isEditMode}
                className="btn-white"
                onClick={(): void => modalOpen()}
                onClick={(): void => setOperationAndOpenEditMode(actionNameLabel.UPDATE_DELIVERY_DATE as ActionName)}
              >
*/}              
              <Button
                disabled={isEditMode}
                className="btn-white"
                onClick={(): void => info()}
              >
                手配確定後納品日変更
              </Button>
            </Col>
            <Col style={{ textAlign: 'center', display: isSupplier ? 'none' : 'block' }}>
              <Button
                disabled={isEditMode}
                className="btn-white"
                onClick={(): void => setOperationAndOpenEditMode(actionNameLabel.DELETE as ActionName)}
              >
                発注データを削除
              </Button>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col span={5} style={{ display: isEditMode || isSupplier ? 'none' : 'block', textAlign: 'center' }}>
              <Button
                disabled={isEditMode}
                onClick={(): void => setOperationAndOpenEditMode(statusActionLabel.ENREY as ActionName)}
              >
                入庫
              </Button>
            </Col>
            <EditModeButtonComponent />
          </Row>
        </div>
      )}
      {StatusIndex[statusIndex] === statusLabel.ARRIVED && (
        <div>
          <Row type="flex" justify="center">
            <Col style={{ textAlign: 'center', display: isSupplier ? 'none' : 'block' }}>
              <Button
                disabled={isEditMode}
                className="btn-white"
                onClick={(): void => setOperationAndOpenEditMode(actionNameLabel.COPY as ActionName)}
              >
                発注データをコピー
              </Button>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <EditModeButtonComponent />
          </Row>
        </div>
      )}
    </div>
  )
}

export default LowerButtonArea
