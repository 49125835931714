import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Tabs, Spin } from 'antd'
import { rootSelector } from 'app/services/store/rootReducer'
import { ItemsSelectors, DataReportSelectors, ItemDetailsSelectors } from 'app/services/store'

import AppLayout from 'app/components/templates/AppLayout'
import InventoryDetailContainer from 'app/container/inventory/InventoryDetailContainer'
import InventoryHisotryContainer from 'app/container/inventory/InventoryHistoryContainer'

const { TabPane } = Tabs

const InventoryDetailRouteContainer = (): JSX.Element => {
  // タブ切り替え時各Containerに切り替えを通知するState
  const [tabDetail, setTabDetail] = useState<boolean>(true)
  const [tabHistory, setTabHistory] = useState<boolean>(false)

  const isItemsLoading = ItemsSelectors.getItemsIsLoading(useSelector(rootSelector))
  const isItemDetalsLoading = ItemDetailsSelectors.getIsLoading(useSelector(rootSelector))
  const isDataReportLoading = DataReportSelectors.getDataReportIsLoading(useSelector(rootSelector))

  const onSelectTabIndex = index => {
    switch (index) {
      case '1': {
        setTabDetail(true)
        setTabHistory(false)
        break
      }
      case '2': {
        setTabDetail(false)
        setTabHistory(true)
        break
      }
      default: {
        break
      }
    }
  }

  return (
    <AppLayout>
      <Spin size="large" spinning={isItemsLoading || isDataReportLoading || isItemDetalsLoading}>
        <div className="card-container">
          <Tabs type="card" onTabClick={onSelectTabIndex}>
            <TabPane tab="在庫詳細" key="1">
              <InventoryDetailContainer isActive={tabDetail} />
            </TabPane>
            <TabPane tab="在庫履歴" key="2">
              <InventoryHisotryContainer isActive={tabHistory} />
            </TabPane>
          </Tabs>
        </div>
      </Spin>
    </AppLayout>
  )
}

export default InventoryDetailRouteContainer
