import { AxiosObservable } from 'axios-observable/dist/axios-observable.interface'
import Axios from 'axios-observable'
import { AxiosRequestConfig } from 'axios'

class HttpService {
  // values here can changed from environment variables
  // use of these constants depends on which router/api-server you want to access.

  /** @static @property {string} HexacloudBasePath   - apicore direct request via hexacloud proxy */
  static readonly HexacloudBasePath: string = 'hexacloud'
  /** @static @property {string} HexaLinkBasePath    - hexacloud aggregated api access and future api processing inside hexacloud */
  static readonly HexaLinkBasePath: string = 'hexalink2'
  /** @static @property {string} LinkerAPIBasePath   - linker-api base path for linker-api proxy */
  static readonly LinkerAPIBasePath: string = 'linker-api'

  /**
   * @static
   * @param  {string} targetApi the target endpoint of the api
   * @param  {string} [basePath=HttpService.HexacloudBasePath] api base we want to target
   *
   * @description return the path to the endpoint
   *
   * @returns {string]
   */
  public static getBaseUrl = (targetApi: string, basePath: string = HttpService.HexacloudBasePath): string => {
    return `/${basePath}/${targetApi}`
  }

  /**
   * @static
   * @param  {string} targetApi the target endpoint of the api
   * @param  {Req} [params] the parameter to pass
   * @param  {string} [basePath] api base we want to target
   * @param  {AxiosRequestConfig} [config] set request headers
   *
   * @description default method to execute a get request
   *
   * @returns {AxiosObservable}
   */
  public static GetAsync<Req, Res>(
    targetApi: string,
    params?: Req,
    basePath?: string,
    config?: AxiosRequestConfig,
  ): AxiosObservable<Res> {
    return Axios.get(this.getBaseUrl(targetApi, basePath), { params, ...config })
  }
  /**
   * @static
   * @param  {string} targetApi the target endpoint of the api
   * @param  {Req} data the parameter to pass
   * @param  {string} [basePath] api base we want to target
   * @param  {AxiosRequestConfig} [config] set request headers
   *
   * @returns {AxiosObservable}
   */
  public static PostAsync<Req, Res>(
    targetApi: string,
    data: Req,
    basePath?: string,
    config?: AxiosRequestConfig,
  ): AxiosObservable<Res> {
    return Axios.post(this.getBaseUrl(targetApi, basePath), data, config)
  }

  public static PutAsync<Req, Res>(
    targetApi: string,
    data: Req,
    basePath?: string,
    config?: AxiosRequestConfig,
  ): AxiosObservable<Res> {
    return Axios.put(this.getBaseUrl(targetApi, basePath), data, config)
  }

  public static DeleteAsync<Req, Res>(
    targetApi: string,
    basePath?: string,
    config?: AxiosRequestConfig,
  ): AxiosObservable<Res> {
    return Axios.delete(this.getBaseUrl(targetApi, basePath), config)
  }
}

export default HttpService
