import { createSlice } from '@reduxjs/toolkit'
import * as reducers from './reducers'
import { initialState } from './state'

/**
 * @namespace
 * @property {Function} getUserinfoRequest   - Request the current user settings
 * @property {Function} getUserinfoSuccess   - Above on success (Do not call outside of epic)
 * @property {Function} getUserinfoFailed    - Above on failure (Do not call outside of epic)
 * @property {Function} loginRequest            - Request to login with given username and password
 * @property {Function} loginSuccess            - Above on success (Do not call outside of epic)
 * @property {Function} loginFailed             - Above on failure (Do not call outside of epic)
 * @property {Function} setUserAuth             - Set the user authentification token in the store
 * @property {Function} logout                  - logout the current user and return to login
 * @property {Function} reset                   - Reset the store to initial state
 */

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUserAuth: reducers.setUserAuth,
    loginRequest: reducers.loginRequest,
    loginSuccess: reducers.loginSuccess,
    loginFailed: reducers.loginFailed,
    forgotPasswordRequest: reducers.forgotPasswordRequest,
    forgotPassowrdSuccess: reducers.loginSuccess,
    forgotPasswordFailed: reducers.loginFailed,
    changePasswordRequest: reducers.changePasswordRequest,
    changePassowrdSuccess: reducers.loginSuccess,
    changePasswordFailed: reducers.loginFailed,
    passwordValidateRequest: reducers.passwordValidateRequest,
    passowrdValidateSuccess: reducers.loginSuccess,
    passwordValidateFailed: reducers.loginFailed,
    getUserinfoRequest: reducers.getUserinfoRequest,
    getUserinfoSuccess: reducers.getUserinfoSuccess,
    getUserinfoFailed: reducers.getUserinfoFailed,
    logout: reducers.logout,
    reset: reducers.reset,
    navigate: reducers.navigate,
  },
})

export const {
  setUserAuth,
  loginRequest,
  loginSuccess,
  loginFailed,
  forgotPasswordRequest,
  forgotPassowrdSuccess,
  forgotPasswordFailed,
  changePasswordRequest,
  changePassowrdSuccess,
  changePasswordFailed,
  passwordValidateRequest,
  passowrdValidateSuccess,
  passwordValidateFailed,
  navigate,
} = userSlice.actions
