import React from 'react'
import { useHistory } from 'react-router'
import { Row, Col, Table } from 'antd'

import { routePath } from 'app/constants/router'
import { Columns, SearchCondition } from 'app/services/store/items/types'
import { filterListColumns } from 'app/utils/components/deliverySlip'
import {
  createDateFormattedItems,
  createFilterableAndSortableColumns,
  getCommonTableScrollSize,
  execSupplierFilter,
} from 'app/utils/components/common'
import { PagenationValue } from './types'
import { dateTypeKeyListOfDeliverySlip } from 'app/constants/deliverySlip'

interface DeliverySlipProps {
  sendChangePageHandle: (conditions, currentPage: number, sortFieldId: string, sortOrder: string) => void
  isDataReportLoading: boolean
  items: { [k: string]: any }[]
  columns: Columns
  pagination: PagenationValue
  isSupplier: boolean
}
const DeliverySlipPage = ({
  isDataReportLoading,
  items,
  columns,
  pagination,
  sendChangePageHandle,
  isSupplier,
}: DeliverySlipProps): JSX.Element => {
  const history = useHistory()

  const renderBrFormat = {
    render: function addAbbreviationText(text) {
      let displayText = text
      if (typeof text == 'string') {
        const targetCount = text.search('\n')
        displayText = targetCount < 0 ? text : text.substr(0, targetCount) + ' ...'
      }
      return <span>{displayText}</span>
    },
  }
  const formatColumns = columns.map(column => {
    return { ...column, ...renderBrFormat }
  })

  // サプライヤーの場合はサプライヤー検索に制限をかける
  let sortColumns = filterListColumns(formatColumns)
  if (isSupplier) sortColumns = execSupplierFilter(sortColumns, [{ title: 'サプライヤー', sortAndSearch: 'none' }])
  const columnsWithSearchAndSort = createFilterableAndSortableColumns(sortColumns)

  const formattedItems = createDateFormattedItems(items, dateTypeKeyListOfDeliverySlip)

  const showTotal = () => {
    return `Total ${pagination.totalCount} items`
  }

  const tableScrollSetting = getCommonTableScrollSize()

  return (
    <div>
      <Row>
        <Col>
          <h2>納品書</h2>
        </Col>
      </Row>
      <div className="tableArea">
        <Row style={{ width: '90vw' }}>
          <Col>
            <Table
              loading={isDataReportLoading}
              dataSource={formattedItems}
              pagination={{
                total: pagination.totalCount,
                showTotal: showTotal,
                defaultPageSize: pagination.defaultSize,
              }}
              columns={columnsWithSearchAndSort}
              onRow={record => {
                return {
                  onClick: (): void => {
                    if (record.items && record.items.length) {
                      history.push({
                        pathname: routePath.DELIVERY_SLIP_DETAIL,
                        state: {
                          iId: record.items[0]['i_id'] as string,
                          selectItems: record.items,
                        },
                      })
                    }
                  },
                }
              }}
              onChange={(page, filters, sorter) => {
                const currentPage = Number(page.current)
                const sortFieldId = sorter && sorter.column ? sorter.column['id'] : ''
                const sortOrder = sorter && sorter.order ? (sorter.order === 'ascend' ? 'asc' : 'desc') : ''
                const conditions = new Array<SearchCondition>()
                Object.entries(filters).forEach(([key, value]) => {
                  if (Array.isArray(value) && value.length > 0) {
                    const column = columns.find(colum => colum.dataIndex === key)
                    if (column != null) {
                      conditions.push({
                        id: column.id,
                        search_value: [value[0]],
                      })
                    }
                  }
                })
                sendChangePageHandle(conditions, currentPage, sortFieldId, sortOrder)
              }}
              scroll={{ y: tableScrollSetting['y'], x: tableScrollSetting['x'] }}
              tableLayout="initial"
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default DeliverySlipPage
