import { createSlice } from "redux-starter-kit";
import * as reducers from './reducers';
import { initialState } from "./state";


export const datastoresSlice = createSlice({
  slice: "datastores",
  initialState: initialState,
  reducers: {
    setCurrentDatastoreId: reducers.setCurrentDatastoreId,
    getDatastoresRequest: reducers.getDatastoresRequest,
    getDatastoresSuccess: reducers.getDatastoresSuccess,
    getDatastoresFailed: reducers.getDatastoresFailed,
    reset: reducers.reset
  }
});

export const {
  setCurrentDatastoreId,
  getDatastoresRequest,
  getDatastoresSuccess,
  getDatastoresFailed,
  reset
} = datastoresSlice.actions;
