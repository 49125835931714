import { Project } from "./types";

export interface ProjectState {
    project_list: Array<Project>;
    current_project_id: string;
    loading: boolean;
    error: string
}

export const initialState: ProjectState = {
    project_list : new Array<Project>(),
    current_project_id: '',
    loading: false,
    error: ''
};