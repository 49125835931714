import { createSelector } from 'reselect'

import { RootState } from '../rootReducer'
import { Datastore } from './types'
import { DatastoresState } from './state'

const datastoresState = (state: RootState) => state.datastores
const getDatastoreList = (state: RootState) => state.datastores.datastores_list

export const getDatastores = createSelector(getDatastoreList, (datastores: Array<Datastore>) => datastores)

export const getCurrentDatastoreId = createSelector(
  datastoresState,
  (datastoresState: DatastoresState) => datastoresState.current_datastore_id,
)
