import { ItemDetailsField, ItemDetailsAction, ItemDetailsOption } from 'app/services/store/itemDetails/types'
import { GroupMembers } from 'app/services/store/group/types'
import { Columns, ColumnsWithOrder } from 'app/services/store/items/types'
import {
  InventoryDetailList,
  InventoryDetailColumns,
  InventoryHistoryDetailColumns,
} from 'app/components/pages/inventory/types'
import { fiscal, getFiscalMonth, isRange } from 'app/utils/components/common'
import StringUtils from 'app/utils/stringUtils'
import Moment from 'moment'

type SupplierOption = {
  u_id: string
  username: string
}[]

type DataSource = {
  [key: string]: string | number
}[]

type historyData = {
  日付: []
  出庫: []
  仮出庫: []
  入庫: []
  有効残: []
  入力者: []
}[]

type temporaryData = {
  日付: []
  仮出庫: []
}[]

export const TABLE_DISPLAY_NAME = {
  ORDER_HISRTORY: '発注DB',
  INVENTORY_HISTORY: '入庫履歴',
  DELIVERY_HISRTORY: '出庫履歴',
  TEMP_HISRTORY: '仮出庫',
}

export const getCategoryOptions = (fields: { [k: string]: ItemDetailsField }): ItemDetailsOption[] | undefined => {
  const categoryFieldOptions = Object.values(fields).find(field => field.display_id === '分類')
  if (categoryFieldOptions) {
    return categoryFieldOptions.options
  }
  return []
}

export const getActionIdAndDid = (
  actions: ItemDetailsAction[],
  actionName: string,
): { a_id: string; d_id: string; name: string } | undefined => {
  const targetAction = actions ? actions.find(action => action.name === actionName) : ''
  if (targetAction) {
    return { a_id: targetAction.a_id, d_id: targetAction.d_id, name: targetAction.name }
  }
  return
}

export const getSupplierOptions = (supplierMembers: GroupMembers): SupplierOption =>
  supplierMembers.members.map(member => {
    return {
      u_id: member.u_id,
      username: member.username,
    }
  })

const labelCellWidth = (): Array<{}> => {
  return [
    { title: '在庫ID', width: 80 },
    { title: '在庫名', width: 150 },
    { title: '管理番号', width: 100 },
    { title: '現在庫', width: 80 },
    { title: '単位', width: 80 },
    { title: '色', width: 80 },
    { title: '保管場所', width: 100 },
    { title: '分類', width: 130 },
    { title: '基本単価', width: 80 },
    { title: '発注点', width: 80 },
    { title: '標準納期', width: 100 },
    { title: '設定在庫', width: 80 },
    { title: 'サプライヤー', width: 150 },
    { title: '在庫共有サプライヤー', width: 150 },
  ]
}
export const filterListColumns = (columns: Columns): Columns => {
  const labelsWidth = labelCellWidth()
  const resultColums = columns
    .filter(
      item =>
        item.title === '在庫ID' ||
        item.title === '在庫名' ||
        item.title === '管理番号' ||
        item.title === '現在庫' ||
        item.title === '単位' ||
        item.title === '色' ||
        item.title === '保管場所' ||
        item.title === '分類' ||
        item.title === '基本単価' ||
        item.title === '発注点' ||
        item.title === '標準納期' ||
        item.title === '設定在庫' ||
        item.title === 'サプライヤー' ||
        item.title === '在庫共有サプライヤー',
    )
    .map(item => {
      const data = labelsWidth.find(record => record['title'] == item.title)
      if (data) {
        item.width = data['width']
      }
      if (item.dataIndex !== item.key) {
        item.dataIndex = item.key
      }
      return item
    })
  return resultColums
}

export const sortInventoryColumns = (columns: Columns): ColumnsWithOrder => {
  columns.forEach((column): void => {
    if (column.key === '分類') {
      column['order'] = 0
    } else if (column.key === 'ID') {
      column['order'] = 1
    } else if (column.key === '管理番号') {
      column['order'] = 2
    } else if (column.key === '物品名') {
      column['order'] = 3
    } else if (column.key === '色') {
      column['order'] = 4
    } else if (column.key === '保管場所') {
      column['order'] = 5
    } else if (column.key === '在庫数') {
      column['order'] = 6
    } else if (column.key === '発注点 for Hexa') {
      column['order'] = 7
    } else if (column.key === '設定在庫') {
      column['order'] = 8
    } else if (column.key === '単位') {
      column['order'] = 9
    } else if (column.key === '標準納期') {
      column['order'] = 10
    } else if (column.key === '単価') {
      column['order'] = 11
    } else if (column.key === '第一発注先2') {
      column['order'] = 12
    } else if (column.key === '在庫共有先') {
      column['order'] = 13
    }
  })
  columns.sort((a, b): number => {
    if (a['order'] > b['order']) {
      return 1
    } else {
      return -1
    }
  })
  return columns as ColumnsWithOrder
}

export const sortInventoryHistoryColumns = (columns: Columns): ColumnsWithOrder => {
  columns.forEach((column): void => {
    if (column.key === 'ID') {
      column['order'] = 0
    } else if (column.key === '物品名') {
      column['order'] = 1
    } else if (column.key === '色') {
      column['order'] = 2
    } else if (column.key === '数量') {
      column['order'] = 3
    } else if (column.key === '現在庫') {
      column['order'] = 4
    } else if (column.key === '入庫日') {
      column['order'] = 5
    } else if (column.key === 'サプライヤー') {
      column['order'] = 6
    } else if (column.key === '入力者') {
      column['order'] = 7
    }
  })
  columns.sort((a, b): number => {
    if (a['order'] > b['order']) {
      return 1
    } else {
      return -1
    }
  })
  return columns as ColumnsWithOrder
}

export const sortDeliveryHistoryColumns = (columns: Columns): ColumnsWithOrder => {
  columns.forEach((column): void => {
    if (column.key === 'ID') {
      column['order'] = 0
    } else if (column.key === '物品名') {
      column['order'] = 1
    } else if (column.key === '色') {
      column['order'] = 2
    } else if (column.key === '数量') {
      column['order'] = 3
    } else if (column.key === '現在庫') {
      column['order'] = 4
    } else if (column.key === '出庫日') {
      column['order'] = 5
    } else if (column.key === '入力者') {
      column['order'] = 6
    }
  })
  columns.sort((a, b): number => {
    if (a['order'] > b['order']) {
      return 1
    } else {
      return -1
    }
  })
  return columns as ColumnsWithOrder
}

export const createUnifyDBsColumns = (inventoryHistoryColumns: InventoryDetailColumns): InventoryDetailColumns => {
  const unifiedDBsColumns = inventoryHistoryColumns.map(column => {
    if (column['title'] === '入庫日') {
      return {
        title: '入出庫日',
        dataIndex: '入出庫日',
        key: '入出庫日',
        id: column['id'],
      }
    }
    return column
  })
  return unifiedDBsColumns
}

export const sortUnifiedColumns = (columns: Columns): ColumnsWithOrder => {
  columns.forEach((column): void => {
    if (column.key === 'ID') {
      column['order'] = 0
    } else if (column.key === '物品名') {
      column['order'] = 1
    } else if (column.key === '色') {
      column['order'] = 2
    } else if (column.key === '数量') {
      column['order'] = 3
    } else if (column.key === '現在庫') {
      column['order'] = 4
    } else if (column.key === '入出庫日') {
      column['order'] = 5
    } else if (column.key === 'サプライヤー') {
      column['order'] = 6
    } else if (column.key === '入力者') {
      column['order'] = 7
    }
  })
  columns.sort((a, b): number => {
    if (a['order'] > b['order']) {
      return 1
    } else {
      return -1
    }
  })
  return columns as ColumnsWithOrder
}

export const addInAndOutHistoryForItems = (items: DataSource): DataSource => {
  const addedItems = items.map(item => {
    if (items) {
      return {
        ...item,
        入出庫日: item['入庫日'] ? item['入庫日'] : item['出庫日'],
      }
    }
    return []
  })
  return addedItems as DataSource
}

const getDaysArray = (start, end) => {
  const dataArray: string[] = []
  for (let arr: string[] = [], dt = start; dt <= end; dt.setDate(dt.getDate() + 1)) {
    const date = Moment(new Date(dt).toISOString()).format('YYYY/MM/DD')
    dataArray.push(date)
  }
  return dataArray
}

const createBlankDataSource = (month: number) => {
  const current = new Date()
  const daylist = getDaysArray(new Date(), current.setMonth(current.getMonth() + month))
  const blankDataSource = {}
  daylist.map(date => {
    blankDataSource[date] = { count: 0 }
  })
  return blankDataSource
}

export const createInventoryHistoryColumns = (dataSource: DataSource, year: string | null) => {
  const currentYear = Moment(new Date().toISOString()).format('YYYY')
  const current = Moment(new Date().toISOString())
  const currentDate = current.format('YYYY/MM/DD')
  const dataSourceMap = {}

  for (const { d_date } of dataSource) {
    if (d_date) {
      dataSourceMap[d_date] = {
        count: dataSourceMap[d_date] ? dataSourceMap[d_date].count + 1 : 1,
      }
    }
  }

  let createBlankCount = 3
  const key = Object.keys(dataSourceMap)
  if (key.length > 0) {
    const recodeDate = Moment(key.pop()).add(3, 'M')
    const diff = current.diff(recodeDate, 'months')
    createBlankCount = Math.abs(diff)
  }

  const blankListSource = year == null || year == currentYear ? createBlankDataSource(createBlankCount) : {}
  const joinDtaSourceMap: object = { ...blankListSource, ...dataSourceMap }

  const dateColumns: Array<InventoryHistoryDetailColumns> = []

  for (const date in joinDtaSourceMap) {
    const isEdit = currentDate < date

    const columObj = {
      title: String(date),
      dataIndex: date,
      key: date,
      width: 240,
      editable: isEdit,
      children: [
        {
          title: '数量',
          dataIndex: date + '.count',
          key: date + '.count',
          width: 80,
          children: undefined,
          editable: isEdit,
        },
        {
          title: '管理No. / サプライヤー',
          dataIndex: date + '.no',
          key: date + '.no',
          width: 160,
          children: undefined,
          editable: isEdit,
        },
      ],
    }
    dateColumns.push(columObj)
  }

  dateColumns.sort((a, b): number => {
    if (a.title > b.title) {
      return 1
    } else {
      return -1
    }
  })
  const categoryColumn = {
    title: '',
    dataIndex: 'category',
    key: 'category',
    width: 80,
    fixed: 'left',
  }
  const totalColumn = {
    title: '合計',
    dataIndex: 'total',
    key: 'total',
    width: 80,
    fixed: 'right',
  }

  return [categoryColumn, ...dateColumns, totalColumn]
}

export const filteredOrderItems = (items: DataSource, orderStatuses?: { [k: string]: any }[]): DataSource => {
  const finishStatus =
    orderStatuses !== undefined && orderStatuses.length > 0
      ? orderStatuses.find(status => status.name === '入荷済')
      : undefined
  const currentDate = Moment(new Date().toISOString()).format('YYYY/MM/DD')
  const addedItems = items
    .filter(item => {
      if (finishStatus !== undefined && item['status_id'] === finishStatus.id) {
        return false
      }
      if (items && (item['回答納期'] || item['希望納期'])) {
        const compareDate = item['回答納期'] ? item['回答納期'] : item['希望納期']
        if (compareDate > currentDate) {
          return true
        }
      }
      return false
    })
    .map(item => {
      const dispalyDate = item['回答納期'] ? item['回答納期'] : item['希望納期']
      const key_count = dispalyDate + '.count'
      const key_no = dispalyDate + '.no'
      item[key_count] = item['回答納期'] ? item['発注個数'] : '(' + item['発注個数'] + ')'
      item[key_no] = item['発注先']
      return {
        ...item,
        入出庫日: dispalyDate,
        d_date: dispalyDate,
        count: item['発注個数'],
      }
    })
  return addedItems as DataSource
}

export const filteredInventoryItems = (items: DataSource): DataSource => {
  const currentDate = Moment(new Date().toISOString()).format('YYYY/MM/DD')
  const addedItems = items
    .filter(item => {
      if (items && item['入庫日']) {
        if (item['入庫日'] <= currentDate) {
          return true
        }
      }
      return false
    })
    .map(item => {
      const dispalyDate = item['入庫日']
      const key_count = dispalyDate + '.count'
      const key_no = dispalyDate + '.no'
      item[key_count] = item['数量']
      item[key_no] = item['管理番号']
      return {
        ...item,
        入出庫日: dispalyDate,
        d_date: dispalyDate,
        count: item['数量'],
      }
    })
  return addedItems as DataSource
}

export const filteredDeliveryItems = (items: DataSource): DataSource => {
  const currentDate = Moment(new Date().toISOString()).format('YYYY/MM/DD')
  const addedItems = items
    .filter(item => {
      if (items && item['出庫日']) {
        if (item['出庫日'] <= currentDate) {
          return true
        }
      }
      return false
    })
    .map(item => {
      const dispalyDate = item['出庫日']
      const key_count = dispalyDate + '.count'
      const key_no = dispalyDate + '.no'
      item[key_count] = item['数量']
      item[key_no] = item['管理番号']
      return {
        ...item,
        入出庫日: dispalyDate,
        d_date: dispalyDate,
        count: item['数量'],
      }
    })
  return addedItems as DataSource
}

interface rowData {
  row: string
  count?: string
  no?: string
}
interface JSONData {
  [k: string]: Array<rowData>
}
export const filteredTempHistoryItems = (items: DataSource): DataSource => {
  if (!items.length) return []

  const resultArray: Array<{}> = []
  const jsonData: JSONData = JSON.parse(String(items[0]['JSONデータ']))
  const dateArray: string[] = Object.keys(jsonData)
  const currentDate = Moment(new Date().toISOString()).format('YYYY/MM/DD')

  if (!dateArray.length) return []

  for (const i in dateArray) {
    const date = dateArray[i]
    if (date <= currentDate) continue

    const keyCount = date + '.count'
    const keyNo = date + '.no'
    for (let index = 0; index < jsonData[date].length; index++) {
      const dataRowNo = jsonData[date][index]['row']
      const rowData = {}
      rowData[keyCount] = jsonData[date][index]['count']
      rowData[keyNo] = jsonData[date][index]['no']
      rowData['dName'] = '仮出庫'
      rowData['key'] = dataRowNo
      if (resultArray[dataRowNo]) {
        resultArray[dataRowNo] = { ...resultArray[dataRowNo], ...rowData }
      } else {
        resultArray.push(rowData)
      }
    }
  }

  return resultArray as DataSource
}

export const findInventoryCount = (datas, isUpper?: boolean) => {
  const dataList = [...datas]
  const filterData = dataList.filter(item => {
    return item.現在庫 ? true : false
  })
  filterData.sort((a, b) => {
    const isSort =
      isUpper !== undefined && isUpper === true
        ? Moment(a['d_date']).isBefore(Moment(b['d_date']))
        : Moment(a['d_date']).isAfter(Moment(b['d_date']))
    if (isSort) {
      return 1
    } else {
      return -1
    }
  })
  if (!filterData.length) return {}

  const duplicate = filterData
    .filter(item => item.d_date == filterData[0].d_date)
    .sort((a, b) => {
      if (Moment(a['created_at']).isBefore(Moment(b['created_at']))) {
        return 1
      } else {
        return -1
      }
    })

  return { date: duplicate[0]['d_date'], count: Number(duplicate[0]['現在庫']), i_id: duplicate[0]['i_id'] }
}

const getFilterYear = (dataSource, range, isThrough?) => {
  if (isThrough !== undefined && isThrough) {
    return dataSource
  }
  const dataList = [...dataSource]
  const filterData = dataList.filter(item => {
    const itemDate = Moment(item.d_date)
    const startDate = Moment(range.start)
    const endDate = Moment(range.end)
    if (itemDate.isBetween(startDate, endDate)) {
      return true
    } else {
      return false
    }
  })
  return filterData
}

const isCurrentYear = targetYear => {
  const currentYear = Moment().format('YYYY')
  return currentYear == targetYear
}

export const getStartYear = (momentDate: Moment.Moment): string => {
  if (isRange(momentDate.format('M'), getFiscalMonth('start'), 12)) {
    return momentDate.format('YYYY')
  }
  if (isRange(momentDate.format('M'), 1, getFiscalMonth('end'))) {
    return momentDate.subtract(1, 'years').format('YYYY')
  }
  return momentDate.format('YYYY')
}

export const createYearSelectOption = (
  orderColumns: Columns,
  orderHistoryItems: DataSource,
  inventoryHistoryItems: DataSource,
  deliveryHistoryItems: DataSource,
  currentYear: string,
) => {
  const orderStatuses = orderColumns.find(field => field.statuses !== undefined)
  const statuses = orderStatuses !== undefined ? orderStatuses.statuses : []
  const formedOrderHistoryItems = filteredOrderItems(orderHistoryItems, statuses)
  const formedInventoryHistoryItems = filteredInventoryItems(inventoryHistoryItems)
  const formedDeliveryHistoryItems = filteredDeliveryItems(deliveryHistoryItems)

  const selectOptions = {}
  const joinData = [...formedOrderHistoryItems, ...formedInventoryHistoryItems, ...formedDeliveryHistoryItems]
  joinData.forEach(item => {
    const year = getStartYear(Moment(item['d_date']))
    if (currentYear >= year) {
      selectOptions[year] =
        currentYear == year
          ? `${year}年度`
          : `${year}年度( ${year}/${fiscal.start}～${Number(year) + 1}/${fiscal.end} )`
    }
  })

  // 今年度が存在しない場合追加
  const thisYear = getStartYear(Moment())
  if (selectOptions[thisYear] === undefined) {
    selectOptions[thisYear] = `${thisYear}年度`
  }

  return Object.entries(selectOptions)
    .map(([key, value]) => ({ key, value }))
    .sort((a, b) => {
      if (a['key'] < b['key']) {
        return 1
      } else {
        return -1
      }
    })
}

export const unifyDBsItems = (
  orderColumns: Columns,
  orderHistoryItems: DataSource,
  inventoryHistoryItems: DataSource,
  deliveryHistoryItems: DataSource,
  tempHistoryItems: DataSource,
  targetYear: string | null,
  currentYear?: string | null,
): DataSource => {
  const range: object = {}
  if (targetYear != null) {
    range['start'] = `${targetYear}/${fiscal.start}`
    range['end'] = `${Number(targetYear) + 1}/${fiscal.end}`
  }

  const orderStatuses = orderColumns.find(field => field.statuses !== undefined)
  const statuses = orderStatuses !== undefined ? orderStatuses.statuses : []
  let formedOrderHistoryItems = filteredOrderItems(orderHistoryItems, statuses)
  let formedInventoryHistoryItems = filteredInventoryItems(inventoryHistoryItems)
  let formedDeliveryHistoryItems = filteredDeliveryItems(deliveryHistoryItems)
  const formedTempHistoryItems = isCurrentYear(currentYear) ? filteredTempHistoryItems(tempHistoryItems) : []

  // 年度チェック
  if (range['start'] && range['end']) {
    const startDate = Moment(range['start'])
    const endDate = Moment(range['end'])
    let flg = false
    if (Moment().isBetween(startDate, endDate)) {
      flg = true
    }
    formedOrderHistoryItems = getFilterYear(formedOrderHistoryItems, range, flg)
    formedInventoryHistoryItems = getFilterYear(formedInventoryHistoryItems, range)
    formedDeliveryHistoryItems = getFilterYear(formedDeliveryHistoryItems, range)
  }

  let inventoryItems = [
    ...formedDeliveryHistoryItems,
    ...formedTempHistoryItems,
    ...formedInventoryHistoryItems,
    ...formedOrderHistoryItems,
  ] as DataSource
  let findFirstDeliveryItem = false
  let findFirstInventoryItem = false
  let findFirstTempItem = false
  for (let i = 0; i < inventoryItems.length; i++) {
    if (!findFirstDeliveryItem && inventoryItems[i].dName === TABLE_DISPLAY_NAME.DELIVERY_HISRTORY) {
      findFirstDeliveryItem = true
      inventoryItems[i].category = '出庫'
    } else if (
      !findFirstInventoryItem &&
      (inventoryItems[i].dName === TABLE_DISPLAY_NAME.INVENTORY_HISTORY ||
        inventoryItems[i].dName === TABLE_DISPLAY_NAME.ORDER_HISRTORY)
    ) {
      findFirstInventoryItem = true
      inventoryItems[i].category = '入庫'
    } else if (!findFirstTempItem && inventoryItems[i].dName === TABLE_DISPLAY_NAME.TEMP_HISRTORY) {
      findFirstTempItem = true
      inventoryItems[i].category = '仮出庫'
    }
    inventoryItems[i].key_index = i
    inventoryItems[i].total = 0
  }

  if (!findFirstTempItem && currentYear !== undefined && currentYear !== null && isCurrentYear(currentYear)) {
    const tempItem = { category: '仮出庫' }
    tempItem['key'] = StringUtils.GenUUID()
    tempItem['dName'] = TABLE_DISPLAY_NAME.TEMP_HISRTORY
    inventoryItems = [
      ...formedDeliveryHistoryItems,
      tempItem,
      ...formedInventoryHistoryItems,
      ...formedOrderHistoryItems,
    ] as DataSource
  }

  return inventoryItems
}

export const getTotalCountItems = (
  dataSource: DataSource,
  columns: Array<InventoryHistoryDetailColumns>,
  initial?: { [k: string]: any | number | undefined },
): DataSource => {
  const dataSourceMap = {}
  const initialVal = initial === undefined || initial['date'] === undefined ? findInventoryCount(dataSource) : initial

  if (initialVal['date']) {
    dataSourceMap[initialVal['date']] = { count: initialVal['count'] }
  }

  for (const index in dataSource) {
    if (!dataSource[index].hasOwnProperty('dName')) continue

    const keys = Object.keys(dataSource[index])
    const countKeyList: string[] = keys.filter(value => value.includes('.count'))
    const isOutPut: boolean = String(dataSource[index]['dName']).includes('出庫')

    // フィルタ・再計算アクセス時の為列合計初期化
    dataSource[index]['total'] = 0

    countKeyList.forEach(keyStr => {
      const d_date = keyStr.substr(0, 10)
      const count = String(dataSource[index][keyStr])
        .replace('(', '')
        .replace(')', '')

      if (isNaN(Number(count))) return

      // 行の合計を計算
      dataSource[index]['total'] = Number(dataSource[index]['total']) + Number(count)
      // 基準値は有効残計算に含めない
      if (dataSource[index]['i_id'] == initialVal['i_id'] && initialVal['date'] == d_date) return

      let saveCount = 0
      if (isOutPut) {
        saveCount = dataSourceMap[d_date] ? Number(dataSourceMap[d_date].count) - Number(count) : -Number(count)
      } else {
        saveCount = dataSourceMap[d_date] ? Number(dataSourceMap[d_date].count) + Number(count) : Number(count)
      }
      dataSourceMap[d_date] = { count: saveCount }
    })
  }

  const totalItem = { key: StringUtils.GenUUID(), category: '有効残' }
  // 前日分の計を格納する
  let previousDayCount = 0
  for (let i = 0; i < columns.length; i++) {
    const date = columns[i].key
    if (dataSourceMap[date]) {
      const key_count = date + '.count'
      // 控えておいた前日分を合算
      totalItem[key_count] = Number(dataSourceMap[date].count) + previousDayCount
      previousDayCount = Number(dataSourceMap[date].count) + previousDayCount
    }
  }
  return [totalItem] as DataSource
}

export const getErrstr = (type: string, label: string, count = ''): string => {
  if (type == null || label == null) return ''

  let baseStr = ''
  switch (type) {
    case 'required':
      baseStr = '%s は入力必須です。'
      break
    case 'number':
      baseStr = '%s は数値で入力して下さい。'
      break
    case 'max_number':
      baseStr = '%s は最大%d文字までです。'
      break
    default:
      break
  }
  return baseStr === '' ? '' : baseStr.replace('%s', label).replace('%d', count)
}

// 入出庫履歴初期値反映
// 1、表示中年度に前日までの履歴が存在したら評価しない
// 2、表示中以前の年度が存在した場合初期値に設定
// 3、いずれも当てはまらない場合本日付けで在庫数設定
export const getInitial = (
  orderColumns: Columns,
  orderHistoryItems: DataSource,
  inventoryHistoryItems: DataSource,
  deliveryHistoryItems: DataSource,
  tempHistoryItems: DataSource,
  targetYear: string | null,
  options: { [k: string]: string | any }[],
  items: DataSource,
  currentDetailList: InventoryDetailList,
) => {
  // 過去分が存在する場合空を返却
  let flag = false
  for (const key of items) {
    if (key.d_date != null) {
      const date = Moment(key.d_date)
      const now = Moment()
      if (date.isBefore(now)) {
        flag = true
      }
    }
  }
  if (flag) return {}

  // 以前の年度分取得
  const selectKey = options.findIndex(v => v.key == targetYear)
  if (selectKey > -1 && options[selectKey + 1] !== undefined) {
    const datas = unifyDBsItems(
      orderColumns,
      orderHistoryItems,
      inventoryHistoryItems,
      deliveryHistoryItems,
      tempHistoryItems,
      options[selectKey + 1].key,
    )
    const result = findInventoryCount(datas, true)
    return { ...result, ...{ date: Moment().format('YYYY/MM/DD') } }
  }

  return { date: Moment().format('YYYY/MM/DD'), count: currentDetailList['在庫数'] }
}
