import React from 'react'

import { useSelector } from 'react-redux'

import { Row } from 'antd'
import { MainSelectors, WorkspaceSelectors } from 'app/services/store'

import './preloader.css'
import { RootState } from 'app/services/store/rootReducer'

function Preloader(): JSX.Element {
  const loadingMain = useSelector((state: RootState) => MainSelectors.getMainLoading(state))
  const loadingWorkspace = useSelector((state: RootState) => WorkspaceSelectors.getWorkspaceIsLoading(state))
  return (
    <div className={loadingMain || loadingWorkspace ? 'preloader' : 'preloader-hide'}>
      <Row type="flex" justify="center" align="middle" style={{ height: '100vh' }}></Row>
    </div>
  )
}

export default Preloader
