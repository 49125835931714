import React, { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Menu } from 'antd'

import { rootSelector } from 'app/services/store/rootReducer'
import { UsersSelectors } from 'app/services/store'
import { routePath } from 'app/constants/router'

const { Item } = Menu

const PrimaryMenu = (): JSX.Element => {
  const history = useHistory()
  const isSupplier = UsersSelectors.isSupplier(useSelector(rootSelector))

  const [selected, setSelected] = useState(isSupplier ? routePath.ORDER_LIST : routePath.FAVORITE)

  useEffect(() => {
    if (selected !== history.location.pathname) {
      const pathnameOfCategory = '/' + history.location.pathname.split('/')[1]
      setSelected(pathnameOfCategory)
    }
  }, [history.location.pathname, selected])

  const handleChangeMenu = useCallback(
    event => {
      const { key } = event
      setSelected(key)
      history.push(key)
    },
    [history],
  )

  if (isSupplier) {
    return (
      <div className="gnav">
        <Menu selectedKeys={[selected]} mode="horizontal" onClick={handleChangeMenu}>
          <Item key={routePath.ORDER_LIST}>
            <span>発注</span>
          </Item>
          <Item key={routePath.DELIVERY_SLIP}>
            <span>納品書</span>
          </Item>
        </Menu>
      </div>
    )
  }

  return (
    <div className="gnav">
      <Menu selectedKeys={[selected]} mode="horizontal" onClick={handleChangeMenu}>
        <Item key={routePath.FAVORITE}>
          <span>よく使う</span>
        </Item>
        <Item key={routePath.INVENTORY_LIST}>
          <span>在庫</span>
        </Item>
        <Item key={routePath.ORDER_LIST}>
          <span>発注</span>
        </Item>
        <Item key={routePath.DELIVERY_SLIP}>
          <span>納品書</span>
        </Item>
      </Menu>
    </div>
  )
}

export default PrimaryMenu
