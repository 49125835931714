import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { rootSelector } from 'app/services/store/rootReducer'
import { datastoreSelector } from 'app/services/store/datastore/reducers'
import {
  DatastoreActions,
  GroupSelectors,
  ItemsSelectors,
  ItemsDetailsActions,
  UsersSelectors,
  ItemsActions,
} from 'app/services/store'

import { getDatastoreIdFromName } from 'app/utils/store'
import { hexaLabel } from 'app/constants/hexalink'
import { inventoryColumnNameLabel } from 'app/constants/inventory'
import { Columns } from 'app/services/store/items/types'

import AppLayout from 'app/components/templates/AppLayout'
import InventoryPage from 'app/components/pages/inventory/InventoryPage'
import { InventoryDetailList } from 'app/components/pages/inventory/types'
import {
  getItemIdFromKey,
  getSelectField,
  mergeHeaderSearchCondition,
  changeConditionRule,
} from 'app/utils/components/common'
import { SearchCondition } from 'app/services/store/items/types'

const InventoryContainer = (): JSX.Element => {
  const dispatch = useDispatch()
  const itemsLoading = ItemsSelectors.getItemsIsLoading(useSelector(rootSelector))
  const displayableEntries = ItemsSelectors.getDisplayableEntries(useSelector(rootSelector))
  const { datastores_list } = useSelector(datastoreSelector)
  const { items, columns } = displayableEntries
  const totalItems = ItemsSelectors.getTotalItems(useSelector(rootSelector))
  const isShiroyama = UsersSelectors.isShiroyama(useSelector(rootSelector))
  const supplierMembers = GroupSelectors.getSupplierGroupMembers(useSelector(rootSelector))
  const supplierFIeldId = getItemIdFromKey<Columns, keyof InventoryDetailList>(
    columns,
    inventoryColumnNameLabel.PRIMARY_SUPPLIER,
  )
  const sharingSupplierFIeldId = getItemIdFromKey<Columns, keyof InventoryDetailList>(
    columns,
    inventoryColumnNameLabel.PARTNER_WHO_SHARES_INVENTORY,
  )
  const selectField = ItemsSelectors.getSelectField(useSelector(rootSelector))

  const getItemDetail = useCallback(
    (itemId: string) => {
      dispatch(ItemsDetailsActions.getItemDetailsRequest({ itemId }))
    },
    [dispatch],
  )

  useEffect(() => {
    if (datastores_list) {
      dispatch(
        DatastoreActions.setCurrentDatastoreId({
          datastoreId: getDatastoreIdFromName(datastores_list, hexaLabel.INVENTORY_DB),
        }),
      )
    }
  }, [dispatch, datastores_list])

  // 特定フィールドはsearch_valueにIDを含まないといけない為フロントにてあいまい検索実装
  const filterCondition = (conditions: Array<SearchCondition>) => {
    // 分類検索
    const classField = getSelectField(selectField, inventoryColumnNameLabel.CATEGORY)
    if (classField !== undefined && classField.options.length > 0) {
      conditions = [...mergeHeaderSearchCondition(conditions, classField.field, classField.options, 'value', 'o_id')]
    }
    // サプライヤー検索 - 第一発注先2
    conditions = [
      ...mergeHeaderSearchCondition(conditions, supplierFIeldId, supplierMembers.members, 'username', 'u_id'),
    ]
    // サプライヤー検索 - 在庫共有先
    conditions = [
      ...mergeHeaderSearchCondition(conditions, sharingSupplierFIeldId, supplierMembers.members, 'username', 'u_id'),
    ]

    return conditions
  }

  const updateSearchParams = (page: number, fieldId: string, order: string, conditions: Array<SearchCondition>) => {
    // 検索条件にフィルタをかける
    conditions = filterCondition(conditions)
    dispatch(ItemsActions.setSearchParams({ page: page, fieldId: fieldId, order: order, conditions: conditions }))
  }

  return (
    <AppLayout>
      <InventoryPage
        getItemDetail={getItemDetail}
        updateSearchParams={updateSearchParams}
        itemsLoading={itemsLoading}
        items={items}
        totalItmes={totalItems}
        columns={columns}
        isShiroyama={isShiroyama}
      />
    </AppLayout>
  )
}

export default InventoryContainer
