import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useLocation } from 'react-router'

import { rootSelector } from 'app/services/store/rootReducer'
import AppLayout from 'app/components/templates/AppLayout'
import DeliverySlipCreatePage from 'app/components/pages/deliverySlip/DeliverySlipCreatePage'
import { Columns } from 'app/services/store/items/types'

import { hexaLabel } from 'app/constants/hexalink'
import { datastoreSelector } from 'app/services/store/datastore/reducers'
import {
  DatastoreActions,
  ItemsSelectors,
  ItemDetailsSelectors,
  ItemsDetailsActions,
  ItemsActions,
  UsersActions,
  UsersSelectors,
} from 'app/services/store'
import { getDatastoreIdFromName } from 'app/utils/store'
import { routePath } from 'app/constants/router'
import { isTypeOperatorNode } from 'typescript'
import { of, Observable, EMPTY, concat, forkJoin } from 'rxjs'
import { deliverySlipActionID } from 'app/constants/hexalink'
import Moment from 'moment'

export interface Item {
  [k: string]: any
}
const dateFormat = 'YYYY-MM-DD'

const DeliverySlipCreateContainer = (): JSX.Element => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { iId } = location.state as { iId: string }
  const itemsLoading = ItemsSelectors.getItemsIsLoading(useSelector(rootSelector))
  const isItemsLoading = ItemsSelectors.getItemsIsLoading(useSelector(rootSelector))
  const isItemDetailsLoading = ItemDetailsSelectors.getIsLoading(useSelector(rootSelector))
  const { selectedCheckBoxDeliveryItemRowKeys } = location.state as { selectedCheckBoxDeliveryItemRowKeys: [] }
  const { selectedCheckBoxDeliveryItemRows } = location.state as { selectedCheckBoxDeliveryItemRows: Columns }
  const { datastores_list } = useSelector(datastoreSelector)
  const displayableEntries = ItemsSelectors.getDisplayableEntries(useSelector(rootSelector))
  const { items, columns } = displayableEntries
  const autoNumberItem = ItemDetailsSelectors.getAutoNumberItem(useSelector(rootSelector))
  const dataStoreItemFields = ItemDetailsSelectors.getDataStoreItemFields(useSelector(rootSelector))
  const getUserId = UsersSelectors.getUserId(useSelector(rootSelector))

  let slipDataStoreInfo

  let slipDBField
  let orderDataStoreInfo

  const submit = useCallback(
    form => {
      form.validateFields((err, values) => {
        if (err) {
          window.scroll({ top: 60, behavior: 'smooth' })
          return
        }

        const dbFields: { [k: string]: string } = {}
        Object.keys(dataStoreItemFields as Record<string, any>).forEach(function(key) {
          if (dataStoreItemFields && dataStoreItemFields[key] != null) {
            dbFields[dataStoreItemFields[key]['name']] = dataStoreItemFields[key]['field_id']
          }
        })

        // 1.納品書番号
        const slipNumber = autoNumberItem
        // 2.納品日
        const slipDate = Moment(values['納品日']).format('YYYY-MM-DD')
        // 3.送付先
        const sendType = values['送付先']
        // 4.住所
        const adress = values['住所']
        // 5.電話番号
        const tel = values['電話番号']

        const DeliveryItems: {}[] = []
        const sendItems: {}[] = []
        selectedCheckBoxDeliveryItemRows.forEach(element => {
          const row: Item = {
            i_id: element['i_id'],
            itemid: element['ID'],
            Name: element['物品名'],
            Num: element['発注個数'],
            AnswerDate: element['回答納期'],
            AnswerDateLink: element['回答納期（リンク用）'],
            出荷日: element['出荷日'],
            発注先: element['発注先'],
            発注先ID: element['発注先ID'],
          }
          DeliveryItems.push(row)
        })

        for (let i = 0; i < DeliveryItems.length; i++) {
          const baseArray: Item = {
            Remarks: values['Remarks' + DeliveryItems[i]['i_id']],
            unit: values['unit' + DeliveryItems[i]['i_id']],
          }
          sendItems.push(baseArray)
        }

        /* 第2フェーズ用納品書作成API */
        slipDBField = dataStoreItemFields
        const field_idArray = {}
        // プロパティ名と値の表示：forEachを使う方法
        Object.keys(slipDBField).forEach(function(key) {
          field_idArray[slipDBField[key].name] = slipDBField[key].field_id
        })

        slipDataStoreInfo = datastores_list.filter(element => {
          return element['name'] === '納品書'
        })

        orderDataStoreInfo = datastores_list.filter(element => {
          return element['name'] === '発注DB'
        })

        let lastFlag = false
        for (let z = 0; z < DeliveryItems.length; z++) {
          const item = {}
          //    '納品番号のフィールドID': 'XXXX-XXXX',
          item[field_idArray['納品書番号']] = slipNumber
          //    '納品日のフィールドID': '2020-10-23',
          item[field_idArray['納品日']] = slipDate
          //    '送付先のフィールドID': 'セレクトボックスで選んだ内容',
          item[field_idArray['送付先']] = sendType
          //    '住所のフィールドID': '入力された住所',
          item[field_idArray['住所']] = adress
          //    '電話番号のフィールドID': '入力された電話番号',
          item[field_idArray['電話番号']] = tel
          //    '在庫IDのフィールドID': '在庫ID',
          item[field_idArray['在庫ID']] = DeliveryItems[z]['itemid']
          //    '在庫名のフィールドID': '在庫名',
          item[field_idArray['在庫名']] = DeliveryItems[z]['Name']
          //    '備考のフィールドID': '空欄の場合もある',
          item[field_idArray['備考']] = sendItems[z]['Remarks']
          //    '発注個数のフィールドID': '発注個数',
          item[field_idArray['発注個数']] = Number(DeliveryItems[z]['Num'])
          item[field_idArray['発注個数(テキスト)']] = String(DeliveryItems[z]['Num'])
          //    '単位のフィールドID': '単位で入力した値',
          item[field_idArray['単位']] = sendItems[z]['unit']
          //    発注DBから転記
          item[field_idArray['回答納期']] = DeliveryItems[z]['AnswerDate']
          item[field_idArray['回答納期(テキスト)']] = DeliveryItems[z]['AnswerDate']
          item[field_idArray['回答納期（リンク用）']] = DeliveryItems[z]['AnswerDateLink']
          item[field_idArray['サプライヤーID']] = DeliveryItems[z]['発注先ID']
          item[field_idArray['出荷日']] = DeliveryItems[z]['出荷日']

          // サプライヤーは別途ログインサプライヤー追加
          item[field_idArray['サプライヤー']] = [getUserId]

          // 最後に登録するアイテムはaction_Idでステータス変更を行う為、フラグをtrueにする
          if (DeliveryItems.length - 1 == z) {
            lastFlag = true
          }

          // QRコード保存
          item[field_idArray['QRコード']] = orderDataStoreInfo[0]['d_id'] + '/' + DeliveryItems[z]['i_id']

          dispatch(
            ItemsDetailsActions.createItemRequest({
              dId: slipDataStoreInfo[0]['d_id'],
              item,
              navigatePath: undefined,
              lastFlag: lastFlag,
              action_Id: deliverySlipActionID.DELIVERYSLIP_CREATED,
            }),
          )
        }

        for (let orderIndex = 0; orderIndex < selectedCheckBoxDeliveryItemRows.length; orderIndex++) {
          const orderItem = {}
          orderItem['納品書番号'] = slipNumber
          // QRコード保存
          orderItem['QRコード'] =
            orderDataStoreInfo[0]['d_id'] + '/' + selectedCheckBoxDeliveryItemRows[orderIndex]['i_id']

          dispatch(
            ItemsDetailsActions.updateItem_v0_Request({
              dId: orderDataStoreInfo[0]['d_id'],
              item: orderItem,
              i_id: selectedCheckBoxDeliveryItemRows[orderIndex]['i_id'],
            }),
          )
        }
        // 画面遷移
        dispatch(UsersActions.navigate({ path: routePath.ORDER_LIST }))
      })
    },
    [dispatch, autoNumberItem, dataStoreItemFields, selectedCheckBoxDeliveryItemRows],
  )

  const getAutoNumber = useCallback(
    (field_Id: string, supplier_Id: string) => {
      dispatch(ItemsDetailsActions.getAutoNumberRequest({ field_Id, supplier_Id }))
    },
    [dispatch],
  )

  const getDataStoreItemFields = useCallback(
    (d_Id: string) => {
      dispatch(ItemsDetailsActions.getDatastoreFieldsRequest({ d_Id }))
    },
    [dispatch],
  )

  useEffect(() => {
    if (datastores_list) {
      dispatch(
        DatastoreActions.setCurrentDatastoreId({
          datastoreId: getDatastoreIdFromName(datastores_list, hexaLabel.ORDER_DB),
        }),
      )
    }

    // 納品書番号のフィールド一覧を取得
    let serialNumber = ''
    let supplier_Id = ''
    columns.forEach(function(columns, index) {
      if ('連番' == columns.dataIndex) {
        serialNumber = columns.id
      }
    })
    supplier_Id = selectedCheckBoxDeliveryItemRows[0]['発注先ID']
    getAutoNumber(serialNumber, supplier_Id)
    // 納品書DBのフィールド一覧を取得
    slipDataStoreInfo = datastores_list.filter(element => {
      return element['name'] === '納品書'
    })
    getDataStoreItemFields(slipDataStoreInfo[0]['d_id'])
  }, [dispatch, datastores_list])

  const deliverySlipCreateResource = {
    getAutoNumber: getAutoNumber,
    itemsLoading,
    isLoading: isItemsLoading || isItemDetailsLoading,
    selectedCheckBoxDeliveryItemRowKeys,
    selectedCheckBoxDeliveryItemRows,
    submit,
    items: items,
    columns: columns,
    autoNumberItem: autoNumberItem,
    dataStoreItemFields: dataStoreItemFields,
  }

  return (
    <AppLayout>
      <DeliverySlipCreatePage deliverySlipCreateResource={deliverySlipCreateResource} />
    </AppLayout>
  )
}

export default DeliverySlipCreateContainer
