import React from 'react'
import { Switch } from 'react-router-dom'

import SessionAlert from 'app/components/utils/sessionAlert/sessionAlert'
import PrivateRoute from 'app/config/router/shared/privateRoute'
import { routePath } from 'app/constants/router'

import InventoryContainer from 'app/container/inventory/InventoryContainer'
import InventoryDetailRouteContainer from 'app/container/inventory/InventoryDetailRouteContainer'
import InventoryDetailContainer from 'app/container/inventory/InventoryDetailContainer'
import InventoryCreateContainer from 'app/container/inventory/InventoryCreateContainer'
import OrderContainer from 'app/container/order/OrderContainer'
import OrderDetailContainer from 'app/container/order/OrderDetailContainer'
import FavoriteContainer from 'app/container/favorite/FavoriteContainer'
import DeliverySlipContainer from 'app/container/deliverySlip/DeliverySlipContainer'
import DeliverySlipDetailContainer from 'app/container/deliverySlip/DeliverySlipDetailContainer'

const ShiroyamaRouter = (): JSX.Element => {
  return (
    <Switch>
      <PrivateRoute exact path={routePath.ORDER_LIST} component={OrderContainer} />
      <PrivateRoute exact path={`${routePath.ORDER_DETAIL}`} component={OrderDetailContainer} />
      <PrivateRoute exact path={routePath.DELIVERY_SLIP} component={DeliverySlipContainer} />
      <PrivateRoute exact path={`${routePath.DELIVERY_SLIP_DETAIL}`} component={DeliverySlipDetailContainer} />
      <PrivateRoute exact path={`${routePath.FAVORITE}`} component={FavoriteContainer} />
      <PrivateRoute exact path={routePath.INVENTORY_LIST} component={InventoryContainer} />
      <PrivateRoute exact path={`${routePath.INVENTORY_DETAIL}`} component={InventoryDetailRouteContainer} />
      <PrivateRoute exact path={`${routePath.INVENTORY_HISTORY}`} component={InventoryDetailContainer} />
      <PrivateRoute exact path={`${routePath.INVENTORY_CREATE}`} component={InventoryCreateContainer} />
      <SessionAlert />
    </Switch>
  )
}

export default ShiroyamaRouter
