import React from 'react'
import { Row, Col, Steps } from 'antd'

import { StatusIndex as DeliverySplipStatusIndex } from 'app/components/organisms/deliverySlip/types'
import { StatusIndex as OrderDetailStatusIndex } from 'app/components/organisms/order/type'
import { WAIT, PROCESS, FINISH } from 'app/constants/antd'

const { Step } = Steps

type Step = typeof WAIT | typeof PROCESS | typeof FINISH
interface WorkflowBarProps {
  currentStatusIndex: number
  numberOfWorkflowTypes: number
  statusList: typeof DeliverySplipStatusIndex | typeof OrderDetailStatusIndex
}
const WorkflowBar = ({ currentStatusIndex, numberOfWorkflowTypes, statusList }: WorkflowBarProps): JSX.Element => {
  const stepStatusList = new Array<Step>(numberOfWorkflowTypes).fill(WAIT).map((step: Step, index: number) => {
    if (index === currentStatusIndex) {
      return PROCESS
    } else if (index < currentStatusIndex) {
      return FINISH
    } else {
      return step
    }
  })
  const stepAttr = stepStatusList.map((step: Step, index: number) => {
    return {
      status: step,
      title: statusList[index],
    }
  })

  return (
    <Row style={{ marginBottom: '30px' }}>
      <Col span={24}>
        <Steps type="navigation" size="small" current={currentStatusIndex}>
          {stepAttr.map((step, index: number) => (
            <Step status={step.status} title={step.title} key={index} />
          ))}
        </Steps>
      </Col>
    </Row>
  )
}

export default WorkflowBar
