import { ofType } from 'redux-observable'
import { switchMap, catchError, concatMap } from 'rxjs/operators'
import { Action } from '@reduxjs/toolkit'
import { of, Observable, EMPTY } from 'rxjs'

import { GroupActions, GroupSelectors, UsersSelectors } from '..'
import HttpService from 'app/services/httpService/httpService'
import { Group, GroupMembers } from './types'
import { AppConstant } from 'app/constants/app'

const initEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(AppConstant.REHYDRATE_ACTION_TYPE),
    concatMap(() => {
      if (UsersSelectors.isUserLogged(state$.value)) {
        const parentGroupId = GroupSelectors.getParentGroupId(state$.value)
        if (parentGroupId.length === 0) {
          return of(GroupActions.getGroupsRequest())
        } else {
          return EMPTY
        }
      } else {
        return EMPTY
      }
    }),
  )

const getGroupsRequestEpic = (action$): Observable<Action<string>> =>
  action$.pipe(
    ofType(GroupActions.getGroupsRequest),
    switchMap(action =>
      HttpService.GetAsync<null, Group>('groups', null, HttpService.LinkerAPIBasePath).pipe(
        switchMap(response => {
          const data = response.data
          return of(GroupActions.getGroupsSuccess(data), GroupActions.getSupplierGroupRequest())
        }),
        catchError((error: string) => {
          return of(GroupActions.getGroupsFaild({ error }))
        }),
      ),
    ),
  )

const getSupplierGroupEpic = (action$, state$): Observable<Action<string>> =>
  action$.pipe(
    ofType(GroupActions.getSupplierGroupRequest),
    switchMap(action => {
      const supplierGroupId = GroupSelectors.getSupplierGroupId(state$.value)

      return HttpService.GetAsync<null, GroupMembers>(
        `users/all/g/${supplierGroupId}`,
        null,
        HttpService.LinkerAPIBasePath,
      ).pipe(
        switchMap(response => {
          const supplierGroup = response.data
          return of(GroupActions.getSupplierGroupSuccess(supplierGroup))
        }),
        catchError((error: string) => {
          return of(GroupActions.getGroupsFaild({ error }))
        }),
      )
    }),
  )

export default [initEpic, getGroupsRequestEpic, getSupplierGroupEpic]
