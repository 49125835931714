import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { rootSelector } from 'app/services/store/rootReducer'
import { datastoreSelector } from 'app/services/store/datastore/reducers'
import { PostFilterDatareportRequest_v1 } from 'app/services/store/dataReport/epic.types'
import { getDatastoreIdFromName } from 'app/utils/store'
import {
  DatastoreActions,
  DataReportActions,
  DataReportSelectors,
  GroupSelectors,
  ItemsSelectors,
  ProjectSelectors,
  UsersSelectors,
} from 'app/services/store'

import { dataReport, hexaLabel, deliverySlipSearchFieldId } from 'app/constants/hexalink'
import { exceptSearchKeys } from 'app/constants/deliverySlip'

import AppLayout from 'app/components/templates/AppLayout'
import DeliverySlipPage from 'app/components/pages/deliverySlip/DeliverySlipPage'
import { PagenationValue } from 'app/components/pages/deliverySlip/types'
import { mergeHeaderSearchCondition } from 'app/utils/components/common'
import { SearchCondition } from 'app/services/store/items/types'

const DeliverySlipContainer = (): JSX.Element => {
  const dispatch = useDispatch()
  const { datastores_list } = useSelector(datastoreSelector)
  const pId = ProjectSelectors.getCurrentProjectId(useSelector(rootSelector))
  const isDataReportLoading = DataReportSelectors.getDataReportIsLoading(useSelector(rootSelector))
  const getTotalCount = DataReportSelectors.getDataReportTotalItems(useSelector(rootSelector))
  const { columns, items } = DataReportSelectors.getDisplayableEntries(useSelector(rootSelector))

  const isShiroyama = UsersSelectors.isShiroyama(useSelector(rootSelector))
  const isSupplier = UsersSelectors.isSupplier(useSelector(rootSelector))
  const getUserId = UsersSelectors.getUserId(useSelector(rootSelector))

  const defaultPerPage = 100
  const conditionsArray = new Array<SearchCondition>()
  const defaultConditions = { conditions: conditionsArray, per_page: defaultPerPage, page: 1, include_item_ref: true }
  const pagination: PagenationValue = { defaultSize: defaultPerPage, totalCount: Number(getTotalCount) }
  const statusField = ItemsSelectors.getStatusField(useSelector(rootSelector))
  const supplierMembers = GroupSelectors.getSupplierGroupMembers(useSelector(rootSelector))

  useEffect(() => {
    if (pId) {
      if (isSupplier && getUserId != null) {
        const supplierCondition: SearchCondition = {
          id: deliverySlipSearchFieldId.SUPPLIER,
          search_value: [getUserId],
        }
        defaultConditions['conditions'].push(supplierCondition)
      }
      dispatch(
        DataReportActions.getFilteredDataReportRequest({
          pId,
          rId: dataReport.DELIVERY_NOTE_LIST,
          conditions: defaultConditions,
        }),
      )
    }
    if (datastores_list) {
      dispatch(
        DatastoreActions.setCurrentDatastoreId({
          datastoreId: getDatastoreIdFromName(datastores_list, hexaLabel.DELIVERY_SLIP_DB),
        }),
      )
    }
  }, [dispatch, pId, datastores_list])

  const getFieldId = (targetKey: string) => {
    const findRecord = columns.find(column => column.key === targetKey)
    return findRecord === undefined ? '' : findRecord.id
  }

  const sendChangePageHandle = (conditions, currentPage: number, sortFieldId: string, sortOrder: string): void => {
    if (currentPage < 0) return

    const param: PostFilterDatareportRequest_v1 = { conditions: conditions }
    param['per_page'] = defaultPerPage
    param['page'] = currentPage
    param['include_item_ref'] = true

    if (sortFieldId != '' && sortOrder != '') {
      param['sort_field_id'] = sortFieldId
      param['sort_order'] = sortOrder
    }

    // ステータス検索
    if (statusField !== undefined && statusField.statuses.length > 0) {
      param.conditions = [
        ...mergeHeaderSearchCondition(
          param.conditions,
          getFieldId(exceptSearchKeys.statusKey),
          statusField.statuses,
          'name',
          'id',
        ),
      ]
    }

    // サプライヤー検索 - 白山アカウントの場合
    if (isShiroyama) {
      param.conditions = [
        ...mergeHeaderSearchCondition(
          param.conditions,
          deliverySlipSearchFieldId.SUPPLIER,
          supplierMembers.members,
          'username',
          'u_id',
        ),
      ]
    }

    if (isSupplier && getUserId != null) {
      const supplierCondition: SearchCondition = {
        id: deliverySlipSearchFieldId.SUPPLIER,
        search_value: [getUserId],
      }
      const condition: Array<SearchCondition> = []
      condition.push(supplierCondition)
      param['conditions'] = [...param['conditions'], ...condition]
    }

    dispatch(
      DataReportActions.getFilteredDataReportRequest({
        pId,
        rId: dataReport.DELIVERY_NOTE_LIST,
        conditions: param,
      }),
    )
  }

  return (
    <AppLayout>
      <DeliverySlipPage
        sendChangePageHandle={sendChangePageHandle}
        isDataReportLoading={isDataReportLoading}
        items={items}
        columns={columns}
        pagination={pagination}
        isSupplier={isSupplier}
      />
    </AppLayout>
  )
}

export default DeliverySlipContainer
