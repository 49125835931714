export const routePath = {
  FAVORITE: '/',
  INVENTORY_LIST: '/inventory',
  INVENTORY_DETAIL: '/inventory/detail',
  INVENTORY_HISTORY: '/inventory/history',
  INVENTORY_CREATE: '/inventory/create',
  ORDER_LIST: '/order',
  ORDER_DETAIL: '/order/detail',
  PORTAL: '/portal',
  PAST_ORDER: '/past-order',
  PAST_ORDER_DETAIL: '/past-order/detail',
  DELIVERY_SLIP: '/delivery-slip',
  DELIVERY_SLIP_DETAIL: '/delivery-slip/detail',
  DELIVERY_SLIP_CREATE: '/delivery-slip/create',
  SUPPLIER: '/supplier',
  SUPPLIER_DETAIL: '/supplier/detail',
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  CHANGE_PASSWORD: '/reset_password/:id',
}
