import React, { useState, useCallback } from 'react'

const useTextInput = (init = ''): [string, (e: React.ChangeEvent<HTMLInputElement>) => void] => {
  const [value, setValue] = useState(init)
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value)
    },
    [setValue],
  )
  return [value, handleChange]
}

export default useTextInput
