import React, { useContext, useState, useEffect, useRef } from 'react'
import { FormComponentProps } from 'antd/lib/form/Form'
import { Row, Col, Table, DatePicker, Input, Button, Form, notification, InputNumber, Spin, Select } from 'antd'
import { createDateFormattedItems, createFilterableAndSortableColumns } from 'app/utils/components/common'
import { filterListColumns, sortOrderColumns } from 'app/utils/components/order'
import { Columns } from 'app/services/store/items/types'
import PropTypes from 'prop-types'
import { CommentData, itemStatusList, autoNumberItem, dataStoreItemField } from 'app/services/store/itemDetails/types'
import { NUMBER_OF_STATUS_TYPES, formKeys, destinationData, selectSendType, status } from 'app/constants/deliverySlip'
import moment from 'moment'

import { maxLengthMessage, errorMessages } from 'app/constants/deliverySlip'
import { strCount, replaceMessage } from 'app/utils/components/common'

const { Item } = Form
const { Option } = Select
const dateFormat = 'YYYY-MM-DD'

const EditableContext = React.createContext(null)
const EditableRow = ({ ...props }) => (
  <EditableContext.Provider value={null}>
    <tr {...props} />
  </EditableContext.Provider>
)
const EditableFormRow = Form.create()(EditableRow)

/** 納品書作成　列情報 */
const deliverySlipCreateColumns = [
  { dataIndex: 'ID', key: 'ID', title: '在庫ID', editable: false, editing: false, cellType: false, inputWidth: '10%' },
  {
    dataIndex: '物品名',
    key: '物品名',
    title: '在庫名',
    editable: true,
    editing: true,
    cellType: false,
    inputWidth: '60%',
  },
  {
    dataIndex: '発注個数(カンマ区切り用)',
    key: '発注個数(カンマ区切り用)',
    title: '発注個数',
    editable: false,
    editing: false,
    cellType: false,
    inputWidth: '15%',
  },
  { dataIndex: '単位', key: '単位', title: '単位', editable: true, editing: true, cellType: true, inputWidth: '15%' },
]

interface EditableCellProps {
  title: React.ReactNode
  editable: boolean
  children: React.ReactNode
  dataIndex: string
  record: {}
  handleSave: (record: {}) => void
  cellType: boolean
  editing: boolean
  inputWidth: string
  form: any
}
const EditableCell: React.FC<EditableCellProps> = ({
  editable,
  editing,
  dataIndex,
  title,
  cellType,
  record,
  children,
  inputWidth,
  handleSave,
  form,
  ...restProps
}) => {
  const [editing2, setEditing] = useState(false)
  const inputRef = useRef<any>()
  const { getFieldDecorator } = form

  useEffect(() => {
    if (editing2) {
      inputRef.current.focus()
    }
  }, [editing2])

  const toggleEdit = () => {
    setEditing(!editing2)
  }

  const saveRemarks = async e => {
    toggleEdit()
    const saveCellData = { [dataIndex]: inputRef.current.state.value }
    handleSave({ ...record, ...saveCellData })
  }

  const saveÚnit = async e => {
    toggleEdit()
    const saveCellData = { [dataIndex]: inputRef.current.state.value }
    handleSave({ ...record, ...saveCellData })
  }

  return (
    <td {...restProps} style={{ paddingTop: '0px', paddingBottom: '0px', marginBottom: '0px', width: inputWidth }}>
      {editing ? (
        !cellType ? (
          <Form.Item>
            <Col>{record[dataIndex]}</Col>
            {getFieldDecorator('Remarks' + record['i_id'], {
              initialValue: '',
              rules: [
                {
                  validator: (rule: any, value: string, callback: (msg?: string) => void) => {
                    const limit = 34
                    strCount(value) > limit
                      ? callback(replaceMessage(maxLengthMessage, '備考', String(limit / 2)))
                      : callback()
                  },
                },
              ],
            })(<Input ref={inputRef} onPressEnter={saveRemarks} onBlur={saveRemarks} />)}
          </Form.Item>
        ) : (
          <Form.Item>
            {getFieldDecorator('unit' + record['i_id'], {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: '単位の入力は必須です。',
                },
                {
                  validator: (rule: any, value: string, callback: (msg?: string) => void) => {
                    const limit = 6
                    strCount(value) > limit
                      ? callback(replaceMessage(maxLengthMessage, '単位', String(limit / 2)))
                      : callback()
                  },
                },
              ],
            })(<Input ref={inputRef} onPressEnter={saveÚnit} onBlur={saveÚnit} maxLength={6} />)}
          </Form.Item>
        )
      ) : (
        children
      )}
    </td>
  )
}

EditableCell.propTypes = {
  title: PropTypes.node,
  editable: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  children: PropTypes.node,
  dataIndex: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  cellType: PropTypes.bool.isRequired,
  inputWidth: PropTypes.string.isRequired,
  form: PropTypes.any.isRequired,
}

interface InnerDeliverySlipCreateProps extends FormComponentProps {
  deliverySlipCreateResource: {
    getAutoNumber: (field_Id: string, supplier_Id: string) => void
    itemsLoading: boolean
    isLoading: boolean
    selectedCheckBoxDeliveryItemRowKeys: object
    selectedCheckBoxDeliveryItemRows: Columns
    submit: (form) => void
    items: object
    columns: Columns
    autoNumberItem: autoNumberItem
    dataStoreItemFields: dataStoreItemField
  }
}

const InnerDeliverySlipCreatePage = ({
  deliverySlipCreateResource: {
    getAutoNumber,
    itemsLoading,
    isLoading,
    selectedCheckBoxDeliveryItemRowKeys,
    selectedCheckBoxDeliveryItemRows,
    submit,
    items,
    columns,
    autoNumberItem,
    dataStoreItemFields,
  },
  form,
}: //  const InventoryHistoryPage = ({ tableColumns, tableRows, saveHandle, deleteHandle }: any): JSX.Element => {
//}: InnerDeliverySlipCreateProps): JSX.Element => {
any): JSX.Element => {
  const [dataSource, setDataSource] = useState(selectedCheckBoxDeliveryItemRows)
  const { getFieldDecorator } = form

  const handleSaveRow = (row): void => {
    const newData = [...dataSource]
    const index = newData.findIndex(item => row.key === item.key)
    const item = newData[index]
    newData.splice(index, 1, {
      ...item,
      ...row,
    })
    setDataSource(newData)
  }

  const mergedColumns = deliverySlipCreateColumns.map(col => {
    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: col.editing,
        cellType: col.cellType,
        inputWidth: col.inputWidth,
        handleSave: handleSaveRow,
        form: form,
      }),
    }
  })

  /** 発注個数をカンマ区切り */
  selectedCheckBoxDeliveryItemRows.forEach(function(value) {
    const result = items.filter(function(items_value) {
      return items_value['i_id'] == value.i_id
    })
    const num = Number(value['発注個数'])
    value['発注個数(カンマ区切り用)'] = num.toLocaleString()
  })

  const selectSendType = {
    /** 白山工業本社工場 */
    HEADOFFICEFACTORY: '白山工業本社工場',
    /** 白山工業茨城第二工場 */
    SECONDOFFICEFACTORY: '白山工業茨城第二工場',
  }

  const selectSendFactoryData = {
    ...destinationData,
  }

  const [selectSend, setSelectSend] = useState('白山工業本社工場')
  const [selectValues, setSelectValues] = useState(Object.values(selectSendType))
  const [selectAddress, setSelectAddress] = useState(selectSendFactoryData['白山工業本社工場'].address)
  const [selectTelephone, setSelectTelephone] = useState(selectSendFactoryData['白山工業本社工場'].telephone)
  const [selectDeliveryDate, setSelectDeliveryDate] = useState()

  const dateHandleChange = date => {
    setSelectDeliveryDate(date)
  }

  const handleChange = event => {
    setSelectSend(event)
    switch (event) {
      case selectSendType.HEADOFFICEFACTORY:
        setSelectAddress(selectSendFactoryData['白山工業本社工場'].address)
        setSelectTelephone(selectSendFactoryData['白山工業本社工場'].telephone)
        break
      case selectSendType.SECONDOFFICEFACTORY:
        setSelectAddress(selectSendFactoryData['白山工業茨城第二工場'].address)
        setSelectTelephone(selectSendFactoryData['白山工業茨城第二工場'].telephone)
        break
      default:
        setSelectAddress('')
        setSelectTelephone('')
        break
    }
  }

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    submit(form)
  }

  const changeTextAddress = event => {
    setSelectAddress(event.target.value)
  }

  const changeTextTelephone = event => {
    setSelectTelephone(event.target.value)
  }

  const inputSendType = inputStr => {
    setSelectValues(prev => {
      const copyDataList = [...prev]
      copyDataList.splice(2, 1, inputStr)
      return copyDataList
    })
  }

  return (
    <Spin size="large" spinning={isLoading}>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <div className="basicInfo">
          <Row style={{ marginBottom: '10px', borderTop: '1px solid lightGray', paddingTop: '30px' }}>
            <Col span={10} style={{ minWidth: '300px' }}>
              <Col>納品書番号</Col>
              <Col span={20}>{autoNumberItem}</Col>
            </Col>
            <Col span={10} style={{ minWidth: '300px' }}>
              <Form.Item label="納品日">
                {getFieldDecorator('納品日', {
                  initialValue: selectDeliveryDate,
                  rules: [
                    {
                      required: true,
                      message: '納品日の選択は必須です。',
                    },
                  ],
                })(<DatePicker onChange={dateHandleChange} format={dateFormat} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={10} style={{ minWidth: '300px' }}>
              <Form.Item label="送付先" key="送付先">
                <Col span={14} style={{ width: '300px' }}>
                  {getFieldDecorator('送付先', {
                    initialValue: selectSendType.HEADOFFICEFACTORY,
                    rules: [
                      {
                        required: true,
                        message: '送付先は必須です。',
                      },
                      {
                        validator: (rule: any, value: string, callback: (msg?: string) => void) => {
                          const limit = 20
                          strCount(value) > limit
                            ? callback(replaceMessage(maxLengthMessage, '送付先', String(limit / 2)))
                            : callback()
                        },
                      },
                    ],
                  })(
                    <Select onChange={handleChange} showSearch={true} onSearch={inputSendType}>
                      {selectValues.map(option => (
                        <Option value={option} key={option}>
                          {option}
                        </Option>
                      ))}
                    </Select>,
                  )}
                  <span style={{ fontSize: '0.8rem', display: 'block' }}>その他の場合は入力してください</span>
                </Col>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={10} style={{ minWidth: '300px' }}>
              <Item label="住所">
                {getFieldDecorator('住所', {
                  initialValue: selectAddress,
                  rules: [
                    {
                      required: true,
                      message: '住所の入力は必須です。',
                    },
                    {
                      validator: (rule: any, value: string, callback: (msg?: string) => void) => {
                        const limit = 60
                        strCount(value) > limit
                          ? callback(replaceMessage(maxLengthMessage, '住所', String(limit / 2)))
                          : callback()
                      },
                    },
                  ],
                })(<Input onChange={changeTextAddress}></Input>)}
              </Item>
            </Col>
            <Col span={10} style={{ minWidth: '300px' }}>
              <Form.Item label="電話番号">
                {getFieldDecorator('電話番号', {
                  initialValue: selectTelephone,
                  rules: [
                    {
                      required: true,
                      message: '電話番号の入力は必須です。',
                    },
                    {
                      max: 13,
                      message: '最大13文字までです。',
                    },
                    {
                      validator: (rule: any, value: string, callback: (msg?: string) => void) => {
                        !/^[0-9-]+$/.test(value) ? callback(errorMessages.TEL) : callback()
                      },
                    },
                  ],
                })(<Input onChange={changeTextTelephone}></Input>)}
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="tableArea">
          <Row>
            <Col style={{ marginBottom: '50px' }}>
              <Table
                components={{
                  body: {
                    row: EditableFormRow,
                    cell: EditableCell,
                  },
                }}
                loading={itemsLoading}
                dataSource={selectedCheckBoxDeliveryItemRows}
                columns={mergedColumns}
                pagination={false}
                tableLayout="initial"
              />
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col>
              <Button htmlType="submit" onClick={handleSubmit}>
                納品書を発行
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Spin>
  )
}

const DeliverySlipCreatePage = Form.create<InnerDeliverySlipCreateProps>()(InnerDeliverySlipCreatePage)
export default DeliverySlipCreatePage
