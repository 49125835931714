import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { rootSelector } from 'app/services/store/rootReducer'
import { UsersActions, UsersSelectors } from 'app/services/store'

import ResetPasswordPage from 'app/components/pages/login/ResetPasswordPage'

const ResetPasswordContainer: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const isLoading = UsersSelectors.isLoading(useSelector(rootSelector))
  const forgotPasswordRequest = React.useCallback(
    (email: string): void => {
      dispatch(UsersActions.forgotPasswordRequest({ email }))
    },
    [dispatch],
  )
  return <ResetPasswordPage isLoading={isLoading} forgotPasswordRequest={forgotPasswordRequest} />
}

export default ResetPasswordContainer
