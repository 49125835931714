import { createEpicMiddleware, combineEpics } from 'redux-observable'
import UserEpics from './user/epic'
import GroupEpics from './group/epic'
import WorkspacesEpics from './workspace/epic'
import ProjectsEpics from './project/epic'
import ItemsEpics from './items/epic'
import DatastoreEpics from './datastore/epic'
import ItemDetailsEpics from './itemDetails/epic'
import DataReportEpics from './dataReport/epic'

export const rootEpic = combineEpics(
  ...UserEpics,
  ...GroupEpics,
  ...WorkspacesEpics,
  ...ProjectsEpics,
  ...DatastoreEpics,
  ...ItemsEpics,
  ...ItemDetailsEpics,
  ...DataReportEpics,
)

const epicMiddleware = createEpicMiddleware()

export default epicMiddleware
